import {Component, Input, Output, OnInit, OnChanges, EventEmitter, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-playlist-collection',
    templateUrl: './playlist-collection.component.html',
    styleUrls: ['./playlist-collection.component.css']
})
export class PlaylistCollectionComponent implements OnChanges {
  @Input() playlist;
  @Input() loading;
  @Input() locale;
  @Output() playOam = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    console.log('value changed', this.playlist);
  }

  selectOam($event){
    this.playOam.emit($event)
  }
}
