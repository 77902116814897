import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SkillService {
    constructor(private http: HttpClient) {}

    private baseUrl = environment.apiurl + 'api/v1/';

    getAllSkills(skip, limit): Observable<any> {
        let url = this.baseUrl + 'skills';
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            skip: skip.toString(),
            limit: limit.toString()
          })
        };
        return this.http.get(url,httpOptions).pipe(map((res:Response) => {
          let result = [];
          result.push(res);
          console.log("result>>",result)
          return result;
        }));
    }

    getWeeklySkills(): Observable<any> {
      let url = this.baseUrl + 'skills/weekly';
      return this.http.get(url).pipe(map((res:Response) => {
        // res["skills"].push(this.tempData);
        // res["skills"].push(this.tempData);
        // res["skills"].push(this.tempData);
        let result = [];
        result.push(res)
        console.log('new siills ',result);
        return result;
      }));
    }
}
