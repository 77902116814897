import {Inject, Component, OnInit} from '@angular/core';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';

import {AuthService} from '../../../lib/auth.service';
import {SharedService} from '../../../lib/shared-service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
    selector: 'app-redirect-home',
    templateUrl: './redirect-home-controller.component.html',
    styleUrls: ['./redirect-home-controller.component.css']
})
export class RedirectHomeControllerComponent implements OnInit {

    constructor(
        private _authService: AuthService,
        private _sharedService: SharedService,
        private router: Router,
        private route: ActivatedRoute,
        private _localeService: LocaleService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
    }

    public locale:any;

    ngOnInit() {
      // this.router.navigate(['/redirect'], {queryParams: {username: 'test'}})
      this.route.queryParams.subscribe(params => {
        console.log('params', params)
        var decodeUserId = this.decrypt(decodeURIComponent(params.userid), environment.secretkey);
        var decodeUsername = this.decrypt(decodeURIComponent(params.username), environment.secretkey);
        var decodeToken = this.decrypt(decodeURIComponent(params.token), environment.secretkey);
        var decodeapiKey = this.decrypt(decodeURIComponent(params.apikey), environment.secretkey);
        var decodePlayerId = this.decrypt(decodeURIComponent(params.playerid), environment.secretkey);
        var redirectPath = params.path;
        var redirectTo = params.to;
        var sessionId = params.sessionId;
        if(decodeUsername && decodeUserId && decodeToken && decodeapiKey) {
          const userObj = {
            username: decodeUsername,
            password: decodeToken,
            apikey: decodeapiKey,
            userid: decodeUserId,
            playerid: decodePlayerId
          }
          if(params.usertype != undefined || params.usertype != null){
            var decodeUsertype = this.decrypt(decodeURIComponent(params.usertype), environment.secretkey);
            userObj['usertype'] = decodeUsertype;
          }
          if(params.orgname != undefined || params.orgname != null){
            var decodeOrgName = this.decrypt(decodeURIComponent(params.orgname), environment.secretkey);
            userObj['orgname'] = decodeOrgName;
          }
          if(params.pa != undefined || params.pa != null){
            var decodePermittedApps = this.decrypt(decodeURIComponent(params.pa), environment.secretkey);
            userObj['permittedApps'] = decodePermittedApps
          }
          if(params.sessionId != undefined || params.sessionId != null){
            userObj['sessionId'] = sessionId;
          }
          let encodedData = this.encrypt(JSON.stringify(userObj), environment.secretkey);
          this.saveData('userInfo', encodedData);

          var _this = this;
          var redirectInterval = setInterval(redirectUrl, 1000);
          function redirectUrl() {
            var getUser = localStorage.getItem('userInfo');
            if(getUser != null || getUser != undefined){
              clearInterval(redirectInterval);
              if(redirectTo != null || redirectTo != undefined){
                _this.router.navigate(['/' + redirectTo],{ replaceUrl: true })
              } else {
                if(redirectPath != null || redirectPath != undefined){
                  _this.router.navigate(['/' + redirectPath],{ replaceUrl: true })
                }else{
                  _this.router.navigate(['/'],{ replaceUrl: true });
                }
              }
            }
          }
        }
      });
      this.getJson();
    }

    getJson(){
      this._localeService.getJson().subscribe(data => {
        console.log("searchResult###############",data)
        this.locale = data;
      })
    }

    encrypt(message: string, secretkey: string) {
        let encrypted = this._authService.encrypt(message, secretkey);
        return encrypted;
    }

    decrypt(encrypted: string, secretkey: string) {
        let decrypted = this._authService.decrypt(encrypted, secretkey);
        return decrypted;
    }

    saveData(key, value) {
      console.log('key ..... ', key);
      this.business.saveData(key, value)
    }
}
