import {Component,Input,Output,EventEmitter,OnChanges} from '@angular/core';

@Component({
    selector: 'app-related-playlist-collection',
    templateUrl: './related-playlist-collection.component.html',
    styleUrls: ['./related-playlist-collection.component.css']
})
export class RelatedPlaylistCollectionComponent implements OnChanges {
  @Input() relatedLearningFlow;
  @Input() loading;
  @Input() locale;
  @Output() onClickPlaylist = new EventEmitter<any>();

  ngOnChanges(){
    console.log("relatedLearningFlow",this.relatedLearningFlow)
    console.log("loading lf---",this.loading)
  }

  onClickLearningFlow($event){
    this.onClickPlaylist.emit($event)
  }
}
