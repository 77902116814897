import {Component,Input,Output,EventEmitter,OnChanges} from '@angular/core';

@Component({
    selector: 'app-related-skills-collection',
    templateUrl: './related-skills-collection.component.html',
    styleUrls: ['./related-skills-collection.component.css']
})
export class RelatedSkillsCollectionComponent implements OnChanges {
  @Input() relatedSkills;
  @Input() locale;
  @Output() onClickRelatedSkill = new EventEmitter<any>();

  ngOnChanges(){
    console.log("relatedSkills in skills details",this.relatedSkills)
  }

  onSelectRelatedSkill($event){
    this.onClickRelatedSkill.emit($event);
  }
}
