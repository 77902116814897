/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./teacher-profile-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared-components/loader/loader.component.ngfactory";
import * as i3 from "../../../shared-components/loader/loader.component";
import * as i4 from "../teacher-profile/teacher-profile.component.ngfactory";
import * as i5 from "../teacher-profile/teacher-profile.component";
import * as i6 from "@angular/common";
import * as i7 from "./teacher-profile-list.component";
var styles_TeacherProfileListComponent = [i0.styles];
var RenderType_TeacherProfileListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TeacherProfileListComponent, data: {} });
export { RenderType_TeacherProfileListComponent as RenderType_TeacherProfileListComponent };
function View_TeacherProfileListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(3, 49152, null, 0, i3.LoaderComponent, [], { width: [0, "width"], height: [1, "height"], spinColor: [2, "spinColor"], spinTopColor: [3, "spinTopColor"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(6, 49152, null, 0, i3.LoaderComponent, [], { width: [0, "width"], height: [1, "height"], spinColor: [2, "spinColor"], spinTopColor: [3, "spinTopColor"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(9, 49152, null, 0, i3.LoaderComponent, [], { width: [0, "width"], height: [1, "height"], spinColor: [2, "spinColor"], spinTopColor: [3, "spinTopColor"] }, null)], function (_ck, _v) { var currVal_0 = "80%"; var currVal_1 = "80px"; var currVal_2 = "#FBF7EC"; var currVal_3 = "#FFD918"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "80%"; var currVal_5 = "80px"; var currVal_6 = "#FBF7EC"; var currVal_7 = "#FFD918"; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = "80%"; var currVal_9 = "80px"; var currVal_10 = "#FBF7EC"; var currVal_11 = "#FFD918"; _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10, currVal_11); }, null); }
function View_TeacherProfileListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "profile-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-teacher-profile", [], null, [[null, "clickProfile"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickProfile" === en)) {
        var pd_0 = (_co.onClickTeacherProfile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TeacherProfileComponent_0, i4.RenderType_TeacherProfileComponent)), i1.ɵdid(2, 49152, null, 0, i5.TeacherProfileComponent, [], { teacherProfile: [0, "teacherProfile"] }, { clickProfile: "clickProfile" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TeacherProfileListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "d-flex align-items-start profile-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeacherProfileListComponent_3)), i1.ɵdid(3, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileList; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TeacherProfileListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "bg-s0 profile-list-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "text-secondary700 h5-strong text-center text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeacherProfileListComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeacherProfileListComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.isLoading; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.locale == null) ? null : _co.locale.liveclasses.teacherProfileTitle); _ck(_v, 2, 0, currVal_0); }); }
export function View_TeacherProfileListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-teacher-profile-list", [], null, null, null, View_TeacherProfileListComponent_0, RenderType_TeacherProfileListComponent)), i1.ɵdid(1, 573440, null, 0, i7.TeacherProfileListComponent, [], null, null)], null, null); }
var TeacherProfileListComponentNgFactory = i1.ɵccf("app-teacher-profile-list", i7.TeacherProfileListComponent, View_TeacherProfileListComponent_Host_0, { profileList: "profileList", locale: "locale", isLoading: "isLoading" }, { onClickProfile: "onClickProfile" }, []);
export { TeacherProfileListComponentNgFactory as TeacherProfileListComponentNgFactory };
