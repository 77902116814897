/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-notification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../text/text.component.ngfactory";
import * as i3 from "../text/text.component";
import * as i4 from "../image/image.component.ngfactory";
import * as i5 from "../image/image.component";
import * as i6 from "@angular/common";
import * as i7 from "../button/button.component.ngfactory";
import * as i8 from "../button/button.component";
import * as i9 from "./custom-notification.component";
var styles_CustomNotificationComponent = [i0.styles];
var RenderType_CustomNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomNotificationComponent, data: {} });
export { RenderType_CustomNotificationComponent as RenderType_CustomNotificationComponent };
function View_CustomNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [["class", "body-l text-s0"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = "body-l text-s0"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_v.context.$implicit == "username") ? _co.user.username : ((_v.context.$implicit == "lesson") ? _co.lesson : _v.context.$implicit)); _ck(_v, 2, 0, currVal_1); }); }
function View_CustomNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "bg-g100 noti-wrapper"], ["id", "success-noti"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "icon-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-image", [], null, null, null, i4.View_ImageComponent_0, i4.RenderType_ImageComponent)), i1.ɵdid(4, 114688, null, 0, i5.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-text", [["class", "text-s0 noti-header toast-message"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(7, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(8, 0, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "m-l-36"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "pt-1 pb-2 work-break"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomNotificationComponent_2)), i1.ɵdid(12, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "app-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onDismissBtnClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ButtonComponent_0, i7.RenderType_ButtonComponent)), i1.ɵdid(14, 49152, null, 0, i8.ButtonComponent, [], { class: [0, "class"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "app-text", [["class", "text-s0 h5-strong"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(16, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(17, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "./assets/image/tick_s.svg"; var currVal_1 = "100%"; var currVal_2 = "100%"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "text-s0 noti-header toast-message"; _ck(_v, 7, 0, currVal_3); var currVal_5 = _co.locale.customNoti.successEnrollMsg; _ck(_v, 12, 0, currVal_5); var currVal_6 = "success-noti-btn"; _ck(_v, 14, 0, currVal_6); var currVal_7 = "text-s0 h5-strong"; _ck(_v, 16, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = ((_co.locale == null) ? null : _co.locale.customNoti.successEnrollTitle); _ck(_v, 8, 0, currVal_4); var currVal_8 = ((_co.locale == null) ? null : _co.locale.customNoti.dismiss); _ck(_v, 17, 0, currVal_8); }); }
export function View_CustomNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomNotificationComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.locale && _co.user); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-notification", [], null, null, null, View_CustomNotificationComponent_0, RenderType_CustomNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i9.CustomNotificationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomNotificationComponentNgFactory = i1.ɵccf("app-custom-notification", i9.CustomNotificationComponent, View_CustomNotificationComponent_Host_0, { user: "user", lesson: "lesson", locale: "locale" }, { onClickDismiss: "onClickDismiss" }, []);
export { CustomNotificationComponentNgFactory as CustomNotificationComponentNgFactory };
