import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LearningFlowService } from './api-services/learning-flow.service';
import { SkillService } from './api-services/skill.service';
import { TopicService } from './api-services/topic.service';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
        private learningFlowService: LearningFlowService,
        private skillService: SkillService,
        private topicService: TopicService
    ) {}
    getTopicDetail(topicId): Observable<any> {
        return this.topicService.getTopic(topicId);
    }

    getAllSkills(id, skip, limit): Observable<any>{
        return this.skillService.getSkills(id, skip, limit);
    }

    getAllLearningFlow(id, skip, limit): Observable<any> {
        return this.learningFlowService.getLearningFlow(id, skip, limit);
    }

}
