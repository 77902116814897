import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/skills-details.mapper";
import * as i2 from "../data.requirements";
export class SkillsDetailsUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(skillId, playlistId) {
        return this.data.getSkillsDetails(skillId, playlistId).pipe(this.mapper.map);
    }
}
SkillsDetailsUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SkillsDetailsUseCase_Factory() { return new SkillsDetailsUseCase(i0.ɵɵinject(i1.SkillsDetailsMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: SkillsDetailsUseCase, providedIn: "root" });
