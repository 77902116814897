import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import { from, Observable } from 'rxjs';

import {DataRequirementsFake} from './data.requirements.fake';
import {GetLiveClassesUseCase} from './use-cases/get-live-classes.use-case';
import {EnrollLiveClassUseCase} from './use-cases/enroll-live-class.use-case';
import {RemoveLocalstorageUseCase} from './use-cases/remove-localstorage.use-case';
import {TeacherListUseCase} from './use-cases/teacher-list.use-case';
import {TeacherProfileUseCase} from './use-cases/teacher-profile.use-case';
import {LiveClassMapper} from './mappers/live-class.mapper';
import {MessageMapper} from './mappers/message.mapper';
import {TeacherListMapper} from './mappers/teacher-list.mapper';
import {TeacherProfileMapper} from './mappers/teacher-profile.mapper';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
            // useClass: DataRequirementsFake
        },
        LiveClassMapper,
        MessageMapper,
        TeacherListMapper,
        TeacherProfileMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
        private getLiveClassesUC: GetLiveClassesUseCase,
        private enrollLiveClassUC: EnrollLiveClassUseCase,
        private removeLocalstorageUC: RemoveLocalstorageUseCase,
        private teacherListUC: TeacherListUseCase,
        private teacherProfileUC: TeacherProfileUseCase
    ) {}

    getLiveClasses():Observable<any> {
        return this.getLiveClassesUC.run();
    }

    enrollLiveClass(id):Observable<any> {
      return this.enrollLiveClassUC.run(id);
    }

    removeLocalStorage(key){
      return this.removeLocalstorageUC.run(key);
    }

    getTeacherList():Observable<any>{
      return this.teacherListUC.run();
    }

    getTeacherProfile(teacherId):Observable<any>{
      return this.teacherProfileUC.run(teacherId);
    }
}
