import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {TopicHomeControllerComponent} from './topic-home-controller.component';
import {RouterModule} from '@angular/router';

import {TopicListComponent} from './components/topic-list/topic-list.component';
import {SkillListComponent} from './components/skill-list/skill-list.component';

@NgModule({
    declarations: [
        TopicHomeControllerComponent,
        TopicListComponent,
        SkillListComponent
    ],
    exports: [
        TopicHomeControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class TopicHomeModule {}
