import { TopicdetailModel } from '../../../models/topicdetail.model';
import {ItemMapper} from '../../../lib/item-mapper';
import { LearningFlowModel } from '../../../models/learning-flow.model';

export class TopicMapper extends ItemMapper<TopicdetailModel> {
    protected mapFromItem(item): TopicdetailModel {
        console.log("item in topic mapper>>",item)
        const topicModel= new TopicdetailModel()
        topicModel.id = item.urlPath;
        topicModel.uniId = item.uniqueID;
        topicModel.title = item.name;
        topicModel.url = item.bannerImage;
        topicModel.description = item.about;
        // let result = item.about.match( /[^\.!\?]+[\.!\?]+/g );
        // topicModel.description = result == null? item.about : result[0];
        topicModel.playlist = item.countInfo.learningFlows;
        topicModel.skill = item.countInfo.skills;
        topicModel.author = item.author;
        let defaultColor = {
          "background": "#030D17",
          "text": "#FFFFFF"
        };
        topicModel.color = item.color != undefined && item.color != null ? item.color : defaultColor;
        return topicModel;
    }
}

