/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./oam-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../language-switch-button/language-switch-button.component.ngfactory";
import * as i3 from "../language-switch-button/language-switch-button.component";
import * as i4 from "@angular/common";
import * as i5 from "../breadcrump-nav/breadcrump-nav.component.ngfactory";
import * as i6 from "../breadcrump-nav/breadcrump-nav.component";
import * as i7 from "@angular/router";
import * as i8 from "./oam-view.component";
import * as i9 from "@angular/platform-browser";
var styles_OamViewComponent = [i0.styles];
var RenderType_OamViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OamViewComponent, data: {} });
export { RenderType_OamViewComponent as RenderType_OamViewComponent };
function View_OamViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "item-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-language-switch-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LanguageSwitchButtonComponent_0, i2.RenderType_LanguageSwitchButtonComponent)), i1.ɵdid(2, 573440, null, 0, i3.LanguageSwitchButtonComponent, [], { localeName: [0, "localeName"], switchButtons: [1, "switchButtons"] }, { onClick: "onClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.localeName; var currVal_1 = _co.switchButtons; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_OamViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["allowfullscreen", "true"], ["class", "oam-iframe"], ["frameborder", "0"], ["scrolling", "no"], ["width", "100%"]], [[8, "id", 0], [8, "height", 0], [8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.oamData == null) ? null : _co.oamData.urlPath), ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.oam_iframe.height, ""); var currVal_2 = _co.safeUrl; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_OamViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "bg-main100 default-oam-view"]], null, null, null, null, null))], null, null); }
export function View_OamViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "oam-nav-container": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(7, { "item-left": 0, "nav-container": 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-breadcrump-nav", [], null, [[null, "onClickNav"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClickNav" === en)) {
        var pd_0 = (_co.goToNav($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_BreadcrumpNavComponent_0, i5.RenderType_BreadcrumpNavComponent)), i1.ɵdid(9, 573440, null, 0, i6.BreadcrumpNavComponent, [i7.Router], { data: [0, "data"], locale: [1, "locale"] }, { onClickNav: "onClickNav" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OamViewComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "oam-view-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OamViewComponent_2)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OamViewComponent_3)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_co.oamData != undefined) && _co.showLanguageSwitchBtn)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, ((_co.oamData != undefined) && _co.showLanguageSwitchBtn), ((_co.oamData == undefined) || !_co.showLanguageSwitchBtn)); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.navData; var currVal_3 = _co.locale; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = ((_co.oamData != undefined) && _co.showLanguageSwitchBtn); _ck(_v, 11, 0, currVal_4); var currVal_5 = (((_co.oamData == null) ? null : _co.oamData.oamUrl) != null); _ck(_v, 14, 0, currVal_5); var currVal_6 = (((_co.oamData == null) ? null : _co.oamData.oamUrl) == null); _ck(_v, 16, 0, currVal_6); }, null); }
export function View_OamViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-oam-view", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).sizeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_OamViewComponent_0, RenderType_OamViewComponent)), i1.ɵdid(1, 573440, null, 0, i8.OamViewComponent, [i9.DomSanitizer], null, null)], null, null); }
var OamViewComponentNgFactory = i1.ɵccf("app-oam-view", i8.OamViewComponent, View_OamViewComponent_Host_0, { oamData: "oamData", locale: "locale", navData: "navData", showLanguageSwitchBtn: "showLanguageSwitchBtn", localeName: "localeName", switchButtons: "switchButtons", oamSrcParams: "oamSrcParams" }, { onClickNavItem: "onClickNavItem", onClickLanguageBtn: "onClickLanguageBtn" }, []);
export { OamViewComponentNgFactory as OamViewComponentNgFactory };
