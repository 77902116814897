import {Inject, Injectable} from '@angular/core';
import { from } from 'rxjs';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {SkillsDetailsMapper} from '../mappers/skills-details.mapper';

@Injectable({
    providedIn: 'root'
})
export class SkillsDetailsUseCase {
    constructor(
      private mapper: SkillsDetailsMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(skillId,playlistId) {
      return this.data.getSkillsDetails(skillId,playlistId).pipe(this.mapper.map)
    }
}
