import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {MessageMapper} from '../mappers/message.mapper';

@Injectable({
    providedIn: 'root'
})
export class EnrollLiveClassUseCase {
    constructor(
        private mapper: MessageMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(classid) {
        return this.data.enrollLiveClass(classid).pipe(this.mapper.map);
    }
}
