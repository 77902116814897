/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./related-playlist-collection.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../../../global-components/text/text.component";
import * as i4 from "@angular/common";
import * as i5 from "../related-playlist/related-playlist.component.ngfactory";
import * as i6 from "../related-playlist/related-playlist.component";
import * as i7 from "../../../shared-components/loading/loading.component.ngfactory";
import * as i8 from "../../../shared-components/loading/loading.component";
import * as i9 from "./related-playlist-collection.component";
var styles_RelatedPlaylistCollectionComponent = [i0.styles];
var RenderType_RelatedPlaylistCollectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RelatedPlaylistCollectionComponent, data: {} });
export { RenderType_RelatedPlaylistCollectionComponent as RenderType_RelatedPlaylistCollectionComponent };
function View_RelatedPlaylistCollectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [["class", "mt-2 mb-2 related-skill-header"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "mt-2 mb-2 related-skill-header"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.locale.playlistDetail.relatedSTEMPath; _ck(_v, 2, 0, currVal_1); }); }
function View_RelatedPlaylistCollectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [["class", "mt-2 mb-2 related-skill-header"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "mt-2 mb-2 related-skill-header"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.locale.playlistDetail.relatedSTEMPaths; _ck(_v, 2, 0, currVal_1); }); }
function View_RelatedPlaylistCollectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "pt-2 pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedPlaylistCollectionComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedPlaylistCollectionComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.relatedLearningFlow.length == 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.relatedLearningFlow.length > 1); _ck(_v, 4, 0, currVal_1); }, null); }
function View_RelatedPlaylistCollectionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-related-playlist", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickLearningFlow(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_RelatedPlaylistComponent_0, i5.RenderType_RelatedPlaylistComponent)), i1.ɵdid(2, 114688, null, 0, i6.RelatedPlaylistComponent, [], { data: [0, "data"], locale: [1, "locale"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.locale; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_RelatedPlaylistCollectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "related-playlist-view"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedPlaylistCollectionComponent_5)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.relatedLearningFlow; _ck(_v, 2, 0, currVal_0); }, null); }
function View_RelatedPlaylistCollectionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [["class", "body-l text-c100"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(2, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "body-l text-c100"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.locale.playlistDetail.noRelatedSTEMPath; _ck(_v, 3, 0, currVal_1); }); }
function View_RelatedPlaylistCollectionComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "pt-2 pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loading", [], null, null, null, i7.View_LoadingComponent_0, i7.RenderType_LoadingComponent)), i1.ɵdid(2, 49152, null, 0, i8.LoadingComponent, [], { width: [0, "width"], height: [1, "height"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "related-playlist-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-loading", [], null, null, null, i7.View_LoadingComponent_0, i7.RenderType_LoadingComponent)), i1.ɵdid(5, 49152, null, 0, i8.LoadingComponent, [], { width: [0, "width"], height: [1, "height"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-loading", [], null, null, null, i7.View_LoadingComponent_0, i7.RenderType_LoadingComponent)), i1.ɵdid(7, 49152, null, 0, i8.LoadingComponent, [], { width: [0, "width"], height: [1, "height"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-loading", [], null, null, null, i7.View_LoadingComponent_0, i7.RenderType_LoadingComponent)), i1.ɵdid(9, 49152, null, 0, i8.LoadingComponent, [], { width: [0, "width"], height: [1, "height"] }, null)], function (_ck, _v) { var currVal_0 = "100%"; var currVal_1 = "20px"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "100%"; var currVal_3 = "200px"; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = "100%"; var currVal_5 = "200px"; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = "100%"; var currVal_7 = "200px"; _ck(_v, 9, 0, currVal_6, currVal_7); }, null); }
export function View_RelatedPlaylistCollectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedPlaylistCollectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedPlaylistCollectionComponent_4)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedPlaylistCollectionComponent_6)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedPlaylistCollectionComponent_7)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.relatedLearningFlow == null) ? null : _co.relatedLearningFlow.length) > 0); _ck(_v, 3, 0, currVal_1); var currVal_2 = (((_co.relatedLearningFlow == null) ? null : _co.relatedLearningFlow.length) == 0); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.loading; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_RelatedPlaylistCollectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-related-playlist-collection", [], null, null, null, View_RelatedPlaylistCollectionComponent_0, RenderType_RelatedPlaylistCollectionComponent)), i1.ɵdid(1, 573440, null, 0, i9.RelatedPlaylistCollectionComponent, [], null, null)], null, null); }
var RelatedPlaylistCollectionComponentNgFactory = i1.ɵccf("app-related-playlist-collection", i9.RelatedPlaylistCollectionComponent, View_RelatedPlaylistCollectionComponent_Host_0, { relatedLearningFlow: "relatedLearningFlow", loading: "loading", locale: "locale" }, { onClickPlaylist: "onClickPlaylist" }, []);
export { RelatedPlaylistCollectionComponentNgFactory as RelatedPlaylistCollectionComponentNgFactory };
