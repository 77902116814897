export class LiveClassModel {
  id: string = 'xxx'
  title: string = "Digestive System S1"
  description: string = 'Importance of the sense of Smell Parts of the Nose'
  startDate: string = '2020-11-25T07:00:00.000Z'
  endDate: string = '2020-11-26T07:00:00.000Z'
  totalSeat: number = 10
  seatTaken: number = 2
  seatLeft: number = 8
  teacherName: string = 'Benjamin Mijares III Benjaminabcdef Benjaminabcdef'
  teacherProfile: string = 'https://pagewerkz-authoring.s3.amazonaws.com/reader/b8edc55399bbf2b6a0c7e92f090a2d3e.png'
  isEnrolled: boolean = true
  background: string = '#D3B3FD'
  note: string = 'Save your spot!'
  button: any = {}
  zoomLink: string = '';
  zoomRoomID:string = '';
  zoomRoomPasscode:string = '';
}
