import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {RouterModule} from '@angular/router';

import {SkillsDetailsControllerComponent} from './skills-details-controller.component';
import {RelatedSkillsCollectionComponent} from './components/related-skills-collection/related-skills-collection.component';
import {UpnextSkillsCollectionComponent} from './components/upnext-skills-collection/upnext-skills-collection.component';

@NgModule({
    declarations: [
        SkillsDetailsControllerComponent,
        RelatedSkillsCollectionComponent,
        UpnextSkillsCollectionComponent
    ],
    exports: [
        SkillsDetailsControllerComponent,
        RelatedSkillsCollectionComponent,
        UpnextSkillsCollectionComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class SkillsDetailsModule {}
