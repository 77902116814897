import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../lib/auth.service";
export class SkillService {
    constructor(http, _authService) {
        this.http = http;
        this._authService = _authService;
        this.baseUrl = environment.apiurl + 'api/v1/';
        this.gameurl = environment.stemgameapiurl + 'api/v2/';
    }
    getSkillDetails(skillId) {
        this.userInfo = this._authService.getUserInfo();
        console.log("getSkillDetails API", skillId);
        let url = this.baseUrl + 'skills/' + skillId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                api_key: this.userInfo.apikey,
                username: this.userInfo.username,
                password: this.userInfo.password,
                option: 'urlPath'
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            console.log("getSkillDetails API res~~~", res);
            let result = [];
            result.push(res);
            return result;
        }));
    }
    userActivity(data) {
        this.userInfo = this._authService.getUserInfo();
        data["userID"] = this.userInfo.userid;
        // let url = this.gameurl + 'userActivity';
        let url = this.gameurl + 'resource-usage';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                api_key: this.userInfo.apikey,
                username: this.userInfo.username,
                password: this.userInfo.password
            })
        };
        return this.http.post(url, data, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
    getTopicDetails(topicId) {
        this.userInfo = this._authService.getUserInfo();
        console.log("getTopicDetails API", topicId);
        let url = this.baseUrl + 'topics/' + topicId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                api_key: this.userInfo.apikey,
                username: this.userInfo.username,
                password: this.userInfo.password,
                option: 'urlPath'
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            console.log("getTopicDetails API res~~~", res);
            let result = [];
            result.push(res);
            return result;
        }));
    }
    updateLocale(data) {
        this.userInfo = this._authService.getUserInfo();
        let url = this.gameurl + 'users/' + this.userInfo.userid;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                api_key: this.userInfo.apikey,
                username: this.userInfo.username,
                password: this.userInfo.password
            })
        };
        return this.http.put(url, data, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
}
SkillService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SkillService_Factory() { return new SkillService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: SkillService, providedIn: "root" });
