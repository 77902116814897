import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-all-skill-wrapper',
    templateUrl: './all-skill-wrapper.component.html',
    styleUrls: ['./all-skill-wrapper.component.css']
})
export class AllSKillWrapperComponent {
  @Input() skillList;
  @Input() totalSkill;
  @Input() loading;
  @Input() empty;
  @Input() locale;

  @Output() onClick = new EventEmitter<any>();
  @Output() onClickLoadMore = new EventEmitter<any>();


  onSkillClick($event) {
    this.onClick.emit($event);
  }

  onLoadMore($event) {
    this.onClickLoadMore.emit($event);
  }
}
