import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {newSkillMapper} from '../mappers/newSkill.mapper';


@Injectable({
    providedIn: 'root'
})
export class GetWeeklySkillsUseCase {
    constructor(
        private mapper: newSkillMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
        return this.data.getNewSkills().pipe(this.mapper.map);
    }
}
