<div class="h1-strong text-c300 mb-4">{{ teacherProfile?.name }}</div>
<div class="bg-s0 info-wrapper mb-4">
  <div>
    <app-text class="text-secondary700 h5-strong text-uppercase">About</app-text>
  </div>
  <div class="d-flex">
    <div class="pr-2">
      <app-image [src]="'./assets/image/location-icon.svg'" [width]="'14px'" [height]="'18px'"></app-image>
    </div>
    <div>
      <app-text class="text-c300 body-l">{{ teacherProfile?.location }}</app-text>
    </div>
  </div>
  <div class="d-flex">
    <div class="icon-wrap pr-2">
      <app-image [src]="'./assets/image/position-icon.svg'" [width]="'100%'" [height]="'100%'"></app-image>
    </div>
    <div>
      <app-text class="text-c300 body-l">{{ teacherProfile?.position }}</app-text>
    </div>
  </div>
</div>
<div class="bg-s0 info-wrapper">
  <div>
    <app-text class="text-secondary700 h5-strong text-uppercase">Bio</app-text>
  </div>
  <div>
    <app-text class="text-c300 light-body-l">{{ teacherProfile?.bio }}</app-text>
  </div>
</div>
