import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import { environment } from 'src/environments/environment';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {AuthService} from '../../../lib/auth.service';

@Injectable({
    providedIn: 'root'
})
export class SkillsDetailsService {
    public userInfo;
    private baseurl = environment.apiurl+'api/v1/';
    public gameurl = environment.stemgameapiurl+'api/v2/';

    constructor(private http: HttpClient,private _authService:AuthService) {}

    getSkillDetails(skillId,playlistId){
      this.userInfo = this._authService.getUserInfo();
      console.log("getSkillDetails API",skillId,playlistId)
      let url = this.baseurl + 'skills/' + skillId + '?learningFlowId=' + playlistId;
      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            api_key: this.userInfo.apikey,
            username: this.userInfo.username,
            password: this.userInfo.password,
            option: 'urlPath'
        })
      };
      return this.http.get(url,httpOptions).pipe(map((res:Response)=>{
        console.log("getSkillDetails API res~~~",res)
        let result = [];
        result.push(res);
        return result;
      }));
    }

    updateLocale(data){
      this.userInfo = this._authService.getUserInfo();
      let url = this.gameurl + 'users/' + this.userInfo.userid;
      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            api_key: this.userInfo.apikey,
            username: this.userInfo.username,
            password: this.userInfo.password
        })
      };
      return this.http.put(url,data,httpOptions).pipe(map((res:Response)=>{
        let result = [];
        result.push(res);
        return result;
      }))
    }
}
