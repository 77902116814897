import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SharedService } from './lib/shared-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'stemwerkz-channel';

  constructor(private _sharedService: SharedService){

  }

  ngOnInit(){
    this.checkUserAgent();
  }

  checkUserAgent(){
    const userAgent = window.navigator.userAgent;
    // const userAgent = 'Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) STEMWerkz/1.0(com.werkzpublishing.stemwerkz.ios.store.kids; build:1; iOS 14.2)';
    // const userAgent = 'Mozilla/5.0 (Linux; Android 10; MI MAX 3 Build/QKQ1.190910.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/87.0.4280.101 Mobile Safari/537.36 STEMWerkz/1.0.0(com.werkzpublishing.stemwerkz.android.store.kids; build:1; Android 10)';
    console.log("useragent",userAgent);
    const browser = this.getBrowser();
    const getMobileOS = this.getMobileOperatingSystem(userAgent);
    console.log("getMobileOS",getMobileOS)
    const isApp = (userAgent.includes('iOS') && userAgent.includes('com.werkzpublishing.stemwerkz.ios.store.kids')) || (userAgent.includes('Android') && userAgent.includes('com.werkzpublishing.stemwerkz.android.store.kids')) ? true : false ;
    const showMenu = !isApp ? true : this.showHideMenu(userAgent);
    console.log("isApp",isApp,"showMenu",showMenu);
    this._sharedService.defineApp({isMobileApp: isApp,showMenu: showMenu,mobileOS: getMobileOS, browser: browser });
  }

  getMobileOperatingSystem(userAgent){
    let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
    if( isIpad == true || userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match(/iPod/i) )
    {
      return 'iOS';
    }
    else if( userAgent.match( /Android/i ) )
    {
      return 'Android';
    }
    else
    {
      return 'Unknown';
    }
  }

  getBrowser(){
    let browser;
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 )
    {
        console.log('Opera');
        browser = 'Opera';
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 )
    {
        console.log('Chrome');
        browser = 'Chrome';
    }
    else if(navigator.userAgent.indexOf("Safari") != -1)
    {
        console.log('Safari');
        browser = 'Safari';
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 )
    {
         console.log('Firefox');
         browser = 'Firefox';
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!(document as any).documentMode == true )) //IF IE > 10
    {
      console.log('IE');
      browser = 'IE';
    }
    else
    {
       console.log('unknown');
       browser = 'unknown'
    }
    return browser;
  }

  showHideMenu(userAgent){
    let showMenu;
    let app = userAgent.includes('com.werkzpublishing.stemwerkz.ios.store.kids') ? 'ios' : 'android';
    console.log("app",app);
    let firstIdx = userAgent.lastIndexOf('/');
    let secondIdx = userAgent.indexOf('com.werkzpublishing');
    let appVer = userAgent.substring(firstIdx+1,secondIdx-1);
    if(app == 'ios'){
      showMenu = this.showHideMenuForios(appVer);
    }else{
      showMenu = this.showHideMenuForAndroid(appVer);
    }
    return showMenu;
  }

  showHideMenuForAndroid(appVersion){
    console.log("appVer for Android",appVersion)
    var strArr = appVersion.split(".");
    console.log("res",strArr)
    var numberArr = strArr.map(data => Number(data))
    console.log("numberArr",numberArr);
    let showMenu;
    if(numberArr[0] == 1 && (numberArr[1] > 0 || numberArr[1] == 0) && numberArr[2] > 1)
      showMenu = false;
    else if(numberArr[0] == 1 && (numberArr[1] > 1 || numberArr[1] == 1) && (numberArr[2] > 0 || numberArr[2] == 0))
      showMenu = false;
    else if(numberArr[0] > 1 && (numberArr[1] > 0 || numberArr[1] == 0) && (numberArr[2] > 0 || numberArr[2] == 0))
      showMenu = false;
    else
      showMenu = true;
    return showMenu;
  }

  showHideMenuForios(appVersion){
    let appVer = parseFloat(appVersion);
    console.log("appVer for iOS",appVer);
    const showMenu = appVer > 1.1 ? false : true;
    console.log("showMenu for iOS",showMenu);
    return showMenu;
  }
}
