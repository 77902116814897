import {ValleyPopupStatusModel} from '../../../models/valley-popup-status.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class ValleyPopupStatusMapper extends ItemMapper<ValleyPopupStatusModel> {
    protected mapFromItem(item): ValleyPopupStatusModel {
        const valleyPopupStatusModel = new ValleyPopupStatusModel();
        valleyPopupStatusModel.showGameDemo = item.showGameDemo;
        return valleyPopupStatusModel;
    }
}
