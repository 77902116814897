import {Component, Input, Output, EventEmitter} from '@angular/core';


@Component({
    selector: 'app-weekly-skills-wrapper',
    templateUrl: './weekly-skills-wrapper.component.html',
    styleUrls: ['./weekly-skills-wrapper.component.css']
})
export class WeeklySkillsWrapperComponent {

    public titleColor = "#FFFFFF";
    public bodyColor = "#B1B1B3";
    public countTextColor = "#FFD900";

    @Input() newSkills=[];
    @Input() loading;
    @Input() locale;

    @Output() onClick = new EventEmitter<any>();  

    onSkillClick($event) {
        this.onClick.emit($event);
    }

}
