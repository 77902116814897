<div (onClick)="onTopicClick($event)" *ngIf="topic" class="topic-item-wrap" [ngStyle]="{'background': 'linear-gradient(50.9deg, rgba(0, 0, 0, 0.85) 0%, rgba(255, 255, 255, 0.15) 83.73%),url(' +topic.url+ ')'}">
  <div class="title-bg">
    <div class="topic-text-wrap text-bg01 body-m">
      <div class="title text-s0 topic-title"> {{topic.title}}</div>
      <div class="d-flex align-items-center">
        <!-- <div *ngIf="topic"> {{topic.author}} </div>
        <div *ngIf="topic && topic.author && topic.author != ''" class="dot bg-bg01"></div> -->
        <app-text *ngIf="topic" class="light-body-m">
          {{topic.playlist}} {{topic.playlist>1 ? locale?.STEM_path.playlists: locale?.STEM_path.playlist}}
          ,&nbsp;{{topic.skill}} {{topic.skill>1 ? locale?.STEM_story.skills: locale?.STEM_story.skill}}
        </app-text>
      </div>

      <div class="light-body-m">{{topic.description}}</div>
    </div>
  </div>
</div>
