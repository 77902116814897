import {Component,Input,Output,EventEmitter} from '@angular/core';

@Component({
    selector: 'app-login-frame',
    templateUrl: './login-frame.component.html',
    styleUrls: ['./login-frame.component.css']
})
export class LoginFrameComponent {
    @Input() parentPortalUrl;
    @Input() isMobileApp:boolean;
    @Input() locale;
    @Output() onClose = new EventEmitter<any>();

    public testString;

    closeClick($event){
        this.onClose.emit($event);
    }

    ngOnInit(){
    }
}
