/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrump-nav.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../image/image.component.ngfactory";
import * as i3 from "../image/image.component";
import * as i4 from "@angular/common";
import * as i5 from "./breadcrump-nav.component";
import * as i6 from "@angular/router";
var styles_BreadcrumpNavComponent = [i0.styles];
var RenderType_BreadcrumpNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumpNavComponent, data: {} });
export { RenderType_BreadcrumpNavComponent as RenderType_BreadcrumpNavComponent };
function View_BreadcrumpNavComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "text-c75"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "right-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(3, 114688, null, 0, i3.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"] }, null)], function (_ck, _v) { var currVal_0 = "./assets/image/rightArrow.svg"; var currVal_1 = "100%"; var currVal_2 = "100%"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_BreadcrumpNavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "position-relative truncate"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickNavItem(_v.context.$implicit.url, "nav-item", _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "d-flex": 0, "cursor": 1, "text-secondary700": 2, "text-c100": 3 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "truncate"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumpNavComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "position-relative truncate"; var currVal_1 = _ck(_v, 3, 0, ((_v.context.index + 1) < ((_co.data == null) ? null : _co.data.length)), ((_v.context.index + 1) < ((_co.data == null) ? null : _co.data.length)), ((_v.context.index + 1) < ((_co.data == null) ? null : _co.data.length)), ((_v.context.index + 1) == ((_co.data == null) ? null : _co.data.length))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = ((_v.context.index + 1) < ((_co.data == null) ? null : _co.data.length)); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_2); }); }
export function View_BreadcrumpNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "d-flex bg-s0 breadcrump-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "d-flex text-secondary700 cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickNavItem("/", "home", null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "text-c75 right-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(6, 114688, null, 0, i3.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "d-flex nav-item-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumpNavComponent_1)), i1.ɵdid(9, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "./assets/image/rightArrow.svg"; var currVal_2 = "100%"; var currVal_3 = "100%"; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.data; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.locale == null) ? null : _co.locale.breadCrumpNav.home); _ck(_v, 3, 0, currVal_0); }); }
export function View_BreadcrumpNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-breadcrump-nav", [], null, null, null, View_BreadcrumpNavComponent_0, RenderType_BreadcrumpNavComponent)), i1.ɵdid(1, 573440, null, 0, i5.BreadcrumpNavComponent, [i6.Router], null, null)], null, null); }
var BreadcrumpNavComponentNgFactory = i1.ɵccf("app-breadcrump-nav", i5.BreadcrumpNavComponent, View_BreadcrumpNavComponent_Host_0, { data: "data", locale: "locale" }, { onClickNav: "onClickNav" }, []);
export { BreadcrumpNavComponentNgFactory as BreadcrumpNavComponentNgFactory };
