import {Component,Input,Output,OnInit,OnChanges,HostListener,EventEmitter} from '@angular/core';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';
import $ from 'jquery';

@Component({
    selector: 'app-oam-view',
    templateUrl: './oam-view.component.html',
    styleUrls: ['./oam-view.component.css']
})
export class OamViewComponent implements OnChanges {
  @Input() oamData;
  @Input() locale;
  @Input() navData;
  @Input() showLanguageSwitchBtn;
  @Input() localeName;
  @Input() switchButtons;
  @Input() oamSrcParams;
  @Output() onClickNavItem = new EventEmitter<any>();
  @Output() onClickLanguageBtn = new EventEmitter<any>();
  public safeUrl;
  public oam_iframe = {
    "width": null,
    "height": null
  };
  public flag = {
    "firstButton": "",
    "secondButton": ""
  }

  constructor(public sanitizer:DomSanitizer){
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.setIframeWidthHeight(this.oamData);
  }

  ngOnChanges(){
    console.log("oam view Data~~~",this.oamData);
    this.setIframeWidthHeight(this.oamData);
    if(this.oamData != undefined){
      if(this.oamSrcParams == null){
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.oamData.oamUrl);
      }else{
        this.oamData.oamUrl = this.oamData.oamUrl;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.oamData.oamUrl + '?sessionId=' + this.encodeData(this.oamSrcParams.sessionId) + '&password=' + this.encodeData(this.oamSrcParams.password) + '&username=' + this.encodeData(this.oamSrcParams.username) + '&apikey=' + this.encodeData(this.oamSrcParams.apikey) + '&env=' + this.encodeData(this.oamSrcParams.env));
      }
    }
  }

  encodeData(data) {
    let encodeStr = encodeURIComponent(data);
    return encodeStr;
  }

  setIframeWidthHeight(data){
    var iframe:any;
    var screenWidth:any;
    screenWidth = document.getElementsByClassName('oam-view-wrap')[0].clientWidth;
    console.log("setIframeWidthHeight > screen width",screenWidth)
    var ratio = (data.fileHeight != undefined ? data.fileHeight : 649) / data.fileWidth;
    console.log("ratio",ratio)
    this.oam_iframe.width = screenWidth;
    this.oam_iframe.height = Math.round(screenWidth * ratio);
  }

  onButtonClick($event){
    this.onClickLanguageBtn.emit($event);
  }

  goToNav($event){
    this.onClickNavItem.emit($event);
  }
}
