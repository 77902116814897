import { TopicModel } from '../../../models/topic.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class MonthlyTopicMapper extends ItemMapper<TopicModel> {
    protected mapFromItem(item): TopicModel {
      console.log("item>>",item)
        if(item._id){
          const topicModel= new TopicModel()
          topicModel.id = item.urlPath;
          topicModel.title = item.name;
          topicModel.url = item.bannerImage;
          topicModel.author = item.author;
          // topicModel.description = item.about;
          let result = item.about.match( /[^\.!\?]+[\.!\?]+/g );
          topicModel.description = result == null? item.about : result[0];
          topicModel.playlist = item.countInfo? item.countInfo.learningFlows : null;
          topicModel.skill = item.countInfo? item.countInfo.skills : null;
          return topicModel;
        }
        else return item;
    }
}
