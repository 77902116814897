import { TopicModel } from '../../../models/topic.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class TopicMapper extends ItemMapper<TopicModel> {
    protected mapFromItem(item): TopicModel {
        if(item._id){
          const topicModel= new TopicModel()
          topicModel.id = item.urlPath;
          topicModel.title = item.name;
          topicModel.url = item.thumbnail;
          topicModel.author = item.author;
          topicModel.description = item.about;
          topicModel.playlist = item.countInfo? item.countInfo.learningFlows : null;
          topicModel.skill = item.countInfo? item.countInfo.skills : null;
          return topicModel;
        }
        else return item;
    }
}
