import {SkillDetailsModel} from '../../../models/skilldetails.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class SkillsDetailsMapper extends ItemMapper<SkillDetailsModel> {
    protected mapFromItem(item): SkillDetailsModel {
      console.log(item)
        const skillModel= new SkillDetailsModel();
        skillModel.details._id = item._id;
        skillModel.details.urlPath = item.urlPath;
        skillModel.details.name = item.details != undefined ? item.details.name : null;
        skillModel.details.url = item.details != undefined ? item.details.targetFileURL : null;
        skillModel.details.urlIOS = item.details != undefined && item.details.targetFileURLIOS != undefined && item.details.targetFileURLIOS != null ? item.details.targetFileURLIOS : null;
        skillModel.details.oamLocales = item.details != undefined && item.details.locales != undefined && item.details.locales != null ? item.details.locales : null;
        skillModel.details.thumbnailUrl = item.details != undefined ? item.details.targetFileThumbnailURL : null;
        skillModel.details.fileWidth = item.details != undefined ? item.details.targetFileWidth : null;
        skillModel.details.fileHeight = item.details != undefined ? item.details.targetFileHeight : null;
        skillModel.details.description = item.details != undefined ? item.details.description : null;
        skillModel.details.aerReferenceID = item.aerReferenceID;
        skillModel.upNext = [];
        skillModel.relatedSkills = [];

        if(item.upnextSkill != undefined){
          item.upnextSkill.map(data => {
            let skill = {
              "_id": data._id,
              "urlPath": data.urlPath,
              "name": data.details != undefined ? data.details.name : null,
              "url": data.details != undefined ? data.details.targetFileURL : null,
              "thumbnailUrl": data.details != undefined ? data.details.targetFileThumbnailURL : null,
              "fileWidth": data.details != undefined ? data.details.targetFileWidth : null,
              "fileHeight": data.details != undefined ? data.details.targetFileHeight : null,
              "description": data.details != undefined ? data.details.description : null
            }
            skillModel.upNext.push(skill);
          })
        }

        if(item.relatedSkill != undefined){
          item.relatedSkill.map(data => {
            let skill = {
              "_id": data._id,
              "urlPath": data.urlPath,
              "name": data.details != undefined ? data.details.name : null,
              "url": data.details != undefined ? data.details.targetFileURL : null,
              "thumbnailUrl": data.details != undefined ? data.details.targetFileThumbnailURL : null,
              "fileWidth": data.details != undefined ? data.details.targetFileWidth : null,
              "fileHeight": data.details != undefined ? data.details.targetFileHeight : null,
              "description": data.details != undefined ? data.details.description : null,
              "subject": data.details != undefined ? data.details.subject : []
            }
            skillModel.relatedSkills.push(skill);
          })
        }

        return skillModel;
    }
}
