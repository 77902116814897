import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface DataRequirements {
  getMonthlyTopic(): Observable<any>;
  getRecommendedLearningFlow(): Observable<any>;
  getAllTopic(): Observable<any>;
  getAllSkill(skip, limit): Observable<any>;
  getPlayerData(playerId):Observable<any>;
  getValleyPopupStatus(data):Observable<any>;
  setValleyPopupStatus(data):Observable<any>;
  setLocalStorage(key,value);
  getLocalStorage(key);
  removeLocalStorage(key);
  getNewSkills(): Observable<any>;
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('home Data Requirements')
