import { LiveClassModel } from '../../../models/live-class.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class LiveClassMapper extends ItemMapper<LiveClassModel> {
    protected mapFromItem(item): LiveClassModel {
        if(item.classId){
          const lcModel = new LiveClassModel();
          lcModel.id = item.classId;
          lcModel.title = item.className;
          lcModel.description = item.classDescription;
          lcModel.startDate = item.classStartDate;
          lcModel.endDate = item.classEndDate;
          lcModel.totalSeat = item.totalNumberOfSeat;
          lcModel.seatTaken = item.seatTakenCount;
          lcModel.seatLeft = item.totalNumberOFSeatLeft;
          lcModel.teacherName = item.teacherFullName;
          lcModel.teacherProfile = item.teacherProfileImageUrl;
          lcModel.isEnrolled = item.isEnrolled;
          lcModel.zoomLink = item.zoomLink == undefined && item.zoomLink == '' ? '' : item.zoomLink;
          lcModel.zoomRoomID = item.zoomRoomID == undefined && item.zoomRoomID == '' ? '' : item.zoomRoomID;
          lcModel.zoomRoomPasscode = item.zoomRoomPasscode == undefined && item.zoomRoomPasscode == '' ? '' : item.zoomRoomPasscode;
          return lcModel;
        }
        else return item;
    }
}
