import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {

    constructor(private router:Router) { }

    encrypt(message: string,secretkey: string) {
        let encrypted = CryptoJS.AES.encrypt(message, secretkey? secretkey: environment.secretkey).toString();
        return encrypted;
    }

    decrypt(encrypted:string, secretkey: string) {
        let decrypted = CryptoJS.AES.decrypt(encrypted, secretkey? secretkey: environment.secretkey).toString(CryptoJS.enc.Utf8);
        return decrypted;
    }

    getUserInfo() {
        try {
          var encodedData = localStorage.getItem('userInfo');
          var userInfo = JSON.parse(this.decrypt(encodedData, null));
          return userInfo;
        }
        catch(err) {
          localStorage.clear();
          this.router.navigate(['/'])
        }
    }

    isLoginUser(){
        // console.log("isLoginUser>>",JSON.parse(localStorage.getItem('userInfo')))
        try {
          var encodedData = localStorage.getItem('userInfo');
          var userInfo = JSON.parse(this.decrypt(encodedData, null));
          if(userInfo == null) return false;
          else return true;
        }
        catch(err) {
          localStorage.clear();
          return false;
        }
    }

    getRedirectAdminUrl() {
      var isLoginUser = this.isLoginUser();
      if(isLoginUser){
        let url, username, userid, token, apikey, userInfo, usertype, playerid, sessionId;
        userInfo = this.getUserInfo();
        username = encodeURIComponent(this.encrypt(userInfo.username, environment.secretkey));
        userid = encodeURIComponent(this.encrypt(userInfo.userid, environment.secretkey));
        token = encodeURIComponent(this.encrypt(userInfo.password, environment.secretkey));
        apikey = encodeURIComponent(this.encrypt(userInfo.apikey, environment.secretkey));
        playerid = encodeURIComponent(this.encrypt(userInfo.playerid, environment.secretkey));
        usertype = encodeURIComponent(this.encrypt(userInfo.usertype, environment.secretkey));
        sessionId = userInfo.sessionId;

        if(sessionId != undefined && sessionId != null)
          url = environment.membershipUrl + 'redirect?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey + '&playerid=' + playerid + '&usertype=' + usertype + '&sessionId=' + sessionId;
        else
          url = environment.membershipUrl + 'redirect?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey + '&playerid=' + playerid + '&usertype=' + usertype;

        return url;
      }
      else return null;
    }

    getRedirectValleyUrl(){
      var isLoginUser = this.isLoginUser();
      if(isLoginUser){
        let url, username, playerid, token, apikey, userInfo, sessionId;
        userInfo = this.getUserInfo();
        username = encodeURIComponent(this.encrypt(userInfo.username, environment.secretkey));
        playerid = encodeURIComponent(this.encrypt(userInfo.playerid, environment.secretkey));
        token = encodeURIComponent(this.encrypt(userInfo.password, environment.secretkey));
        apikey = encodeURIComponent(this.encrypt(userInfo.apikey, environment.secretkey));
        sessionId = userInfo.sessionId;

        if(sessionId != undefined && sessionId != null)
          url = environment.valleyUrl + '?username=' + username + '&userid=' + playerid + '&token=' + token + '&apikey=' + apikey + '&sessionId=' + sessionId;
        else
          url = environment.valleyUrl + '?username=' + username + '&userid=' + playerid + '&token=' + token + '&apikey=' + apikey;
        return url;
      }
      else return null;
    }

    getRedirectDashboardUrl(){
      var isLoginUser = this.isLoginUser();
      if(isLoginUser){
        let url, username, playerid, token, apikey, userInfo, sessionId;
        userInfo = this.getUserInfo();
        username = encodeURIComponent(this.encrypt(userInfo.username, environment.secretkey));
        playerid = encodeURIComponent(this.encrypt(userInfo.playerid, environment.secretkey));
        token = encodeURIComponent(this.encrypt(userInfo.password, environment.secretkey));
        apikey = encodeURIComponent(this.encrypt(userInfo.apikey, environment.secretkey));
        sessionId = userInfo.sessionId;

        if(userInfo.sessionId != undefined && userInfo.sessionId != null)
          url = environment.dashboardUrl + '?username=' + username + '&userid=' + playerid + '&token=' + token + '&apikey=' + apikey + '&sessionId=' + sessionId;
        else
          url = environment.dashboardUrl + '?username=' + username + '&userid=' + playerid + '&token=' + token + '&apikey=' + apikey;
        return url;
      }
      else return null;
    }

    getRedirectQuestsUrl(){
      var isLoginUser = this.isLoginUser();
      if(isLoginUser){
        let url, username, userid, playerid, token, apikey, userInfo, sessionId;
        userInfo = this.getUserInfo();
        username = encodeURIComponent(this.encrypt(userInfo.username, environment.secretkey));
        userid = encodeURIComponent(this.encrypt(userInfo.userid, environment.secretkey))
        playerid = encodeURIComponent(this.encrypt(userInfo.playerid, environment.secretkey));
        token = encodeURIComponent(this.encrypt(userInfo.password, environment.secretkey));
        apikey = encodeURIComponent(this.encrypt(userInfo.apikey, environment.secretkey));
        sessionId = userInfo.sessionId;

        if(userInfo.sessionId != undefined && userInfo.sessionId != null)
          url = environment.questsUrl + '?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey + '&idPlayer=' + playerid + '&sessionId=' + sessionId;
        else
          url = environment.questsUrl + '?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey + '&idPlayer=' + playerid;
        return url;
      }
      else return null;
    }


    getRedirectNodmaUrl(){
      var isLoginUser = this.isLoginUser();
      if(isLoginUser){
        let url, username, playerid, token, apikey, userInfo;
        userInfo = this.getUserInfo();
        username = encodeURIComponent(this.encrypt(userInfo.username, environment.secretkey));
        playerid = encodeURIComponent(this.encrypt(userInfo.playerid, environment.secretkey));
        token = encodeURIComponent(this.encrypt(userInfo.password, environment.secretkey));
        apikey = encodeURIComponent(this.encrypt(userInfo.apikey, environment.secretkey));

        url = environment.nodmaUrl;
        return url;
      }
      else return null;
    }

    getRedirectReaderUrl(){
      var isLoginUser = this.isLoginUser();
      if(isLoginUser){
        let url,username, idUser, token, apikey, usertype, orgname, permittedApps, userInfo;
        userInfo = this.getUserInfo();
        username = encodeURIComponent(this.encrypt(userInfo.username, environment.secretkey));
        idUser = encodeURIComponent(this.encrypt(userInfo.idUser, environment.secretkey));
        token = encodeURIComponent(this.encrypt(userInfo.password, environment.secretkey));
        apikey = encodeURIComponent(this.encrypt(userInfo.apikey, environment.secretkey));
        usertype = encodeURIComponent(this.encrypt(userInfo.usertype, environment.secretkey));
        orgname = encodeURIComponent(this.encrypt(userInfo.orgname, environment.secretkey));
        permittedApps = encodeURIComponent(this.encrypt(JSON.stringify(userInfo.permittedApps), environment.secretkey));

        url = environment.nodmaUrl + '#/redirect?app=reader&username=' + username + '&token=' + token + '&apikey=' + apikey + '&usertype=' + usertype + '&idUser=' + idUser + '&pa=' + permittedApps + '&orgname=' + orgname;
        return url;
      }
    }
}
