<div class="d-flex justify-content-center not-found-wrap">
  <div>
    <div class="w-100 d-flex justify-content-center"><app-image [src]="'./assets/image/page-not-found.png'"></app-image></div>
    <div class="not-found-header">
      <app-text class="text-c300 notfound-header">{{locale?.pageNotFound.title}}...</app-text>
    </div>
    <div class="not-found-content">
      <app-text class="body-xl-light text-c100 lh-32">
        {{locale?.pageNotFound.body}}
      </app-text>
    </div>
    <div class="w-100 d-flex justify-content-center pt-4">
      <app-button [class]="'flat-button'" (onClick)="navigateToHome($event)">
        <app-text class="body-l">{{locale?.pageNotFound.btnText}}</app-text>
      </app-button>
    </div>
  </div>
</div>
