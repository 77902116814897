<div class="topic-result bg-secondary500 d-flex flex-column align-items-center">
    <div class="search-border">
        <app-image [src]="'assets/image/search_result_bottom_border.svg'" [width]="'100%'"></app-image>
    </div>
    <app-brush [color]="'text-secondary700'" [center]="'justify-content-center'" [width]="'150'">
      {{topic.length}} {{topic.length>1? locale?.STEM_quest.quests : locale?.STEM_quest.quest}}
    </app-brush>
    <div class="topic-center row" [ngStyle]="{'max-width': topic.length==1? '566px': ''}">
        <app-topic-item [locale]="locale" class="cursor" *ngFor="let item of topic" [topic]="item" (click)="onTopicClick(item)"></app-topic-item>
    </div>
</div>
