import {Inject, Component, OnInit, HostListener} from '@angular/core';
import {Router,ActivatedRoute,Params} from '@angular/router';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';
import {SharedDataService} from '../../../lib/shared_data.service';
import {SharedService} from '../../../lib/shared-service';
import {AuthService} from '../../../lib/auth.service';
import {LocaleService} from '../../../lib/locale.service';
import { ToastrService } from 'ngx-toastr';
import { LanguageSwitchButton } from 'src/app/models/language_switch_button.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-skills-details',
    templateUrl: './skills-details-controller.component.html',
    styleUrls: ['./skills-details-controller.component.css']
})
export class SkillsDetailsControllerComponent implements OnInit {
    public skillDetails;
    public currentOam;
    public skill_nav:any = [];
    public alreadyLoggedin:boolean = false;
    public userInfoSubscription;
    public skillId;
    public topicId;
    public isLoading:boolean = false;
    public locale;
    public isApp;
    public mobileOS;
    public browser;
    public showLanguageSwitchBtn = false;
    public switchButtons = LanguageSwitchButton;
    public localeName;
    public oamSrcParams = null;
    public envName = environment.envName;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _sharedDataService: SharedDataService,
        private _sharedService: SharedService,
        private _authService: AuthService,
        private toastr: ToastrService,
        private _localeService: LocaleService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
      this.userInfoSubscription = this._sharedService.changeUserInfo$.subscribe(
        data => {
          this.checkLoginData();
      });
      // route reload whenever params change;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    }

    ngOnInit(){
        this.alreadyLoggedin = this._authService.isLoginUser()
        console.log('is login user---', this.alreadyLoggedin)
        this.route.params.subscribe((params:Params) => {
          console.log("Params",params)
          this.skillId = params.skillid;
          this.topicId = params.topicid;
          if(this.alreadyLoggedin){
            this.getSkillDetails(this.skillId);
            this.getUserPermittedApps();
            this._sharedService.isApp.subscribe(data => {
              this.isApp = data["isMobileApp"];
              this.mobileOS = data["mobileOS"].toLowerCase();
              this.browser = data["browser"].toLowerCase();
            });
            let userInfo = this._authService.getUserInfo();
            if(userInfo.sessionId != undefined && userInfo.sessionId != null) {
              this.oamSrcParams = {
                sessionId: userInfo.sessionId,
                password: userInfo.password,
                username: userInfo.username,
                apikey: userInfo.apikey,
                env: this.envName
              }
            }else {
              this.oamSrcParams = null;
            }
          }
          else this.checkLoginData();
        })
        this.getLocale();
    }

    checkLoginData(){
        this.alreadyLoggedin = this._authService.isLoginUser();
        if(!this.alreadyLoggedin) this._sharedService.setEnableLogin({enable: true, url: this.router.url});
    }

    getLocale(){
      this._localeService.getJson().subscribe(data => {
        this.locale = data;
      })
    }

    getUserPermittedApps(){
      this._sharedDataService.getUserPermittedApps().subscribe(data => {
        console.log("skills > getUserPermittedApps > data",data);
        this._sharedService.defineUserPermittedApps(data);
        this.getLocale();
      })
    }

    getSkillDetails(skillId){
      console.log("getSkillDetails",skillId)
      this.isLoading = true;
      this.business.getSkillDetails(skillId).subscribe(data => {
        this.localeName = this._localeService.getCurrentLocale() || 'en-US';
        console.log("localeName",this.localeName);
        this.isLoading = false;
        this.skillDetails = data[0];
        this.currentOam = this.skillDetails.details;
        this.checkForLanguageSwitch();
        this.checkOamName(this.localeName);
        this.checkOamDescription(this.localeName);
        if(this.currentOam.oamLocales != null && ((this.currentOam.oamLocales.targetFileURL != undefined && this.currentOam.oamLocales.targetFileURL != null) || (this.currentOam.oamLocales.targetFileURLIOS != undefined && this.currentOam.oamLocales.targetFileURLIOS != null))){
          this.checkLocalesUrl(this.localeName);
        }else{
          this.currentOam["oamUrl"] = this.mobileOS == 'ios' && this.browser == 'safari' && !this.isApp && this.currentOam.urlIOS != null ? this.currentOam.urlIOS : this.currentOam.url;
          console.log("currentOam URL~~~~~~~~~",this.currentOam.oamUrl);
        }

        //sample oam url
        // this.currentOam.url = 'http://data.amdon.sg.tmp.s3.amazonaws.com/ygn/ley-01-01-i1/Assets/index.html';
        // this.currentOam.url = 'http://data.amdon.sg.tmp.s3.amazonaws.com/ygn/ley-01-01-i1-v2/Assets/index.html';

        console.log("skillDetails",this.skillDetails)
        this.trackUserActivity(this.currentOam, "VIEW")
        this.checkForSetBreadcrumb(this.skillDetails)
      },err=>{
        console.log("err",err);
        this.router.navigate(['/page-not-found']);
        // if(err.status ==  404){
        //   this.toastr.error(err.message)
        // }else{
        //   this.toastr.error(err.error.message)
        // }
      })
    }

    checkOamName(locale){
      if(this.currentOam.oamLocales != null && (this.currentOam.oamLocales.name != undefined || this.currentOam.oamLocales.name != null)){
        let oamNameArr = this.getKeyArray(this.currentOam.oamLocales.name);
        console.log("oamNameArr",oamNameArr,"locale_name",locale);
        let idx = this.findIndex(oamNameArr,locale.toLowerCase());
        if(idx != -1) this.currentOam["name"] = this.currentOam.oamLocales.name[locale];
        else this.currentOam["name"] = this.currentOam.name;
      }
    }

    checkOamDescription(locale){
      if(this.currentOam.oamLocales != null && (this.currentOam.oamLocales.description != undefined || this.currentOam.oamLocales.description != null)){
        let oamDescriptionArr = this.getKeyArray(this.currentOam.oamLocales.description);
        console.log("oamDescriptionArr",oamDescriptionArr,"locale_name",locale);
        let idx = this.findIndex(oamDescriptionArr,locale.toLowerCase());
        if(idx != -1) this.currentOam["description"] = this.currentOam.oamLocales.description[locale];
        else this.currentOam["description"] = this.currentOam.description;
      }
    }

    checkForLanguageSwitch(){
      console.log("checkForLanguageSwitch~~~~~~~~~")
      if(this.currentOam.oamLocales != null && ((this.currentOam.oamLocales.targetFileURL != undefined && this.currentOam.oamLocales.targetFileURL != null) || (this.currentOam.oamLocales.targetFileURLIOS != undefined && this.currentOam.oamLocales.targetFileURLIOS != null))){
        if(this.mobileOS == 'ios' && this.browser == 'safari' && !this.isApp && this.currentOam.urlIOS != null){
          this.checkToShowLanguageSwitch(this.currentOam.oamLocales.targetFileURLIOS);
        }else{
          this.checkToShowLanguageSwitch(this.currentOam.oamLocales.targetFileURL);
        }
      }else{
        this.showLanguageSwitchBtn = false;
      }
    }

    checkToShowLanguageSwitch(urlObject){
      let urlArr = this.getKeyArray(urlObject);
      console.log("urlArr~~~",urlArr);
      let userLocale = this._localeService.getUserLocale();
      console.log("userLocale",userLocale);
      if(userLocale.locale != null && userLocale.secondaryLocale != null){
        let findLocale = this.findIndex(urlArr,userLocale.locale.toLowerCase());
        console.log("findLocale",findLocale);
        let findSecondaryLocale = this.findIndex(urlArr,userLocale.secondaryLocale.toLowerCase());
        console.log("findSecondaryLocale",findSecondaryLocale);
        if(urlArr.length > 1 && findLocale != -1 && findSecondaryLocale != -1){
          this.showLanguageSwitchBtn = true;
          this.showSwitchButton(userLocale);
        }else{
          this.showLanguageSwitchBtn = false;
        }
      }
    }

    showSwitchButton(locale){
      this._localeService.getCountryList().subscribe(res => {
        let countryList = res;
        this.switchButtons.firstButton.buttonLocale = locale.locale;
        this.switchButtons.firstButton.buttonText = countryList[locale.locale] == undefined || countryList[locale.locale] == null ? locale.locale : countryList[locale.locale];
        this.switchButtons.firstButton.flag = this.getFlagName(locale.locale);
        this.switchButtons.secondButton.buttonLocale = locale.secondaryLocale;
        this.switchButtons.secondButton.buttonText = countryList[locale.secondaryLocale] == undefined || countryList[locale.secondaryLocale] == null ? locale.secondaryLocale : countryList[locale.secondaryLocale] ;
        this.switchButtons.secondButton.flag = this.getFlagName(locale.secondaryLocale);
      });
    }

    getFlagName(str){
      let flagName = str.substr(str.indexOf("-")+1,str.length).toLowerCase();
      return flagName;
    }

    getKeyArray(obj){
      let arr = Object.keys(obj).map(data => data.toLowerCase());
      return arr;
    }

    findIndex(arr,data){
      let idx = arr.indexOf(data);
      return idx;
    }

    checkLocalesUrl(locale){
      if(this.mobileOS == 'ios' && this.browser == 'safari' && !this.isApp && this.currentOam.urlIOS != null){
        this.checkOamUrlIOS(locale);
      }else{
        this.checkOamUrl(locale);
      }
    }

    checkOamUrl(locale){
      let targetFileURLArr = this.getKeyArray(this.currentOam.oamLocales.targetFileURL);
      console.log("targetFileURLArr",targetFileURLArr,"locale_name",this.localeName);
      let idx = this.findIndex(targetFileURLArr,locale.toLowerCase());
      if(idx != -1) this.currentOam["oamUrl"] = this.currentOam.oamLocales.targetFileURL[locale];
      else this.currentOam["oamUrl"] = this.currentOam.url;
      console.log("currentOam URL#########",this.currentOam.oamUrl);
    }

    checkOamUrlIOS(locale){
      let targetFileURLIOSArr = this.getKeyArray(this.currentOam.oamLocales.targetFileURLIOS);
      console.log("targetFileURLIOSArr",targetFileURLIOSArr,"locale_name",this.localeName);
      let idx = this.findIndex(targetFileURLIOSArr,locale.toLowerCase());
      if(idx != -1) this.currentOam["oamUrl"] = this.currentOam.oamLocales.targetFileURLIOS[locale];
      else this.currentOam["oamUrl"] = this.currentOam.urlIOS;
      console.log("currentOam URL#########",this.currentOam.oamUrl);
    }

    // checkForSetBreadcrumb(data){
    //   console.log("checkForSetBreadcrumb",data)
    //   let obj = {
    //     '_id': data.details.urlPath,
    //     'name': data.details.name,
    //     'url': '/' + data.details.topicId + '/' + data.details.urlPath,
    //     'type': 'skill'
    //   };
    //   if(this.skill_nav.length > 0){
    //     let arrLastIdx = this.skill_nav.length - 1;
    //     if(this.skill_nav[arrLastIdx].type == 'skill'){
    //       this.skill_nav[arrLastIdx] = obj;
    //     }else{
    //       this.skill_nav.push(obj)
    //     }
    //   }else{
    //     this.skill_nav.push(obj)
    //   }
    // }

    checkForSetBreadcrumb(data){
      this.business.getTopicDetails(this.topicId).subscribe(res =>{
        this.setBreadcrumbData(res[0],data);
      },err => {
        this.setBreadcrumbData(null,data)
      })
    }

    setBreadcrumbData(topic,skill){
      this.skill_nav = [];
      let skillObj = {
        '_id': skill.details.urlPath,
        'name': skill.details.name,
        'url': '/' + skill.details.topicId + '/' + skill.details.urlPath,
        'type': 'skill'
      };
      if(topic != null){
        let topicObj = {
          '_id': topic.id,
          'name': topic.title,
          'url': '/' + topic.id,
          'type': 'topics'
        };
        this.skill_nav.push(topicObj);
        this.skill_nav.push(skillObj);
      }else{
        this.skill_nav.push(skillObj);
      }
    }

    onSelectSkill($event){
      console.log("onSelectSkill",$event);
      this.router.navigate(['/'+ $event.topicId + '/' + $event.urlPath])
      // this.getSkillDetails($event._id,)
    }

    goToNavItem(url){
      console.log("goToNavItem",url);
      this.router.navigate([url]);
      this._sharedService.defineSkillNav([]);
    }

    trackUserActivity(oam, action){
      console.log('tracking user acitivity', oam)
      let data = {
        "subject": "OAM",
        "action": action,
        "details": {
          "learningflow": null,
          "skill": oam.aerReferenceID
        },
        "origin": "CHANNEL"
      }
      this.business.trackUserActivity(data).subscribe(res => {
        console.log("trackUserActivity______",res, action)
      })
    }

    changeLocale(locale){
      console.log("changeLocale",locale);
      let localeObj = {
        "currentLocale": locale
      };
      this.business.updateLocale(localeObj).subscribe(res => {
        console.log("Update locale successfully")
      })
      this._localeService.updateCurrentLocale(locale);
      this.localeName = locale;
      this.checkLocalesUrl(this.localeName);
    }

    ngOnDestroy() {
      this.userInfoSubscription.unsubscribe();
    }
    @HostListener('window:message', ['$event'])
    messageListener (e) {
      // if(e.origin!=="https://amdon-tools.pagewerkz.com") return;
      if (typeof e.data === 'string' || e.data instanceof String){
        try {
          var data = JSON.parse(e.data.toString())
          if( data && data.id == "oam_tracking"){
            console.log('oam_tracking', data)
            if(data.action == 'COMPLETE' || data.action == 'PLAY'){
              this.trackUserActivity(this.currentOam, data.action)
            }
            // action enum: ['COMPLETE', 'PLAY', 'PAUSE', 'REWIND', 'VIEW']
          }
        }
        catch {
          console.log('oam_tracking error---', e)
        }
      }
    }
}
