import {Component,Input,Output,EventEmitter} from '@angular/core';

@Component({
    selector: 'app-user-popup',
    templateUrl: './user-popup.component.html',
    styleUrls: ['./user-popup.component.css']
})
export class UserPopupComponent {
    @Input() profileObj;
    @Input() userObj;
    @Input() locale;
    @Output() onClickUserLogOut = new EventEmitter<any>();

    clickLogout($event) {
        this.onClickUserLogOut.emit($event);
    }

}
