import {Component,Input} from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
  @Input() width = '50px';
  @Input() height = '50px';
  @Input() spinColor = '#FBF7EC';
  @Input() spinTopColor = '#FFFFFF';
  @Input() spinWidth = '6px';

}
