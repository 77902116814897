import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../lib/auth.service";
export class TeacherProfileService {
    constructor(http, _authService) {
        this.http = http;
        this._authService = _authService;
        this.baseUrl = environment.liveClassApiurl + 'api/v1/';
    }
    getTeacherList() {
        let url = this.baseUrl + 'teachers';
        let userInfo = this._authService.getUserInfo();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                username: userInfo.username,
                api_key: userInfo.apikey,
                password: userInfo.password,
                userid: userInfo.userid
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            return res;
        }));
    }
    getTeacherProfile(teacherId) {
        let url = this.baseUrl + 'teachers/' + teacherId;
        let userInfo = this._authService.getUserInfo();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                username: userInfo.username,
                api_key: userInfo.apikey,
                password: userInfo.password,
                userid: userInfo.userid
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
}
TeacherProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TeacherProfileService_Factory() { return new TeacherProfileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: TeacherProfileService, providedIn: "root" });
