import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { locale_short_code } from '../../locale/locale-code';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  public locale;
  public localeJson;
  public localeCodeList

  constructor(private http: HttpClient,private router: Router) {
    this.localeCodeList = Object.keys(locale_short_code);
    this.localeCodeList = this.localeCodeList.map(data => data.replace(/_/g,"-"));
  }

  getLocaleName(){
    let localeObj = JSON.parse(localStorage.getItem("userLocale"));
    let localeLs = localeObj != undefined ? localeObj.locale : null;
    let locale = localeLs != undefined && this.localeCodeList.indexOf(localeLs) != -1 ? localeLs.toLocaleLowerCase() : 'en-US'.toLocaleLowerCase();
    this.localeJson = 'locale/locale_' + locale + '.json';
    console.log("locale json~~~~~~",this.localeJson);
    return this.localeJson;
  }

  getJson(){
    let localeName = this.getLocaleName();
    return this.http.get(localeName).pipe(map((res:Response) => {
      let locale = {};
      locale = res[0];
      console.log("locale",locale)
      return locale;
    }),
    catchError(error => {
      return this.getDefaultJson("locale/locale_en-us.json")
    }));
  }

  getDefaultJson(locale){
    return this.http.get(locale).pipe(map((res:Response) => {
      let locale = {};
      locale = res[0];
      console.log("getDefaultJson",locale);
      return locale;
    }))
  }

  updateCurrentLocale(data){
    localStorage.setItem("current_locale",data);
  }

  getCurrentLocale(){
    let current_locale = localStorage.getItem("current_locale");
    return current_locale;
  }

  getUserLocale(){
    let userLocale = JSON.parse(localStorage.getItem("userLocale"));
    return userLocale;
  }

  getCountryList(){
    let country_json = "locale/country_name.json";
    return this.http.get(country_json).pipe(map((res:Response) => {
      let country_list = {};
      country_list = res[0];
      return country_list;
    }),
    catchError(error => {
      console.log("error~~~",error)
      return error;
    }));
  }
}
