<div id="custom-popup" class="popup-wrapper">

  <!-- Modal content -->
  <div class="popup-content bg-s0">
    <div class="popup-content-wrapper">
      <div class="d-flex justify-content-between">
        <app-text class="text-secondary700 h2-bold popup-title">{{title? title : locale?.customPopup.ohNo}}</app-text>
        <div class="popup-close-btn" (click)="onClickCloseBtn('cross_click')">
          <app-image [src]="'./assets/image/cross_icon.svg'" [width]="'100%'" [height]="'100%'"></app-image>
        </div>
      </div>
      <div class="pt-4 pb-4">
        <div *ngIf="!msg && !joinInstruction" class="text-c300 light-body-l">
          <app-text *ngFor="let val of locale?.customPopup.lessonTriedToBook">
            {{val=='lesson'? lesson: val}}
          </app-text>
        </div>
        <app-text *ngIf="msg">{{msg}}</app-text>
        <div *ngIf="joinInstruction && joinInstruction.zoomLink == ''" class="text-c300 light-body-l">
          <app-text *ngFor="let val of locale?.customPopup.joinClassInfo" [ngClass]="val=='classname'? 'body-l':  val=='teachername'? 'body-l cursor' : ''">
              {{val=='classname'? joinInstruction.classname: val=='teachername'? joinInstruction.teachername: val!='nodma'? val : ''}}
              <a *ngIf="val=='nodma'" [href]="joinInstruction.nodmaUrl" target="_blank"><app-text class="text-b100"> {{locale?.customPopup[val]}} </app-text></a>
          </app-text>
        </div>
        <div *ngIf="joinInstruction && joinInstruction.zoomLink != ''" class="text-c300 light-body-l">
          <app-text *ngFor="let val of locale?.customPopup.joinZoomInfo" [ngClass]="val=='classname'? 'body-l':  val=='teachername'? 'body-l cursor' : ''">
              {{val=='classname'? joinInstruction.classname: val=='teachername'? joinInstruction.teachername: val!='zoomicon'? val : ''}}
              <app-image *ngIf="val=='zoomicon'" [src]="'./assets/image/zoom_icon.png'" [width]="'22px'" [height]="'22px'" [class]="'zoomicon-mb'"></app-image>
              <!-- <a *ngIf="val=='zoom'" [href]="joinInstruction.zoomLink" target="_blank"><app-text class="text-b100"> {{locale?.customPopup[val]}} </app-text></a> -->
          </app-text>
        </div>
        <div *ngIf="joinInstruction && joinInstruction.zoomLink == ''" class="instruction-wrap bg-s10 mt-4">
            <app-text class="caption-bold text-uppercase text-secondary700 mb-1">{{locale?.customPopup.instruction}}</app-text>
            <div *ngFor="let number of [0,1,2,3]" class="d-flex align-items-baseline mt-2">
                <div [ngClass]="number==3? 'not-visible' : ''" class="list-dot bg-s70"></div>
                <div class="w-100 light-body-l text-c300">
                  <div *ngIf="number==0">
                    <app-text *ngFor="let val of locale?.customPopup.instruction1" [ngClass]="val=='username'? 'text-accent700': ''">
                      {{val=='username'? joinInstruction.username: val}}
                    </app-text>
                  </div>
                  <div *ngIf="number==1">
                    <app-text *ngFor="let val of locale?.customPopup.instruction2" [ngClass]="val=='reader'? 'body-l': ''">
                      {{val=='reader'? locale?.customPopup[val]: val}}
                    </app-text>
                  </div>
                  <div *ngIf="number==2">
                    <app-text *ngFor="let val of locale?.customPopup.instruction3" [ngClass]="val=='classname'? 'body-l': ''">
                      {{val=='classname'? joinInstruction.classname: val}}
                    </app-text>
                  </div>
                  <app-image *ngIf="number==3" [src]="'./assets/image/join_sample.svg'"></app-image>
                </div>
            </div>
        </div>
        <div *ngIf="joinInstruction && joinInstruction.zoomLink != ''" class="instruction-wrap bg-s10 mt-4">
            <app-text class="caption-bold text-uppercase text-secondary700 mb-1">{{locale?.customPopup.instruction}}</app-text>
            <div *ngFor="let number of [0,1,2,3]" class="d-flex align-items-baseline">
                <!-- <div [ngClass]="number==3? 'not-visible' : ''" class="list-dot bg-s70"></div> -->
                <div class="w-100">
                  <div *ngIf="number==0" class="text-c300 light-body-normal pt-1">
                    <app-text class="text-c300">
                      {{ locale?.customPopup.zoomInstruction1 }}
                    </app-text>
                  </div>
                  <div *ngIf="number==1" class="zoomlink-wrap">
                    <div *ngFor="let val of locale?.customPopup.zoomInstruction2">
                      <app-text *ngIf="val!='zoomlink'" class="text-c300 body-m">
                        {{ val }}
                      </app-text>
                      <a *ngIf="val=='zoomlink'" [href]="joinInstruction.zoomLink" [ngClass]="{'disabledClick': isAndroidApp}" target="_blank">
                        <app-text class="text-accent500 light-body-m cursor text-break-all">
                          {{ joinInstruction.zoomLink }}
                        </app-text>
                      </a>
                    </div>
                  </div>
                  <div *ngIf="number==2" class="pb-1 d-flex">
                    <div *ngFor="let val of locale?.customPopup.zoomInstruction3" class="zoom-data">
                      <app-text class="text-c300" [ngClass]="val=='zoomid'? 'h5-strong': 'body-m'">
                        {{val=='zoomid'? joinInstruction.zoomRoomID : val}}
                      </app-text>
                    </div>
                  </div>
                  <div *ngIf="number==3" class="d-flex">
                    <div *ngFor="let val of locale?.customPopup.zoomInstruction4" class="zoom-data">
                      <app-text class="text-c300" [ngClass]="val=='passcode'? 'h5-strong': 'body-m'">
                        {{val=='passcode'? joinInstruction.zoomRoomPasscode : val}}
                      </app-text>
                    </div>
                  </div>
                  <!-- <app-image *ngIf="number==3" [src]="'./assets/image/join_sample.svg'"></app-image> -->
                </div>
            </div>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-end"> -->
      <a *ngIf="joinInstruction && joinInstruction.zoomLink == ''" [href]="joinInstruction.nodmaUrl" target="_blank">
        <app-button [class]="'button-primary'" (onClick)="onClickCloseBtn('button_click')" [width]="'100%'" [height]="'40px'">
          <app-text class="text-s0 h5-bold">{{locale?.customPopup.got_It}}</app-text>
        </app-button>
      </a>

      <a *ngIf="!isAndroidApp && joinInstruction && joinInstruction.zoomLink != ''" [href]="joinInstruction.zoomLink" target="_blank">
        <app-button [class]="'default-btn'" [width]="'100%'" [height]="'40px'">
          <app-text class="text-s0 h5-bold">{{locale?.customPopup.join_zoom}}</app-text>
        </app-button>
      </a>

      <div *ngIf="isAndroidApp && joinInstruction && joinInstruction.zoomLink != ''">
        <app-text class="text-c100 body-m">{{ locale?.customPopup.deviceMessageZoom }}</app-text>
      </div>

      <app-button *ngIf="!joinInstruction" [class]="'button-primary'" (onClick)="onClickCloseBtn('button_click')" [width]="'100%'" [height]="'40px'">
          <app-text class="text-s0 h5-bold">{{locale?.customPopup.got_It}}</app-text>
      </app-button>
    </div>
    <!-- </div> -->
    <div *ngIf="joinInstruction && joinInstruction.zoomLink != ''" class="bg-s10 nodma-info">
      <div class="pt-4 pb-3">
        <app-text class="text-c300 light-body-l">{{ locale?.customPopup.nodma_text }}</app-text>
      </div>
      <a [href]="joinInstruction.nodmaUrl" target="_blank">
        <app-button [class]="'button-secondary'" (onClick)="onClickCloseBtn('button_click')" [height]="'40px'">
          <app-text class="h5-bold">{{locale?.customPopup.go_to_nodma}}</app-text>
        </app-button>
      </a>
    </div>
  </div>

</div>
