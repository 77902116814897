import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {TopicMapper} from '../mappers/topic.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetAllTopicUseCase {
    constructor(
        private mapper: TopicMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
        return this.data.getAllTopic().pipe(this.mapper.map);
    }
}
