<div [ngClass]="{'oam-nav-container': oamData != undefined && showLanguageSwitchBtn}">
  <div [ngClass]="{'item-left': oamData != undefined && showLanguageSwitchBtn, 'nav-container': oamData == undefined || !showLanguageSwitchBtn}">
    <app-breadcrump-nav [data]="navData" [locale]="locale" (onClickNav)="goToNav($event)"></app-breadcrump-nav>
  </div>
  <div *ngIf="oamData != undefined && showLanguageSwitchBtn" class="item-right">
    <app-language-switch-button [switchButtons]="switchButtons" [localeName]="localeName" (onClick)="onButtonClick($event)"></app-language-switch-button>
  </div>
</div>
<div class="oam-view-wrap">
  <iframe id="{{oamData?.urlPath}}" allowfullscreen="true" *ngIf="oamData?.oamUrl != null" class="oam-iframe" width="100%" height="{{oam_iframe.height}}" [src]="safeUrl" scrolling="no" frameborder = "0"></iframe>
  <div *ngIf="oamData?.oamUrl == null" class="bg-main100 default-oam-view"></div>
  <!-- <div *ngIf="showLanguageSwitchBtn" class="locale_button_wrap bg-s0">
    <button class="locale-btn-mr-8" [ngClass]="[switchButtons.firstButton.buttonLocale.toLowerCase() == localeName.toLowerCase() ? 'select_locale_button' : 'locale_button']" (click)="onButtonClick(switchButtons.firstButton.buttonLocale)">
      <app-text [class]="'text-c300 h5-bold locale-btn-mr-8'">{{ switchButtons.firstButton.buttonText }}</app-text>
      <span class="flag-icon flag-icon-squared" [ngClass]="['flag-icon-' + switchButtons.firstButton.flag]"></span>
    </button>
    <button [ngClass]="[switchButtons.secondButton.buttonLocale.toLowerCase() == localeName.toLowerCase() ? 'select_locale_button' : 'locale_button']" (click)="onButtonClick(switchButtons.secondButton.buttonLocale)">
      <app-text [class]="'text-c300 h5-bold locale-btn-mr-8'">{{ switchButtons.secondButton.buttonText }}</app-text>
      <span class="flag-icon flag-icon-squared" [ngClass]="['flag-icon-' + switchButtons.secondButton.flag]"></span>
    </button>
  </div> -->
</div>
