import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/search-topic.mapper";
import * as i2 from "../data.requirements";
export class GetSearchTopicUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(keyword) {
        return this.data.getSearchTopic(keyword).pipe(this.mapper.map);
    }
}
GetSearchTopicUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetSearchTopicUseCase_Factory() { return new GetSearchTopicUseCase(i0.ɵɵinject(i1.SearchTopicMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetSearchTopicUseCase, providedIn: "root" });
