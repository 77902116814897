import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-learning-flow-list',
    templateUrl: './learning-flow-list.component.html',
    styleUrls: ['./learning-flow-list.component.css']
})
export class LearningFlowListComponent {
  @Input() topic;
  @Input() learningFlowList;
  @Input() learningFlowCount;
  @Input() sort;
  @Input() loading;
  @Input() locale;

  @Output() onClickLoadMore = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();

  onPlaylistClick($event) {
    this.onClick.emit($event);
  }

  onLoadMore($event) {
    this.onClickLoadMore.emit($event);
  }

}
