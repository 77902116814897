import {PlayerModel} from '../../../models/player.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class PlayerMapper extends ItemMapper<PlayerModel> {
    protected mapFromItem(item): PlayerModel {
        const playerModel = new PlayerModel();
        playerModel.name = item.name;
        playerModel.playerId = item._id;
        playerModel.userId = item.userId;
        playerModel.level = item.level;
        playerModel.lastLoginRewardedAt = item.lastLoginRewardedAt;
        playerModel.credits = item.credits;
        if(item.townList != undefined && item.townList.length > 0){
          playerModel.currentLevel = item.townList[0].currentLevel != undefined ? item.townList[0].currentLevel : null;
        }else{
          playerModel.currentLevel = null
        }
        return playerModel;
    }
}
