import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {SearchTopicMapper} from '../mappers/search-topic.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetSearchTopicUseCase {
    constructor(
        private mapper: SearchTopicMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(keyword) {
      return this.data.getSearchTopic(keyword).pipe(this.mapper.map);
    }
}
