/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../../../global-components/text/text.component";
import * as i4 from "../../../../../global-components/image/image.component.ngfactory";
import * as i5 from "../../../../../global-components/image/image.component";
import * as i6 from "@angular/common";
import * as i7 from "./user-popup.component";
var styles_UserPopupComponent = [i0.styles];
var RenderType_UserPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserPopupComponent, data: {} });
export { RenderType_UserPopupComponent as RenderType_UserPopupComponent };
function View_UserPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "profile_initial"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [["class", "body-m text-c300"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(2, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "body-m text-c300"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.profileObj.text; _ck(_v, 3, 0, currVal_1); }); }
export function View_UserPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "user-info-wrap bg-s0"], ["id", "user-toogle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "user-info d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-image", [], null, null, null, i4.View_ImageComponent_0, i4.RenderType_ImageComponent)), i1.ɵdid(5, 114688, null, 0, i5.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"], class: [3, "class"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserPopupComponent_1)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "text-username"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "app-text", [["id", "id_username"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(10, 49152, null, 0, i3.TextComponent, [], null, null), (_l()(), i1.ɵted(11, 0, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickLogout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-image", [], null, null, null, i4.View_ImageComponent_0, i4.RenderType_ImageComponent)), i1.ɵdid(14, 114688, null, 0, i5.ImageComponent, [], { src: [0, "src"], class: [1, "class"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "app-text", [], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(16, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(17, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileObj.url; var currVal_1 = "40px"; var currVal_2 = "40px"; var currVal_3 = "mr-3"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.profileObj.isDisplay; _ck(_v, 7, 0, currVal_4); var currVal_6 = "assets/image/logout.svg"; var currVal_7 = "ml-2"; _ck(_v, 14, 0, currVal_6, currVal_7); var currVal_8 = "ml-4"; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.userObj.username; _ck(_v, 11, 0, currVal_5); var currVal_9 = ((_co.locale == null) ? null : _co.locale.profile.logoutButton); _ck(_v, 17, 0, currVal_9); }); }
export function View_UserPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-popup", [], null, null, null, View_UserPopupComponent_0, RenderType_UserPopupComponent)), i1.ɵdid(1, 49152, null, 0, i7.UserPopupComponent, [], null, null)], null, null); }
var UserPopupComponentNgFactory = i1.ɵccf("app-user-popup", i7.UserPopupComponent, View_UserPopupComponent_Host_0, { profileObj: "profileObj", userObj: "userObj", locale: "locale" }, { onClickUserLogOut: "onClickUserLogOut" }, []);
export { UserPopupComponentNgFactory as UserPopupComponentNgFactory };
