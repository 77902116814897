import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { DataService } from './api-services/data.service';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
        private dataApiService: DataService
    ) {}

    playerLogin(username, password) : Observable<any>{
        return this.dataApiService.loginUser(username, password);
    }

    getLocalstorage(key){
        return localStorage.getItem(key);
    }

    setLocalStorage(key, value){
        localStorage.setItem(key, value);
    }

    removeLocalStorage(key) {
        localStorage.removeItem(key);
    }

    getSearchTopic(keyword) : Observable<any> {
        return this.dataApiService.getSearchResult(keyword, 'topic', 0, 10);
    }

    getSearchLearningFlow(keyword, skip, limit) : Observable<any> {
        return this.dataApiService.getSearchResult(keyword, 'learningflow', skip, limit);
    }

    getSearchSkill(keyword, skip, limit) : Observable<any> {
        return this.dataApiService.getSearchResult(keyword, 'skill', skip, limit);
    }

    getUserInfo() : Observable<any>{
        return this.dataApiService.userInfo();
    }

    getPermittedApps(userId):Observable<any>{
      return this.dataApiService.getPermittedApps(userId);
    }
}
