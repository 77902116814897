import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {

  @Input() disabled = false;
  @Input() class = '';
  @Input() width;
  @Input() height;
  @Input() loading;
  @Input() padding;

  @Output() onClick = new EventEmitter<any>();

  onButtonClick($event) {
    this.onClick.emit($event);
  }

  constructor() { }


}
