/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../image/image.component.ngfactory";
import * as i3 from "../image/image.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./input.component";
var styles_InputComponent = [i0.styles];
var RenderType_InputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
function View_InputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-image", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Imgclick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(2, 114688, null, 0, i3.ImageComponent, [], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = "./assets/image/search_l.svg"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["single", 1]], null, 8, "input", [], [[8, "placeholder", 0], [8, "type", 0], [8, "readOnly", 0], [8, "className", 0], [8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "width": 0, "height": 1, "text-align": 2 }), i1.ɵdid(4, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(6, 540672, null, 0, i5.FormControlDirective, [[8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR], [2, i5.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.NgControl, null, [i5.FormControlDirective]), i1.ɵdid(8, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_12 = _ck(_v, 3, 0, (_co.width ? _co.width : "auto"), (_co.height ? _co.height : "auto"), (_co.textAlign ? _co.textAlign : "left")); _ck(_v, 2, 0, currVal_12); var currVal_13 = _co.control; _ck(_v, 6, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hint; var currVal_1 = _co.type; var currVal_2 = _co.readonly; var currVal_3 = _co.textClass; var currVal_4 = _co.id; var currVal_5 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_6 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_7 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_8 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_9 = i1.ɵnov(_v, 8).ngClassValid; var currVal_10 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_11 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }); }
function View_InputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.id == "Search") || (_co.id == "search-landing")); _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.multiline; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.class; _ck(_v, 0, 0, currVal_0); }); }
function View_InputComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["multi", 1]], null, 8, "textarea", [], [[8, "rows", 0], [8, "placeholder", 0], [8, "readOnly", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "width": 0, "height": 1 }), i1.ɵdid(4, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(6, 540672, null, 0, i5.FormControlDirective, [[8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR], [2, i5.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.NgControl, null, [i5.FormControlDirective]), i1.ɵdid(8, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = _ck(_v, 3, 0, (_co.width ? _co.width : "auto"), _co.height); _ck(_v, 2, 0, currVal_10); var currVal_11 = _co.control; _ck(_v, 6, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rows; var currVal_1 = _co.hint; var currVal_2 = _co.readonly; var currVal_3 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 8).ngClassValid; var currVal_8 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_InputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.multiline; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { single: 1 }), i1.ɵqud(671088640, 2, { multi: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_4)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.multiline; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.multiline; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_InputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i1.ɵdid(1, 114688, null, 0, i6.InputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputComponentNgFactory = i1.ɵccf("app-input", i6.InputComponent, View_InputComponent_Host_0, { type: "type", multiline: "multiline", rows: "rows", hint: "hint", class: "class", readonly: "readonly", textAlign: "textAlign", textClass: "textClass", id: "id", control: "control", width: "width", height: "height" }, { searchImgclick: "searchImgclick" }, ["*"]);
export { InputComponentNgFactory as InputComponentNgFactory };
