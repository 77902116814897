/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-switch-button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../text/text.component.ngfactory";
import * as i4 from "../text/text.component";
import * as i5 from "./language-switch-button.component";
var styles_LanguageSwitchButtonComponent = [i0.styles];
var RenderType_LanguageSwitchButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageSwitchButtonComponent, data: {} });
export { RenderType_LanguageSwitchButtonComponent as RenderType_LanguageSwitchButtonComponent };
export function View_LanguageSwitchButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "language-switch-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["class", "locale-btn-mr-8"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick(_co.switchButtons.firstButton.buttonLocale) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-text", [], null, null, null, i3.View_TextComponent_0, i3.RenderType_TextComponent)), i1.ɵdid(6, 49152, null, 0, i4.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(7, 0, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick(_co.switchButtons.secondButton.buttonLocale) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(11, 1), (_l()(), i1.ɵeld(12, 0, null, null, 2, "app-text", [], null, null, null, i3.View_TextComponent_0, i3.RenderType_TextComponent)), i1.ɵdid(13, 49152, null, 0, i4.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(14, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "locale-btn-mr-8"; var currVal_1 = _ck(_v, 4, 0, ((_co.switchButtons.firstButton.buttonLocale.toLowerCase() == _co.localeName.toLowerCase()) ? "select_locale_button" : "locale_button")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "text-c300 h5-bold"; _ck(_v, 6, 0, currVal_2); var currVal_4 = _ck(_v, 11, 0, ((_co.switchButtons.secondButton.buttonLocale.toLowerCase() == _co.localeName.toLowerCase()) ? "select_locale_button" : "locale_button")); _ck(_v, 10, 0, currVal_4); var currVal_5 = "text-c300 h5-bold"; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.switchButtons.firstButton.buttonText; _ck(_v, 7, 0, currVal_3); var currVal_6 = _co.switchButtons.secondButton.buttonText; _ck(_v, 14, 0, currVal_6); }); }
export function View_LanguageSwitchButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-language-switch-button", [], null, null, null, View_LanguageSwitchButtonComponent_0, RenderType_LanguageSwitchButtonComponent)), i1.ɵdid(1, 573440, null, 0, i5.LanguageSwitchButtonComponent, [], null, null)], null, null); }
var LanguageSwitchButtonComponentNgFactory = i1.ɵccf("app-language-switch-button", i5.LanguageSwitchButtonComponent, View_LanguageSwitchButtonComponent_Host_0, { localeName: "localeName", switchButtons: "switchButtons" }, { onClick: "onClick" }, []);
export { LanguageSwitchButtonComponentNgFactory as LanguageSwitchButtonComponentNgFactory };
