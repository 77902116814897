
<!-- <div class="main-wrapper" [ngStyle]="{'background-image': 'url(/assets/image/bg.svg),url(' +topicDetail?.url+ ')'}"> -->
<!-- <div class="main-wrapper" [ngStyle]="{'background-image': 'url(),url(' +topicDetail?.url+ ')'}" [style.background-color]="topicDetail?.color.background">
  <div class="container col topic-container">
    <div class="col-md-6 px-0 main-title">
      <app-text class="topic-title" [ngStyle]="{'color': topicDetail?.color.text}">{{topicDetail?.title}}</app-text>
      <br>
      <div class="mt-2 mb-2 d-flex align-items-center">
          <app-text *ngIf="topicDetail" class="light-body-l" [ngStyle]="{'color': topicDetail?.color.text}">
            {{topicDetail?.playlist>1? topicDetail?.playlist+' STEMPath' : topicDetail?.playlist+' STEMPath'}},&nbsp;
            {{topicDetail.skill>1? topicDetail.skill+' STEMStories' : topicDetail.skill+' STEMStory'}}
          </app-text>
      </div>
      <app-text class="light-body-l" [ngStyle]="{'color': topicDetail?.color.text}">{{topicDetail?.description}}</app-text>
    </div>
    <div *ngIf="!topicDetail || topicDetail?.length==0" class="p-20">
      <app-text *ngIf="!loadingtopic" class="body-l text-s0"> There is no STEMQuest.</app-text>
      <div *ngIf="loadingtopic" class="row d-flex align-items-center">
        <div class="col-md-6">
            <app-loader [spinColor]="'#FAE6EC'" [height]="'10px'" [width]="'70%'"></app-loader>
            <app-loader [spinColor]="'#FAE6EC'" [height]="'40px'" [width]="'100%'"></app-loader>
        </div>
        <div class="col-md-4">
            <app-loader [spinColor]="'#FAE6EC'" [height]="'100px'" [width]="'100%'"></app-loader>
        </div>
      </div>
    </div>
  </div>

</div> -->
<div *ngIf="topicDetail" [style.background-color]="topicDetail?.color.background">
  <div class="d-flex justify-content-center overflow-hidden">
    <img id="topic-bg" [src]="topicDetail?.url">
  </div>
  <div class="d-flex justify-content-center">
    <div class="topic-info-text">
      <app-text class="topic-title" [ngStyle]="{'color': topicDetail?.color.text}">{{topicDetail?.title}}</app-text>
      <br>
      <div class="mt-2 mb-2 d-flex align-items-center">
          <app-text *ngIf="topicDetail" class="light-body-l" [ngStyle]="{'color': topicDetail?.color.text}">
            {{topicDetail?.playlist>1? topicDetail?.playlist+' '+locale?.STEM_path.playlists : topicDetail?.playlist+' '+locale?.STEM_path.playlist}},&nbsp;
            {{topicDetail.skill>1? topicDetail.skill+' '+locale?.STEM_story.skills : topicDetail.skill+' '+locale?.STEM_story.skill}}
          </app-text>
      </div>
      <app-text class="light-body-l" [ngStyle]="{'color': topicDetail?.color.text}">{{topicDetail?.description}}</app-text>
    </div>
  </div>
</div>
<div *ngIf="!topicDetail || topicDetail?.length==0" class="bg-s0">
  <app-text *ngIf="!loadingtopic" class="body-l text-s0"> {{locale?.STEM_quest.noQuest}}</app-text>
  <div *ngIf="loadingtopic" class="row d-flex align-items-center">
    <div class="col-md-12">
      <app-loader [spinColor]="'#FAE6EC'" [height]="'10px'" [width]="'70%'"></app-loader>
      <app-loader [spinColor]="'#FAE6EC'" [height]="'40px'" [width]="'100%'"></app-loader>
      <app-loader [spinColor]="'#FAE6EC'" [height]="'100px'" [width]="'100%'"></app-loader>
    </div>
  </div>
</div>
<div>
  <!-- <div class="recommend-image">
    <app-image class="recommend-top" [src]="'assets/image/bg.svg'" [width]="'100%'"></app-image>
  </div> -->
  <div class="bg-bg33">
    <div class="container lf-container">
      <div class="text-center">
        <app-text *ngIf="totalPlaylist==1" class="h2-strong lf-title">{{locale?.STEM_path.playlist}} <app-text *ngIf="totalPlaylist">{{totalPlaylist}}</app-text></app-text>
        <app-text *ngIf="totalPlaylist>1" class="h2-strong lf-title">{{locale?.STEM_path.playlists}} <app-text *ngIf="totalPlaylist">{{totalPlaylist}}</app-text></app-text>
      </div>
      <!-- <div class="mb-34"> -->
        <app-topic-list [loading]="loadingLf"  [locale]="locale" (onClick)="clickLearningFlowDetail($event)" class="row mb-32" [learningFlow]="alllearningFlowList"></app-topic-list>
        <app-button *ngIf="totalPlaylist>alllearningFlowList?.length" [width]="'210px'" [height]="'56px'" [class]="'button-primary'" [loading]="loadingLf" class="topic-btn" (onClick)="loadMorelearningFlow($event)">
          <app-text class="text-s0 mr-13 body-l">{{loadingLf? locale?.loading+'...' : locale?.loadMore}}</app-text> &nbsp;
          <app-image *ngIf="!loadingLf" [src]="'assets/image/downArrow.svg'"></app-image>
        </app-button>
      <!-- </div> -->
      <div *ngIf="!alllearningFlowList || alllearningFlowList?.length==0" class="pb-4">
        <app-text *ngIf="!loadingLf && !alllearningFlowList" class="body-l text-s0"> {{locale?.STEM_path.noPlaylist}}</app-text>
        <div class="row d-flex">
          <div class="col-md-6">
            <app-loader *ngIf="loadingLf" [spinColor]="'#b1b1b3'" [spinTopColor]="'#333333'" [height]="'80px'" [width]="'80%'"></app-loader>
            <app-loader *ngIf="loadingLf" [spinColor]="'#b1b1b3'" [spinTopColor]="'#333333'" [height]="'80px'" [width]="'80%'"></app-loader>
          </div>
          <div class="col-md-6">
            <app-loader *ngIf="loadingLf" [spinColor]="'#b1b1b3'" [spinTopColor]="'#333333'" [height]="'80px'" [width]="'80%'"></app-loader>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="recommend-image">
    <app-image class="recommend-bottom" [src]="'assets/image/Vector101.svg'" [width]="'100%'"></app-image>
  </div>
</div>

<app-skill-list [empty]="emptySkill" [skillList]="skillList" [totalSkill]="totalSkill" [locale]="locale" (onClickLoadMore)="loadMoreSkill($event)" [loading]="isLoadmoreLoading" (onClick)="clickSkillDetail($event)"></app-skill-list>

