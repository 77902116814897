import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-all-topic-wrapper',
    templateUrl: './all-topic-wrapper.component.html',
    styleUrls: ['./all-topic-wrapper.component.css']
})
export class AllTopicWrapperComponent {
  @Input() topicList;
  @Input() locale;

  @Output() onClick = new EventEmitter<any>();

  onTopicClick($event) {
    this.onClick.emit($event);
  }

}
