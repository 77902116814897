/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-home-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/valley-popup/valley-popup.component.ngfactory";
import * as i3 from "./components/valley-popup/valley-popup.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./components/search-landing/search-landing.component.ngfactory";
import * as i6 from "./components/search-landing/search-landing.component";
import * as i7 from "@angular/forms";
import * as i8 from "./components/topic-wrapper/topic-wrapper.component.ngfactory";
import * as i9 from "./components/topic-wrapper/topic-wrapper.component";
import * as i10 from "./components/learning-flow-wrapper/learning-flow-wrapper.component.ngfactory";
import * as i11 from "./components/learning-flow-wrapper/learning-flow-wrapper.component";
import * as i12 from "./components/weekly-skills-wrapper/weekly-skills-wrapper.component.ngfactory";
import * as i13 from "./components/weekly-skills-wrapper/weekly-skills-wrapper.component";
import * as i14 from "./components/all-topic-wrapper/all-topic-wrapper.component.ngfactory";
import * as i15 from "./components/all-topic-wrapper/all-topic-wrapper.component";
import * as i16 from "./components/all-skill-wrapper/all-skill-wrapper.component.ngfactory";
import * as i17 from "./components/all-skill-wrapper/all-skill-wrapper.component";
import * as i18 from "@angular/common";
import * as i19 from "./app-home-controller.component";
import * as i20 from "@angular/router";
import * as i21 from "ngx-toastr";
import * as i22 from "../../../lib/shared-service";
import * as i23 from "../../../lib/auth.service";
import * as i24 from "../../../lib/shared_data.service";
import * as i25 from "../../../lib/locale.service";
import * as i26 from "../business-logic.requirements";
var styles_AppHomeControllerComponent = [i0.styles];
var RenderType_AppHomeControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppHomeControllerComponent, data: {} });
export { RenderType_AppHomeControllerComponent as RenderType_AppHomeControllerComponent };
function View_AppHomeControllerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-valley-popup", [], null, [[null, "onClickClose"], [null, "onChangeCheckButton"], [null, "onClickButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClickClose" === en)) {
        var pd_0 = (_co.closevalleyPopup() !== false);
        ad = (pd_0 && ad);
    } if (("onChangeCheckButton" === en)) {
        var pd_1 = (_co.onClickNotShowAgain($event) !== false);
        ad = (pd_1 && ad);
    } if (("onClickButton" === en)) {
        var pd_2 = (_co.redirectValley() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ValleyPopupComponent_0, i2.RenderType_ValleyPopupComponent)), i1.ɵdid(1, 770048, null, 0, i3.ValleyPopupComponent, [i4.DomSanitizer], { notShowAgain: [0, "notShowAgain"], firstPopup: [1, "firstPopup"], locale: [2, "locale"] }, { onClickClose: "onClickClose", onChangeCheckButton: "onChangeCheckButton", onClickButton: "onClickButton" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notShowAgainPopup; var currVal_1 = _co.firstValleyPopup; var currVal_2 = _co.locale; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppHomeControllerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "bg-bg01"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-search-landing", [], null, [[null, "searchValue"], [null, "clickSearch"], [null, "search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchValue" === en)) {
        var pd_0 = (_co.getSearchValue($event) !== false);
        ad = (pd_0 && ad);
    } if (("clickSearch" === en)) {
        var pd_1 = (_co.searchData() !== false);
        ad = (pd_1 && ad);
    } if (("search" === en)) {
        var pd_2 = (_co.searchData() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_SearchLandingComponent_0, i5.RenderType_SearchLandingComponent)), i1.ɵdid(2, 638976, null, 0, i6.SearchLandingComponent, [i7.FormBuilder], { imgUrl: [0, "imgUrl"], data: [1, "data"], locale: [2, "locale"] }, { searchValue: "searchValue", clickSearch: "clickSearch" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-topic-wrapper", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.clickTopicDetail($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_TopicWrapperComponent_0, i8.RenderType_TopicWrapperComponent)), i1.ɵdid(4, 49152, null, 0, i9.TopicWrapperComponent, [], { topic: [0, "topic"], locale: [1, "locale"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-learning-flow-wrapper", [["class", "hide"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.clickLearningDetail($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_LearningFlowWrapperComponent_0, i10.RenderType_LearningFlowWrapperComponent)), i1.ɵdid(6, 49152, null, 0, i11.LearningFlowWrapperComponent, [], { learningFlow: [0, "learningFlow"], loading: [1, "loading"], locale: [2, "locale"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-weekly-skills-wrapper", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.clickSkillDetail($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_WeeklySkillsWrapperComponent_0, i12.RenderType_WeeklySkillsWrapperComponent)), i1.ɵdid(8, 49152, null, 0, i13.WeeklySkillsWrapperComponent, [], { newSkills: [0, "newSkills"], loading: [1, "loading"], locale: [2, "locale"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-all-topic-wrapper", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.clickTopicDetail($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_AllTopicWrapperComponent_0, i14.RenderType_AllTopicWrapperComponent)), i1.ɵdid(10, 49152, null, 0, i15.AllTopicWrapperComponent, [], { topicList: [0, "topicList"], locale: [1, "locale"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-all-skill-wrapper", [], null, [[null, "onClick"], [null, "onClickLoadMore"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.clickSkillDetail($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClickLoadMore" === en)) {
        var pd_1 = (_co.onSkillLoadMore($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i16.View_AllSKillWrapperComponent_0, i16.RenderType_AllSKillWrapperComponent)), i1.ɵdid(12, 49152, null, 0, i17.AllSKillWrapperComponent, [], { skillList: [0, "skillList"], totalSkill: [1, "totalSkill"], loading: [2, "loading"], empty: [3, "empty"], locale: [4, "locale"] }, { onClick: "onClick", onClickLoadMore: "onClickLoadMore" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHomeControllerComponent_1)), i1.ɵdid(14, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchLandingBg; var currVal_1 = _co.control; var currVal_2 = _co.locale; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.monthlyTopic; var currVal_4 = _co.locale; _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = _co.learningFlow; var currVal_6 = _co.loadingLf; var currVal_7 = _co.locale; _ck(_v, 6, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.weeklySkills; var currVal_9 = _co.loadingNew; var currVal_10 = _co.locale; _ck(_v, 8, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.topicList; var currVal_12 = _co.locale; _ck(_v, 10, 0, currVal_11, currVal_12); var currVal_13 = _co.skillList; var currVal_14 = _co.totalSkill; var currVal_15 = _co.loadmoreLoading; var currVal_16 = _co.emptySkill; var currVal_17 = _co.locale; _ck(_v, 12, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_18 = _co.showValleyPopup; _ck(_v, 14, 0, currVal_18); }, null); }
export function View_AppHomeControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-home", [], null, null, null, View_AppHomeControllerComponent_0, RenderType_AppHomeControllerComponent)), i1.ɵdid(1, 245760, null, 0, i19.AppHomeControllerComponent, [i20.ActivatedRoute, i20.Router, i21.ToastrService, i22.SharedService, i23.AuthService, i24.SharedDataService, i25.LocaleService, i26.BusinessRequirementsInjectionToken], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppHomeControllerComponentNgFactory = i1.ɵccf("app-app-home", i19.AppHomeControllerComponent, View_AppHomeControllerComponent_Host_0, {}, {}, []);
export { AppHomeControllerComponentNgFactory as AppHomeControllerComponentNgFactory };
