export const profileBackground = [
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-1.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-2.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-3.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-4.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-5.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-6.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-7.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-8.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-9.png',
  './assets/teacher-profile-background/Fancy_Plants_Solo_Plant-10.png'
];
