import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
  getMonthlyTopic(): Observable<any>;
  getRecommendedLearningFlow(): Observable<any>;
  getAllTopics(): Observable<any>;
  getAllSkills(skip, limit): Observable<any>;
  getPlayerData(playerId): Observable<any>;
  getValleyPopupStatus(data): Observable<any>;
  setValleyPopupStatus(data): Observable<any>;
  setLocalStorage(key, value);
  getLocalStorage(key);
  removeLocalStorage(key);
  getNewSkills(): Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('home Business Requirements')
