/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playlist-details-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../global-components/play-view-loading/play-view-loading.component.ngfactory";
import * as i3 from "../../../global-components/play-view-loading/play-view-loading.component";
import * as i4 from "../../../global-components/oam-view/oam-view.component.ngfactory";
import * as i5 from "../../../global-components/oam-view/oam-view.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../global-components/oam-view-data/oam-view-data.component.ngfactory";
import * as i8 from "../../../global-components/oam-view-data/oam-view-data.component";
import * as i9 from "@angular/common";
import * as i10 from "./components/playlist-related-skills/playlist-related-skills.component.ngfactory";
import * as i11 from "./components/playlist-related-skills/playlist-related-skills.component";
import * as i12 from "./components/playlist-collection/playlist-collection.component.ngfactory";
import * as i13 from "./components/playlist-collection/playlist-collection.component";
import * as i14 from "./components/related-playlist-collection/related-playlist-collection.component.ngfactory";
import * as i15 from "./components/related-playlist-collection/related-playlist-collection.component";
import * as i16 from "./playlist-details-controller.component";
import * as i17 from "@angular/router";
import * as i18 from "../../../lib/shared-service";
import * as i19 from "../../../lib/auth.service";
import * as i20 from "ngx-toastr";
import * as i21 from "../../../lib/shared_data.service";
import * as i22 from "../../../lib/locale.service";
import * as i23 from "../business-logic.requirements";
var styles_PlaylistDetailsControllerComponent = [i0.styles];
var RenderType_PlaylistDetailsControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlaylistDetailsControllerComponent, data: {} });
export { RenderType_PlaylistDetailsControllerComponent as RenderType_PlaylistDetailsControllerComponent };
function View_PlaylistDetailsControllerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-play-view-loading", [], null, null, null, i2.View_PlayViewLoadingComponent_0, i2.RenderType_PlayViewLoadingComponent)), i1.ɵdid(1, 49152, null, 0, i3.PlayViewLoadingComponent, [], { width: [0, "width"], height: [1, "height"], blockColor: [2, "blockColor"], locale: [3, "locale"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "100%"; var currVal_1 = "650px"; var currVal_2 = "#FBF7EC"; var currVal_3 = _co.locale; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PlaylistDetailsControllerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-oam-view", [], null, [[null, "onClickNavItem"], [null, "onClickLanguageBtn"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).sizeChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClickNavItem" === en)) {
        var pd_1 = (_co.goToNavItem($event) !== false);
        ad = (pd_1 && ad);
    } if (("onClickLanguageBtn" === en)) {
        var pd_2 = (_co.changeLocale($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_OamViewComponent_0, i4.RenderType_OamViewComponent)), i1.ɵdid(1, 573440, null, 0, i5.OamViewComponent, [i6.DomSanitizer], { oamData: [0, "oamData"], locale: [1, "locale"], navData: [2, "navData"], showLanguageSwitchBtn: [3, "showLanguageSwitchBtn"], localeName: [4, "localeName"], switchButtons: [5, "switchButtons"], oamSrcParams: [6, "oamSrcParams"] }, { onClickNavItem: "onClickNavItem", onClickLanguageBtn: "onClickLanguageBtn" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentOam; var currVal_1 = _co.locale; var currVal_2 = _co.playlist_nav; var currVal_3 = _co.showLanguageSwitchBtn; var currVal_4 = _co.localeName; var currVal_5 = _co.switchButtons; var currVal_6 = _co.oamSrcParams; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PlaylistDetailsControllerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-oam-view-data", [], null, null, null, i7.View_OamViewDataComponent_0, i7.RenderType_OamViewDataComponent)), i1.ɵdid(1, 49152, null, 0, i8.OamViewDataComponent, [], { oamData: [0, "oamData"], locale: [1, "locale"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentOam; var currVal_1 = _co.locale; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PlaylistDetailsControllerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-oam-view-data", [], null, null, null, i7.View_OamViewDataComponent_0, i7.RenderType_OamViewDataComponent)), i1.ɵdid(1, 49152, null, 0, i8.OamViewDataComponent, [], { oamData: [0, "oamData"], locale: [1, "locale"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentOam; var currVal_1 = _co.locale; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PlaylistDetailsControllerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "playlist-details-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "column-left bg-s0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistDetailsControllerComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistDetailsControllerComponent_3)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "oam-view-pc"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistDetailsControllerComponent_4)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "related-skills-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-playlist-related-skills", [], null, [[null, "onClickRelatedSkill"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClickRelatedSkill" === en)) {
        var pd_0 = (_co.goToSkillDetails($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_PlaylistRelatedSkillsComponent_0, i10.RenderType_PlaylistRelatedSkillsComponent)), i1.ɵdid(11, 573440, null, 0, i11.PlaylistRelatedSkillsComponent, [], { relatedSkills: [0, "relatedSkills"], loading: [1, "loading"], locale: [2, "locale"] }, { onClickRelatedSkill: "onClickRelatedSkill" }), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [["class", "column-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-playlist-collection", [], null, [[null, "playOam"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("playOam" === en)) {
        var pd_0 = (_co.playOamFromPlaylist($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_PlaylistCollectionComponent_0, i12.RenderType_PlaylistCollectionComponent)), i1.ɵdid(14, 573440, null, 0, i13.PlaylistCollectionComponent, [], { playlist: [0, "playlist"], loading: [1, "loading"], locale: [2, "locale"] }, { playOam: "playOam" }), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "oam-view-ipad"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistDetailsControllerComponent_5)), i1.ɵdid(17, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-related-playlist-collection", [], null, [[null, "onClickPlaylist"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClickPlaylist" === en)) {
        var pd_0 = (_co.onSelectPlaylist($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_RelatedPlaylistCollectionComponent_0, i14.RenderType_RelatedPlaylistCollectionComponent)), i1.ɵdid(19, 573440, null, 0, i15.RelatedPlaylistCollectionComponent, [], { relatedLearningFlow: [0, "relatedLearningFlow"], loading: [1, "loading"], locale: [2, "locale"] }, { onClickPlaylist: "onClickPlaylist" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentOam == undefined); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.currentOam != undefined); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.currentOam != undefined); _ck(_v, 8, 0, currVal_2); var currVal_3 = ((_co.playlistDetails == null) ? null : _co.playlistDetails.relatedSkills); var currVal_4 = _co.loadingSkill; var currVal_5 = _co.locale; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.playlistCollection; var currVal_7 = _co.loadingPlay; var currVal_8 = _co.locale; _ck(_v, 14, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = (_co.currentOam != undefined); _ck(_v, 17, 0, currVal_9); var currVal_10 = ((_co.playlistDetails == null) ? null : _co.playlistDetails.relatedLearningFlow); var currVal_11 = _co.loadinglf; var currVal_12 = _co.locale; _ck(_v, 19, 0, currVal_10, currVal_11, currVal_12); }, null); }
export function View_PlaylistDetailsControllerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistDetailsControllerComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alreadyLoggedin; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PlaylistDetailsControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-playlist-details", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).messageListener($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PlaylistDetailsControllerComponent_0, RenderType_PlaylistDetailsControllerComponent)), i1.ɵdid(1, 245760, null, 0, i16.PlaylistDetailsControllerComponent, [i17.ActivatedRoute, i17.Router, i18.SharedService, i19.AuthService, i20.ToastrService, i21.SharedDataService, i22.LocaleService, i23.BusinessRequirementsInjectionToken], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlaylistDetailsControllerComponentNgFactory = i1.ɵccf("app-playlist-details", i16.PlaylistDetailsControllerComponent, View_PlaylistDetailsControllerComponent_Host_0, {}, {}, []);
export { PlaylistDetailsControllerComponentNgFactory as PlaylistDetailsControllerComponentNgFactory };
