import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class SharedDataService {
    private gameUrl = environment.stemgameapiurl;

    constructor(private http: HttpClient,private _authService: AuthService,private router:Router) { }

    getUserPermittedApps(){
      let userInfo = this._authService.getUserInfo();
      let url = this.gameUrl + 'api/v2/users/permitted-apps';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          apikey: userInfo.apikey,
          username: userInfo.username,
          password: userInfo.password
        })
      };
      return this.http.get(url, httpOptions).pipe(map((res:Response) => {
        let result = res;
        return result;
      }));
    }
}
