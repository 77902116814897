import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {SearchResultControllerComponent} from './search-result-controller.component';
import {RouterModule} from '@angular/router';

import {SearchResultListComponent} from './components/search-result-list/search-result-list.component';
import {TopicListComponent} from './components/topic-list/topic-list.component';
import {LearningFlowListComponent} from './components/learning-flow-list/learning-flow-list.component';
import {SkillListComponent} from './components/skill-list/skill-list.component';

@NgModule({
    declarations: [
        SearchResultControllerComponent,
        SearchResultListComponent,
        TopicListComponent,
        LearningFlowListComponent,
        SkillListComponent
    ],
    exports: [
        SearchResultControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class SearchResultModule {}
