import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  @Input() width = '100%';
  @Input() height = '50px';
  @Input() spinColor = '#FBF7EC';
  @Input() spinTopColor = '#FFFFFF';
  @Input() spinWidth = '6px';

}
