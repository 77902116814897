import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {PlaylistService} from './api-services/playlist.service';
import {SkillsDetailsService} from './api-services/skills-details.service';
import { from, Observable } from 'rxjs';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
      private playlistApiService: PlaylistService,
      private skillsDetailsService: SkillsDetailsService
    ) {}

    getLearningflowDetails(playlistId):Observable<any>{
      return this.playlistApiService.getLearningflowDetails(playlistId);
    }

    getSkillsDetails(skillId,playlistId):Observable<any>{
      return this.skillsDetailsService.getSkillDetails(skillId,playlistId);
    }

    trackUserActivity(data):Observable<any>{
      return this.playlistApiService.userActivity(data);
    }

    getTopicDetails(topicId):Observable<any>{
      return this.playlistApiService.getTopicDetails(topicId);
    }

    updateLocale(data):Observable<any>{
      return this.skillsDetailsService.updateLocale(data);
    }
}
