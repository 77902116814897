import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {DashboardHomeControllerComponent} from './dashboard-home-controller.component';
import {RouterModule} from '@angular/router';
import {HeaderBarComponent} from './components/header-bar/header-bar.component';
import {UserPopupComponent} from './components/user-popup/user-popup.component';
import {ZoneComponent} from './components/zone/zone.component';
import {MobileSwitchZoneComponent} from './components/mobile-switch-zone/mobile-switch-zone.component';
import {OpenInAppComponent} from './components/open-in-app/open-in-app.component';
import {OpenAppDefaultComponent} from './components/open-app-default/open-app-default.component';

import {LoginModule} from '../login/login.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        DashboardHomeControllerComponent,
        HeaderBarComponent,
        UserPopupComponent,
        ZoneComponent,
        MobileSwitchZoneComponent,
        OpenInAppComponent,
        OpenAppDefaultComponent
    ],
    exports: [
        DashboardHomeControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
        LoginModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class DashboardHomeModule {}
