/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pause-academy.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../global-components/image/image.component.ngfactory";
import * as i3 from "../../../../global-components/image/image.component";
import * as i4 from "./pause-academy.component";
var styles_PauseAcademyComponent = [i0.styles];
var RenderType_PauseAcademyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PauseAcademyComponent, data: {} });
export { RenderType_PauseAcademyComponent as RenderType_PauseAcademyComponent };
export function View_PauseAcademyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "d-flex justify-content-center pauseacademy-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(4, 114688, null, 0, i3.ImageComponent, [], { src: [0, "src"], width: [1, "width"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "text-secondary700 h2-strong text-uppercase pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Our teachers are going on a break! "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "text-c100 label-text pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" In the meantime, stay tuned for an improved Academy. "]))], function (_ck, _v) { var currVal_0 = "./assets/image/break_icon_2x.png"; var currVal_1 = "250px"; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_PauseAcademyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pause-academy", [], null, null, null, View_PauseAcademyComponent_0, RenderType_PauseAcademyComponent)), i1.ɵdid(1, 49152, null, 0, i4.PauseAcademyComponent, [], null, null)], null, null); }
var PauseAcademyComponentNgFactory = i1.ɵccf("app-pause-academy", i4.PauseAcademyComponent, View_PauseAcademyComponent_Host_0, {}, {}, []);
export { PauseAcademyComponentNgFactory as PauseAcademyComponentNgFactory };
