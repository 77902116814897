import {Component, Input, Output, EventEmitter} from '@angular/core';
@Component({
    selector: 'app-topic-item',
    templateUrl: './topic-item.component.html',
    styleUrls: ['./topic-item.component.css']
})
export class TopicItemComponent {
  @Input() url='https://picsum.photos/200/300';
  @Input() title='Cell system';
  @Input() source='Amdon';
  @Input() playlist='8';
  @Input() skill='20';
  @Input() description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pulvinar tellus\\';
  @Input() topic;
  @Input() locale;
  @Output() onClick = new EventEmitter<any>();
  onTopicClick($event) {
    this.onClick.emit($event);
  }
}
