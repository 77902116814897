import {Component, Input} from '@angular/core';
import { TopicModel } from '../../models/topic.model';

@Component({
    selector: 'app-skill-item',
    templateUrl: './skill-item.component.html',
    styleUrls: ['./skill-item.component.css']
})
export class SkillItemComponent {
  public play = './assets/image/play.png';
  public play_hover = './assets/image/play_hover.png'
  // @Input('item') item: TopicModel[] = []
  @Input('item') item: TopicModel = new TopicModel()
  @Input() image = './assets/image/skill_cover.svg';
  @Input() title = "";
  @Input() duration = '1h 20m';
  @Input() type = '';
  @Input() width = '500px';
  @Input() height = '300px';
  @Input() stackPlay = false;
  @Input() titleColor = '#363F4D';
  @Input() bodyColor = '#5C6773';
  @Input() hoverColor= "";
  public imgClass = "";

  ngOnInit() {
    if(this.title != ""){
      this.imgClass = 'skill-img-cover';
    }
  }
}
