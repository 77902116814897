import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import { LiveClassMapper } from '../mappers/live-class.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetLiveClassesUseCase {
    constructor(
        private mapper: LiveClassMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
      return this.data.getLiveClasses().pipe(this.mapper.map);
    }
}
