<div class="related-skill-wrap">
  <div class="pt-40 pb-32">
    <app-text *ngIf="relatedSkills.length == 1" class="mt-2 mb-2 related-skill-header">{{ locale?.relatedSTEM_story.relatedStory }}</app-text>
    <app-text *ngIf="relatedSkills.length > 1" class="mt-2 mb-2 related-skill-header">{{ locale?.relatedSTEM_story.relatedStories }}</app-text>
  </div>
  <div class="d-flex" *ngIf="relatedSkills?.length > 0">
    <app-related-skill *ngFor="let skill of relatedSkills" [skill]="skill" (onClickSkill)="onSelectRelatedSkill(skill)"></app-related-skill>
  </div>
  <div *ngIf="relatedSkills?.length == 0">
    <app-text class="body-l text-c100">{{ locale?.relatedSTEM_story.noRelatedStory }}</app-text>
  </div>
</div>
