import {Component,Input,Output,EventEmitter, OnInit} from '@angular/core';

@Component({
    selector: 'app-related-playlist',
    templateUrl: './related-playlist.component.html',
    styleUrls: ['./related-playlist.component.css']
})
export class RelatedPlaylistComponent implements OnInit {
  @Input() data;
  @Input() locale;
  @Output() onClickLearningflow = new EventEmitter<any>();
  public moreItems = 0;
  public skillListLength = 0;

  ngOnInit(){
    console.log("locale",this.locale)
    console.log('this.data.skillList ',this.data.skillList);
    console.log(this.data.skillList.length);
    this.skillListLength = this.data.skillList.length;
    console.log('this.skillListLength ',this.skillListLength);
    if(this.skillListLength > 4){
      this.moreItems = this.skillListLength - 4;
      console.log(this.moreItems);
      this.data.skillList.splice(3,this.moreItems)
      console.log('moreItems ',this.moreItems);
    }
  }

  onSelectLearningflow(data){
    this.onClickLearningflow.emit(data)
  }

}
