import {Inject, Component, OnInit} from '@angular/core';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from '../../../lib/shared-service';
import { AuthService } from '../../../lib/auth.service';
import { environment } from 'src/environments/environment';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
    selector: 'app-login',
    templateUrl: './login-controller.component.html',
    styleUrls: ['./login-controller.component.css']
})
export class LoginControllerComponent implements OnInit {
    public isShowPwd = false;
    public username;
    public password;
    public isLoading = false;
    public isLoadingParent = false;
    public usernameError;
    public loginError;
    public previousUrl;
    public defaultHome = '/';
    public usertype;
    public parent = false;
    public adminUrl;
    public parentPortalUrl = environment.membershipUrl+ 'dashboard';
    public isApp:boolean;
    public locale: any;

    constructor(
        private router: Router,
        private _sharedService: SharedService,
        private toastr: ToastrService,
        private _authService: AuthService,
        private _localeService: LocaleService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
    }

    ngOnInit() {
      this.getJson();
        document.getElementById('dashboard-home').style.zIndex = '2';
        var url = this._sharedService.getPreviousUrl();
        this.previousUrl = url? url: this.defaultHome;

        var isAlreadyLogin = this._authService.isLoginUser();
        if(isAlreadyLogin) {
          var userObj  = this._authService.getUserInfo();
          if(userObj.usertype && userObj.usertype!='3' && userObj.usertype!=3 && userObj.usertype!='5' && userObj.usertype!=5 ){
            // this.parent = true;
            this.adminUrl = this._authService.getRedirectAdminUrl();
          }else {
            this._sharedService.setEnableLogin({enable: false, url: 'previous'});
            this.router.navigate([this.previousUrl]);
          }
        }

        console.log('this.previousURl', this.previousUrl, this.router.url);
        this.checkUserAgent();
    }

    getJson(){
      this._localeService.getJson().subscribe(data => {
        this.locale = data;
      })
    }

    checkUserAgent(){
      this._sharedService.isApp.subscribe(data => {
        this.isApp = data['isMobileApp'];
        console.log("isMobileApp",this.isApp)
      })
    }

    onClickClose() {
        this._sharedService.setEnableLogin({enable: false, url: 'previous'});
        console.log('on click close button----', this.previousUrl);
        this.router.navigate([this.previousUrl]);
    }

    loginEnterKeyEvt() {
      if(this.username && this.password) this.login();
    }

    login() {
        console.log('on click login button');
        this.isLoading = true;
        this.business.loginPlayer(this.username, this.password).subscribe(res=>{
            console.log('res----------', res[0]);
            this.isLoading = false;
            res[0].username = this.username;
            this.usertype = res[0].usertype;
            console.log("this.usertype>>",this.usertype);
            let userInfo = this._authService.encrypt(JSON.stringify(res[0]), null);
            this.business.setLocalstorage('userInfo', userInfo);
            this._sharedService.emitUserInfo(true);

            if(this.usertype == 3  || this.usertype == '3' || this.usertype == 5 || this.usertype == '5'){
                this.parent = false;
                if(this.router.url=='/login') {
                  console.log('navigate to previousurl')
                  this.onClickClose();
                }else {
                  this.business.getUserInfo().subscribe(res => {
                    let language = JSON.parse(this.business.getLocalstorage("userLocale"));
                    let currentLanguage = res[0].currentLocale == undefined || res[0].currentLocale == null ? res[0].locale : res[0].currentLocale;
                    let user_locale = {
                      "locale": res[0].locale,
                      "secondaryLocale": res[0].secondaryLocale
                    }
                    if(language == undefined || (language.locale != res[0].locale || language.locale != res[0].secondaryLocale)){
                      this.business.setLocalstorage("userLocale",JSON.stringify(user_locale));
                      this.business.setLocalstorage("current_locale",currentLanguage);
                    }
                    this.navigateToNextRoute()
                  },err => {
                    this.navigateToNextRoute()
                  })
                }
            }else {
              this.adminUrl = this._authService.getRedirectAdminUrl();
              // this.parent = true;
            }

            // window.history.back();
        }, err=>{
            console.log('login error', err);
            this.isLoading = false;
            // this.toastr.error('Invalid log in.')
            // this.username = null;
            // this.password = null;
            if(err.error && err.error.message){
              if(err.error.message.includes('User does not exist')) this.usernameError = err.error.message;
              else this.loginError = err.error.message;
            }
            else this.loginError = 'Invalid log in';
        });
    }

    navigateToNextRoute(){
      console.log('navigate to next route');
      this._sharedService.setEnableLogin({enable: false, url: null});
    }

    onShowPwd(e) {
        console.log('on show pwd',e)
        this.isShowPwd = !this.isShowPwd;
    }

    onInputChange(value, type) {
        this.loginError = null;
        this.usernameError = null;
        if(type=='username') {
          this.username = value? value: null;
        }
        else if(type=='password') {
          this.password = value? value: null;
        }
    }

    ngOnDestroy() {
        document.getElementById('dashboard-home').style.zIndex = null;
    }

    onClickLogout() {
        this.business.removeLocalstorage('userInfo');
        this._sharedService.emitUserInfo(true);
        this.parent = false;
    }

    clickAdminLogin() {
      console.log('click admin login');
      this.isLoadingParent = true;
      setTimeout(() => {
        this.onClickAdminClose();
      }, 500);
    }

    onClickAdminClose() {
      this.onClickLogout();
      this.onClickClose();
    }

}
