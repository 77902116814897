import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import { FormControl, AbstractControl, Validators, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-search-landing',
    templateUrl: './search-landing.component.html',
    styleUrls: ['./search-landing.component.css']
})
export class SearchLandingComponent implements OnChanges {
  @Input() imgUrl;
  @Input() data: AbstractControl | FormControl;
  @Input() locale;
  @Output() searchValue = new EventEmitter<any>();
  @Output() clickSearch = new EventEmitter<any>();

  private searchData = null;
  public form = this.fb.group({
    value: this.fb.control('', [Validators.required]),
  });

  constructor( private fb: FormBuilder) {
  }

  ngOnInit(){
    this.form.valueChanges.subscribe(value => {
      this.searchData = value;
      this.searchValue.emit(this.searchData.value);
    });
  }

  ngOnChanges(){

  }

  getSearchValue($event){
    this.searchValue.emit($event);
  }

  onSearchClick(){
    this.clickSearch.emit();
  }
}
