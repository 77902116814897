import { TeacherProfile } from '../../../models/teacher-profile.model';
import { ItemMapper } from '../../../lib/item-mapper';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../environments/environment';

export class TeacherProfileMapper extends ItemMapper<TeacherProfile> {
    protected mapFromItem(item): TeacherProfile {
      let teacherProfile = new TeacherProfile();

      let userData = this.getUserInfo();
      console.log("userData",userData)

      teacherProfile.profile.name = item.teacherFullName;
      teacherProfile.profile.imgUrl = item.teacherProfileImageUrl;
      teacherProfile.profile.location = item.location;
      teacherProfile.profile.position = item.position;
      teacherProfile.profile.bio = item.bio;
      teacherProfile.profile.teacherId = item.teacherId;

      if(item.classes.length > 0){
        item.classes.map(data => {
          let classData = {}
          if(data.classId){
            classData["id"] = data.classId;
            classData["title"] = data.className;
            classData["description"] = data.classDescription;
            classData["startDate"] = data.classStartDate;
            classData["endDate"] = data.classEndDate;
            classData["totalSeat"] = data.totalNumberOfSeat;
            classData["seatTaken"] = data.seatTaken;
            classData["seatLeft"] = data.seatLeft;
            classData["background"] = '#D3B3FD',
            classData["note"] = 'Save your spot!',
            classData["button"] = {};
            classData["zoomLink"] = data.zoomLink == undefined || data.zoomLink == '' ? '' : data.zoomLink;
            if(data.students.length > 0){
              let index = data.students.indexOf(userData.userid);
              classData["isEnrolled"] = index == -1 ? false : true;
            }else{
              classData["isEnrolled"] = false;
            }
            console.log("class~~~",classData)
            teacherProfile.classes.push(classData);
          }else{
            classData = data;
          }
        })
        console.log("teacherProfile classes",teacherProfile.classes)
      }

      return teacherProfile;
    }

    getUserInfo() {
        try {
          var encodedData = localStorage.getItem('userInfo');
          var userInfo = JSON.parse(this.decrypt(encodedData, null));
          return userInfo;
        }
        catch(err) {
          localStorage.clear();
        }
    }

    decrypt(encrypted:string, secretkey: string) {
        let decrypted = CryptoJS.AES.decrypt(encrypted, secretkey? secretkey: environment.secretkey).toString(CryptoJS.enc.Utf8);
        return decrypted;
    }
}
