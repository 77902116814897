import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/skill.mapper";
import * as i2 from "../data.requirements";
export class GetAllSkillUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(skip, limit) {
        return this.data.getAllSkill(skip, limit).pipe(this.mapper.map);
    }
}
GetAllSkillUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetAllSkillUseCase_Factory() { return new GetAllSkillUseCase(i0.ɵɵinject(i1.SkillMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetAllSkillUseCase, providedIn: "root" });
