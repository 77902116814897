<div class="shimmerBG title-line end {{header_loading}}"></div>
<div class="{{wrapper_class}}">
  <div class="{{card_class}}">
    <div class="shimmerBG media"></div>
    <div class="m-16">
      <div class="shimmerBG title-line end"></div>
      <div class="shimmerBG content-line m-t-4"></div>
    </div>
  </div>
  <div class="{{card_class}}">
    <div class="shimmerBG media"></div>
    <div class="m-16">
      <div class="shimmerBG title-line end"></div>
      <div class="shimmerBG content-line m-t-4"></div>
    </div>
  </div>
  <div class="{{card_class}}">
    <div class="shimmerBG media"></div>
    <div class="m-16">
      <div class="shimmerBG title-line end"></div>
      <div class="shimmerBG content-line m-t-4"></div>
    </div>
  </div>
</div>
