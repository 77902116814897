import {Component} from '@angular/core';

@Component({
    selector: 'app-pause-academy',
    templateUrl: './pause-academy.component.html',
    styleUrls: ['./pause-academy.component.css']
})
export class PauseAcademyComponent {

}
