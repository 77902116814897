import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import { environment } from 'src/environments/environment';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {AuthService} from '../../../lib/auth.service';

@Injectable({
    providedIn: 'root'
})
export class SkillService {
    public userInfo;
    private baseUrl = environment.apiurl + 'api/v1/';
    private gameurl = environment.stemgameapiurl + 'api/v2/';
    constructor(private http: HttpClient,private _authService:AuthService) {}

    getSkillDetails(skillId){
      this.userInfo = this._authService.getUserInfo();
      console.log("getSkillDetails API",skillId);
      let url = this.baseUrl + 'skills/' + skillId;
      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            api_key: this.userInfo.apikey,
            username: this.userInfo.username,
            password: this.userInfo.password,
            option: 'urlPath'
        })
      };
      return this.http.get(url,httpOptions).pipe(map((res:Response)=>{
        console.log("getSkillDetails API res~~~",res)
        let result = [];
        result.push(res);
        return result;
      }));
    }

    userActivity(data){
      this.userInfo = this._authService.getUserInfo();
      data["userID"] = this.userInfo.userid;
      // let url = this.gameurl + 'userActivity';
      let url = this.gameurl + 'resource-usage';
      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            api_key: this.userInfo.apikey,
            username: this.userInfo.username,
            password: this.userInfo.password
        })
      };
      return this.http.post(url,data,httpOptions).pipe(map((res:Response)=>{
        let result = [];
        result.push(res);
        return result;
      }))
    }

    getTopicDetails(topicId){
      this.userInfo = this._authService.getUserInfo();
      console.log("getTopicDetails API",topicId);
      let url = this.baseUrl + 'topics/' + topicId;
      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            api_key: this.userInfo.apikey,
            username: this.userInfo.username,
            password: this.userInfo.password,
            option: 'urlPath'
        })
      };
      return this.http.get(url,httpOptions).pipe(map((res:Response)=>{
        console.log("getTopicDetails API res~~~",res)
        let result = [];
        result.push(res);
        return result;
      }));
    }

    updateLocale(data){
      this.userInfo = this._authService.getUserInfo();
      let url = this.gameurl + 'users/' + this.userInfo.userid;
      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            api_key: this.userInfo.apikey,
            username: this.userInfo.username,
            password: this.userInfo.password
        })
      };
      return this.http.put(url,data,httpOptions).pipe(map((res:Response)=>{
        let result = [];
        result.push(res);
        return result;
      }))
    }
}
