import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-search-result-list',
    templateUrl: './search-result-list.component.html',
    styleUrls: ['./search-result-list.component.css']
})
export class SearchResultListComponent {
  @Input() topic;
  @Input() skill;
  @Input() playlist;
  @Input() sort;
  @Input() loading;
  @Input() locale;

  @Output() clickSearch = new EventEmitter<any>();

  ngOnInit(){
    console.log(this.locale,'locales')
  }

  onClickSearch(type) {
    this.clickSearch.emit(type);
  }

}
