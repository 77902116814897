import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {SkillMapper} from '../mappers/skill.mapper';

@Injectable({
    providedIn: 'root'
})
export class SkillDetailUseCase {
    constructor(
        private mapper: SkillMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(skillId) {
      return this.data.getSkillDetails(skillId).pipe(this.mapper.map)
    }
}
