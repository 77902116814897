import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TopicService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl + 'api/v1/';
    }
    getTopic(topicId) {
        console.log("topicId~~~", topicId);
        let url = this.baseUrl + 'topics/' + topicId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                option: 'urlPath'
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            console.log("topic service result", result);
            return result;
        }));
    }
}
TopicService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TopicService_Factory() { return new TopicService(i0.ɵɵinject(i1.HttpClient)); }, token: TopicService, providedIn: "root" });
