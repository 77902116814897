/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../global-components/image/image.component.ngfactory";
import * as i3 from "../../../global-components/image/image.component";
import * as i4 from "../../../global-components/text/text.component.ngfactory";
import * as i5 from "../../../global-components/text/text.component";
import * as i6 from "../../../global-components/button/button.component.ngfactory";
import * as i7 from "../../../global-components/button/button.component";
import * as i8 from "./page-not-found-controller.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../lib/locale.service";
import * as i11 from "../business-logic.requirements";
var styles_PageNotFoundControllerComponent = [i0.styles];
var RenderType_PageNotFoundControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundControllerComponent, data: {} });
export { RenderType_PageNotFoundControllerComponent as RenderType_PageNotFoundControllerComponent };
export function View_PageNotFoundControllerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "d-flex justify-content-center not-found-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "w-100 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(4, 114688, null, 0, i3.ImageComponent, [], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "not-found-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-text", [["class", "text-c300 notfound-header"]], null, null, null, i4.View_TextComponent_0, i4.RenderType_TextComponent)), i1.ɵdid(7, 49152, null, 0, i5.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(8, 0, ["", "..."])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "not-found-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "app-text", [["class", "body-xl-light text-c100 lh-32"]], null, null, null, i4.View_TextComponent_0, i4.RenderType_TextComponent)), i1.ɵdid(11, 49152, null, 0, i5.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(12, 0, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "w-100 d-flex justify-content-center pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "app-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.navigateToHome($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(15, 49152, null, 0, i7.ButtonComponent, [], { class: [0, "class"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "app-text", [["class", "body-l"]], null, null, null, i4.View_TextComponent_0, i4.RenderType_TextComponent)), i1.ɵdid(17, 49152, null, 0, i5.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(18, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "./assets/image/page-not-found.png"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "text-c300 notfound-header"; _ck(_v, 7, 0, currVal_1); var currVal_3 = "body-xl-light text-c100 lh-32"; _ck(_v, 11, 0, currVal_3); var currVal_5 = "flat-button"; _ck(_v, 15, 0, currVal_5); var currVal_6 = "body-l"; _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.locale == null) ? null : _co.locale.pageNotFound.title); _ck(_v, 8, 0, currVal_2); var currVal_4 = ((_co.locale == null) ? null : _co.locale.pageNotFound.body); _ck(_v, 12, 0, currVal_4); var currVal_7 = ((_co.locale == null) ? null : _co.locale.pageNotFound.btnText); _ck(_v, 18, 0, currVal_7); }); }
export function View_PageNotFoundControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundControllerComponent_0, RenderType_PageNotFoundControllerComponent)), i1.ɵdid(1, 114688, null, 0, i8.PageNotFoundControllerComponent, [i9.Router, i10.LocaleService, i11.BusinessRequirementsInjectionToken], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundControllerComponentNgFactory = i1.ɵccf("app-page-not-found", i8.PageNotFoundControllerComponent, View_PageNotFoundControllerComponent_Host_0, {}, {}, []);
export { PageNotFoundControllerComponentNgFactory as PageNotFoundControllerComponentNgFactory };
