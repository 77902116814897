/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "bg-accent100 button-loading": 0, "bg-s60 body-m text-s70 cursor-disable": 1, "body-m text-s0 bg-accent500": 2, "body-m text-accent500 bg-s0": 3, "body-m text-c300 bg-s30": 4 }), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "width": 0, "height": 1, "padding": 2 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.disabled || _co.loading) ? "" : _co.class); var currVal_1 = _ck(_v, 3, 0, _co.loading, (_co.disabled && !_co.loading), (((_co.class == "button-primary") && !_co.disabled) && !_co.loading), (((_co.class == "button-secondary") && !_co.disabled) && !_co.loading), (((_co.class == "button-tertiary") && !_co.disabled) && !_co.loading)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 6, 0, (_co.width ? _co.width : "auto"), (_co.height ? _co.height : "auto"), _co.padding); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.ButtonComponent, [], null, null)], null, null); }
var ButtonComponentNgFactory = i1.ɵccf("app-button", i3.ButtonComponent, View_ButtonComponent_Host_0, { disabled: "disabled", class: "class", width: "width", height: "height", loading: "loading", padding: "padding" }, { onClick: "onClick" }, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
