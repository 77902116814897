<div *ngIf="title != ''" class="skill-item-wrap w-340" [ngStyle]="{'width': width}">
  <!-- <div *ngIf="image" class="skill-image-wrapper" [ngStyle]="{'width': width, height: height}">
    <app-image [src]="image" [class]="imgClass" [width]="width" [height]="height"></app-image>
  </div> -->
  <div *ngIf="image" class="skill-image-wrapper">
    <img [src]="image" class="skill-img-cover" [ngStyle]="{'width': width, height: height}">
  </div>
  <div *ngIf="!image" class="img-default bg-main100" [ngStyle]="{'width': width, 'height': height}"></div>
  <!-- <img [src]="image" class="item-cover" > -->
  <div class="d-flex align-items-start mt-12 relative">
    <div class="play">
      <app-image [src]="play" [ngClass]="stackPlay? 'stack-image' : 'mr-12'" [width]="'48px'"></app-image>
    </div>
    <div class="play_hover">
      <app-image [src]="play_hover" [ngClass]="stackPlay? 'stack-image' : 'mr-12'" [width]="'48px'"></app-image>
    </div>
    <div class="d-flex flex-column relative w-84">
      <app-text class="h3-strong" [ngStyle]="{'color': titleColor}" [ngClass]="hoverColor == 'accent500' ? 'skill-title-accent' : 'skill-title-main'" >{{title}}</app-text>
      <div class="d-flex align-items-center light-body-l" [ngStyle]="{'color': bodyColor}">
        <!-- <app-text>{{duration}}</app-text>
        <div *ngIf="duration && duration != '' && type" class="dot"> </div> -->
        <app-text *ngIf="type">{{type}}</app-text>
      </div>
    </div>
  </div>
</div>

<div *ngIf="title == ''" class="empty-div" [ngStyle]="{'width': width}">
  <app-image *ngIf="image" [src]="image" [class]="imgClass" [width]="width" [height]="height"></app-image>
  <div *ngIf="!image" class="img-default bg-main100" [ngStyle]="{'width': width, 'height': height}"></div>
  <!-- <img [src]="image" class="item-cover" > -->
</div>


