<div class="teacher-profile-wrapper">
  <app-profile-header [profileBgImage]="profileBgImage" [profileBgColor]="profileBgColor" [profileImage]="profileImage" [isLoading]="isLoading" (onClickBackArrowBtn)="onClickBackArrowButton()"></app-profile-header>
  <div *ngIf="!isLoading" class="profile-column-wrapper jusitify-content-center profile-info-wrapper">
   <div class="info-column p-0">
      <app-profile-info [teacherProfile]="teacherInfo" [locale]="locale"></app-profile-info>
   </div>
   <div class="classlist-column p-0 class-list-wrapper">
    <app-class-lists [classes]="teacherClasses" [locale]="locale" [usertype]="userData.usertype" (clickEnrollBtn)="onClickClass($event)"></app-class-lists>
   </div>
  </div>
  <div *ngIf="isLoading" class="profile-column-wrapper jusitify-content-center profile-info-wrapper">
    <div class="info-column p-0">
      <app-loader [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
      <app-loader [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
    </div>
    <div class="classlist-column p-0 class-list-wrapper">
      <div class="class-list-loading">
        <app-loader [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
        <app-loader [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
      </div>
      <div class="class-list-loading">
        <app-loader [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
        <app-loader [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
      </div>
    </div>
  </div>
  <app-custom-notification *ngIf="notiPoup" [locale]="locale" [user]="userData" [lesson]="lesson" (onClickDismiss)="hideNoti()"></app-custom-notification>
  <app-custom-popup *ngIf="errorPoup" [locale]="locale" [lesson]="lesson" [isAndroidApp]="isAndroidApp" (onClickClose)="hideErrorPopup()"></app-custom-popup>
  <app-custom-popup *ngIf="joinPopup.enable" [locale]="locale" [isAndroidApp]="isAndroidApp" (onClickClose)="hideJoinPopup($event)" [title]="joinPopup.title" [joinInstruction]="joinPopup"></app-custom-popup>
</div>
