import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/permitted-apps.mapper";
import * as i2 from "../data.requirements";
export class PermittedAppsUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(userId) {
        return this.data.getPermittedApps(userId).pipe(this.mapper.map);
    }
}
PermittedAppsUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermittedAppsUseCase_Factory() { return new PermittedAppsUseCase(i0.ɵɵinject(i1.PermittedAppsMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: PermittedAppsUseCase, providedIn: "root" });
