<!-- <div class="input-group d-flex align-items-center search-group"
[ngStyle]="{
  'width' : boxWidth !== null ? boxWidth : '400px',
  'height' : boxHeight !== null ? boxHeight : '48px'
}"
>
  <span class="input-group-append">

    <app-image (click)="clickSearchIMage()" [src]="'./assets/image/search_l.svg'"></app-image>
  </span>
  <app-input [class]="inputSearch"
  type="search"
  [hint]="hint"
  id="staff-search-input"
  [control]="form.get('searchData')"
  [width]="width"
  [id]="'Search'"
  >
  </app-input>
</div> -->
<div class="input-group d-flex align-items-center" [ngClass]="{'search-group': type != 'searchLanding', 'search-landing-group':  type == 'searchLanding'}"
[ngStyle]="{
  'width' : boxWidth !== null ? boxWidth : '400px',
  'height' : boxHeight !== null ? boxHeight : '48px'
}"
>
  <app-input [class]="type == 'searchLanding' ? inputSearchLanding : inputSearch"
  type="search"
  [hint]="hint"
  id="staff-search-input"
  [control]="form.get('searchData')"
  [width]="'100%'"
  [height]="type == 'searchLanding' ? boxHeight : '48px'"
  [id]="id"
  (searchImgclick)="searchImgclick()"
  >
  </app-input>
  <span *ngIf="type == 'searchLanding'" class="d-flex justify-content-center align-items-center bg-accent500 icon-wrapper" (click)="searchImgclick()">
    <app-image [src]="'./assets/image/white-search-icon.svg'" [width]="'18px'" [height]="'18px'"></app-image>
  </span>
</div>
