/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./oam-view-data.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../text/text.component.ngfactory";
import * as i3 from "../text/text.component";
import * as i4 from "../brush/brush.component.ngfactory";
import * as i5 from "../brush/brush.component";
import * as i6 from "./oam-view-data.component";
var styles_OamViewDataComponent = [i0.styles];
var RenderType_OamViewDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OamViewDataComponent, data: {} });
export { RenderType_OamViewDataComponent as RenderType_OamViewDataComponent };
export function View_OamViewDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "oam-detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "oam-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-text", [["class", "title text-bg33 pt-4"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(3, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-brush", [], null, null, null, i4.View_BrushComponent_0, i4.RenderType_BrushComponent)), i1.ɵdid(6, 114688, null, 0, i5.BrushComponent, [], { brushColor: [0, "brushColor"], color: [1, "color"], padding: [2, "padding"] }, null), (_l()(), i1.ɵted(7, 0, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "oam-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "app-text", [["class", "body-xl-light text-c100"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(10, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(11, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "title text-bg33 pt-4"; _ck(_v, 3, 0, currVal_0); var currVal_2 = "bg-s30"; var currVal_3 = "text-bg33"; var currVal_4 = 17; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = "body-xl-light text-c100"; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.oamData == null) ? null : _co.oamData.name); _ck(_v, 4, 0, currVal_1); var currVal_5 = ((_co.locale == null) ? null : _co.locale.oamViewData.about); _ck(_v, 7, 0, currVal_5); var currVal_7 = ((_co.oamData == null) ? null : _co.oamData.description); _ck(_v, 11, 0, currVal_7); }); }
export function View_OamViewDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-oam-view-data", [], null, null, null, View_OamViewDataComponent_0, RenderType_OamViewDataComponent)), i1.ɵdid(1, 49152, null, 0, i6.OamViewDataComponent, [], null, null)], null, null); }
var OamViewDataComponentNgFactory = i1.ɵccf("app-oam-view-data", i6.OamViewDataComponent, View_OamViewDataComponent_Host_0, { oamData: "oamData", locale: "locale" }, {}, []);
export { OamViewDataComponentNgFactory as OamViewDataComponentNgFactory };
