/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./text.component";
var styles_TextComponent = [i0.styles];
var RenderType_TextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextComponent, data: {} });
export { RenderType_TextComponent as RenderType_TextComponent };
export function View_TextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], [[8, "className", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "cursor": 0, "width": 1 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, _co.cursor, _co.width); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.class; _ck(_v, 0, 0, currVal_0); }); }
export function View_TextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text", [], null, null, null, View_TextComponent_0, RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextComponent, [], null, null)], null, null); }
var TextComponentNgFactory = i1.ɵccf("app-text", i3.TextComponent, View_TextComponent_Host_0, { class: "class", cursor: "cursor", width: "width" }, {}, ["*"]);
export { TextComponentNgFactory as TextComponentNgFactory };
