import {Component,Input} from '@angular/core';

@Component({
    selector: 'app-oam-view-data',
    templateUrl: './oam-view-data.component.html',
    styleUrls: ['./oam-view-data.component.css']
})
export class OamViewDataComponent {
  @Input() oamData;
  @Input() locale;
}
