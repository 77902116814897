import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/teacher-profile.mapper";
import * as i2 from "../data.requirements";
export class TeacherProfileUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(teacherId) {
        return this.data.getTeacherProfile(teacherId).pipe(this.mapper.map);
    }
}
TeacherProfileUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TeacherProfileUseCase_Factory() { return new TeacherProfileUseCase(i0.ɵɵinject(i1.TeacherProfileMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: TeacherProfileUseCase, providedIn: "root" });
