import {Component,Input,Output,EventEmitter} from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent {
  @Input() isChecked:boolean;
  @Input() locale;
  @Output() onChange = new EventEmitter<any>();

  onChangeCheckbox(value:boolean){
    this.isChecked = value == false ? true : false;
    this.onChange.emit(this.isChecked);
  }
}
