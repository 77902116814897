import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/topic.mapper";
import * as i2 from "../data.requirements";
export class TopicUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(topicId) {
        // return this.data.getTopicDetail(topicId);
        return this.data.getTopicDetail(topicId).pipe(this.mapper.map);
    }
}
TopicUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TopicUseCase_Factory() { return new TopicUseCase(i0.ɵɵinject(i1.TopicMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: TopicUseCase, providedIn: "root" });
