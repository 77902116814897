import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface DataRequirements {
  getLearningflowDetails(playlistsId):Observable<any>
  getSkillsDetails(skillId,playlistsId):Observable<any>
  trackUserActivity(data):Observable<any>
  getTopicDetails(topicId):Observable<any>
  updateLocale(data):Observable<any>
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('playlists Data Requirements')
