export const LanguageSwitchButton = {
  firstButton: {
    "buttonText": "",
    "buttonLocale": "",
    "flag": ""
  },
  secondButton: {
    "buttonText": "",
    "buttonLocale": "",
    "flag": ""
  }
}
