import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../global-components/global-components.module';
import {SharedComponentsModule} from './presentation/shared-components/shared-components.module';
import {BusinessLogicFacade} from './business-logic/business-logic.facade';
import {BusinessRequirementsInjectionToken} from './presentation/business-logic.requirements';

import {SkillHomeModule} from './presentation/skill-home/skill-home.module';
import {SkillsDetailsModule} from './presentation/skills-details/skills-details.module';
import { from } from 'rxjs';

@NgModule({
    imports: [
        BusinessLogicFacade,
        CommonModule,
        GlobalComponentsModule,
        SharedComponentsModule,

        SkillHomeModule,
        SkillsDetailsModule
    ],
    providers: [
        {
            provide: BusinessRequirementsInjectionToken,
            useClass: BusinessLogicFacade
        }
    ],
    exports: []
})
export class SkillsFeatureModule {}
