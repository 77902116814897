import { LearningFlowModel } from '../../../models/learning-flow.model';
import {ItemMapper} from '../../../lib/item-mapper';
import { TotalSkillTopicPlaylistModel } from '../../../models/total-skill-topic-playlist.model';

export class LearningFlowMapper extends ItemMapper<TotalSkillTopicPlaylistModel> {
    protected mapFromItem(item): TotalSkillTopicPlaylistModel {
          const searchResult = new TotalSkillTopicPlaylistModel();
          searchResult.playlist = item.total;
          searchResult.data = [];
          item.result.forEach(element => {
            const lfObj = new LearningFlowModel();
            lfObj.id = element.urlPath;
            lfObj.title = element.name;
            lfObj.description = element.about;
            lfObj.author = '';
            lfObj.duration = element.duration;
            lfObj.skillList = [];
            // lfObj.topicId = item.result[0].topicId;
            lfObj.topicId = element.topicInfo? element.topicInfo.urlPath: null;
            lfObj.skillId = element.skills[0].urlPath;
            element.skills.forEach(skill => {
                lfObj.skillList.push(skill.details? skill.details.targetFileThumbnailURL : null);
                lfObj.author = skill.details? skill.details.author : null;
            });
            searchResult.data.push(lfObj);
        });

          return searchResult;
      }
}

