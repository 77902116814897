import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ClassCardStore} from './class-card.store';

@Component({
    selector: 'app-liveclass-card',
    templateUrl: './liveclass-card.component.html',
    styleUrls: ['./liveclass-card.component.css']
})
export class LiveclassCardComponent {
    constructor(private store: ClassCardStore) {}

    @Input() liveclass;
    @Input() locale;
    @Input() usertype;
    @Output() clickenroll = new EventEmitter<any>();

    public teacherProfile = '';
    public startdate = '';
    public time;
    public fullName = '';

    ngOnInit() {
        this.teacherProfile = this.liveclass.teacherProfile;
        this.fullName = this.liveclass.teacherName;
    }

    classClick($event) {
        this.clickenroll.emit($event);
    }
}
