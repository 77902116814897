<div *ngIf="alreadyLoggedin" class="skill-details-wrapper">
  <div class="column-left bg-s0">
    <!-- <div [ngClass]="{'oam-nav-container': currentOam != undefined && showLanguageSwitchBtn}">
      <div [ngClass]="{'item-left': currentOam != undefined && showLanguageSwitchBtn, 'nav-container': currentOam == undefined || !showLanguageSwitchBtn}">
        <app-breadcrump-nav [data]="skill_nav" [locale]="locale" (onClickNav)="goToNavItem($event)"></app-breadcrump-nav>
      </div>
      <div *ngIf="currentOam != undefined && showLanguageSwitchBtn" class="item-right">
        <app-language-switch-button [switchButtons]="switchButtons" [localeName]="localeName" (onClick)="changeLocale($event)"></app-language-switch-button>
      </div>
    </div> -->
    <app-play-view-loading *ngIf="currentOam == undefined" [width]="'100%'" [height]="'650px'" [blockColor]="'#FBF7EC'" [locale]="locale"></app-play-view-loading>
    <app-oam-view *ngIf="currentOam != undefined" [oamData]="currentOam" [locale]="locale" [navData]="skill_nav" [showLanguageSwitchBtn]="showLanguageSwitchBtn" [switchButtons]="switchButtons" [localeName]="localeName" [oamSrcParams]="oamSrcParams" (onClickNavItem)="goToNavItem($event)" (onClickLanguageBtn)="changeLocale($event)"></app-oam-view>
    <app-oam-view-data *ngIf="currentOam != undefined" [oamData]="currentOam" [locale]="locale"></app-oam-view-data>
    <app-skill-loading *ngIf="isLoading" [wrapper_class]="'skills-loading-wrapper'" [card_class]="'loading-card'" [header_loading]="'related-skill-header'" [locale]="locale"></app-skill-loading>
    <app-related-skills-collection *ngIf="!isLoading" [relatedSkills]="skillDetails?.relatedSkills" [locale]="locale" (onClickRelatedSkill)="onSelectSkill($event)"></app-related-skills-collection>
  </div>
  <div class="column-right">
    <app-skill-loading *ngIf="isLoading" [wrapper_class]="'upnext-loading'" [card_class]="'upnext-card'" [header_loading]="'upnext-header'" [locale]="locale"></app-skill-loading>
    <app-upnext-skills-collection *ngIf="!isLoading" [upnext]="skillDetails?.upNext" [locale]="locale" (onClickUpnextSkill)="onSelectSkill($event)"></app-upnext-skills-collection>
  </div>
</div>
