<div class="bg-s20 search-wrap">
  <app-search-result-list *ngIf="topicList?.length==0 && totalPlaylist==0 && totalSkill==0" [skill]="totalSkill" [loading]="!loadingSkill && !loadingPlaylist && !loadingTopic? false: true"
  [playlist]="totalPlaylist" [topic]="topicList?.length" [sort]="filterType" [locale]="locale"
  (clickSearch)="onClickFilter($event)">
  </app-search-result-list>
  <div class="bg-main500">

        <!-- <app-search-result-list [skill]="totalSkill" [loading]="!loadingSkill && !loadingPlaylist && !loadingTopic? false: true"
          [playlist]="totalPlaylist" [topic]="topicList?.length" [sort]="filterType"
          (clickSearch)="onClickFilter($event)">
        </app-search-result-list>

        <app-topic-list *ngIf="(filterType==0 || filterType==1) && topicList?.length>0"
          [topic]="topicList" (onClick)="onClickTopic($event)">
        </app-topic-list>

        <app-learning-flow-list *ngIf="(filterType==0 || filterType==2) && learningFlowList?.length>0"
          [loading]="loadingLFButton" [topic]="topicList?.length" [learningFlowList]="learningFlowList" [learningFlowCount]="totalPlaylist" [sort]="filterType"
          (onClick)="onClickLearningFlow($event)" (onClickLoadMore)="loadMoreLearningFlow($event)">
        </app-learning-flow-list>

        <app-skill-list *ngIf="(filterType==0 || filterType==3) && skillList?.length>0"
          [skillList]="skillList" [totalSkill]="totalSkill" [loading]="loadingSkillButton"
          (onClick)="onClickSkill($event)" (onClickLoadMore)="loadMoreSkill($event)">
        </app-skill-list> -->
        <app-search-result-list *ngIf="topicList?.length!=0 || totalPlaylist!=0 || totalSkill!=0" [skill]="totalSkill" [loading]="!loadingSkill && !loadingPlaylist && !loadingTopic? false: true"
        [playlist]="totalPlaylist" [topic]="topicList?.length" [sort]="filterType" [locale]="locale"
        (clickSearch)="onClickFilter($event)">
        </app-search-result-list>

        <app-topic-list id="1" *ngIf="topicList?.length>0" [locale]="locale"
          [topic]="topicList" (onClick)="onClickTopic($event)">
        </app-topic-list>

        <app-learning-flow-list id="2" *ngIf="learningFlowList?.length>0" [locale]="locale"
          [loading]="loadingLFButton" [topic]="topicList?.length" [learningFlowList]="learningFlowList" [learningFlowCount]="totalPlaylist" [sort]="filterType"
          (onClick)="onClickLearningFlow($event)" (onClickLoadMore)="loadMoreLearningFlow($event)">
        </app-learning-flow-list>

        <app-skill-list id="3" *ngIf="skillList?.length>0" [locale]="locale"
          [skillList]="skillList" [totalSkill]="totalSkill" [loading]="loadingSkillButton"
          (onClick)="onClickSkill($event)" (onClickLoadMore)="loadMoreSkill($event)">
        </app-skill-list>

        <div *ngIf="(loadingSkill || loadingPlaylist || loadingTopic) && !loadingSkillButton && !loadingLFButton" class="loading-wrap bg-s20">
            <div class="main-loader"></div>
        </div>

    </div>
</div>
