import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TopicListStore} from './topic-list.store';
import { TopicModel } from '../../../../../models/topic.model';

@Component({
    selector: 'app-topic-list',
    templateUrl: './topic-list.component.html',
    styleUrls: ['./topic-list.component.css']
})
export class TopicListComponent {
    @Input() loading;
    @Input('learningFlow') learningFlow: TopicModel[] = []
    @Input() locale;
    @Output() onClick = new EventEmitter<any>();
    onlfClick($event) {
        console.log("$event>>",$event)
        this.onClick.emit($event);
    }

}
