import { TeacherModel } from '../../../models/teacher.model';
import { ItemMapper } from '../../../lib/item-mapper';

export class TeacherListMapper extends ItemMapper<TeacherModel> {
    protected mapFromItem(item): TeacherModel {
      const teacherModel = new TeacherModel();
      teacherModel.name = item.teacherFullName;
      teacherModel.imgUrl = item.teacherProfileImageUrl;
      teacherModel.teacherId = item.teacherId;
      return teacherModel;
    }
}
