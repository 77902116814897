import { LearningFlowModel } from '../../../models/learning-flow.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class PlaylistMapper extends ItemMapper<LearningFlowModel> {
    protected mapFromItem(item): LearningFlowModel {
        if(item._id){
          const lfModel = new LearningFlowModel();
          lfModel.id = item.urlPath;
          lfModel.title = item.name;
          lfModel.description = item.about;
          lfModel.author = item.topicInfo.author;
          lfModel.duration = item.duration;
          // lfModel.topicId = item.topicId? item.topicId : 'default-topicId';
          lfModel.topicId = item.topicInfo? item.topicInfo.urlPath: null;
          lfModel.skillId = item.skills[0]? item.skills[0].urlPath : null;
          lfModel.skillList = [];
          item.skills.forEach(element => {
            lfModel.skillList.push(element.details? element.details.targetFileThumbnailURL : null);
          });

          return lfModel;
        }
        else return item;
    }
}
