import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import {DataRequirementsFake} from './data.requirements.fake';
import {SkillDetailUseCase} from './use-cases/skill-detail.use-case';
import {UserActivityUseCase} from './use-cases/user-activity.use-case';
import {TopicDetailsUseCase} from './use-cases/topic-details.use-case';
import {UpdateLocaleUseCase} from './use-cases/update-locale.use-case';

import {SkillMapper} from './mappers/skill.mapper';
import {TopicDetailsMapper} from './mappers/topic-details.mapper';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
            // useClass: DataRequirementsFake
        },
        SkillMapper,
        TopicDetailsMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
      private skillDetailUsecase: SkillDetailUseCase,
      private userActivityUsecase: UserActivityUseCase,
      private topicDetailsUsecase: TopicDetailsUseCase,
      private updateLocaleUsecase: UpdateLocaleUseCase
    ) {}

    getSkillDetails(skillId){
      return this.skillDetailUsecase.run(skillId);
    }

    trackUserActivity(data){
      console.log("data123457---",data)
      return this.userActivityUsecase.run(data);
    }

    getTopicDetails(topicId){
      return this.topicDetailsUsecase.run(topicId);
    }

    updateLocale(data){
      return this.updateLocaleUsecase.run(data);
    }
}
