<div class="all-skill-wrap">
    <div class="all-skill-top">
      <app-image [src]="'assets/image/allskill_top_border.svg'" [width]="'100%'"></app-image>
    </div>
    <div class="bg-main500">
      <div class="center-wrap">
        <app-brush [brushColor]="'bg-main700'"  [color]="'text-s0'" [width]="'180'" [height]="'34'" [margin]="'0 0 16px -4px'">{{skillList?.length > 1? locale?.home.exploreSTEMStories : locale?.home.exploreSTEMStory}}</app-brush>
        <div *ngIf="skillList" class="skill-list row">
            <app-skill-item *ngFor="let skill of skillList"
              [width]="'363px'"
              [height]="'200px'"
              [image]="skill.url"
              [title]="skill.title"
              [duration]="skill.duration"
              [type]="skill.type"
              [hoverColor]="'accent500'"
              (click)="onSkillClick(skill)"
              class="cursor"
              ></app-skill-item>
        </div>
        <div *ngIf="!skillList || loading" class="p-4 ml-4">
          <app-loader [spinColor]="'#FFEF9F'" [height]="'30px'" [width]="'80%'"></app-loader>
          <app-loader [spinColor]="'#FFEF9F'" [height]="'30px'" [width]="'70%'"></app-loader>
          <app-loader [spinColor]="'#FFEF9F'" [height]="'30px'" [width]="'60%'"></app-loader>
        </div>

        <div *ngIf="!empty && skillList" class="d-flex justify-content-center load-more-pad">
            <app-button [height]="'56px'" [class]="'button-primary'" [width]="'322px'" [loading]="loading" (onClick)="onLoadMore($event)">
              <app-text class="text-s0 mr-13 body-l">{{loading? locale?.loading : locale?.loadMore}}</app-text>
              <app-image *ngIf="!loading" [src]="'assets/image/downArrow.svg'"></app-image>
            </app-button>
        </div>
        <div *ngIf="skillList?.length==0" class="p-20">
          <app-text class="body-l text-s0">{{ locale?.STEM_story.noSkill }}</app-text>
        </div>
      </div>
    </div>
</div>
