import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../global-components/global-components.module';
import {SharedComponentsModule} from './presentation/shared-components/shared-components.module';
import {BusinessLogicFacade} from './business-logic/business-logic.facade';
import {BusinessLogicRequirementsFake} from './presentation/business-logic.requirements.fake';
import {BusinessRequirementsInjectionToken} from './presentation/business-logic.requirements';

import {AppHomeModule} from './presentation/app-home/app-home.module';
import { from } from 'rxjs';


@NgModule({
    imports: [
        BusinessLogicFacade,
        CommonModule,
        GlobalComponentsModule,
        SharedComponentsModule,
        AppHomeModule
    ],
    providers: [
        {
            provide: BusinessRequirementsInjectionToken,
            useClass: BusinessLogicFacade
            // useClass: BusinessLogicRequirementsFake
        }
    ],
    exports: []
})
export class HomeFeatureModule {}
