import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../lib/auth.service";
export class DataService {
    constructor(http, _authService) {
        this.http = http;
        this._authService = _authService;
        this.baseUrl = environment.apiurl;
        this.api_key = environment.apikey;
        this.gameUrl = environment.stemgameapiurl;
    }
    getSearchResult(keyword, area, skip, limit) {
        let url = this.baseUrl + 'api/v1/search';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                keyword: keyword,
                // area: 'topic',
                area: area,
                skip: skip.toString(),
                limit: limit.toString()
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            if (area == "topic")
                return result[0].result;
            else
                return result;
        }));
    }
    loginUser(username, password) {
        let url = this.gameUrl + 'login';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: this.api_key,
                username: username,
                password: password,
                checksub: 'true'
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
    userInfo() {
        let userInfo = this._authService.getUserInfo();
        let url = this.gameUrl + 'api/v2/users/' + userInfo.userid;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: userInfo.apikey,
                username: userInfo.username,
                password: userInfo.password
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            return res;
        }));
    }
    getPermittedApps(userId) {
        let userInfo = this._authService.getUserInfo();
        let url = this.gameUrl + 'api/v2/users/permitted-apps';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: userInfo.apikey,
                username: userInfo.username,
                password: userInfo.password
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: DataService, providedIn: "root" });
