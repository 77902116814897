import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { LocaleService } from '../lib/locale.service';
import {Router} from '@angular/router';

@Injectable()
export class DeeplinkService {

    constructor(private _localeService: LocaleService,private router:Router) {}

    deepLink(mobileOS,browser,confirmLocale){
      // let confirmApp = mobileOS == 'ios' ? confirm(confirmLocale) : document.hasFocus();
      this.openInApp(mobileOS);
      setTimeout(()=>{
        if(document.hasFocus()){
          this.fallbackToStore(mobileOS)
        }
      },250)
    }

    openInApp(mobileOS){
      let appLink = this.getAppLink();
      window.location.href = appLink;
    }

    fallbackToStore(mobileOS) {
      let playstoreUrl = "https://play.google.com/store/apps/details?id=com.werkzpublishing.stemwerkz.android.store.kids&hl=en&gl=US";
      let appStoreUrl = "https://apps.apple.com/sg/app/stemwerkz/id1545876287";
      let fallbackUrl = mobileOS == 'ios' ? appStoreUrl : playstoreUrl;
      window.open(fallbackUrl, '_system');
    }

    getAppLink(){
      let url = window.location.href;
      let idx = this.getIndexOfNthChar(url, '/', 3);
      let path = url.substr(idx+1,url.length);
      let appLink = "stemwerkz://?app=CHANNEL&path=" + path;
      return appLink;
    }

    getIndexOfNthChar(string, character, n){
      var count= 0, i=0;
      while(count<n && (i=string.indexOf(character,i)+1)){
          count++;
      }
      if(count== n) return i-1;
      return NaN;
    }

}
