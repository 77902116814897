<div>
  <!-- <app-open-app-default *ngIf="showDefaultOpenApp && !isApp && mobileOS != 'unknown'" [locale]="locale" (onClickOpen)="triggerAppOpen('default-banner')"></app-open-app-default> -->
  <app-header-bar [data]="control" [userObj]="userObj" [profileObj]="profileObj" [stemUrl]="stemUrl" [valleyUrl]="valleyUrl" [academyUrl]="academyUrl" [loginUrl]="loginUrl" [parentLoginUrl]="parentLoginUrl" [dashboardUrl]="dashboardUrl" [questsUrl]="questsUrl" [isMobileApp]="isApp" [permittedApps]="permittedApps" [locale]="locale" [showNavBarMenu]="showNavBarMenu" [isHomePage]="isHomePage"
  (clickSearch) = "searchData()"
  (searchValue)="getSearchValue($event)"
  (search)="searchData()"
  (onClickLogo)="onClickLogo()"
  (onClickLogOut)="onClickLogout()"
  (clickUser)="toggleDropdown($event)"
  (kidLogin)="goKidLogin($event)"
  ></app-header-bar>
  <div id="dashboard-home" class="dashboard-body-wrap min-h100">
    <app-login *ngIf="isLogin"></app-login>
    <div>
      <!-- <div>{{ locale?.featured?.titleSingle }}</div>
      <div>{{ locale?.searchInput?.searchPlaceholder }}</div> -->
        <!-- <div>Testing</div>
        <div *ngFor="let localeData of locale">
          <app-text class="text-c300 h5-strong">{{localeData.testText.Download}}</app-text>
          <app-text class="text-c300 h5-strong pl-2">{{localeData.testText.Admin}}</app-text>
        </div> -->
    </div>
    <router-outlet *ngIf="!isLogin"></router-outlet>
  </div>
  <!-- <app-open-in-app *ngIf="openApp && !isApp && mobileOS != 'unknown'" [locale]="locale" [appLink]="appLink" (onClickNotNow)="showDefaultBanner()" (onClickOpenInApp)="triggerAppOpen('bottom-openapp-banner')"></app-open-in-app> -->
</div>
