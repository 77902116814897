<div *ngIf="topic!=0 || playlist!=0 || skill!=0" class="search-result-wrap">
    <div class="bg-main500">
        <div class="pad-search d-flex justify-content-center">
            <app-text class="body-m text-c300">{{locale?.SearchResult.title}}</app-text>
        </div>
        <div class="result-list d-flex justify-content-center align-items-center">

            <div *ngIf="topic!=0" class="choice cursor" [ngClass]="topic && sort==1? 'active bg-accent500' : 'bg-accent100'" (click)="onClickSearch(1)">
              <app-text *ngIf="topic>0" [ngClass]="topic && sort==1? 'body-l text-s0' : 'body-l text-accent500'">{{topic>1? topic + ' '+ locale?.STEM_quest.quests : topic + ' '+ locale?.STEM_quest.quest}}</app-text>
              <div *ngIf="topic==null || topic==undefined" class="loading-margin dot-pulse"></div>
            </div>

            <div *ngIf="topic!=0 && (playlist!=0 || skill!=0)" class="dot bg-s0"></div>
              <div *ngIf="playlist!=0" class="choice cursor" [ngClass]="playlist && sort==2? 'active bg-accent500' : 'bg-accent100'" (click)="onClickSearch(2)">
                <app-text *ngIf="playlist>0" [ngClass]="playlist && sort==2? 'body-l text-s0' : 'body-l text-accent500'">{{playlist>1? playlist + ' '+ locale?.STEM_path.playlists : playlist + ' '+ locale?.STEM_path.playlist}}</app-text>
                <div *ngIf="playlist==null || playlist==undefined" class="loading-margin dot-pulse"></div>
              </div>
            <div *ngIf="playlist!=0 && skill!=0" class="dot bg-s0"></div>
            <div *ngIf="skill!=0" class="choice cursor" [ngClass]="skill && sort==3? 'active bg-accent500' : 'bg-accent100'" (click)="onClickSearch(3)">
              <app-text *ngIf="skill>0" [ngClass]="skill && sort==3? 'body-l text-s0' : 'body-l text-accent500'">{{skill>1? skill + ' '+ locale?.STEM_story.skills : skill + ' ' +locale?.STEM_story.skill}}</app-text>
              <div *ngIf="skill==null || skill==undefined" class="loading-margin dot-pulse"></div>
            </div>
        </div>

        <div class="d-flex align-items-center flex-column">
          <!-- <app-text *ngIf="!loading && topic==0 && playlist==0 && skill==0" class="body-m text-c100 zindex-1">No search found.</app-text> -->
          <div *ngIf="(topic==0 && playlist==0 && skill==0) || (loading && !(playlist>0 || topic>0))"
          class="topic-bottom bg-s20">
              <app-image [src]="'assets/image/search_result_bottom_border.svg'" [width]="'100%'"></app-image>
          </div>
        </div>
    </div>
</div>
<div *ngIf="!loading && topic==0 && playlist==0 && skill==0" class="search-not-found-wrap">
  <div class="w-100 d-flex justify-content-center"><app-image [src]="'./assets/image/search-not-found.png'"></app-image></div>
  <div class="not-found-text">
    <div class="d-flex justify-content-center not-found-header"><app-text>{{locale?.SearchResult.noDataTitle}}</app-text></div>
    <div class="d-flex justify-content-center not-found-result text-c100"><app-text>{{locale.SearchResult.noDataBody}}</app-text></div>
  </div>
</div>

