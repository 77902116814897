<div class="bg-bg01">
    <app-search-landing [imgUrl]="searchLandingBg" [data]="control" [locale]="locale" (searchValue)="getSearchValue($event)" (clickSearch)="searchData()" (search)="searchData()"></app-search-landing>

    <app-topic-wrapper (onClick)="clickTopicDetail($event)" [topic]="monthlyTopic" [locale]="locale"></app-topic-wrapper>

    <app-learning-flow-wrapper class="hide" [learningFlow]="learningFlow" [loading]="loadingLf" [locale]="locale" (onClick)="clickLearningDetail($event)"></app-learning-flow-wrapper>

    <app-weekly-skills-wrapper [newSkills]="weeklySkills" [loading]="loadingNew" [locale]="locale" (onClick)="clickSkillDetail($event)"></app-weekly-skills-wrapper>

    <app-all-topic-wrapper [topicList]="topicList" [locale]="locale" (onClick)="clickTopicDetail($event)"></app-all-topic-wrapper>

    <app-all-skill-wrapper [empty]="emptySkill" [skillList]="skillList" [totalSkill]="totalSkill" [loading]="loadmoreLoading" [locale]="locale" (onClick)="clickSkillDetail($event)" (onClickLoadMore)="onSkillLoadMore($event)"></app-all-skill-wrapper>

    <app-valley-popup *ngIf="showValleyPopup" [notShowAgain]="notShowAgainPopup" [firstPopup]="firstValleyPopup" [locale]="locale" (onClickClose)="closevalleyPopup()" (onChangeCheckButton)="onClickNotShowAgain($event)" (onClickButton)="redirectValley()"></app-valley-popup>

</div>
