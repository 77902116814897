import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ClassCardStore} from './class-card.store'

@Component({
    selector: 'app-class-card',
    templateUrl: './class-card.component.html',
    styleUrls: ['./class-card.component.css']
})
export class ClassCardComponent {
    constructor(private store: ClassCardStore) {}
    @Input() liveClassList;
    @Input() locale;
    @Input() usertype;
    @Output() clickenroll = new EventEmitter<any>();

    public teacherProfile = '';
    public startdate = '';
    public time;
    public fullName = '';
    ngOnInit() {
        this.teacherProfile = this.liveClassList.teacherProfile;
        this.fullName = this.liveClassList.teacherName;
    }
    classClick($event) {
        this.clickenroll.emit($event);
    }

}
