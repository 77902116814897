<div class="w-368 bg-s0 elevation-border">

  <div class="class-top d-flex justify-content-between" [ngStyle]="{'background': liveclass.background}">
      <div class="class-date bg-s0">
          <app-text class="text-c300 caption"> {{liveclass.startDate | date: 'EEE, d MMM y'}}</app-text>
          <app-text class="text-c100 caption"> / {{liveclass.startDate | date: 'shortTime' }} - {{liveclass.endDate| date: 'shortTime' }}</app-text>
      </div>
  </div>
  <div class="wave-container d-flex justify-content-end" [ngStyle]="{ 'background' : liveclass.background}">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 35px; width: 100%;">
        <path d="M-1.12,56.73 C185.10,-49.83 295.14,236.34 500.00,71.53 L500.00,150.00 L0.00,150.00 Z" style="stroke: none; fill: #fff;"></path>
      </svg>
  </div>
  <div class="class-detail">
      <app-text class="text-c300 h3-strong">{{liveclass.title}}</app-text>
      <br>
      <div class="mt-2">
          <app-text class="text-c100 light-body-m">{{liveclass.description}}</app-text>
      </div>

  </div>
  <!-- <span class="hr-row"></span> -->
  <hr class="hr-row">
  <div *ngIf="liveclass.deviceMessageText != null" class="class-btn">
    <app-text class="text-c100 body-m">{{ liveclass.deviceMessageText }}</app-text>
  </div>
  <div *ngIf="(usertype != 5 || usertype != '5') && liveclass.deviceMessageText == null" class="class-btn d-flex align-items-center justify-content-between">
      <app-text *ngIf="liveclass.note != null" class="body-m text-c100">{{liveclass.note}}</app-text>
      <app-button [ngStyle]="{'width': liveclass.note? '50%' : '100%'}" (onClick)="classClick(liveclass)" [class]="liveclass.button.class" [disabled]="liveclass.button.disabled" [width]="'100%'" [height]="'40px'" [loading]="liveclass.button.loading"> <app-text>{{ liveclass.button.loading? locale?.pleaseWait+'...' : liveclass.button.text}}</app-text> </app-button>
  </div>
  <div *ngIf="(usertype == 5 || usertype == '5') && liveclass.deviceMessageText == null" class="parentMsg">
    <app-text class="text-c100 h5-bold">{{ locale?.parentAccountMessage.msgTitle }}</app-text>
    <div>
      <app-text class="text-c100 light-body-m">{{ locale?.parentAccountMessage.msgText }}</app-text>
    </div>
  </div>
</div>
