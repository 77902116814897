import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';

import { DashboardHomeControllerComponent } from '../app/dashboard/presentation/dashboard-home/dashboard-home-controller.component';
import { TopicHomeControllerComponent } from '../app/topics/presentation/topic-home/topic-home-controller.component';
import { AppHomeControllerComponent } from '../app/home/presentation/app-home/app-home-controller.component';
import {SkillsDetailsControllerComponent} from '../app/skills/presentation/skills-details/skills-details-controller.component';
import {PlaylistDetailsControllerComponent} from '../app/playlists/presentation/playlist-details/playlist-details-controller.component';
import {SearchResultControllerComponent} from '../app/dashboard/presentation/search-result/search-result-controller.component';
import {LoginControllerComponent} from '../app/dashboard/presentation/login/login-controller.component';
import {RedirectHomeControllerComponent} from '../app/redirect/presentation/redirect-home/redirect-home-controller.component';
import {PageNotFoundControllerComponent} from '../app/dashboard/presentation/page-not-found/page-not-found-controller.component';
import {LiveClassesHomeControllerComponent} from '../app/live-classes/presentation/live-classes-home/live-classes-home-controller.component';
import {TeacherProfileControllerComponent} from '../app/live-classes/presentation/teacher-profile/teacher-profile-controller.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: ''},
  {
    path: 'redirect',
    component: RedirectHomeControllerComponent
  },
  {
    path: '',
    component: DashboardHomeControllerComponent,
    children: [
      {
        path: '',
        component: AppHomeControllerComponent
      },
      {
        path: 'page-not-found',
        component: PageNotFoundControllerComponent
      },
      {
        path: 'search/:keyword',
        component: SearchResultControllerComponent
      },
      {
        path: 'teacher/:teacherid',
        component: TeacherProfileControllerComponent
      },
      {
        path: 'login',
        component: LoginControllerComponent
      },
      {
        path: 'liveclasses',
        component: LiveClassesHomeControllerComponent
      },
      {
        path: ':id',
        component: TopicHomeControllerComponent
      },
      {
        path: ':topicid/:skillid/:flowid',
        component: PlaylistDetailsControllerComponent
      },
      {
        path: ':topicid/:skillid',
        component: SkillsDetailsControllerComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
