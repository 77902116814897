import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {PlaylistDetailsControllerComponent} from './playlist-details-controller.component';
import {RouterModule} from '@angular/router';
import {RelatedPlaylistComponent} from '../playlist-details/components/related-playlist/related-playlist.component';
import {PlaylistCollectionComponent} from '../playlist-details/components/playlist-collection/playlist-collection.component';
import {PlaylistCollectionCardComponent} from '../playlist-details/components/playlist-collection-card/playlist-collection-card.component';
import {PlaylistRelatedSkillsComponent} from '../playlist-details/components/playlist-related-skills/playlist-related-skills.component';
import {RelatedPlaylistCollectionComponent} from '../playlist-details/components/related-playlist-collection/related-playlist-collection.component';
import { from } from 'rxjs';

@NgModule({
    declarations: [
        PlaylistDetailsControllerComponent,
        RelatedPlaylistComponent,
        PlaylistCollectionComponent,
        PlaylistCollectionCardComponent,
        PlaylistRelatedSkillsComponent,
        RelatedPlaylistCollectionComponent
    ],
    exports: [
        PlaylistDetailsControllerComponent,
        RelatedPlaylistComponent,
        PlaylistCollectionComponent,
        PlaylistCollectionCardComponent,
        PlaylistRelatedSkillsComponent,
        RelatedPlaylistCollectionComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class PlaylistDetailsModule {}
