import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class SetLocalstorageUseCase {
    constructor(data) {
        this.data = data;
    }
    run(key, value) {
        this.data.setLocalStorage(key, value);
    }
}
SetLocalstorageUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SetLocalstorageUseCase_Factory() { return new SetLocalstorageUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: SetLocalstorageUseCase, providedIn: "root" });
