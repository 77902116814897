import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {AppHomeControllerComponent} from './app-home-controller.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TopicWrapperComponent} from '../app-home/components/topic-wrapper/topic-wrapper.component';
import {LearningFlowWrapperComponent} from '../app-home/components/learning-flow-wrapper/learning-flow-wrapper.component';
import {AllTopicWrapperComponent} from '../app-home/components/all-topic-wrapper/all-topic-wrapper.component';
import {AllSKillWrapperComponent} from './components/all-skill-wrapper/all-skill-wrapper.component';
import {ValleyPopupComponent} from './components/valley-popup/valley-popup.component';
import {VideoPlayerComponent} from './components/video-player/video-player.component';
import {WeeklySkillsWrapperComponent} from './components/weekly-skills-wrapper/weekly-skills-wrapper.component';
import {SearchLandingComponent} from './components/search-landing/search-landing.component';

@NgModule({
    declarations: [
        AppHomeControllerComponent,
        TopicWrapperComponent,
        LearningFlowWrapperComponent,
        AllTopicWrapperComponent,
        AllSKillWrapperComponent,
        ValleyPopupComponent,
        VideoPlayerComponent,
        WeeklySkillsWrapperComponent,
        SearchLandingComponent
    ],
    exports: [
        AppHomeControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class AppHomeModule {}
