import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-learning-flow-wrapper',
    templateUrl: './learning-flow-wrapper.component.html',
    styleUrls: ['./learning-flow-wrapper.component.css']
})
export class LearningFlowWrapperComponent {
  @Input() learningFlow;
  @Input() loading;
  @Input() locale;

  @Output() onClick = new EventEmitter<any>();
  onPlayClick($event) {
    this.onClick.emit($event);
  }
}
