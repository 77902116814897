import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { locale_short_code } from '../../locale/locale-code';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class LocaleService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
        this.localeCodeList = Object.keys(locale_short_code);
        this.localeCodeList = this.localeCodeList.map(data => data.replace(/_/g, "-"));
    }
    getLocaleName() {
        let localeObj = JSON.parse(localStorage.getItem("userLocale"));
        let localeLs = localeObj != undefined ? localeObj.locale : null;
        let locale = localeLs != undefined && this.localeCodeList.indexOf(localeLs) != -1 ? localeLs.toLocaleLowerCase() : 'en-US'.toLocaleLowerCase();
        this.localeJson = 'locale/locale_' + locale + '.json';
        console.log("locale json~~~~~~", this.localeJson);
        return this.localeJson;
    }
    getJson() {
        let localeName = this.getLocaleName();
        return this.http.get(localeName).pipe(map((res) => {
            let locale = {};
            locale = res[0];
            console.log("locale", locale);
            return locale;
        }), catchError(error => {
            return this.getDefaultJson("locale/locale_en-us.json");
        }));
    }
    getDefaultJson(locale) {
        return this.http.get(locale).pipe(map((res) => {
            let locale = {};
            locale = res[0];
            console.log("getDefaultJson", locale);
            return locale;
        }));
    }
    updateCurrentLocale(data) {
        localStorage.setItem("current_locale", data);
    }
    getCurrentLocale() {
        let current_locale = localStorage.getItem("current_locale");
        return current_locale;
    }
    getUserLocale() {
        let userLocale = JSON.parse(localStorage.getItem("userLocale"));
        return userLocale;
    }
    getCountryList() {
        let country_json = "locale/country_name.json";
        return this.http.get(country_json).pipe(map((res) => {
            let country_list = {};
            country_list = res[0];
            return country_list;
        }), catchError(error => {
            console.log("error~~~", error);
            return error;
        }));
    }
}
LocaleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocaleService_Factory() { return new LocaleService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: LocaleService, providedIn: "root" });
