<div *ngIf="!loading" class="pt-2 pb-2">
  <app-text *ngIf="relatedLearningFlow.length == 1" class="mt-2 mb-2 related-skill-header">{{ locale.playlistDetail.relatedSTEMPath }}</app-text>
  <app-text *ngIf="relatedLearningFlow.length > 1" class="mt-2 mb-2 related-skill-header">{{ locale.playlistDetail.relatedSTEMPaths }}</app-text>

</div>
<div class="related-playlist-view" *ngIf="relatedLearningFlow?.length > 0">
  <div *ngFor="let learningflow of relatedLearningFlow">
    <app-related-playlist [data]="learningflow" [locale]="locale" (click)="onClickLearningFlow(learningflow)"></app-related-playlist>
  </div>
</div>
<div *ngIf="relatedLearningFlow?.length == 0">
  <app-text class="body-l text-c100">{{ locale.playlistDetail.noRelatedSTEMPath }}</app-text>
</div>

<div *ngIf="loading" class="pt-2 pb-2">
  <app-loading [width]="'100%'" [height]="'20px'"></app-loading>
  <div class="related-playlist-view">
    <app-loading [width]="'100%'" [height]="'200px'"></app-loading>
    <app-loading [width]="'100%'" [height]="'200px'"></app-loading>
    <app-loading [width]="'100%'" [height]="'200px'"></app-loading>
  </div>
</div>
