import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {TopicDetailsMapper} from '../mappers/topic-details.mapper';

@Injectable({
    providedIn: 'root'
})
export class TopicDetailsUseCase {
    constructor(
        private mapper: TopicDetailsMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(topicId) {
      return this.data.getTopicDetails(topicId).pipe(this.mapper.map);
    }
}
