import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {TeacherProfileMapper} from '../mappers/teacher-profile.mapper';

@Injectable({
    providedIn: 'root'
})
export class TeacherProfileUseCase {
    constructor(
        private mapper: TeacherProfileMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(teacherId) {
      return this.data.getTeacherProfile(teacherId).pipe(this.mapper.map);
    }
}
