import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {PlaylistMapper} from '../mappers/playlist.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetRecommendedLearningFlowUseCase {
    constructor(
        private mapper: PlaylistMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
        return this.data.getRecommendedLearningFlow().pipe(this.mapper.map);
    }
}
