import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class RemoveLocalstorageUseCase {
    constructor(data) {
        this.data = data;
    }
    run(key) {
        this.data.removeLocalStorage(key);
    }
}
RemoveLocalstorageUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoveLocalstorageUseCase_Factory() { return new RemoveLocalstorageUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: RemoveLocalstorageUseCase, providedIn: "root" });
