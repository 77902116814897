/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./redirect-home-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../global-components/text/text.component";
import * as i4 from "./redirect-home-controller.component";
import * as i5 from "../../../lib/auth.service";
import * as i6 from "../../../lib/shared-service";
import * as i7 from "@angular/router";
import * as i8 from "../../../lib/locale.service";
import * as i9 from "../business-logic.requirements";
var styles_RedirectHomeControllerComponent = [i0.styles];
var RenderType_RedirectHomeControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RedirectHomeControllerComponent, data: {} });
export { RenderType_RedirectHomeControllerComponent as RenderType_RedirectHomeControllerComponent };
export function View_RedirectHomeControllerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "redirect-wrap d-flex flex-column align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [["class", "m-4 text-c300 body-m"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(2, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(3, 0, ["", "..."])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "redirect-loader"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "m-4 text-c300 body-m"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.locale == null) ? null : _co.locale.redirecting); _ck(_v, 3, 0, currVal_1); }); }
export function View_RedirectHomeControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-redirect-home", [], null, null, null, View_RedirectHomeControllerComponent_0, RenderType_RedirectHomeControllerComponent)), i1.ɵdid(1, 114688, null, 0, i4.RedirectHomeControllerComponent, [i5.AuthService, i6.SharedService, i7.Router, i7.ActivatedRoute, i8.LocaleService, i9.BusinessRequirementsInjectionToken], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RedirectHomeControllerComponentNgFactory = i1.ɵccf("app-redirect-home", i4.RedirectHomeControllerComponent, View_RedirectHomeControllerComponent_Host_0, {}, {}, []);
export { RedirectHomeControllerComponentNgFactory as RedirectHomeControllerComponentNgFactory };
