<!-- <div class="skill-image">
  <app-image class="" [src]="'assets/image/Vector101.svg'" [width]="'100%'"></app-image>
</div> -->

<div class="bg-skill">
  <div class="container skill-list-container">
    <app-text *ngIf="totalSkill == 1" class="h2-strong sk-title">{{locale?.STEM_story.skill}} <app-text *ngIf="skillList">{{totalSkill}}</app-text></app-text>
    <app-text *ngIf="totalSkill > 1" class="h2-strong sk-title">{{locale?.STEM_story.skills}}  <app-text *ngIf="skillList">{{totalSkill}}</app-text></app-text>
    <div *ngIf="skillList" class="row" style="width: 110%;padding-top: 24px;">
        <app-skill-item *ngFor="let skill of skillList"
        [width]="'363px'"
        [height]="'200px'"
        [image]="skill.url"
        [title]="skill.title"
        [duration]="skill.duration"
        [type]="skill.type"
        [hoverColor]="'accent500'"
        (click)="onSkillClick(skill)"
        class="cursor"
      ></app-skill-item>

    </div>
    <!-- <div *ngIf="totalSkill>skillList?.length" class="d-flex justify-content-center load-more-pad"> -->
      <div *ngIf="!empty && skillList" class="d-flex justify-content-center load-more-pad">
      <app-button [height]="'56px'" [class]="'button-primary'" [width]="'322px'" [loading]="loading" (onClick)="onLoadMore($event)">
        <app-text class="text-s0 mr-13 body-l">{{loading? locale?.loading+'...' : locale?.loadMore}}</app-text> &nbsp;
        <app-image *ngIf="!loading" [src]="'assets/image/downArrow.svg'"></app-image>
      </app-button>
    </div>
    <div *ngIf="!skillList || skillList?.length==0">
      <app-text *ngIf="!loading" class="body-l text-c200">{{locale?.STEM_story.noSkill}}</app-text>
      <div class="row d-flex p-20">
        <div class="col-md-3">
          <app-loader *ngIf="loading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
          <app-loader *ngIf="loading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
        </div>
        <div class="col-md-3">
          <app-loader *ngIf="loading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
          <app-loader *ngIf="loading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
        </div>
        <div class="col-md-3">
          <app-loader *ngIf="loading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
          <app-loader *ngIf="loading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
        </div>
      </div>
    </div>
  </div>
</div>




