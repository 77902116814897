import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../lib/auth.service";
export class ClassService {
    constructor(http, _authService) {
        this.http = http;
        this._authService = _authService;
        this.baseUrl = environment.liveClassApiurl + 'api/v1/';
    }
    getLiveClasslist() {
        let url = this.baseUrl + 'live-classes';
        let userInfo = this._authService.getUserInfo();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                username: userInfo.username,
                api_key: userInfo.apikey,
                password: userInfo.password,
                userid: userInfo.userid
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            return res;
        }));
    }
    enrollLiveClass(classId) {
        let url = this.baseUrl + 'live-classes/' + classId + '/enroll';
        let userInfo = this._authService.getUserInfo();
        let data = {
            "userId": userInfo.userid
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                username: userInfo.username,
                api_key: userInfo.apikey,
                password: userInfo.password
            })
        };
        return this.http.post(url, data, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
}
ClassService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClassService_Factory() { return new ClassService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: ClassService, providedIn: "root" });
