/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video-player.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/image/image.component.ngfactory";
import * as i3 from "../../../../../global-components/image/image.component";
import * as i4 from "@angular/common";
import * as i5 from "./video-player.component";
var styles_VideoPlayerComponent = [i0.styles];
var RenderType_VideoPlayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoPlayerComponent, data: {} });
export { RenderType_VideoPlayerComponent as RenderType_VideoPlayerComponent };
function View_VideoPlayerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "play-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playVideo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(2, 114688, null, 0, i3.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"] }, null)], function (_ck, _v) { var currVal_0 = "./assets/image/play_video.svg"; var currVal_1 = "100%"; var currVal_2 = "100%"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_VideoPlayerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { videoPlayer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "video-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["videoPlayer", 1]], null, 1, "video", [["class", "popup-video"], ["height", "100%"], ["id", "game-demo"], ["playsinline", ""], ["poster", "./assets/image/STEAMValley_video_cover.jpg"], ["preload", "metadata"], ["width", "100%"]], null, [[null, "ended"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ended" === en)) {
        var pd_0 = (_co.endedVideo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "source", [["preload", "auto"], ["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoPlayerComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showPlayBtn; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoUrl; _ck(_v, 3, 0, currVal_0); }); }
export function View_VideoPlayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video-player", [], null, null, null, View_VideoPlayerComponent_0, RenderType_VideoPlayerComponent)), i1.ɵdid(1, 114688, null, 0, i5.VideoPlayerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoPlayerComponentNgFactory = i1.ɵccf("app-video-player", i5.VideoPlayerComponent, View_VideoPlayerComponent_Host_0, { videoUrl: "videoUrl" }, {}, []);
export { VideoPlayerComponentNgFactory as VideoPlayerComponentNgFactory };
