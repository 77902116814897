import {SkillDetailsModel} from '../../../models/skilldetails.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class SkillMapper extends ItemMapper<SkillDetailsModel> {
    protected mapFromItem(item): SkillDetailsModel {
      console.log(item)
        const skillModel= new SkillDetailsModel()
        {
          skillModel.details._id = item._id,
          skillModel.details.urlPath = item.urlPath,
          skillModel.details.name = null,
          skillModel.details.oamLocales = null,
          skillModel.details.url = null,
          skillModel.details.urlIOS = null,
          skillModel.details.thumbnailUrl = null,
          skillModel.details.fileWidth = null,
          skillModel.details.fileHeight = null,
          skillModel.details.description = null,
          skillModel.details.topicId = 'topic',
          skillModel.details.aerReferenceID = item.aerReferenceID,
          skillModel.upNext = [],
          skillModel.relatedSkills = []
        }
        if(item.details != undefined){
          skillModel.details.name = item.details.name;
          skillModel.details.oamLocales = item.details.locales != undefined && item.details.locales != null ? item.details.locales : null;
          skillModel.details.url = item.details.targetFileURL;
          skillModel.details.urlIOS = item.details.targetFileURLIOS == undefined || item.details.targetFileURLIOS == '' || item.details.targetFileURLIOS == null ? null : item.details.targetFileURLIOS;
          skillModel.details.thumbnailUrl = item.details.targetFileThumbnailURL;
          skillModel.details.fileWidth = item.details.targetFileWidth;
          skillModel.details.fileHeight = item.details.targetFileHeight;
          skillModel.details.description = item.details.description;
        }
        if(item.topicInfo && item.topicInfo[0] && item.topicInfo[0].urlPath)
          skillModel.details.topicId = item.topicInfo[0].urlPath;

        if(item.upnextSkill != undefined){
          console.log(item.upnextSkill);
          item.upnextSkill.map(data => {
            let skill = {
              "_id": data._id,
              "urlPath": data.urlPath,
              "name": null,
              "url": null,
              "thumbnailUrl": null,
              "fileWidth": null,
              "fileHeight": null,
              "description": null,
              "topicId": 'topic'
            }
            if(data.details != undefined){
              skill.name= data.details.name,
              skill.url= data.details.targetFileURL,
              skill.thumbnailUrl= data.details.targetFileThumbnailURL,
              skill.fileWidth= data.details.targetFileWidth,
              skill.fileHeight= data.details.targetFileHeight,
              skill.description= data.details.description
            }
            if(data.topicInfo && data.topicInfo[0] && data.topicInfo[0].urlPath)
              skill.topicId = data.topicInfo[0].urlPath
            skillModel.upNext.push(skill);
          })
        }

        if(item.relatedSkill != undefined){
          item.relatedSkill.map(data => {
            let skill = {
              "_id": data._id,
              "urlPath": data.urlPath,
              "name": null,
              "url": null,
              "thumbnailUrl": null,
              "fileWidth": null,
              "fileHeight": null,
              "description": null,
              "subject": [],
              "topicId": 'topic'
            }
            if(data.details != undefined){
              skill.name= data.details.name,
              skill.url= data.details.targetFileURL,
              skill.thumbnailUrl= data.details.targetFileThumbnailURL,
              skill.fileWidth= data.details.targetFileWidth,
              skill.fileHeight= data.details.targetFileHeight,
              skill.description= data.details.description,
              skill.subject= data.details.subject
            }
            if(data.topicInfo && data.topicInfo[0] && data.topicInfo[0].urlPath)
              skill.topicId = data.topicInfo[0].urlPath
            skillModel.relatedSkills.push(skill);
          })
        }
        return skillModel;
    }
}
