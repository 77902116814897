import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class SaveDataUseCase {
    constructor(data) {
        this.data = data;
    }
    run(keyword, value) {
        this.data.saveData(keyword, value);
    }
}
SaveDataUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaveDataUseCase_Factory() { return new SaveDataUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: SaveDataUseCase, providedIn: "root" });
