export class SkillModel {
  id: string = 'xxx'
  url:  string = 'https://picsum.photos/200/300'
  title: string = "Let's explore how light travels from Model"
  duration: string = '1h 30m'
  type: string = 'Science'
  total:  number = 20
  topicId: string = 'topic-xxx'
}

