<div id="success-noti" class="bg-g100 noti-wrapper" *ngIf="locale && user">
  <div class="d-flex">
    <div class="icon-wrap">
      <app-image [src]="'./assets/image/tick_s.svg'" [width]="'100%'" [height]="'100%'"></app-image>
    </div>
    <div>
      <app-text class="text-s0 noti-header toast-message">{{locale?.customNoti.successEnrollTitle}}</app-text>
    </div>
  </div>
  <div class="m-l-36">
    <div class="pt-1 pb-2 work-break">
      <app-text *ngFor="let val of locale.customNoti.successEnrollMsg" class="body-l text-s0">
        {{val=='username'? user.username: val=='lesson'? lesson: val}}
      </app-text>
    </div>
    <app-button [class]="'success-noti-btn'" (onClick)="onDismissBtnClick()">
      <app-text class="text-s0 h5-strong">{{locale?.customNoti.dismiss}}</app-text>
    </app-button>
  </div>
</div>
