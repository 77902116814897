import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
    selector: 'app-custom-popup',
    templateUrl: './custom-popup.component.html',
    styleUrls: ['./custom-popup.component.css']
})
export class CustomPopupComponent implements OnInit {
  @Input() lesson;
  @Input() msg;
  @Input() joinInstruction;
  @Input() title;
  @Input() locale;
  @Input() isAndroidApp;
  @Output() onClickClose = new EventEmitter<any>();

  public joinUrl = '';

  onClickCloseBtn(e){
    this.onClickClose.emit(e)
  }

  ngOnInit() {
    this.joinUrl = this.joinInstruction.zoomLink == '' ? this.joinInstruction.nodmaUrl : this.joinInstruction.zoomLink;
    console.log("joinUrl",this.joinUrl)
    document.getElementById('dashboard-home').style.zIndex = '2';
    document.getElementById('dashboard-home').style.maxHeight = 'calc(100vh - 72px)';
    document.getElementById('dashboard-home').style.overflow = 'hidden';
  }

  ngOnDestroy() {
      document.getElementById('dashboard-home').style.zIndex = null;
      document.getElementById('dashboard-home').style.maxHeight = null;
    document.getElementById('dashboard-home').style.overflow = null;
  }
}
