import {Component,Input,Output,EventEmitter,OnChanges} from '@angular/core';

@Component({
    selector: 'app-upnext-skills-collection',
    templateUrl: './upnext-skills-collection.component.html',
    styleUrls: ['./upnext-skills-collection.component.css']
})
export class UpnextSkillsCollectionComponent implements OnChanges {
  @Input() upnext;
  @Input() locale;
  @Output() onClickUpnextSkill = new EventEmitter<any>();
  public upnextSkills;

  ngOnChanges(){
    this.upnextSkills = this.upnext;
  }

  onSelectUpnextSkill($event){
    this.onClickUpnextSkill.emit($event);
  }
}
