import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';

export interface DataRequirements {
  playerLogin(username, password) : Observable<any>;
  setLocalStorage(key, value);
  removeLocalStorage(key);
  getSearchTopic(keyword) : Observable<any>;
  getSearchLearningFlow(keyword, skip, limit) : Observable<any>;
  getSearchSkill(keyword, skip, limit) : Observable<any>;
  getUserInfo() : Observable<any>;
  getPermittedApps(userId): Observable<any>;
  getLocalstorage(key);
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('dashboard Data Requirements')
