import {Component,Input,Output,OnInit,OnChanges,EventEmitter} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-breadcrump-nav',
    templateUrl: './breadcrump-nav.component.html',
    styleUrls: ['./breadcrump-nav.component.css']
})
export class BreadcrumpNavComponent implements OnChanges {
  @Input() data;
  @Input() locale;
  @Output() onClickNav = new EventEmitter<any>();

  constructor(private router: Router){

  }

  ngOnChanges(){
    console.log("breadcrumb data",this.data)
  }

  clickNavItem(data, type, idx){
    console.log("clickNavItem",data);
    if(type == "home" || (type == 'nav-item' && idx+1 < this.data.length))
      this.onClickNav.emit(data);
  }
}
