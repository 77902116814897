import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';

import {SaveDataUseCase} from './use-cases/save-data.use-case';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
        }
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
      private saveDataUsecase: SaveDataUseCase
    ) {}

    saveData(keyword, value){
      this.saveDataUsecase.run(keyword, value);
    }
}
