<div class="relative d-flex align-items-center" [ngClass]="center" [ngStyle]="{'width': width+'px', 'height': height+'px', 'margin': margin}">

  <div class="colored-brush"
      [ngClass]="brushColor"
      [ngStyle]="{'width': width+'px', 'height': height+'px', 'transform': 'scale('+ default_w +')'}">
    </div>
    <app-text class="h5-strong z-index" [class]="color" [ngStyle]="{'padding-left': padding+'px'}">
        <ng-content></ng-content>
    </app-text>
</div>
