import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {
    private emitUserObj = new Subject<any>();
    changeUserInfo$ = this.emitUserObj.asObservable();
    emitUserInfo(change: any) {
        this.emitUserObj.next(change);
    }

    private setPlaylistNav = new BehaviorSubject([]);
    currentPlaylistNav = this.setPlaylistNav.asObservable();
    definePlaylistNav(data:any){
      this.setPlaylistNav.next(data)
    }

    private setSkillNav = new BehaviorSubject([]);
    currentSkillNav = this.setSkillNav.asObservable();
    defineSkillNav(data:any){
      this.setSkillNav.next(data)
    }

    private setCurrentURL;
    setPreviousURL(data:any){
      this.setCurrentURL = data;
    }
    getPreviousUrl() {
      return this.setCurrentURL;
    }

    private enableLogin = new BehaviorSubject({});
    enableLogin$ = this.enableLogin.asObservable();
    setEnableLogin(dataObj: any) {
      console.log("dataObj>>",dataObj)
        this.enableLogin.next(dataObj);
    }

    private setPrevSearchUrl = new BehaviorSubject({});
    prevSearchUrl = this.setPrevSearchUrl.asObservable();
    definePreviousUrl(data: any){
      this.setPrevSearchUrl.next(data);
    }

    private isMobileApp = new BehaviorSubject({});
    isApp = this.isMobileApp.asObservable();
    defineApp(data: any){
      this.isMobileApp.next(data);
    }

    private permittedApps = new BehaviorSubject({});
    getUserPermittedApps = this.permittedApps.asObservable();
    defineUserPermittedApps(data: any){
      this.permittedApps.next(data)
    }
}
