<div class="w-368 bg-s0 elevation-border">

    <div class="class-top d-flex justify-content-between" [ngStyle]="{'background': liveClassList.background}">
        <div class="class-date bg-s0 d-flex flex-column">
            <app-text class="text-c100 caption"> {{liveClassList.startDate | date: 'EEE, d MMM y'}}</app-text>
            <app-text class="text-c100 caption">{{liveClassList.startDate | date: 'shortTime' }} - {{liveClassList.endDate| date: 'shortTime' }}</app-text>
        </div>
        <div class="multi-line-truncate">
            <app-text class="text-c300 h5-strong">{{fullName}}</app-text>
        </div>

    </div>
    <div class="wave-container d-flex justify-content-end" [ngStyle]="{ 'background' : liveClassList.background}">
        <div class="teacher-photo">
            <app-image [width]="'64px'" [src]="teacherProfile" [class]="'obj-fit-contain'"></app-image>
        </div>
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 35px; width: 100%;">
          <path d="M-1.12,56.73 C185.10,-49.83 295.14,236.34 500.00,71.53 L500.00,150.00 L0.00,150.00 Z" style="stroke: none; fill: #fff;"></path>
        </svg>
    </div>
    <div class="class-detail">
        <app-text class="text-c300 h3-strong">{{liveClassList.title}}</app-text>
        <br>
        <div class="mt-2">
            <app-text class="text-c100 light-body-m">{{liveClassList.description}}</app-text>
        </div>

    </div>
    <!-- <span class="hr-row"></span> -->
    <hr class="hr-row">
    <div *ngIf="liveClassList.deviceMessageText != null" class="class-btn">
      <app-text class="text-c100 body-m">{{ liveClassList.deviceMessageText }}</app-text>
    </div>
    <div *ngIf="(usertype != 5 || usertype != '5') && liveClassList.deviceMessageText == null" class="class-btn d-flex align-items-center justify-content-between">
        <app-text *ngIf="liveClassList.note != null" class="body-m text-c100">{{liveClassList.note}}</app-text>
        <app-button [ngStyle]="{'width': liveClassList.note? '50%' : '100%'}" (onClick)="classClick(liveClassList)" [class]="liveClassList.button.class" [disabled]="liveClassList.button.disabled" [width]="'100%'" [height]="'40px'" [loading]="liveClassList.button.loading"> <app-text>{{ liveClassList.button.loading? locale?.pleaseWait+'...' : liveClassList.button.text}}</app-text> </app-button>
    </div>
    <div *ngIf="(usertype == 5 || usertype == '5') && liveClassList.deviceMessageText == null" class="parentMsg">
      <app-text class="text-c100 h5-bold">{{ locale?.parentAccountMessage.msgTitle }}</app-text>
      <div>
        <app-text class="text-c100 light-body-m">{{ locale?.parentAccountMessage.msgText }}</app-text>
      </div>
    </div>
</div>
