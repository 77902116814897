<div [ngStyle]="{'background-image': profileBgColor}" class="d-flex justify-content-center align-items-center profile-header position-relative">
  <div>
    <app-image [src]="profileBgImage" [width]="'210px'" [height]="'140px'"></app-image>
  </div>
  <div *ngIf="!isLoading" class="profile-image">
    <app-image [src]="profileImage" [width]="'100%'" [height]="'144px'" [class]="'teacher-profile'"></app-image>
  </div>
  <div class="top-right-pattern">
    <app-image [src]="'./assets/image/top-right-pattern.svg'" [width]="'128px'" [height]="'128px'"></app-image>
  </div>
  <div class="bottom-left-pattern">
    <app-image [src]="'./assets/image/bottom-left-pattern.svg'" [width]="'128px'" [height]="'128px'"></app-image>
  </div>
  <div class="d-flex justify-content-center align-items-center left-arrow-icon bg-s0" (click)="onClickBackArrowButton()">
    <app-image [src]="'./assets/image/vector.svg'"></app-image>
  </div>
</div>
