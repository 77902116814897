import { TotalSkillTopicPlaylistModel } from '../../../models/total-skill-topic-playlist.model';
import { LearningFlowModel } from '../../../models/learning-flow.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class SearchLearningFlowMapper extends ItemMapper<TotalSkillTopicPlaylistModel> {
    protected mapFromItem(item): TotalSkillTopicPlaylistModel {
        if(item.playlist) return item;

        const searchResult = new TotalSkillTopicPlaylistModel();
        searchResult.playlist = item.total;
        searchResult.data = [];

        item.result.forEach(element => {
            const lfObj = new LearningFlowModel();
            lfObj.id = element.urlPath;
            lfObj.title = element.name;
            lfObj.description = element.about;
            lfObj.author = element.author;
            lfObj.duration = element.duration;
            // lfObj.topicId = element.topicId? element.topicId : 'default-topicId';
            lfObj.topicId = element.topicInfo ? element.topicInfo.urlPath : null;
            lfObj.skillId = element.skills && element.skills.length > 0 ? element.skills[0].urlPath : null;
            lfObj.skillList = [];
            element.skills.forEach(skill => {
                lfObj.skillList.push(skill.details? skill.details.targetFileThumbnailURL: null);
            });
            searchResult.data.push(lfObj);
        });

        return searchResult;
    }
}
