import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class RemoveLocalStorageUseCase {
    constructor(data) {
        this.data = data;
    }
    run(key) {
        return this.data.removeLocalStorage(key);
    }
}
RemoveLocalStorageUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoveLocalStorageUseCase_Factory() { return new RemoveLocalStorageUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: RemoveLocalStorageUseCase, providedIn: "root" });
