<div *ngIf="!loading">
  <div class="bg-1f playlist-header-wrap">
    <div>
      <app-text class="text-s0 h5-strong">{{playlist.name}}</app-text>
    </div>
    <div class="d-flex">
      <!-- <app-text class="text-s0 opacity08 caption">{{playlist.author}}</app-text>
      <div *ngIf="playlist.author && playlist.author == ''" class="small-dot"></div> -->
      <app-text class="text-s0 opacity08 caption-light">{{playlist.skills?.length}} {{(playlist.skills?.length > 1) ? locale?.STEM_story.skills : locale?.STEM_story.skill}} </app-text>
    </div>
  </div>
  <div class="bg-bg33 playlist-wrap">
    <app-playlist-collection-card *ngFor="let oam of playlist.skills;let idx = index;" [oam]="oam" [index]="idx" (onClickOam)="selectOam($event)"></app-playlist-collection-card>
  </div>
</div>

<div *ngIf="loading">
  <div class="bg-1f playlist-header-wrap">
    <app-loading [width]="'30%'" [height]="'20px'"></app-loading>
  </div>
  <div class="bg-bg33 playlist-wrap" style="padding: 0px 10px 20px 10px">
    <app-loading [width]="'100%'" [height]="'50px'"></app-loading>
    <app-loading [width]="'100%'" [height]="'50px'"></app-loading>
  </div>
</div>
