import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/player.mapper";
import * as i2 from "../data.requirements";
export class LoginPlayerUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(username, password) {
        return this.data.playerLogin(username, password).pipe(this.mapper.map);
    }
}
LoginPlayerUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginPlayerUseCase_Factory() { return new LoginPlayerUseCase(i0.ɵɵinject(i1.PlayerMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: LoginPlayerUseCase, providedIn: "root" });
