<div class="all-topic-wrap">
  <div class="topic-list">
      <div class="d-flex justify-content-center pt-3 pb-2">
        <app-brush [brushColor]="'bg-bg02'" [color]="'text-bg04'" [width]="'180'" [height]="'34'" [margin]="'0 0 16px -4px'">{{topicList?.length>1? locale?.home.exploreSTEMQuests : locale?.home.exploreSTEMQuest}}</app-brush>
      </div>
      <div *ngIf="topicList" class="row">
          <div *ngFor="let topic of topicList" class="topic-item-wrap bg-bg01 cursor" (click)="onTopicClick(topic)">
              <div class="topic-item-image-wrap">
                <div class="topic-item" [ngStyle]="{'background-image': 'url('+ topic.url +')'}"></div>
              </div>
              <div class="topic-item-text text-bg05 body-l">
                <app-text>{{topic.title}}</app-text>
              </div>
          </div>
      </div>
      <div *ngIf="!topicList" class="light-body-l text-bg04 m-20">
          <app-loader [spinColor]="'#E6DFCC'" [height]="'50px'" [width]="'80%'"></app-loader>
          <app-loader [spinColor]="'#E6DFCC'" [height]="'60px'" [width]="'60%'"></app-loader>
      </div>
      <div *ngIf="topicList?.length==0" class="light-body-l text-bg04 m-20">{{ locale?.STEM_quest.noQuest }}</div>
  </div>
</div>
