import {Component,Input,Output,OnChanges,OnInit,EventEmitter} from '@angular/core';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-valley-popup',
    templateUrl: './valley-popup.component.html',
    styleUrls: ['./valley-popup.component.css']
})
export class ValleyPopupComponent implements OnInit,OnChanges {
  @Input() notShowAgain:boolean;
  @Input() firstPopup:boolean;
  @Input() locale;
  @Output() onClickClose = new EventEmitter<any>();
  @Output() onChangeCheckButton = new EventEmitter<any>();
  @Output() onClickButton = new EventEmitter<any>();
  public valleyVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.valleyPopupVideoUrl);

  constructor(private sanitizer:DomSanitizer){

  }

  ngOnInit(){
    // document.getElementById('dashboard-home').style.zIndex = '2';
    // document.getElementById('dashboard-home').style.maxHeight = 'calc(100vh - 72px)';
    // document.getElementById('dashboard-home').style.overflow = 'hidden';
  }

  ngOnChanges(){

  }

  ngOnDestroy(){
    // document.getElementById('dashboard-home').style.zIndex = null;
    // document.getElementById('dashboard-home').style.maxHeight = null;
    // document.getElementById('dashboard-home').style.overflow = null;
  }

  onClickCloseBtn(){
    this.onClickClose.emit();
  }

  doNotShowAgain(value:boolean){
    this.notShowAgain = value;
    console.log("notShowAgain",this.notShowAgain);
    this.onChangeCheckButton.emit(value);
  }

  onClickRedirectValley(){
    this.onClickButton.emit();
  }
}
