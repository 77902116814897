import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormControl, AbstractControl, Validators, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})
export class SearchComponent {
  @Input() control;
  @Input() hint='';
  @Input() id;
  @Input() boxHeight = null;
  @Input() boxWidth = null;
  @Input() type = null;

  @Output() searchOutput = new EventEmitter<any>();
  @Output() searchClick = new EventEmitter<any>();

  public data='';
  btnBorder="button-border button-search bg-s20";
  inputSearch = "input-search";
  inputSearchLanding = "input-search-landing"

  public form = this.fb.group({
    searchData: this.fb.control('', [Validators.required]),
  });

  constructor( private fb: FormBuilder) { }

  ngOnInit() {
    if(this.control && this.control!= "[object Event]") {
      this.form.get('searchData').setValue(this.control);
      this.data= this.control;
    }
    this.form.valueChanges.subscribe(value => {
        this.data = value.searchData;
        this.searchOutput.emit(this.data);
    });
  }

  ngOnChanges() {
    if( this.control!= "[object Event]"){
      this.form.get('searchData').setValue(this.control);
    }
  }

  searchImgclick() {
    this.searchClick.emit(true);
  }

}
