/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topic-item.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../text/text.component.ngfactory";
import * as i3 from "../text/text.component";
import * as i4 from "@angular/common";
import * as i5 from "./topic-item.component";
var styles_TopicItemComponent = [i0.styles];
var RenderType_TopicItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopicItemComponent, data: {} });
export { RenderType_TopicItemComponent as RenderType_TopicItemComponent };
function View_TopicItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [["class", "light-body-m"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " ", " ,\u00A0", " ", " "]))], function (_ck, _v) { var currVal_0 = "light-body-m"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.topic.playlist; var currVal_2 = ((_co.topic.playlist > 1) ? ((_co.locale == null) ? null : _co.locale.STEM_path.playlists) : ((_co.locale == null) ? null : _co.locale.STEM_path.playlist)); var currVal_3 = _co.topic.skill; var currVal_4 = ((_co.topic.skill > 1) ? ((_co.locale == null) ? null : _co.locale.STEM_story.skills) : ((_co.locale == null) ? null : _co.locale.STEM_story.skill)); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_TopicItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "topic-item-wrap"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onTopicClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "title-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "topic-text-wrap text-bg01 body-m"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "title text-s0 topic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopicItemComponent_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "light-body-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (("linear-gradient(50.9deg, rgba(0, 0, 0, 0.85) 0%, rgba(255, 255, 255, 0.15) 83.73%),url(" + _co.topic.url) + ")")); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.topic; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.topic.title; _ck(_v, 7, 0, currVal_1); var currVal_3 = _co.topic.description; _ck(_v, 12, 0, currVal_3); }); }
export function View_TopicItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopicItemComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.topic; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TopicItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-topic-item", [], null, null, null, View_TopicItemComponent_0, RenderType_TopicItemComponent)), i1.ɵdid(1, 49152, null, 0, i5.TopicItemComponent, [], null, null)], null, null); }
var TopicItemComponentNgFactory = i1.ɵccf("app-topic-item", i5.TopicItemComponent, View_TopicItemComponent_Host_0, { url: "url", title: "title", source: "source", playlist: "playlist", skill: "skill", description: "description", topic: "topic", locale: "locale" }, { onClick: "onClick" }, []);
export { TopicItemComponentNgFactory as TopicItemComponentNgFactory };
