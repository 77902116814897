/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playlist-related-skills.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../../../global-components/text/text.component";
import * as i4 from "../../../../../global-components/related-skill/related-skill.component.ngfactory";
import * as i5 from "../../../../../global-components/related-skill/related-skill.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../shared-components/loading/loading.component.ngfactory";
import * as i8 from "../../../shared-components/loading/loading.component";
import * as i9 from "./playlist-related-skills.component";
var styles_PlaylistRelatedSkillsComponent = [i0.styles];
var RenderType_PlaylistRelatedSkillsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlaylistRelatedSkillsComponent, data: {} });
export { RenderType_PlaylistRelatedSkillsComponent as RenderType_PlaylistRelatedSkillsComponent };
function View_PlaylistRelatedSkillsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [["class", "mt-2 mb-2 related-skill-header"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "mt-2 mb-2 related-skill-header"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.locale.relatedSTEM_story.relatedStory; _ck(_v, 2, 0, currVal_1); }); }
function View_PlaylistRelatedSkillsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [["class", "mt-2 mb-2 related-skill-header"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "mt-2 mb-2 related-skill-header"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.locale.relatedSTEM_story.relatedStories; _ck(_v, 2, 0, currVal_1); }); }
function View_PlaylistRelatedSkillsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-related-skill", [], null, [[null, "onClickSkill"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClickSkill" === en)) {
        var pd_0 = (_co.onClickSkill($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_RelatedSkillComponent_0, i4.RenderType_RelatedSkillComponent)), i1.ɵdid(1, 114688, null, 0, i5.RelatedSkillComponent, [], { skill: [0, "skill"] }, { onClickSkill: "onClickSkill" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PlaylistRelatedSkillsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "related-skill-list-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistRelatedSkillsComponent_5)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.relatedSkills; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PlaylistRelatedSkillsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [["class", "body-l text-c100"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(2, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "body-l text-c100"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.locale.relatedSTEM_story.noRelatedStory; _ck(_v, 3, 0, currVal_1); }); }
function View_PlaylistRelatedSkillsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "related-skill-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "pt-40 pb-32"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistRelatedSkillsComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistRelatedSkillsComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistRelatedSkillsComponent_4)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistRelatedSkillsComponent_6)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.relatedSkills.length == 1); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.relatedSkills.length > 1); _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_co.relatedSkills == null) ? null : _co.relatedSkills.length) > 0); _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.relatedSkills == null) ? null : _co.relatedSkills.length) == 0); _ck(_v, 9, 0, currVal_3); }, null); }
function View_PlaylistRelatedSkillsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "related-skill-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loading", [], null, null, null, i7.View_LoadingComponent_0, i7.RenderType_LoadingComponent)), i1.ɵdid(2, 49152, null, 0, i8.LoadingComponent, [], { width: [0, "width"], height: [1, "height"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-loading", [], null, null, null, i7.View_LoadingComponent_0, i7.RenderType_LoadingComponent)), i1.ɵdid(6, 49152, null, 0, i8.LoadingComponent, [], { width: [0, "width"], height: [1, "height"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-loading", [], null, null, null, i7.View_LoadingComponent_0, i7.RenderType_LoadingComponent)), i1.ɵdid(9, 49152, null, 0, i8.LoadingComponent, [], { width: [0, "width"], height: [1, "height"] }, null)], function (_ck, _v) { var currVal_0 = "30%"; var currVal_1 = "20px"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "100%"; var currVal_3 = "200px"; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = "100%"; var currVal_5 = "200px"; _ck(_v, 9, 0, currVal_4, currVal_5); }, null); }
export function View_PlaylistRelatedSkillsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistRelatedSkillsComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistRelatedSkillsComponent_7)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PlaylistRelatedSkillsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-playlist-related-skills", [], null, null, null, View_PlaylistRelatedSkillsComponent_0, RenderType_PlaylistRelatedSkillsComponent)), i1.ɵdid(1, 573440, null, 0, i9.PlaylistRelatedSkillsComponent, [], null, null)], null, null); }
var PlaylistRelatedSkillsComponentNgFactory = i1.ɵccf("app-playlist-related-skills", i9.PlaylistRelatedSkillsComponent, View_PlaylistRelatedSkillsComponent_Host_0, { relatedSkills: "relatedSkills", loading: "loading", locale: "locale" }, { onClickRelatedSkill: "onClickRelatedSkill" }, []);
export { PlaylistRelatedSkillsComponentNgFactory as PlaylistRelatedSkillsComponentNgFactory };
