import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-skill-list',
    templateUrl: './skill-list.component.html',
    styleUrls: ['./skill-list.component.css']
})
export class SkillListComponent {
  @Input() totalSkill;
  @Input() skillList;
  @Input() loading;
  @Input() locale;

  @Output() onClickLoadMore = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();

  onSkillClick($event) {
    this.onClick.emit($event);
  }

  onLoadMore($event) {
    this.onClickLoadMore.emit($event);
  }

}
