<div class="recommend-wrap">
  <div class="recommend-image">
    <app-image class="recommend-top" [src]="'assets/image/recommended_top_boder.svg'" [width]="'100%'"></app-image>
  </div>
  <div class="bg-bg33 margin-bottom-1 margin-top-1">
        <div class="wrap">
          <div class="d-flex justify-content-center title-alignment">
            <app-text [ngStyle]="{'color': countTextColor, 'font-family': 'Nunito', 'font-size': '20px', 'font-weight': 'bold'}">{{ locale?.weeklyskills.newSkills }} </app-text>
            <div class="cntBox"><app-text [ngStyle]="{'font-family': 'Nunito', 'font-size': '17px', 'font-weight': 'bold'}">{{newSkills?.length}}</app-text></div>
          </div>
        <!-- <app-brush [brushColor]="'bg-main700'"  [color]="'text-s0'" [width]="'180'" [height]="'34'" [margin]="'0 0 16px -4px'">{{skillList?.length > 1? 'Explore STEMStories' : 'Explore STEMStory'}}</app-brush> -->
        <div *ngIf="newSkills" class="skill-list d-flex">
            <app-skill-item
              [width]="'315px'"
              [height]="'216px'"
              [image]="'assets/image/newSkillsBottom.svg'"
              [title]=""
              [duration]=""
              [hoverColor]="'main500'"
            >
            </app-skill-item>
            <app-skill-item *ngFor="let skill of newSkills"
              [width]="'384px'"
              [height]="'216px'"
              [image]="skill.url"
              [title]="skill.title"
              [duration]="skill.duration"
              [type]="skill.type"
              class="cursor"
              [titleColor]="titleColor"
              [bodyColor]="bodyColor"
              [hoverColor]="'main500'"
              (click)="onSkillClick(skill)"
              ></app-skill-item>
              <app-skill-item
                *ngIf="newSkills.length>1"
                [width]="'315px'"
                [height]="'216px'"
                [image]="'assets/image/newSkillsBottom.svg'"
                [hoverColor]="'main500'"
              >
              </app-skill-item>
        </div>
        <div *ngIf="!newSkills || loading" class="p-4 ml-4">
          <app-loader [spinColor]="'#FFEF9F'" [height]="'30px'" [width]="'80%'"></app-loader>
          <app-loader [spinColor]="'#FFEF9F'" [height]="'30px'" [width]="'70%'"></app-loader>
          <app-loader [spinColor]="'#FFEF9F'" [height]="'30px'" [width]="'60%'"></app-loader>
        </div>
        <div *ngIf="newSkills?.length==0" class="p-20">
          <app-text class="body-l text-s0">{{ locale?.weeklyskills.noSkills }}</app-text>
        </div>
      </div>
  </div>
  <div class="recommend-image">
    <app-image
      class="recommend-bottom"
      [src]="'assets/image/newSkillsBottom.svg'"
      [width]="'100%'"
    ></app-image>
  </div>
</div>
