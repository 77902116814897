<div class="valley-popup-overlay">
  <div class="content-wrapper bg-s0">
    <div class="position-relative text-center spacing-bottom">
      <div class="title-wrap">
        <app-text class="text-secondary700 popup-title">
          {{ locale?.valleyPopup.title }}
        </app-text>
      </div>
      <div class="close-btn" (click)="onClickCloseBtn()">
        <app-image [src]="'./assets/image/searchClose.svg'" [width]="'100%'" [height]="'100%'"></app-image>
      </div>
    </div>
    <!-- <div class="video-wrapper">
      <video class="popup-video" width="100%" height="100%" poster="./assets/image/STEAMValley.svg">
        <source [src]="valleyVideoUrl" type="video/mp4">
      </video>
    </div> -->
    <app-video-player [videoUrl]="valleyVideoUrl"></app-video-player>
    <div class="demo-button-wrapper" [ngClass]="{'justify-content-between': !firstPopup,'justify-content-center': firstPopup}">
      <div *ngIf="!firstPopup" class="d-flex justify-content-center align-items-center showAgainTick spacing-top">
        <app-checkbox [isChecked]="notShowAgain" [locale]="locale" (onChange)="doNotShowAgain($event)"></app-checkbox>
      </div>
      <div class="d-flex justify-content-center spacing-top">
        <!-- <app-button [class]="'button-primary'" [width]="'100%'" [height]="'48px'" (onClick)="onClickRedirectValley()"><app-text class="text-s0 body-l">Help restore peace now!</app-text></app-button> -->
        <button class="redirect-btn" (click)="onClickRedirectValley()">
          <app-text class="text-s0">{{ locale?.valleyPopup.btnText }}</app-text>
        </button>
      </div>
    </div>
  </div>
</div>
