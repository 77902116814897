import {Inject, Component, OnInit} from '@angular/core';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';

import {Router,ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import {SharedService} from '../../../lib/shared-service';
import {AuthService} from '../../../lib/auth.service';
import {SharedDataService} from '../../../lib/shared_data.service';
import {LocaleService} from '../../../lib/locale.service';
import { SetValleyPopupStatusUseCase } from 'src/app/home/business-logic/use-cases/set-valley-popup-status.use-case';
import { SearchBackground } from './search-background';

@Component({
    selector: 'app-app-home',
    templateUrl: './app-home-controller.component.html',
    styleUrls: ['./app-home-controller.component.css']
})
export class AppHomeControllerComponent implements OnInit {
    public monthlyTopic;
    public learningFlow;
    public topicList;
    public skillList;
    public totalSkill;
    public loadmoreLoading = false;
    public loadingLf = false;
    public loadingNew = false;
    public emptySkill = false;
    public showValleyPopup:boolean = false;
    public notShowAgainPopup:boolean = false;
    public firstValleyPopup:boolean;
    public locale;
    public weeklySkills;
    public userInfoSubscription;
    public searchLandingBg;
    public control;
    public previousUrl = null;
    // public tempNotShowAgain:boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private _sharedService: SharedService,
        private _authService: AuthService,
        private _sharedDataService: SharedDataService,
        private _localeService: LocaleService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
      this.userInfoSubscription = this._sharedService.changeUserInfo$.subscribe(
        data => {
          this.checkLoginData();
      });
    }

    ngOnInit() {
        // this.toastr.error('Password updated successfully!', null, {disableTimeOut: true})
        // this.toastr.success('Password updated successfully!', null, {disableTimeOut: true})
        console.log("app-home-controller-component works")
        this._sharedService.setPreviousURL(this.router.url);
        this.getMonthlyTopicList();
        // this.getRecommendation();
        this.getTopicList();
        this.getSkill(0, 9);
        this.getWeeklySkills();
        this.getLocale();
        this.getSearchBackground();
        let isAlreadyLogin = this._authService.isLoginUser();
        if(isAlreadyLogin){
          let userInfo = this._authService.getUserInfo();
          this.getUserPermittedApps();
          if(userInfo.playerid != undefined){
            this.getPlayerData(userInfo.playerid);
          }else{
            this.checkForShowingValleyPopup();
          }
        }
    }

    ngOnDestroy() {
      this.userInfoSubscription.unsubscribe();
    }

    getSearchBackground(){
      let backgroundList = SearchBackground;
      let randomNumber = Math.floor(Math.random() * backgroundList.length);
      this.searchLandingBg = backgroundList[randomNumber];
    }

    checkLoginData(){
      let alreadyLoggedin = this._authService.isLoginUser();
      if(!alreadyLoggedin){
        console.log("log out~~~");
        this.getLocale()
        // this.ngOnInit();
      }
    }

    getUserPermittedApps(){
      this._sharedDataService.getUserPermittedApps().subscribe(data => {
        console.log("home > getUserPermittedApps > data",data);
        this._sharedService.defineUserPermittedApps(data);
        this.getLocale();
      })
    }

    getLocale(){
      this._localeService.getJson().subscribe(data => {
        this.locale = data;
      })
    }

    getPlayerData(playerId){
      console.log("playerId",playerId)
      this.business.getPlayerData(playerId).subscribe(data => {
        let playerLevel = data[0].currentLevel;
        console.log("playerLevel",playerLevel)
        if(playerLevel < 0 || playerLevel == 0){
          this.checkForShowingValleyPopup()
        }else{
          console.log("playerLevel > 0 ~~~ don't show valley popup")
          this.showValleyPopup = false;
        }
      })
    }

    checkForShowingValleyPopup(){
      this.business.getValleyPopupStatus(null).subscribe(res => {
        let showGameDemo = res[0].showGameDemo;
        let diffMins = this.checkDiffHours();
        console.log("diffMins",diffMins);
        if(showGameDemo){
          if((diffMins > 60 || diffMins == 60 || diffMins == null)){//check diffHrs is greater than or equal to 1hour
          // if((diffMins > 1 || diffMins == 1 || diffMins == null)){
            console.log("show valley popup")
            this.showValleyPopup = true;
            this.toggleValleyPopup(this.showValleyPopup);
          }else{
            console.log("diffHrs < 1 ~~~ don't show valley popup")
            this.showValleyPopup = false;
          }
        }
        else{
          console.log("don't show valley popup")
          this.showValleyPopup = false;
          this.business.removeLocalStorage("popupCloseTime")
        }
      },err => {
        console.log("err",err)
      })
    }

    checkDiffHours(){
      let getSaveDate = this.business.getLocalStorage("popupCloseTime");
      if(getSaveDate != undefined){
        this.firstValleyPopup = false;
        let currentDate = new Date();
        let saveDate = new Date(JSON.parse(getSaveDate).date);
        // let currentDate = new Date();
        // let saveDate = new Date('2021-01-11T11:30:00.968Z');
        let diffMs = (currentDate.getTime() - saveDate.getTime()); // milliseconds
        let seconds = diffMs/1000;
        let minutes = seconds/60;
        return minutes;
      }else{
        this.firstValleyPopup = true;
        return null;
      }
    }

    getMonthlyTopicList() {
        this.business.getMonthlyTopic().subscribe(res=>{
            this.monthlyTopic = res;
            // console.log("this.monthlyTopic>>",this.monthlyTopic)
        }, err=> {
            // this.toastr.error('Fail to load monthly topic!');
            this.monthlyTopic = [];
        });
    }

    getRecommendation() {
        this.loadingLf = true;
        this.business.getRecommendedLearningFlow().subscribe(res=>{
            this.learningFlow = res[0];
            this.loadingLf = false;
        },err=>{
            // this.toastr.error('Fail to load recommended Learning flow!');
            this.learningFlow = null;
            this.loadingLf = false;
        });
    }

    getWeeklySkills(){
      this.loadingNew = true;
      this.business.getNewSkills().subscribe(res=>{
          // this.learningFlow = res[0];
          console.log(res);
          this.weeklySkills = res[0].data;
          this.loadingNew = false;
      },err=>{
          // this.toastr.error('Fail to load recommended Learning flow!');
          this.weeklySkills = [];
          this.loadingNew = false;
      });
    }

    getTopicList() {
        this.business.getAllTopics().subscribe(res=>{
            this.topicList = res;
        },err=>{
            // this.toastr.error('Fail to load topic!');
            this.topicList = [];
        });
    }

    getSkill(skip, limit) {
    //   console.log('get skill ', skip, limit)
        this.business.getAllSkills(skip, limit).subscribe(res=>{
            if(skip==0) this.skillList = res[0].data;
            else this.skillList = [...this.skillList, ...res[0].data];
            if (res[0].data.length == 0){
                this.emptySkill = true;
            }
            this.totalSkill = res[0].skill;
            this.loadmoreLoading = false;
        },err=>{
            // this.toastr.error('Fail to load skill!');
            if(skip==0) this.skillList = [];
            this.loadmoreLoading = false;
        });
    }

    clickLearningDetail(e) {
        console.log('click learning flow detail',this.learningFlow);
        var alreadyLoggedin = this._authService.isLoginUser();
        if(alreadyLoggedin) {
            this.router.navigate(['/' + this.learningFlow.topicId + '/' + this.learningFlow.skillId + '/' + this.learningFlow.id ])
        }
        else this._sharedService.setEnableLogin({enable: true, url: '/' + this.learningFlow.topicId + '/' + this.learningFlow.skillId + '/' + this.learningFlow.id});
    }

    clickTopicDetail(e) {
        console.log('click topic detail',e)
        this.router.navigate(['/' + e.id])
    }

    clickSkillDetail(e) {
        console.log('click skill detail',e)
        var alreadyLoggedin = this._authService.isLoginUser();
        if(alreadyLoggedin) {
            this.router.navigate(['/' + e.topicId + '/' + e.id]);
        }
        else {
          if(environment.showStartView == true) {
            let url = environment.stemwerkzStartUrl;
            window.open(url,'_blank');
          }else {
            this._sharedService.setEnableLogin({enable: true, url: '/' + e.topicId + '/' + e.id});
          }
        }
    }

    onSkillLoadMore(e) {
        console.log('click skill loadmore');
        this.loadmoreLoading = true;
        // console.log("this.skillList.length>>",this.skillList.length)
        this.getSkill(this.skillList.length, 9);
    }

    closevalleyPopup(){
      this.showValleyPopup = false;
      this.savePopupCloseTime();
      this.toggleValleyPopup(this.showValleyPopup);
    }

    savePopupCloseTime(){
      console.log("savePopupCloseTime")
      let saveTime = {
        "date": new Date()
      }
      this.business.setLocalStorage("popupCloseTime", JSON.stringify(saveTime))
    }

    onClickNotShowAgain(value:boolean){
      //api integration for don't show me again checkbox input
      this.notShowAgainPopup = value;
      let data = {
        "showGameDemo": this.notShowAgainPopup == true ? false : true
      }
      console.log("on click Don't show me again",this.notShowAgainPopup);
      this.business.setValleyPopupStatus(data).subscribe(res => {
        console.log("setValleyPopupStatus > res",res);
      })
    }

    redirectValley(){
      let url = this._authService.getRedirectValleyUrl();
      window.open(url,'_blank');
    }

    toggleValleyPopup(show){
      if(show){
        console.log("toggleValleyPopup > show",show)
        document.getElementById('dashboard-home').style.zIndex = '2';
        document.getElementById('dashboard-home').style.maxHeight = 'calc(100vh - 72px)';
        document.getElementById('dashboard-home').style.overflow = 'hidden';
      }else{
        console.log("toggleValleyPopup > hide",show)
        document.getElementById('dashboard-home').style.zIndex = null;
        document.getElementById('dashboard-home').style.maxHeight = null;
        document.getElementById('dashboard-home').style.overflow = null;
        let video = <HTMLVideoElement>document.getElementById('game-demo');
        video.pause();
      }
    }

    getSearchValue(val){
      console.log("search landing val>>",val)
        this.control = val;
        if(val == null || val == ''){
          this._sharedService.prevSearchUrl.subscribe(data => {
            this.previousUrl = data['url'] != null && data['url'] != undefined ? data['url'] : '';
            this.router.navigate([this.previousUrl]);
          })
        }
    }

    searchData(){
      console.log("call searching ....");
      if(this.control == null || this.control == ''){
          this._sharedService.prevSearchUrl.subscribe(data => {
            this.previousUrl = data['url'] != null && data['url'] != undefined ? data['url'] : '';
            this.router.navigate([this.previousUrl]);
          })
      }else {
          this.router.navigate(['/search/' + encodeURIComponent(this.control)]);
      }
    }
}
