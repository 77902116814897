import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TopicService {
    constructor(private http: HttpClient) {}

    private baseUrl = environment.apiurl + 'api/v1/';
    private userInfo = {
      username: "teacher_htet",
      password: "t4a62zxbp5jt4a62zxbp5j",
      api_key: "ndmc4acd43956e895b4de8aa7ec742edd3c"
    };

    getMonthlyTopic(): Observable<any> {
        let url = this.baseUrl + 'monthly-topics';
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            API_KEY: this.userInfo.api_key,
            username: this.userInfo.username,
            password: this.userInfo.password
          })
        };
        return this.http.get(url).pipe(map((res:Response) => {
          console.log("res>>",res)
          return res;
        }));
    }

    getAllToics(): Observable<any> {
        let url = this.baseUrl + 'topics';
        return this.http.get(url).pipe(map((res:Response) => {
          return res;
        }));
    }
}
