import {Component,Input,Output,EventEmitter,OnInit} from '@angular/core';

import { Validators, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-login-input',
    templateUrl: './login-input.component.html',
    styleUrls: ['./login-input.component.css']
})
export class LoginInputComponent implements OnInit {
    @Input() placeholder;
    @Input() type;
    @Input() rightTitle;
    @Input() leftTitle;
    // @Input() value;
    @Input() error;
    @Input() locale;
    @Output() onChange = new EventEmitter<any>();
    @Output() onInputChange = new EventEmitter<any>();

    public form = this.fb.group({
        data: this.fb.control('', [Validators.required]),
    });

    constructor(
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.form.valueChanges.subscribe(value => {
            this.onInputChange.emit(value.data);
        });
    }

    // ngOnChanges() {
    //   if(!this.value) this.form.get('data').setValue('');
    // }

    clickText(){
        this.onChange.emit(this.rightTitle);
    }

}
