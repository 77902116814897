<div class="related-skill-block cursor" (click)="onSelectSkill(skill)">
  <!-- <img width="363" height="200" [src]="skill.thumbnailUrl"> -->
  <div *ngIf="skill.thumbnailUrl != undefined" class="skill-thumbnail-wrapper">
    <!-- <app-image [src]="skill.thumbnailUrl" [width]="'363px'" [height]="'200px'" [class]="'oam-img-cover'"></app-image> -->
    <img [src]="skill.thumbnailUrl" class="oam-img-cover">
  </div>
  <div *ngIf="skill.thumbnailUrl == undefined" class="bg-main100 oam-default-img"></div>
  <div class="d-flex spacing-pt-12">
    <div class="play">
      <app-image [src]="'./assets/image/play.png'" [class]="'play_icon'" [maxWidth]="'48px'"></app-image>
    </div>
    <div class="play_hover">
      <app-image [src]="'./assets/image/play_hover.png'" [class]="'play_hover_icon'" [maxWidth]="'48px'"></app-image>
    </div>
    <div class="spacing-pl-12">
      <app-text class="skill-title h2-strong">{{skill.name}}</app-text>
      <!-- <div>
        <app-text class="text-c100 body-l">1h 30min <span class="text-c100">.</span> Science</app-text>
      </div> -->
      <div class="d-flex">
        <!-- <app-text *ngIf="skill?.duration != undefined && skill?.duration != ''" class="text-c100 body-l">{{skill?.duration}}</app-text>
        <div *ngIf="skill?.duration != undefined && skill?.duration != ''" class="small-dot"></div> -->
        <div class="skill-subject-wrap" *ngFor="let subject of skill?.subject">
          <app-text class="text-c100 body-l">{{subject}}</app-text>
          <span>,</span>
        </div>
      </div>
    </div>
  </div>
</div>
