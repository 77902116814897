import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LearningFlowService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl + 'api/v1/';
    }
    getLearningFlow(id, skip, limit) {
        let url = this.baseUrl + 'learningflows';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                topicId: id.toString(),
                skip: skip.toString(),
                limit: limit.toString()
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            console.log("lf service res", result);
            return result;
        }));
    }
}
LearningFlowService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LearningFlowService_Factory() { return new LearningFlowService(i0.ɵɵinject(i1.HttpClient)); }, token: LearningFlowService, providedIn: "root" });
