import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/topic.mapper";
import * as i2 from "../data.requirements";
export class GetAllTopicUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run() {
        return this.data.getAllTopic().pipe(this.mapper.map);
    }
}
GetAllTopicUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetAllTopicUseCase_Factory() { return new GetAllTopicUseCase(i0.ɵɵinject(i1.TopicMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetAllTopicUseCase, providedIn: "root" });
