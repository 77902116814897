import {Component,Input,Output,OnInit,EventEmitter} from '@angular/core';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

@Component({
    selector: 'app-oam-small-block',
    templateUrl: './oam-small-block.component.html',
    styleUrls: ['./oam-small-block.component.css']
})
export class OamSmallBlockComponent implements OnInit {
  @Input() oamData;
  @Output() onClickSkill = new EventEmitter<any>();
  constructor(public sanitizer:DomSanitizer){}

  ngOnInit(){
    // console.log("oamData",this.oamData)
    this.oamData.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.oamData.url);
  }

  onSelectOam(data){
    this.onClickSkill.emit(data);
  }
}
