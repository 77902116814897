import {Inject, Component,OnInit, HostListener} from '@angular/core';
import {Router,ActivatedRoute,Params} from '@angular/router';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';
import {SharedService} from '../../../lib/shared-service';
import {AuthService} from '../../../lib/auth.service';
import { ToastrService } from 'ngx-toastr';
import {SharedDataService} from '../../../lib/shared_data.service';
import {LocaleService} from '../../../lib/locale.service';
import { LanguageSwitchButton } from 'src/app/models/language_switch_button.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-playlist-details',
    templateUrl: './playlist-details-controller.component.html',
    styleUrls: ['./playlist-details-controller.component.css']
})
export class PlaylistDetailsControllerComponent implements OnInit {
    public playlistDetails;
    public currentOam;
    public playlistCollection;
    public playlist_nav:any = [];
    public alreadyLoggedin:boolean = false;
    public playlistId;
    public topicId;
    public skillId;
    public topicData;
    public userInfoSubscription;
    public navSubscription;
    public loadingSkill = true;
    public loadinglf = true;
    public loadingPlay = true;
    public locale;
    public isApp;
    public mobileOS;
    public browser;
    public showLanguageSwitchBtn = false;
    public switchButtons = LanguageSwitchButton;
    public localeName;
    public oamSrcParams = null;
    public envName = environment.envName;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _sharedService: SharedService,
        private _authService: AuthService,
        private toastr: ToastrService,
        private _sharedDataService: SharedDataService,
        private _localeService: LocaleService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
      // this.navSubscription = this._sharedService.currentPlaylistNav.subscribe(data => (this.playlist_nav = data))
      this.userInfoSubscription = this._sharedService.changeUserInfo$.subscribe(
        data => {
          this.checkLoginData();
      });
      // route reload when params change
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit(){
        this.alreadyLoggedin = this._authService.isLoginUser();
        console.log('is login user---', this.alreadyLoggedin)
        this.route.params.subscribe((params:Params) => {
          console.log("Params",params)
          this.playlistId = params.flowid;
          this.topicId = params.topicid;
          this.skillId = params.skillid;
          if(this.alreadyLoggedin) {
            this.getPlaylistDetails(this.playlistId);
            this.getUserPermittedApps();
            this._sharedService.isApp.subscribe(data => {
              this.isApp = data["isMobileApp"];
              this.mobileOS = data["mobileOS"].toLowerCase();
              this.browser = data["browser"].toLowerCase();
            });
            this.localeName = this._localeService.getCurrentLocale();
            console.log("localeName",this.localeName);
            let userInfo = this._authService.getUserInfo();
            if(userInfo.sessionId != undefined && userInfo.sessionId != null) {
              this.oamSrcParams = {
                sessionId: userInfo.sessionId,
                password: userInfo.password,
                username: userInfo.username,
                apikey: userInfo.apikey,
                env: this.envName
              }
            }else {
              this.oamSrcParams = null;
            }
          }
          else this.checkLoginData();
        })
        this.getLocale();
    }

    checkLoginData(){
        this.alreadyLoggedin = this._authService.isLoginUser();
        if(!this.alreadyLoggedin) this._sharedService.setEnableLogin({enable: true, url: this.router.url});
    }

    getLocale(){
      this._localeService.getJson().subscribe(data => {
        this.locale = data;
      })
    }

    getUserPermittedApps(){
      this._sharedDataService.getUserPermittedApps().subscribe(data => {
        console.log("playlist > getUserPermittedApps > data",data);
        this._sharedService.defineUserPermittedApps(data);
        this.getLocale();
      })
    }

    getPlaylistDetails(playlistId){
      this.business.getLearningflowDetails(playlistId).subscribe(data => {
        this.playlistDetails = data[0];
        // this.currentOam = this.playlistDetails.details.skills[0];
        // let currentSkillId = this.playlistDetails.details.skills[0].urlPath;
        this.getSkillsDetails(this.skillId,playlistId);
        this.playlistCollection = this.playlistDetails.details;
        console.log("playlistDetails..",this.playlistDetails);
        this.checkForSetBreadcrumbNav(this.playlistDetails);
        this.setPlaylistOam(this.skillId,this.playlistCollection)
        if(this.playlistCollection){
          this.loadingPlay = false;
        }
        if(this.playlistDetails.relatedLearningFlow){
          this.loadinglf = false;
        }
      },err=>{
        // console.log("err",err);
        // this.toastr.error(err.error.message);
        this.router.navigate(['/page-not-found'])
      })
    }

    setPlaylistOam(currentId,playlist){
      console.log("setPlaylistOam currentId",currentId,'playlist',playlist);
      playlist.skills.map(data => {
        if(data.urlPath == currentId){
          data["isCurrentOam"] = true
        }else{
          data["isCurrentOam"] = false
        }
      })
    }

    getSkillsDetails(skillId,playlistId){
      this.business.getSkillsDetails(skillId,playlistId).subscribe(data => {
        console.log("getSkillsDetails>>",data);
        this.loadingSkill = false;
        this.currentOam = data[0].details;
        this.checkForLanguageSwitch();
        this.checkOamName(this.localeName);
        this.checkOamDescription(this.localeName);
        if(this.currentOam.oamLocales != null && ((this.currentOam.oamLocales.targetFileURL != undefined && this.currentOam.oamLocales.targetFileURL != null) || (this.currentOam.oamLocales.targetFileURLIOS != undefined && this.currentOam.oamLocales.targetFileURLIOS != null))){
          this.checkLocalesUrl(this.localeName);
        }else{
          this.currentOam["oamUrl"] = this.mobileOS == 'ios' && this.browser == 'safari' && !this.isApp && this.currentOam.urlIOS != null ? this.currentOam.urlIOS : this.currentOam.url;
          console.log("currentOam URL~~~~~~~~~",this.currentOam.oamUrl);
        }

        //sample oam url
        // this.currentOam.url = 'http://data.amdon.sg.tmp.s3.amazonaws.com/ygn/ley-01-01-i1/Assets/index.html';
        // this.currentOam.url = 'http://data.amdon.sg.tmp.s3.amazonaws.com/ygn/ley-01-01-i1-v2/Assets/index.html';

        this.trackUserActivity(this.currentOam, "VIEW")
      },err => {
        // this.toastr.error(err.error.message)
      })
    }

    checkOamName(locale){
      if(this.currentOam.oamLocales != null && (this.currentOam.oamLocales.name != undefined || this.currentOam.oamLocales.name != null)){
        let oamNameArr = this.getKeyArray(this.currentOam.oamLocales.name);
        console.log("oamNameArr",oamNameArr,"locale_name",locale);
        let idx = this.findIndex(oamNameArr,locale.toLowerCase());
        if(idx != -1) this.currentOam["name"] = this.currentOam.oamLocales.name[locale];
        else this.currentOam["name"] = this.currentOam.name;
      }
    }

    checkOamDescription(locale){
      if(this.currentOam.oamLocales != null && (this.currentOam.oamLocales.description != undefined || this.currentOam.oamLocales.description != null)){
        let oamDescriptionArr = this.getKeyArray(this.currentOam.oamLocales.description);
        console.log("oamDescriptionArr",oamDescriptionArr,"locale_name",locale);
        let idx = this.findIndex(oamDescriptionArr,locale.toLowerCase());
        if(idx != -1) this.currentOam["description"] = this.currentOam.oamLocales.description[locale];
        else this.currentOam["description"] = this.currentOam.description;
      }
    }

    checkForLanguageSwitch(){
      console.log("checkForLanguageSwitch~~~~~~~~~")
      if(this.currentOam.oamLocales != null && ((this.currentOam.oamLocales.targetFileURL != undefined && this.currentOam.oamLocales.targetFileURL != null) || (this.currentOam.oamLocales.targetFileURLIOS != undefined && this.currentOam.oamLocales.targetFileURLIOS != null))){
        if(this.mobileOS == 'ios' && this.browser == 'safari' && !this.isApp && this.currentOam.urlIOS != null){
          this.checkToShowLanguageSwitch(this.currentOam.oamLocales.targetFileURLIOS);
        }else{
          this.checkToShowLanguageSwitch(this.currentOam.oamLocales.targetFileURL);
        }
      }else{
        this.showLanguageSwitchBtn = false;
      }
    }

    checkToShowLanguageSwitch(urlObject){
      let urlArr = this.getKeyArray(urlObject);
      console.log("urlArr~~~",urlArr);
      let userLocale = this._localeService.getUserLocale();
      console.log("userLocale",userLocale);
      if(userLocale.locale != null && userLocale.secondaryLocale != null){
        let findLocale = this.findIndex(urlArr,userLocale.locale.toLowerCase());
        console.log("findLocale",findLocale);
        let findSecondaryLocale = this.findIndex(urlArr,userLocale.secondaryLocale.toLowerCase());
        console.log("findSecondaryLocale",findSecondaryLocale);
        if(urlArr.length > 1 && findLocale != -1 && findSecondaryLocale != -1){
          this.showLanguageSwitchBtn = true;
          this.showSwitchButton(userLocale);
        }else{
          this.showLanguageSwitchBtn = false;
        }
      }
    }

    showSwitchButton(locale){
      this._localeService.getCountryList().subscribe(res => {
        let countryList = res;
        this.switchButtons.firstButton.buttonLocale = locale.locale;
        this.switchButtons.firstButton.buttonText = countryList[locale.locale] == undefined || countryList[locale.locale] == null ? locale.locale : countryList[locale.locale];
        this.switchButtons.firstButton.flag = this.getFlagName(locale.locale);
        this.switchButtons.secondButton.buttonLocale = locale.secondaryLocale;
        this.switchButtons.secondButton.buttonText = countryList[locale.secondaryLocale] == undefined || countryList[locale.secondaryLocale] == null ? locale.secondaryLocale : countryList[locale.secondaryLocale];
        this.switchButtons.secondButton.flag = this.getFlagName(locale.secondaryLocale);
      });
    }

    getFlagName(str){
      let flagName = str.substr(str.indexOf("-")+1,str.length).toLowerCase();
      return flagName;
    }

    getKeyArray(obj){
      let arr = Object.keys(obj).map(data => data.toLowerCase());
      return arr;
    }

    findIndex(arr,data){
      let idx = arr.indexOf(data);
      return idx;
    }

    checkLocalesUrl(locale){
      if(this.mobileOS == 'ios' && this.browser == 'safari' && !this.isApp && this.currentOam.urlIOS != null){
        this.checkOamUrlIOS(locale);
      }else{
        this.checkOamUrl(locale);
      }
    }

    checkOamUrl(locale){
      let targetFileURLArr = this.getKeyArray(this.currentOam.oamLocales.targetFileURL);
      console.log("targetFileURLArr",targetFileURLArr,"locale_name",this.localeName);
      let idx = this.findIndex(targetFileURLArr,locale.toLowerCase());
      if(idx != -1) this.currentOam["oamUrl"] = this.currentOam.oamLocales.targetFileURL[locale];
      else this.currentOam["oamUrl"] = this.currentOam.url;
      console.log("currentOam URL#########",this.currentOam.oamUrl);
    }

    checkOamUrlIOS(locale){
      let targetFileURLIOSArr = this.getKeyArray(this.currentOam.oamLocales.targetFileURLIOS);
      console.log("targetFileURLIOSArr",targetFileURLIOSArr,"locale_name",this.localeName);
      let idx = this.findIndex(targetFileURLIOSArr,locale.toLowerCase());
      if(idx != -1) this.currentOam["oamUrl"] = this.currentOam.oamLocales.targetFileURLIOS[locale];
      else this.currentOam["oamUrl"] = this.currentOam.urlIOS;
      console.log("currentOam URL#########",this.currentOam.oamUrl);
    }

    checkForSetBreadcrumbNav(data){
      this.business.getTopicDetails(this.topicId).subscribe(res => {
        console.log(res);
        this.setBreadcrumbData(res[0],data)
      },err => {
        this.setBreadcrumbData(null,data)
      })
    }

    setBreadcrumbData(topic,playlist){
      this.playlist_nav = [];
      let playlistObj = {
        "_id": playlist.details.urlPath,
        "name": playlist.details.name,
        "url": this.router.url,
        "type": 'playlist'
      };
      if(topic != null){
        let topicObj = {
          "_id": topic.id,
          "name": topic.title,
          "url": '/' + topic.id,
          "type": 'topic'
        };
        this.playlist_nav.push(topicObj);
        this.playlist_nav.push(playlistObj)
      }else{
        this.playlist_nav.push(playlistObj);
      }
      console.log("playlist nav",this.playlist_nav)
    }

    getTopicDetails(topicId){
      this.business.getTopicDetails(topicId).subscribe(data => {
        console.log("getTopicDetails",data)
        this.topicData = {
          "_id": data.id,
          "name":data.title,
          "url": data.id
        };
      })
    }

    onSelectPlaylist($event){
      console.log("onSelectPlaylist",$event);
      this.router.navigate(['/' + $event.topicId + '/' + $event.skillList[0].urlPath + '/' + $event.urlPath ])
      // this.getPlaylistDetails($event._id)
    }

    goToSkillDetails($event){
      console.log("goToSkillDetails",$event);
      this.router.navigate(['/' + $event.topicId + '/' + $event.urlPath]);
    }

    playOamFromPlaylist($event){
      console.log("playOamFromPlaylist",$event);
      // this.currentOam = $event;
      // this.setPlaylistOam($event.urlPath,this.playlistCollection)
      // this.getSkillsDetails($event.urlPath,this.playlistId);
      this.router.navigate(['/' + $event.topicId + '/' + $event.urlPath + '/' + this.playlistId])
    }

    showNextOam(currentOam){
      console.log("showNextOam",currentOam);
      let nextOam = this.getNextOam(currentOam._id);
      console.log("nextOam",nextOam);
      if(nextOam != null)
        this.router.navigate(['/' + nextOam.topicId + '/' + nextOam.urlPath + '/' + this.playlistId])
    }

    getNextOam(oamId){
      let oamPlaylist = this.playlistCollection.skills;
      let nextIdx = oamPlaylist.map(data => {
        return data._id;
      }).indexOf(oamId) + 1;
      let oam = nextIdx == oamPlaylist.length ? null : oamPlaylist[nextIdx];
      return oam;
    }

    goToNavItem(url){
      console.log("navigate",url);
      this.router.navigate([url]);
      this._sharedService.definePlaylistNav([])
    }

    trackUserActivity(oam, action){
      let data = {
        "subject": "OAM",
        "action": action,
        "details": {
          "learningflow": this.playlistDetails.details.uniqueID,
          "skill": oam.aerReferenceID
        },
        "origin": "CHANNEL"
      }
      console.log("tracking user activity",oam)
      this.business.trackUserActivity(data).subscribe(res => {
        console.log("trackUserActivity______",res, action)
      })
    }

    changeLocale(locale){
      console.log("changeLocale",locale);
      let localeObj = {
        "currentLocale": locale
      };
      this.business.updateLocale(localeObj).subscribe(res => {
        console.log("Update locale successfully");
      })
      this._localeService.updateCurrentLocale(locale);
      this.localeName = locale;
      this.checkLocalesUrl(this.localeName);
    }

    ngOnDestroy() {
      this.userInfoSubscription.unsubscribe();
      // this.navSubscription.unsubscribe();
    }

    @HostListener('window:message', ['$event'])
    messageListener (e) {
      // if(e.origin!=="https://amdon-tools.pagewerkz.com") return;
      if (typeof e.data === 'string' || e.data instanceof String){
        try {
          var data = JSON.parse(e.data.toString())
          if( data && data.id == "oam_tracking"){
            console.log('oam_tracking', data)
            if(data.action == 'COMPLETE' || data.action == 'PLAY'){
              this.trackUserActivity(this.currentOam, data.action);
              this.showNextOam(this.currentOam);
            }
            // action enum: ['COMPLETE', 'PLAY', 'PAUSE', 'REWIND', 'VIEW']
          }
        }
        catch {
          console.log('oam_tracking error---', e)
        }
      }
    }

}
