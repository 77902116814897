<!-- <div class="loader-spin"
    [ngStyle]="{
      'width': width,
      'height': height,
      'border': spinWidth + ' solid ' + spinColor,
      'border-top': spinWidth + ' solid ' + spinTopColor}">
</div> -->

<div class="wrapper">
    <div class="line animate" [ngStyle]="{
      'background': 'linear-gradient(to right, ' + spinColor +' 4%, #fff 25%, '+ spinColor + ' 36%)',
      'width': width,
      'height': height
    }"></div>
</div>
