import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {SearchSkillMapper} from '../mappers/search-skill.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetSearchSkillUseCase {
    constructor(
        private mapper: SearchSkillMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(keyword, skip, limit) {
      return this.data.getSearchSkill(keyword, skip, limit).pipe(this.mapper.map);
    }
}
