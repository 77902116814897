import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import { from, Observable, of } from 'rxjs';
import { TopicService } from './api-services/topic.service';
import { PlaylistService } from './api-services/playlist.service';
import { SkillService } from './api-services/skill.service';
import { DataService } from './api-services/data.service';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
      private topicApiService: TopicService,
      private playlistApiService: PlaylistService,
      private skillApiService: SkillService,
      private dataApiService: DataService
    ) {}

    getMonthlyTopic(): Observable<any> {
        return this.topicApiService.getMonthlyTopic();
    }

    getRecommendedLearningFlow(): Observable<any> {
        return this.playlistApiService.getRecommendedPlaylist();
    }

    getNewSkills(): Observable<any> {
      return this.skillApiService.getWeeklySkills();
  }

    getAllTopic(): Observable<any> {
        return this.topicApiService.getAllToics();
    }

    getAllSkill(skip, limit): Observable<any> {
        return this.skillApiService.getAllSkills(skip, limit);
    }

    getPlayerData(playerId):Observable<any>{
      return this.dataApiService.getPlayerData(playerId);
    }

    getValleyPopupStatus(player):Observable<any>{
      return this.dataApiService.getValleyPopupStatus(player);
    }

    setValleyPopupStatus(data):Observable<any>{
      return this.dataApiService.setValleyPopupStatus(data);
    }

    setLocalStorage(key, value){
      localStorage.setItem(key, value);
    }

    getLocalStorage(key){
      return localStorage.getItem(key);
    }

    removeLocalStorage(key){
      localStorage.removeItem(key);
    }
}
