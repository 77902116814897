import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {TeacherProfileControllerComponent} from './teacher-profile-controller.component';
import {RouterModule} from '@angular/router';

import {ProfileHeaderComponent} from './components/profile-header/profile-header.component';
import {ProfileInfoComponent} from './components/profile-info/profile-info.component';
import {ClassListsComponent} from './components/class-lists/class-lists.component';

@NgModule({
    declarations: [
        TeacherProfileControllerComponent,
        ProfileHeaderComponent,
        ProfileInfoComponent,
        ClassListsComponent
    ],
    exports: [
        TeacherProfileControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule
    ]
})
export class TeacherProfileModule {}
