import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {LearningFlowMapper} from '../mappers/learningflow.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetLearningFlowUseCase {
    constructor(
        private mapper: LearningFlowMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(id, skip, limit) {
        return this.data.getAllLearningFlow(id, skip, limit).pipe(this.mapper.map);
    }
}
