/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./valley-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/checkbox/checkbox.component.ngfactory";
import * as i3 from "../../../../../global-components/checkbox/checkbox.component";
import * as i4 from "../../../../../global-components/text/text.component.ngfactory";
import * as i5 from "../../../../../global-components/text/text.component";
import * as i6 from "../../../../../global-components/image/image.component.ngfactory";
import * as i7 from "../../../../../global-components/image/image.component";
import * as i8 from "../video-player/video-player.component.ngfactory";
import * as i9 from "../video-player/video-player.component";
import * as i10 from "@angular/common";
import * as i11 from "./valley-popup.component";
import * as i12 from "@angular/platform-browser";
var styles_ValleyPopupComponent = [i0.styles];
var RenderType_ValleyPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ValleyPopupComponent, data: {} });
export { RenderType_ValleyPopupComponent as RenderType_ValleyPopupComponent };
function View_ValleyPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex justify-content-center align-items-center showAgainTick spacing-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-checkbox", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.doNotShowAgain($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CheckboxComponent_0, i2.RenderType_CheckboxComponent)), i1.ɵdid(2, 49152, null, 0, i3.CheckboxComponent, [], { isChecked: [0, "isChecked"], locale: [1, "locale"] }, { onChange: "onChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notShowAgain; var currVal_1 = _co.locale; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ValleyPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "valley-popup-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "content-wrapper bg-s0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "position-relative text-center spacing-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "title-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-text", [["class", "text-secondary700 popup-title"]], null, null, null, i4.View_TextComponent_0, i4.RenderType_TextComponent)), i1.ɵdid(5, 49152, null, 0, i5.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(6, 0, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickCloseBtn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-image", [], null, null, null, i6.View_ImageComponent_0, i6.RenderType_ImageComponent)), i1.ɵdid(9, 114688, null, 0, i7.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-video-player", [], null, null, null, i8.View_VideoPlayerComponent_0, i8.RenderType_VideoPlayerComponent)), i1.ɵdid(11, 114688, null, 0, i9.VideoPlayerComponent, [], { videoUrl: [0, "videoUrl"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 10, "div", [["class", "demo-button-wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(15, { "justify-content-between": 0, "justify-content-center": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValleyPopupComponent_1)), i1.ɵdid(17, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "d-flex justify-content-center spacing-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "button", [["class", "redirect-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickRedirectValley() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "app-text", [["class", "text-s0"]], null, null, null, i4.View_TextComponent_0, i4.RenderType_TextComponent)), i1.ɵdid(21, 49152, null, 0, i5.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(22, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text-secondary700 popup-title"; _ck(_v, 5, 0, currVal_0); var currVal_2 = "./assets/image/searchClose.svg"; var currVal_3 = "100%"; var currVal_4 = "100%"; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.valleyVideoUrl; _ck(_v, 11, 0, currVal_5); var currVal_6 = "demo-button-wrapper"; var currVal_7 = _ck(_v, 15, 0, !_co.firstPopup, _co.firstPopup); _ck(_v, 14, 0, currVal_6, currVal_7); var currVal_8 = !_co.firstPopup; _ck(_v, 17, 0, currVal_8); var currVal_9 = "text-s0"; _ck(_v, 21, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.locale == null) ? null : _co.locale.valleyPopup.title); _ck(_v, 6, 0, currVal_1); var currVal_10 = ((_co.locale == null) ? null : _co.locale.valleyPopup.btnText); _ck(_v, 22, 0, currVal_10); }); }
export function View_ValleyPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-valley-popup", [], null, null, null, View_ValleyPopupComponent_0, RenderType_ValleyPopupComponent)), i1.ɵdid(1, 770048, null, 0, i11.ValleyPopupComponent, [i12.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ValleyPopupComponentNgFactory = i1.ɵccf("app-valley-popup", i11.ValleyPopupComponent, View_ValleyPopupComponent_Host_0, { notShowAgain: "notShowAgain", firstPopup: "firstPopup", locale: "locale" }, { onClickClose: "onClickClose", onChangeCheckButton: "onChangeCheckButton", onClickButton: "onClickButton" }, []);
export { ValleyPopupComponentNgFactory as ValleyPopupComponentNgFactory };
