import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {DashboardFeatureModule} from './dashboard/dashboard.module';
import {TopicsFeatureModule} from './topics/topics.module';
import {SkillsFeatureModule} from './skills/skills.module';
import {PlaylistsFeatureModule} from './playlists/playlists.module';
import {HomeFeatureModule} from './home/home.module';
import {RedirectFeatureModule} from './redirect/redirect.module';
import {LiveClassesFeatureModule} from './live-classes/live-classes.module';

import { SharedDataService } from './lib/shared_data.service';
import { SharedService } from './lib/shared-service';
import { AuthService } from './lib/auth.service';
import { DeeplinkService } from './lib/deeplink.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),

    DashboardFeatureModule,
    TopicsFeatureModule,
    SkillsFeatureModule,
    PlaylistsFeatureModule,
    HomeFeatureModule,
    RedirectFeatureModule,
    LiveClassesFeatureModule
  ],
  providers: [SharedService, AuthService, SharedDataService, DeeplinkService],
  bootstrap: [AppComponent]
})
export class AppModule { }
