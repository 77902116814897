import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import { share } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SkillService {
    constructor(private http: HttpClient) {}

    private baseUrl = environment.apiurl + 'api/v1/';

    getSkills(id, skip, limit): Observable<any> {
        let url = this.baseUrl + 'skills';
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            topicId: id.toString(),
            skip: skip.toString(),
            limit: limit.toString()
          })
        };
        console.log("headers>>",httpOptions.headers)
        return this.http.get(url,httpOptions).pipe(map((res:Response) => {
          let result = [];
          result.push(res);
          console.log("skill service res>>",result)
          return result;
        }));
    }

}
