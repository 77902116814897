import {Inject, Component, OnInit} from '@angular/core';
import {Router,ActivatedRoute,Params} from '@angular/router';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';

import { ToastrService } from 'ngx-toastr';
import {profileBackground} from './profile-background';
import {profileBgColor} from './profile-bgcolor';
import {AuthService} from '../../../lib/auth.service';
import {LocaleService} from '../../../lib/locale.service';
import {SharedService} from '../../../lib/shared-service';
import {SharedDataService} from '../../../lib/shared_data.service';

@Component({
    selector: 'app-teacher-profile',
    templateUrl: './teacher-profile-controller.component.html',
    styleUrls: ['./teacher-profile-controller.component.css']
})
export class TeacherProfileControllerComponent implements OnInit {
    public profileBgImage;
    public profileBgColor;
    public profileImage;
    public userInfoSubscription;
    public alreadyLoggedin:boolean = false;
    public locale;
    public teacherId;
    public teacherInfo;
    public teacherClasses:any = [];
    public isLoading:boolean = true;
    public lesson;
    public notiPoup;
    public errorPoup;
    public joinPopup = {enable: false, title: null, classname: null, teachername: null, username: null, nodmaUrl: null, zoomLink: '', zoomRoomID: '', zoomRoomPasscode: ''};
    public userData;
    public isApp:boolean;
    public mobileOS;
    public isAndroidApp:boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _sharedDataService: SharedDataService,
        private _sharedService: SharedService,
        private _authService: AuthService,
        private _localeService: LocaleService,
        private toastr: ToastrService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
      this.userInfoSubscription = this._sharedService.changeUserInfo$.subscribe(
        data => {
          this.checkLoginData();
      });
    }

    ngOnInit(){
      this.checkUserAgent();
      this.getLocale();
      this.route.params.subscribe((params:Params) => {
        console.log("Params",params);
        this.teacherId = params.teacherid;
        if(this.alreadyLoggedin)
          this.getUserPermittedApps();
          this.getProfileBackgroundImage();
          this.getBackgroundColor();
          this.getTeacherProfile(this.teacherId);
          this.userData = this._authService.getUserInfo();
          this.joinPopup.nodmaUrl = this._authService.getRedirectReaderUrl();
      })
    }

    getUserPermittedApps(){
      this._sharedDataService.getUserPermittedApps().subscribe(data => {
        console.log("teacherProfileDetails > getUserPermittedApps > data",data);
        this._sharedService.defineUserPermittedApps(data);
        this.getLocale();
      })
    }
    checkUserAgent(){
      this._sharedService.isApp.subscribe(data => {
        this.isApp = data['isMobileApp'];
        this.mobileOS = data['mobileOS'].toLowerCase();
        this.isAndroidApp = this.isApp && this.mobileOS == 'android' ? true : false;
        console.log("checkUserAgent~~~",this.isApp,this.mobileOS)
      })
    }

    ngOnDestroy() {
      this.userInfoSubscription.unsubscribe();
    }

    checkLoginData(){
      this.alreadyLoggedin = this._authService.isLoginUser();
      if(!this.alreadyLoggedin) this._sharedService.setEnableLogin({enable: true, url: this.router.url});
    }

    getLocale(){
      this._localeService.getJson().subscribe(data => {
        this.locale = data;
      })
    }

    getProfileBackgroundImage(){
      let backgroundList = profileBackground;
      let randomNumber = Math.floor(Math.random() * backgroundList.length);
      console.log("randomNumber",randomNumber);
      this.profileBgImage = backgroundList[randomNumber];
      console.log("profileBgImage~~~~~~~",this.profileBgImage);
    }

    getBackgroundColor(){
      let bgColorList = profileBgColor;
      let randomNumber = Math.floor(Math.random() * bgColorList.length);
      this.profileBgColor = bgColorList[randomNumber];
      console.log("profileBgColor",this.profileBgColor);
    }

    getTeacherProfile(teacherId){
      console.log("teacherId~~~~~~",teacherId);
      this.isLoading = true;
      this.business.getTeacherProfile(teacherId).subscribe(res => {
        this.teacherInfo = res[0].profile;
        this.teacherClasses = res[0].classes;
        this.profileImage = this.teacherInfo.imgUrl;
        this.teacherClasses.forEach(data => {
          data = this.checkClassCondition(data);
        })
        this.isLoading = false;
        console.log("getTeacherProfile > teacherInfo",this.teacherInfo);
        console.log("getTeacherProfile > teacherClass",this.teacherClasses)
      })
    }

    checkCurrentTime( date, scope ) {
        var datetime = new Date( date ).getTime();
        var now = new Date().getTime();
        var result = false;

        if (datetime > now) {
            result = scope=='start'? false :  true;
        }
        else result = scope=='start'? true : false;

        return result;
    }

    checkClassCondition(classObj) {
        var day = new Date(classObj.startDate).getDay();
        switch(day){
          case 0 : classObj.background = '#CCC1A5'
            break;
          case 1 : classObj.background = '#79E1FA'
            break;
          case 2 : classObj.background = '#FFD918'
            break;
          case 3 : classObj.background = '#52CC85'
            break;
          case 4 : classObj.background = '#D3B3FD'
            break;
          case 5 : classObj.background = '#FEB3DF'
            break;
          case 6 : classObj.background = '#FEC985'
            break;
        }
        var start = this.checkCurrentTime(classObj.startDate, 'start');
        var end = this.checkCurrentTime(classObj.endDate, 'end');
        if(!end) {
          console.log('----> button is in class ended condition');
          classObj.button = {text: this.locale.liveclasses.classEnded, disabled: true, class: 'button-primary', loading: false}
          classObj.note = null;
          classObj.deviceMessageText = null;
        }
        else if(classObj.isEnrolled) {
          if(start && end ) {
            console.log('----> button is in join now condition',this.mobileOS);
            classObj.button = {text: this.locale.liveclasses.joinNow, disabled: this.userData.usertype != 5 || this.userData.usertype != '5' ? false : true, class: 'button-secondary', loading: false}
            classObj.note = null;
            classObj.deviceMessageText = this.mobileOS != 'unknown' && classObj.zoomLink == '' ? this.locale.liveclasses.deviceMessage : null;
          }
          else {
            console.log('----> button is in enrolled condition');
            classObj.button = {text: this.locale.liveclasses.enrolledButton, disabled: false, class: 'button-tertiary', loading: false}
            classObj.note = null;
            classObj.deviceMessageText = null;
          }
          this.realTimeChecker(classObj);
        }
        else {
          var classSizePercentage = (classObj.seatTaken/classObj.totalSeat)*100;
          if(classSizePercentage==100){
            console.log('----> Fully booked');
            classObj.button = {text: this.locale.liveclasses.fullyBookedButton, disabled: true, class: 'button-primary', loading: false}
            classObj.note = null;
            classObj.deviceMessageText = null;
          }
          else if(classSizePercentage>=50) {
            console.log('enroll----> Limited seats left!');
            classObj.button = {text: this.locale.liveclasses.enrollButton, disabled: this.userData.usertype != 5 || this.userData.usertype != '5' ? false : true, class: 'button-primary', loading: false}
            classObj.note = this.locale.liveclasses.limitedSeatsLeft;
            classObj.deviceMessageText = null;
          }
          else {
            console.log('enroll----> Save your spot!',this.userData);
            classObj.button = {text: this.locale.liveclasses.enrollButton, disabled: this.userData.usertype != 5 || this.userData.usertype != '5' ? false : true, class: 'button-primary', loading: false}
            classObj.note = this.locale.liveclasses.saveSpot;
            classObj.deviceMessageText = null;
          }
        }
        return classObj;
    }

    realTimeChecker(data) {
        var start = this.checkCurrentTime(data.startDate, 'start');
        var end = this.checkCurrentTime(data.endDate, 'end');
        var timeout;
        if(!end) {
          data.button = {text: this.locale.liveclasses.classEnded, disabled: true, class: 'button-primary', loading: false}
          data.note = null;
          data.deviceMessageText = null;
        }
        else if(start && end ) {
          data.button = {text: this.locale.liveclasses.joinNow, disabled: this.userData.usertype != 5 || this.userData.usertype != '5' ? false : true, class: 'button-secondary', loading: false}
          data.note = null;
          data.deviceMessageText = this.mobileOS != 'unknown' && data.zoomLink == '' ? this.locale.liveclasses.deviceMessage : null;;
          timeout = (new Date(data.endDate).getTime() - new Date().getTime());
        }
        else {
          data.button = {text: this.locale.liveclasses.enrolledButton, disabled: false, class: 'button-tertiary', loading: false}
          data.note = null;
          data.deviceMessageText = null;
          timeout = (new Date(data.startDate).getTime() - new Date().getTime());
        }
        timeout = Math.abs(Math.round(timeout));
        setTimeout(() => {
          if(end) this.realTimeChecker(data);
          else return null;
        }, timeout);
    }

    onClickBackArrowButton(){
      console.log("onClickBackArrowButton");
      this.router.navigate(['/liveclasses']);
    }

    onClickClass(obj){
        console.log("onClickEnrollBtn",obj);
        if(obj.button.text === this.locale.liveclasses.enrollButton) {
          this.enrollClass(obj);
        }
        else if(obj.button.text === this.locale.liveclasses.joinNow) {
          this.openJoinPopup(obj);
        }
        else console.log('click', obj)
    }

    enrollClass(classObj) {
        this.lesson = classObj.title;
        classObj.button.loading = true;
        this.business.enrollLiveClass(classObj.id).subscribe(res=>{
          console.log('enroll live class------>', res[0].message)
          classObj.button.loading = false;
          this.notiPoup = true;
          // this.loadData();
          this.getTeacherProfile(this.teacherId);
          document.getElementById('header_nav').style.zIndex = "unset";
          setTimeout(()=>{
            this.notiPoup = false;
            document.getElementById('header_nav').style.zIndex = "2";
            document.getElementById('dashboard-home').style.zIndex = null;
            document.getElementById('dashboard-home').style.maxHeight = null;
            document.getElementById('dashboard-home').style.overflow = null;
          },3500)
        },err=>{
          console.log('enroll error-----',err)
          if(err.code == "LIVECLASS.enrollLiveClassLimitReached"){
            this.errorPoup = true;
          }else{
            if(err.error.message){
              this.toastr.error(err.error.message);
            }else{
              this.toastr.error("something went wrong");
            }
          }
          classObj.button.loading = false;
        })
    }

    openJoinPopup(classObj) {
      console.log('>>>open join popup')
      this.joinPopup.enable = true;
      this.joinPopup.title = classObj.zoomLink == '' ? this.locale.liveclasses.joining+'...' : this.locale.liveclasses.joinLesson;
      this.joinPopup.classname = classObj.title;
      this.joinPopup.teachername = classObj.teacherName;
      this.joinPopup.username = this.userData.username;
      this.joinPopup.zoomLink = classObj.zoomLink;
      this.joinPopup.zoomRoomID = classObj.zoomRoomID;
      this.joinPopup.zoomRoomPasscode = classObj.zoomRoomPasscode;
    }

    hideJoinPopup(e) {
      console.log("e>>",e)
      // if(e=='button_click') this.joinClass();
      // else this.joinPopup.enable = false;
      this.joinPopup.enable = false;
      console.log("this.joinPopup.enable >>",this.joinPopup.enable)
    }

    hideNoti(){
      this.notiPoup = false;
      document.getElementById('header_nav').style.zIndex = "2";
    }

    hideErrorPopup(){
      this.errorPoup = false;
    }
}
