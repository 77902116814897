import { SkillModel } from '../../../models/skill.model';
import {ItemMapper} from '../../../lib/item-mapper';
import { TotalSkillTopicPlaylistModel } from '../../../models/total-skill-topic-playlist.model';

export class SkillMapper extends ItemMapper<TotalSkillTopicPlaylistModel> {
    protected mapFromItem(item): TotalSkillTopicPlaylistModel {
        // console.log("item in skill mapper",item)
        const result = new TotalSkillTopicPlaylistModel();
        result.skill = item.total;
        item.result.forEach(element => {
            const skillModel= new SkillModel()
            skillModel.id = element.urlPath;
            skillModel.title = element.details? element.details.name : null;
            skillModel.url = element.details? element.details.targetFileThumbnailURL : null;
            skillModel.type = element.details? element.details.subject[0] : null;
            skillModel.duration = null;
            skillModel.topicId = item.result[0]? item.result[0].topicId[0].urlPath : 'default-topicId';
            result.data.push(skillModel);
        });

        return result;
    }

}
