import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class UpdateLocaleUseCase {
    constructor(data) {
        this.data = data;
    }
    run(data) {
        return this.data.updateLocale(data);
    }
}
UpdateLocaleUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateLocaleUseCase_Factory() { return new UpdateLocaleUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: UpdateLocaleUseCase, providedIn: "root" });
