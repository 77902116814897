<div class="oam-small-block cursor" (click)="onSelectOam(oamData)">
  <div class="oam-thumbnail">
    <div *ngIf="oamData.thumbnailUrl != null" class="oam-thumbnail-wrap">
      <!-- <app-image [width]="'275px'" [height]="'150px'" [src]="oamData.thumbnailUrl" [class]="'oam-img-cover'"></app-image> -->
      <img [src]="oamData.thumbnailUrl" class="oam-img-cover">
    </div>
    <div *ngIf="oamData.thumbnailUrl == null" class="bg-main100 default-img-cover"></div>
    <div class="play-btn">
      <app-image [src]="'./assets/image/play.png'" [maxWidth]="'36px'"></app-image>
    </div>
    <div class="play-hover-btn">
      <app-image [src]="'./assets/image/play_hover.png'" [maxWidth]="'36px'"></app-image>
    </div>
  </div>
  <div>
    <app-text class="oam-title h5-strong">{{oamData.name}}</app-text>
  </div>
</div>
