/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../image/image.component.ngfactory";
import * as i3 from "../image/image.component";
import * as i4 from "@angular/common";
import * as i5 from "../input/input.component.ngfactory";
import * as i6 from "../input/input.component";
import * as i7 from "./search.component";
import * as i8 from "@angular/forms";
var styles_SearchComponent = [i0.styles];
var RenderType_SearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchComponent, data: {} });
export { RenderType_SearchComponent as RenderType_SearchComponent };
function View_SearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "d-flex justify-content-center align-items-center bg-accent500 icon-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchImgclick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(2, 114688, null, 0, i3.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"] }, null)], function (_ck, _v) { var currVal_0 = "./assets/image/white-search-icon.svg"; var currVal_1 = "18px"; var currVal_2 = "18px"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_SearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "input-group d-flex align-items-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "search-group": 0, "search-landing-group": 1 }), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "width": 0, "height": 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-input", [["id", "staff-search-input"], ["type", "search"]], null, [[null, "searchImgclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchImgclick" === en)) {
        var pd_0 = (_co.searchImgclick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_InputComponent_0, i5.RenderType_InputComponent)), i1.ɵdid(8, 114688, null, 0, i6.InputComponent, [], { type: [0, "type"], hint: [1, "hint"], class: [2, "class"], id: [3, "id"], control: [4, "control"], width: [5, "width"], height: [6, "height"] }, { searchImgclick: "searchImgclick" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "input-group d-flex align-items-center"; var currVal_1 = _ck(_v, 3, 0, (_co.type != "searchLanding"), (_co.type == "searchLanding")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 6, 0, ((_co.boxWidth !== null) ? _co.boxWidth : "400px"), ((_co.boxHeight !== null) ? _co.boxHeight : "48px")); _ck(_v, 5, 0, currVal_2); var currVal_3 = "search"; var currVal_4 = _co.hint; var currVal_5 = ((_co.type == "searchLanding") ? _co.inputSearchLanding : _co.inputSearch); var currVal_6 = _co.id; var currVal_7 = _co.form.get("searchData"); var currVal_8 = "100%"; var currVal_9 = ((_co.type == "searchLanding") ? _co.boxHeight : "48px"); _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (_co.type == "searchLanding"); _ck(_v, 10, 0, currVal_10); }, null); }
export function View_SearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search", [], null, null, null, View_SearchComponent_0, RenderType_SearchComponent)), i1.ɵdid(1, 638976, null, 0, i7.SearchComponent, [i8.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchComponentNgFactory = i1.ɵccf("app-search", i7.SearchComponent, View_SearchComponent_Host_0, { control: "control", hint: "hint", id: "id", boxHeight: "boxHeight", boxWidth: "boxWidth", type: "type" }, { searchOutput: "searchOutput", searchClick: "searchClick" }, []);
export { SearchComponentNgFactory as SearchComponentNgFactory };
