<div id="zone-toogle" class="zone-info-wrap bg-s0">
  <!-- <div class="zone-title">
        <app-text class="text-secondary700">{{locale?.zones.stemwerkzZone}}</app-text>
    </div> -->

  <div class="zone-info">
    <div class="d-flex bg-s20 ml-4 mr-4 pr-3 dashboard-menu-wrap position-relative mt-4">
      <a class="d-flex w-100" [href]="dashboardUrl">
        <div class="icon-wrap">
          <app-image [src]="zoneDashboardLogoUrl" [width]="'100%'" [height]="'100%'"></app-image>
        </div>
        <div class="mt-3">
          <div class="text-c300 menu-dashboard">
            {{ locale?.zones.dashboardTitle }}
          </div>
          <div class="text-c100 caption-light">
            {{ locale?.zones.dashboardText }}
          </div>
        </div>
        <div class="position-absolute d-flex align-items-center h-100 r-30">
          <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'8px'" [height]="'14px'"></app-image>
        </div>
      </a>
    </div>
    <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('steamvalley') != -1" class="cl item1 cursor">
      <a class="d-flex pt-3 pl-4 pr-4" [href]="vUrl" target="_blank">
        <div class="item icon-wrapper bg-s20">
          <div class="zone-steamvalley">
            <app-image *ngIf="logoConfig == 'english'" [src]="zoneValleyLogoUrl"></app-image>
            <app-image *ngIf="logoConfig == 'chinese'" [src]="zoneValleyCnLogoUrl"></app-image>
          </div>
        </div>
        <div class="d-flex justify-content-between ml-3 zone-text-wrap border-bottom">
          <div class="item light-body-m text-c100 mt-1">{{locale?.zones.valleyDescription}}</div>
          <div class="d-flex align-items-center pl-3 pr-3">
            <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'8px'" [height]="'14px'"></app-image>
          </div>
        </div>
      </a>
    </div>
    <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('channel') != -1" class="cl cursor">
      <a class="d-flex pt-3 pl-4 pr-4" [href]="'/'" target="_blank">
        <div class="item icon-wrapper bg-s20">
          <div class="zone-channel">
            <app-image *ngIf="logoConfig == 'english'" [src]="zoneChannelLogoUrl"></app-image>
            <app-image *ngIf="logoConfig == 'chinese'" [src]="zoneChannelCnLogoUrl"></app-image>
          </div>
        </div>
        <div class="d-flex justify-content-between ml-3 zone-text-wrap border-bottom">
          <div class="item light-body-m text-c100 mt-1">{{locale?.zones.channelDescription}}</div>
          <div class="d-flex align-items-center pl-3 pr-3">
            <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'8px'" [height]="'14px'"></app-image>
          </div>
        </div>
      </a>
    </div>
    <div *ngIf="!isAcademyDisabled && (permittedApps?.length == 0 || permittedApps.indexOf('academy') != -1)"
      class="cl cursor">
      <a class="d-flex pt-3 pl-4 pr-4" [href]="academyUrl" target="_blank">
        <div class="item icon-wrapper bg-s20">
          <app-image [src]="zoneAcademyLogoUrl"></app-image>
        </div>
        <div class="d-flex justify-content-between ml-3 zone-text-wrap border-bottom">
          <div class="item light-body-m text-c100 mt-1">{{locale?.zones.academyDescription}}</div>
          <div class="d-flex align-items-center pl-3 pr-3">
            <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'8px'" [height]="'14px'"></app-image>
          </div>
        </div>
      </a>
    </div>
    <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('quest') != -1" class="cl cursor">
      <a class="d-flex pt-3 pl-4 pr-4" [href]="questsUrl" target="_blank">
        <div class="item icon-wrapper bg-s20 position-relative">
          <div class="zone-stemquest">
            <app-image *ngIf="logoConfig == 'english'" [src]="zoneQuestLogoUrl"></app-image>
            <app-image *ngIf="logoConfig == 'chinese'" [src]="zoneQuestCnLogoUrl"></app-image>
          </div>
        </div>
        <div class="d-flex justify-content-between ml-3 zone-text-wrap">
          <div class="item light-body-m text-c100 mt-1">{{locale?.zones.questDescription}}</div>
          <div class="d-flex align-items-center pl-3 pr-3">
            <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'8px'" [height]="'14px'"></app-image>
          </div>
        </div>
      </a>
    </div>
    <!-- <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('steamvalley') != -1" class="cl item1 cursor">
            <a class="d-flex pt-3 pb-3 pl-4 pr-4" [href]="vUrl" target="_blank">
                <div class="mr-auto item"> <app-image [src]="'assets/image/Zone-STEAMVALLEY.png'"></app-image></div>
                <div class="item zone-text light-body-m text-c300">{{locale?.zones.valleyDescription}}</div>
            </a>
            <div class="ml-4 mr-4 border-bottom"></div>
        </div>
        <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('channel') != -1" class="cl cursor">
            <a class="d-flex pt-3 pb-3 pl-4 pr-4" [href]="'/'" target="_blank">
                <div class="mr-auto item"> <app-image [src]="'assets/image/Zone-CHANNEL.png'"></app-image></div>
                <div class="item zone-text light-body-m text-c300">{{locale?.zones.channelDescription}}</div>
            </a>
            <div class="ml-4 mr-4 border-bottom"></div>
        </div>
        <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('academy') != -1" class="cl cursor">
            <a class="d-flex pt-3 pb-3 pl-4 pr-4" [href]="academyUrl" target="_blank">
                <div class="mr-auto item"> <app-image [src]="'assets/image/Zone_ACADEMY.png'"></app-image></div>
                <div class="item zone-text light-body-m text-c300">{{locale?.zones.academyDescription}}</div>
            </a>
        </div> -->
    <!-- <div *ngIf="!isMobileApp" class="d-flex cl last ml-4 mr-4 pb-3 pt-3">
      <div class="mr-auto item light-body-m text-c100 d-flex">
        <div *ngFor="let data of locale?.zones.stemwerkzUrlLabel" class="pr-1">
          <app-text *ngIf="data!='stemwerkzLink'">{{data}}</app-text>
          <a *ngIf="data=='stemwerkzLink'" class="text-secondary700" [href]="stemUrl"
            target="_blank">{{locale.zones[data]}}</a>
        </div>
      </div>
      <div class="item light-body-m">
        <a class="text-secondary700" [href]="loginUrl" target="_blank">
          {{locale?.zones.parentLogin}}
        </a>
      </div>
    </div> -->
  </div>
</div>