<!-- <div class="d-flex bg-s0 breadcrump-nav">
  <div class="text-secondary700 cursor" (click)="clickNavItem('/')">{{ locale?.breadCrumpNav.home }}</div>
  <div class="text-c75 right-arrow">
    <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'8px'" [height]="'14px'"></app-image>
  </div>
  <div class="d-flex" *ngFor="let item of data;let i=index;">
    <div class="text-secondary700 cursor" *ngIf="i+1 < data?.length" href="#" (click)="clickNavItem(item.url)">{{item.name}}</div>
    <div class="text-c100 cursor truncate" *ngIf="i+1 == data?.length">{{item.name}}</div>
    <div *ngIf="i+1 < data?.length" class="text-c75 right-arrow">
      <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'8px'" [height]="'14px'"></app-image>
    </div>
  </div>
</div> -->
<div class="d-flex bg-s0 breadcrump-nav">
  <div class="d-flex text-secondary700 cursor" (click)="clickNavItem('/', 'home' , null)">
    <div>{{ locale?.breadCrumpNav.home }}</div>
    <div class="text-c75 right-arrow">
      <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'100%'" [height]="'100%'"></app-image>
    </div>
  </div>
  <div class="d-flex nav-item-container">
    <div *ngFor="let item of data;let i=index;" class="position-relative truncate" [ngClass]="{'d-flex': i+1 < data?.length, 'cursor': i+1 < data?.length, 'text-secondary700': i+1 < data?.length, 'text-c100': i+1 == data?.length}" (click)="clickNavItem(item.url, 'nav-item', i)">
      <div class="truncate">{{item.name}}</div>
      <div *ngIf="i+1 < data?.length" class="text-c75">
        <div class="right-arrow">
          <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'100%'" [height]="'100%'"></app-image>
        </div>
      </div>
    </div>
  </div>
</div>
