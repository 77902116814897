import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';

import {PlayerMapper} from '../mappers/player.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetPlayerDataUseCase {
    constructor(
        private mapper: PlayerMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(playerId) {
      return this.data.getPlayerData(playerId).pipe(this.mapper.map);
    }
}
