<div class="bg-s0 profile-list-wrapper">
  <div class="text-secondary700 h5-strong text-center text-uppercase">
    {{ locale?.liveclasses.teacherProfileTitle }}
  </div>
  <div *ngIf="isLoading" class="d-flex">
    <div class="col-md-4">
      <app-loader [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
    </div>
    <div class="col-md-4">
      <app-loader [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
    </div>
    <div class="col-md-4">
      <app-loader [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
    </div>
  </div>
  <div *ngIf="!isLoading" class="d-flex align-items-start profile-list-container">
    <div class="d-flex mx-auto">
      <div *ngFor="let item of profileList" class="profile-wrapper">
        <app-teacher-profile [teacherProfile]="item" (clickProfile)="onClickTeacherProfile($event)"></app-teacher-profile>
      </div>
    </div>
  </div>
</div>
