import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../global-components/global-components.module';
import {SharedComponentsModule} from './presentation/shared-components/shared-components.module';
import {BusinessLogicFacade} from './business-logic/business-logic.facade';
import {BusinessRequirementsInjectionToken} from './presentation/business-logic.requirements';
import {BusinessLogicRequirementsFake} from './presentation/business-logic.requirements.fake';

import {DashboardHomeModule} from './presentation/dashboard-home/dashboard-home.module';
import {SearchResultModule} from './presentation/search-result/search-result.module';
import {LoginModule} from './presentation/login/login.module';
import {PageNotFoundModule} from './presentation/page-not-found/page-not-found.module';

@NgModule({
    imports: [
        BusinessLogicFacade,
        CommonModule,
        GlobalComponentsModule,
        SharedComponentsModule,

        DashboardHomeModule,
        SearchResultModule,
        LoginModule,
        PageNotFoundModule
    ],
    providers: [
        {
            provide: BusinessRequirementsInjectionToken,
            useClass: BusinessLogicFacade
            // useClass: BusinessLogicRequirementsFake
        }
    ],
    exports: []
})
export class DashboardFeatureModule {}
