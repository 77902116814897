/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./teacher-profile.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/image/image.component.ngfactory";
import * as i3 from "../../../../../global-components/image/image.component";
import * as i4 from "./teacher-profile.component";
var styles_TeacherProfileComponent = [i0.styles];
var RenderType_TeacherProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TeacherProfileComponent, data: {} });
export { RenderType_TeacherProfileComponent as RenderType_TeacherProfileComponent };
export function View_TeacherProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickProfile(_co.teacherProfile) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex justify-content-center image-cropper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(3, 114688, null, 0, i3.ImageComponent, [], { src: [0, "src"], class: [1, "class"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "text-c100 body-m text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teacherProfile.imgUrl; var currVal_1 = "rounded-profile-img"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.teacherProfile.name; _ck(_v, 5, 0, currVal_2); }); }
export function View_TeacherProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-teacher-profile", [], null, null, null, View_TeacherProfileComponent_0, RenderType_TeacherProfileComponent)), i1.ɵdid(1, 49152, null, 0, i4.TeacherProfileComponent, [], null, null)], null, null); }
var TeacherProfileComponentNgFactory = i1.ɵccf("app-teacher-profile", i4.TeacherProfileComponent, View_TeacherProfileComponent_Host_0, { teacherProfile: "teacherProfile" }, { clickProfile: "clickProfile" }, []);
export { TeacherProfileComponentNgFactory as TeacherProfileComponentNgFactory };
