<div class="bg-main500 skill-result d-flex align-items-center flex-column" >
    <app-brush [brushColor]="'bg-main700'" [center]="'justify-content-center'" [color]="'text-s0'" [width]="'150'">
      {{totalSkill}} {{totalSkill>1? locale?.STEM_story.skills : locale?.STEM_story.skill}}
    </app-brush>
    <div class="skill-center row" [ngStyle]="{'max-width': skillList.length==1? '395px': skillList.length==2? '790px': ''}">
        <app-skill-item *ngFor="let skill of skillList"
          [width]="'363px'"
          [height]="'200px'"
          [image]="skill.url"
          [title]="skill.title"
          [duration]="skill.duration"
          [type]="skill.type"
          [hoverColor]="'accent500'"
          (click)="onSkillClick(skill)"
          class="cursor"
        ></app-skill-item>
    </div>
    <app-button *ngIf="totalSkill>skillList.length" [height]="'56px'" [class]="'button-primary'" [width]="'322px'" [loading]="loading" (onClick)="onLoadMore($event)">
      <app-text class="text-s0 mr-13 body-l">{{loading? locale?.loading+'...' : locale?.loadMore+'...'}}</app-text>
      <app-image *ngIf="!loading" [src]="'assets/image/downArrow.svg'"></app-image>
    </app-button>
</div>
