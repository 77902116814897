import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-teacher-profile',
    templateUrl: './teacher-profile.component.html',
    styleUrls: ['./teacher-profile.component.css']
})
export class TeacherProfileComponent {
  @Input() teacherProfile;
  @Output() clickProfile = new EventEmitter<any>();

  onClickProfile(data){
    this.clickProfile.emit(data);
  }
}
