import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import {DataRequirementsFake} from '../business-logic/data.requirements.fake';

import { from, Observable } from 'rxjs';
import {GetSearchLearningFlowUseCase} from './use-cases/get-search-learning-flow.use-case';
import {GetSearchSkillUseCase} from './use-cases/get-search-skill.use-case';
import {GetSearchTopicUseCase} from './use-cases/get-search-topic.use-case';
import {LoginPlayerUseCase} from './use-cases/login-player.use-case';
import {SetLocalstorageUseCase} from './use-cases/set-localstorage.use-case';
import {RemoveLocalstorageUseCase} from './use-cases/remove-localstorage.use-case';
import {UserInfoUseCase} from './use-cases/user-info.use-case';
import {PermittedAppsUseCase} from './use-cases/permitted-apps.use-case';
import {GetLocalstorageUseCase} from './use-cases/get-localstorage.use-case';

import {SearchTopicMapper} from './mappers/search-topic.mapper';
import {SearchLearningFlowMapper} from './mappers/search-learning-flow.mapper';
import {SearchSkillMapper} from './mappers/search-skill.mapper';
import {PlayerMapper} from './mappers/player.mapper';
import {UserMapper} from './mappers/user.mapper';
import {PermittedAppsMapper} from './mappers/permitted-apps.mapper';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
            // useClass: DataRequirementsFake
        },
        SearchTopicMapper,
        SearchLearningFlowMapper,
        SearchSkillMapper,
        PlayerMapper,
        UserMapper,
        PermittedAppsMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
      private loginPlayerusecase: LoginPlayerUseCase,
      private getSearchLFusecase: GetSearchLearningFlowUseCase,
      private getSearchSkillusecase: GetSearchSkillUseCase,
      private getSearchTopicusecase: GetSearchTopicUseCase,
      private setLSusecase: SetLocalstorageUseCase,
      private removeLSusecase: RemoveLocalstorageUseCase,
      private userInfoUseCase: UserInfoUseCase,
      private permittedAppsUseCase: PermittedAppsUseCase,
      private getLocalstorageUseCase: GetLocalstorageUseCase
    ) {}

    loginPlayer(username, password) : Observable<any>{
        return this.loginPlayerusecase.run(username, password);
    }
    setLocalstorage(key, value){
        this.setLSusecase.run(key, value);
    }
    removeLocalstorage(key) {
      this.removeLSusecase.run(key);
    }
    getSearchTopic(keyword) : Observable<any> {
      return this.getSearchTopicusecase.run(keyword);
    }
    getSearchLearningFlow(keyword, skip, limit) : Observable<any> {
      return this.getSearchLFusecase.run(keyword, skip, limit)
    }
    getSearchSkill(keyword, skip, limit) : Observable<any> {
      return this.getSearchSkillusecase.run(keyword, skip,limit)
    }
    getUserInfo() : Observable<any>{
      return this.userInfoUseCase.run();
    }
    getPermittedApps(userId):Observable<any>{
      return this.permittedAppsUseCase.run(userId);
    }
    getLocalstorage(key){
      return this.getLocalstorageUseCase.run(key);
    }
}
