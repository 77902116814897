/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./class-lists.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared-components/liveclass-card/liveclass-card.component.ngfactory";
import * as i3 from "../../../shared-components/liveclass-card/liveclass-card.component";
import * as i4 from "../../../shared-components/liveclass-card/class-card.store";
import * as i5 from "../../../../../global-components/text/text.component.ngfactory";
import * as i6 from "../../../../../global-components/text/text.component";
import * as i7 from "@angular/common";
import * as i8 from "./class-lists.component";
var styles_ClassListsComponent = [i0.styles];
var RenderType_ClassListsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClassListsComponent, data: {} });
export { RenderType_ClassListsComponent as RenderType_ClassListsComponent };
function View_ClassListsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-liveclass-card", [], null, [[null, "clickenroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickenroll" === en)) {
        var pd_0 = (_co.onClickEnroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LiveclassCardComponent_0, i2.RenderType_LiveclassCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.LiveclassCardComponent, [i4.ClassCardStore], { liveclass: [0, "liveclass"], locale: [1, "locale"], usertype: [2, "usertype"] }, { clickenroll: "clickenroll" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.locale; var currVal_2 = _co.usertype; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ClassListsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [["class", "body-l text-c200"]], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i6.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "body-l text-c200"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.locale == null) ? null : _co.locale.liveclasses.noData); _ck(_v, 2, 0, currVal_1); }); }
export function View_ClassListsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-text", [["class", "text-secondary700 h2-strong"]], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(3, 49152, null, 0, i6.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "class-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClassListsComponent_1)), i1.ɵdid(7, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClassListsComponent_2)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text-secondary700 h2-strong"; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.classes; _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.classes == null) ? null : _co.classes.length) == 0); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.locale == null) ? null : _co.locale.liveclasses.liveclassTitle); _ck(_v, 4, 0, currVal_1); }); }
export function View_ClassListsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-class-lists", [], null, null, null, View_ClassListsComponent_0, RenderType_ClassListsComponent)), i1.ɵdid(1, 49152, null, 0, i8.ClassListsComponent, [], null, null)], null, null); }
var ClassListsComponentNgFactory = i1.ɵccf("app-class-lists", i8.ClassListsComponent, View_ClassListsComponent_Host_0, { classes: "classes", locale: "locale", usertype: "usertype" }, { clickEnrollBtn: "clickEnrollBtn" }, []);
export { ClassListsComponentNgFactory as ClassListsComponentNgFactory };
