import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
    selector: 'app-custom-notification',
    templateUrl: './custom-notification.component.html',
    styleUrls: ['./custom-notification.component.css']
})
export class CustomNotificationComponent implements OnInit {
  @Input() user;
  @Input() lesson;
  @Input() locale;
  @Output() onClickDismiss = new EventEmitter<any>();

  constructor(){

  }

  ngOnInit(){
    document.getElementById('dashboard-home').style.zIndex = '2';
    document.getElementById('dashboard-home').style.maxHeight = 'calc(100vh - 72px)';
    document.getElementById('dashboard-home').style.overflow = 'hidden';
  }

  onDismissBtnClick(){
    document.getElementById('dashboard-home').style.zIndex = null;
    document.getElementById('dashboard-home').style.maxHeight = null;
    document.getElementById('dashboard-home').style.overflow = null;
    this.onClickDismiss.emit();
  }

}
