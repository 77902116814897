import {Inject, Component, OnInit} from '@angular/core';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';

import {Router,ActivatedRoute} from '@angular/router';
import {SharedService} from '../../../lib/shared-service';
import {AuthService} from '../../../lib/auth.service';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result-controller.component.html',
    styleUrls: ['./search-result-controller.component.css']
})
export class SearchResultControllerComponent implements OnInit {
    public topicList;
    public learningFlowList;
    public skillList;
    public filterType = 0; // filter by topic
    public totalSkill;
    public totalPlaylist;
    public keyword;
    public loadingSkill;
    public loadingPlaylist;
    public loadingTopic;
    public loadingLFButton;
    public loadingSkillButton;
    public locale:any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _sharedService: SharedService,
        private _authService: AuthService,
        private _localeService: LocaleService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
    }

    ngOnInit(){
        this._sharedService.setPreviousURL(this.router.url);
        this.route.params.subscribe(params => {
            console.log('change detecting---',params)
            var keyword = decodeURIComponent(params.keyword);
            if( keyword!= "[object Event]" && this.keyword !== keyword){
              console.log('change key---',keyword)
                this.keyword = keyword;
                this.getSearchData();
            }
        });
        this.getJson();
    }

    getJson(){
      this._localeService.getJson().subscribe(data => {
        console.log("searchResult###############",data)
        this.locale = data;
      })
    }

    getSearchData() {
        this.topicList = null;
        this.learningFlowList = null;
        this.skillList = null;
        this.totalSkill = null;
        this.totalPlaylist = null;
        this.getTopicList();
        this.getLearningFlow(0,4);
        this.getSkillList(0, 9);
    }

    getTopicList() {
        this.loadingTopic = true;
        this.business.getSearchTopic(this.keyword).subscribe(res=>{
            this.loadingTopic = false;
            this.topicList = res;
        }, err=>{
            this.topicList = [];
            this.loadingTopic = false;
        })
    }

    getLearningFlow(skip, limit) {
        this.loadingPlaylist = true;
        this.business.getSearchLearningFlow(this.keyword, skip, limit).subscribe(res=>{
            this.loadingPlaylist = false;
            this.loadingLFButton = false;
            if(skip==0)this.learningFlowList = res[0].data;
            else this.learningFlowList = [...this.learningFlowList, ...res[0].data];
            this.totalPlaylist = res[0].playlist;
        }, err=>{
            console.log('error', err)
            if(this.learningFlowList==null) {
                this.learningFlowList = [];
                this.totalPlaylist = 0;
            }
            this.loadingPlaylist = false;
            this.loadingLFButton = false;
        })
    }

    getSkillList(skip,limit) {
        this.loadingSkill = true;
        this.business.getSearchSkill(this.keyword,skip,limit).subscribe(res=>{
            this.loadingSkill = false;
            this.loadingSkillButton = false;
            if(skip==0) this.skillList = res[0].data;
            else this.skillList = [...this.skillList, ...res[0].data];
            this.totalSkill = res[0].skill;
            console.log('res', res)
        }, err=>{
            console.log('error', err)
            if(this.skillList==null) {
                this.skillList = [];
                this.totalSkill = 0;
            }
            this.loadingSkill = false;
            this.loadingSkillButton = false;
        })
    }

    onClickFilter(e) {
        this.filterType = this.filterType==e? 0 : e;
        let element = document.getElementById(e);
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        console.log('on click filter', e)
    }

    loadMoreSkill(e) {
        this.loadingSkillButton = true;
        this.getSkillList(this.skillList.length, 9)
        console.log('on click load more skill', e)
    }

    onClickSkill(e) {
        console.log('on click skill', e)
        var alreadyLoggedin = this._authService.isLoginUser();
        if(alreadyLoggedin) {
            this.router.navigate(['/' + e.topicId + '/' + e.id]);
        }
        else this._sharedService.setEnableLogin({enable: true, url: '/' + e.topicId + '/' + e.id});
    }

    onClickLearningFlow(e) {
        console.log('on click learning flow',e)
        var alreadyLoggedin = this._authService.isLoginUser();
        if(alreadyLoggedin) {
            this.router.navigate(['/' + e.topicId + '/' + e.skillId + '/' + e.id ])
        }
        else this._sharedService.setEnableLogin({enable: true, url: '/' + e.topicId + '/' + e.skillId + '/' + e.id});
    }

    onClickTopic(e) {
        console.log('on click topic', e)
        this.router.navigate(['/' + e.id])
    }

    loadMoreLearningFlow(e) {
        this.loadingLFButton = true;
        this.getLearningFlow(this.learningFlowList.length, 4)
        console.log('on click load more learning flow', e)
    }

    // test for push
}
