import {Component, Input, OnChanges} from '@angular/core';

@Component({
    selector: 'app-profile-info',
    templateUrl: './profile-info.component.html',
    styleUrls: ['./profile-info.component.css']
})
export class ProfileInfoComponent implements OnChanges {
  @Input() teacherProfile;
  @Input() locale;

  ngOnChanges(){
    console.log("teacherProfile",this.teacherProfile)
  }
}
