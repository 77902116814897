<div class="month-topic-wrap bg-secondary500">
    <div class="center-wrap">
        <app-brush [color]="'text-secondary700'" [width]="'180'" [height]="'34'" [margin]="'0 0 16px -4px'">{{topic?.length>1? locale?.home.featuredSTEMQuests : locale?.home.featuredSTEMQuest}}</app-brush>
        <div *ngIf="topic" class="row">
            <app-topic-item (click)="onTopicClick(item)" *ngFor="let item of topic" [topic]="item" [locale]="locale"></app-topic-item>
        </div>
        <div>
          <app-text *ngIf="topic?.length==0" class="body-l text-secondary700">{{ locale?.home.featuredSTEMQuest}}</app-text>
          <div *ngIf="!topic">
              <app-loader [spinColor]="'#CCF5FF'" [height]="'30px'" [width]="'85%'"></app-loader>
              <app-loader [spinColor]="'#CCF5FF'"></app-loader>
              <app-loader [spinColor]="'#CCF5FF'" [height]="'20px'" [width]="'75%'"></app-loader>
          </div>
        </div>
    </div>
</div>
