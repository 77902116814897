import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {UserMapper} from '../mappers/user.mapper';

@Injectable({
    providedIn: 'root'
})
export class UserInfoUseCase {
    constructor(
        private mapper: UserMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
        return this.data.getUserInfo().pipe(this.mapper.map);
    }
}
