<!-- <div class="login-frame bg-s0">
    <div class="close-form cursor" (click)="closeClick($event)">
        <app-image [src]="'assets/image/loginclose.svg'"></app-image>
    </div>
    <div class="row d-flex align-items-center justify-content-center">
        <div class="col-md-12 w-65">
            <app-image [src]="'assets/image/STEMWerkzLogo2.png'"></app-image>
        </div>
        <div class="col-md-12 w-65 login-text">
            <app-text class="h2-strong text-s0 position-abs">PLAYER LOGIN</app-text>
        </div>
    </div>
    <div class="d-flex flex-column">
        <ng-content></ng-content>
    </div>
</div> -->
<div class="login-frame bg-s0">
  <div class="login-img-wrapper">
    <div class="login-logo-wrapper">
      <app-image [src]="'assets/image/STEMWerkzLogo2.png'" [width]="'100%'" [height]="'100%'"></app-image>
    </div>
  </div>
  <div class="d-flex flex-column login-form-wrapper">
    <div class="form-wrapper">
      <div class="pb-3 position-relative">
        <div class="login-mobileview-logo">
          <app-image [src]="'assets/image/STEMWerkzLogo2.png'" [width]="'100%'" [height]="'100%'"></app-image>
        </div>
        <app-text class="title text-secondary700">
          {{locale?.Login.frameTitle}}
        </app-text>
        <div class="close-btn" (click)="closeClick($event)">
          <app-image [src]="'assets/image/searchClose.svg'" [width]="'100%'" [height]="'100%'"></app-image>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
    <div *ngIf="!isMobileApp" class="text-center bg-s10 parent-portal-url-wrap">
      <app-text *ngFor="let val of locale?.Login.parentInfo">
        <a *ngIf="val=='parent_portal'" href="{{parentPortalUrl}}" class="light-body-m text-secondary700 parent-portal-link">{{locale.Login[val]}}</a>
        {{val!='parent_portal'? val: ''}}
      </app-text>
    </div>
  </div>
</div>
