import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {PlaylistDetailsMapper} from '../mappers/playlist-details.mapper';

@Injectable({
    providedIn: 'root'
})
export class PlaylistDetailUseCase {
    constructor(
        public mapper: PlaylistDetailsMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(playlistId) {
      return this.data.getLearningflowDetails(playlistId).pipe(this.mapper.map)
    }
}
