import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
    clearConsole();
}
function clearConsole() {
    window.console.log = () => { };
    window.console.warn = () => { };
    window.console.info = () => { };
    window.console.error = () => { };
    window.console.debug = () => { };
    window.console.group = () => { };
    window.console.trace = () => { };
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(err => console.error(err));
