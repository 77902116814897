import {Component,Input} from '@angular/core';

@Component({
    selector: 'app-play-view-loading',
    templateUrl: './play-view-loading.component.html',
    styleUrls: ['./play-view-loading.component.css']
})
export class PlayViewLoadingComponent {
 @Input() width;
 @Input() height;
 @Input() blockColor;
 @Input() locale;
}
