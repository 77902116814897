/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topic-wrapper.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/topic-item/topic-item.component.ngfactory";
import * as i3 from "../../../../../global-components/topic-item/topic-item.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../global-components/text/text.component.ngfactory";
import * as i6 from "../../../../../global-components/text/text.component";
import * as i7 from "../../../shared-components/loader/loader.component.ngfactory";
import * as i8 from "../../../shared-components/loader/loader.component";
import * as i9 from "../../../../../global-components/brush/brush.component.ngfactory";
import * as i10 from "../../../../../global-components/brush/brush.component";
import * as i11 from "./topic-wrapper.component";
var styles_TopicWrapperComponent = [i0.styles];
var RenderType_TopicWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopicWrapperComponent, data: {} });
export { RenderType_TopicWrapperComponent as RenderType_TopicWrapperComponent };
function View_TopicWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-topic-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTopicClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TopicItemComponent_0, i2.RenderType_TopicItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.TopicItemComponent, [], { topic: [0, "topic"], locale: [1, "locale"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.locale; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TopicWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopicWrapperComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.topic; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TopicWrapperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [["class", "body-l text-secondary700"]], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i6.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "body-l text-secondary700"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.locale == null) ? null : _co.locale.home.featuredSTEMQuest); _ck(_v, 2, 0, currVal_1); }); }
function View_TopicWrapperComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i1.ɵdid(2, 49152, null, 0, i8.LoaderComponent, [], { width: [0, "width"], height: [1, "height"], spinColor: [2, "spinColor"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-loader", [], null, null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i1.ɵdid(4, 49152, null, 0, i8.LoaderComponent, [], { spinColor: [0, "spinColor"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-loader", [], null, null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i1.ɵdid(6, 49152, null, 0, i8.LoaderComponent, [], { width: [0, "width"], height: [1, "height"], spinColor: [2, "spinColor"] }, null)], function (_ck, _v) { var currVal_0 = "85%"; var currVal_1 = "30px"; var currVal_2 = "#CCF5FF"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "#CCF5FF"; _ck(_v, 4, 0, currVal_3); var currVal_4 = "75%"; var currVal_5 = "20px"; var currVal_6 = "#CCF5FF"; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6); }, null); }
export function View_TopicWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "month-topic-wrap bg-secondary500"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "center-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-brush", [], null, null, null, i9.View_BrushComponent_0, i9.RenderType_BrushComponent)), i1.ɵdid(3, 114688, null, 0, i10.BrushComponent, [], { width: [0, "width"], height: [1, "height"], color: [2, "color"], margin: [3, "margin"] }, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopicWrapperComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopicWrapperComponent_3)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopicWrapperComponent_4)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "180"; var currVal_1 = "34"; var currVal_2 = "text-secondary700"; var currVal_3 = "0 0 16px -4px"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.topic; _ck(_v, 6, 0, currVal_5); var currVal_6 = (((_co.topic == null) ? null : _co.topic.length) == 0); _ck(_v, 9, 0, currVal_6); var currVal_7 = !_co.topic; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = ((((_co.topic == null) ? null : _co.topic.length) > 1) ? ((_co.locale == null) ? null : _co.locale.home.featuredSTEMQuests) : ((_co.locale == null) ? null : _co.locale.home.featuredSTEMQuest)); _ck(_v, 4, 0, currVal_4); }); }
export function View_TopicWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-topic-wrapper", [], null, null, null, View_TopicWrapperComponent_0, RenderType_TopicWrapperComponent)), i1.ɵdid(1, 49152, null, 0, i11.TopicWrapperComponent, [], null, null)], null, null); }
var TopicWrapperComponentNgFactory = i1.ɵccf("app-topic-wrapper", i11.TopicWrapperComponent, View_TopicWrapperComponent_Host_0, { topic: "topic", locale: "locale" }, { onClick: "onClick" }, []);
export { TopicWrapperComponentNgFactory as TopicWrapperComponentNgFactory };
