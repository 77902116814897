import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import { environment } from 'src/environments/environment';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {AuthService} from '../../../lib/auth.service';

@Injectable({
    providedIn: 'root'
})
export class TeacherProfileService {
    constructor(private http: HttpClient,private _authService:AuthService) {}

    private baseUrl = environment.liveClassApiurl + 'api/v1/';

    getTeacherList(){
      let url = this.baseUrl + 'teachers';
      let userInfo = this._authService.getUserInfo();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          username: userInfo.username,
          api_key: userInfo.apikey,
          password: userInfo.password,
          userid: userInfo.userid
        })
      };
      return this.http.get(url,httpOptions).pipe(map((res:Response) => {
        return res;
      }));
    }

    getTeacherProfile(teacherId){
      let url = this.baseUrl + 'teachers/' + teacherId;
      let userInfo = this._authService.getUserInfo();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          username: userInfo.username,
          api_key: userInfo.apikey,
          password: userInfo.password,
          userid: userInfo.userid
        })
      };
      return this.http.get(url,httpOptions).pipe(map((res:Response) => {
        let result = [];
        result.push(res);
        return result;
      }));
    }
}
