import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../lib/auth.service";
export class PlaylistService {
    constructor(http, _authService) {
        this.http = http;
        this._authService = _authService;
        this.baseurl = environment.apiurl + 'api/v1/';
        this.gameurl = environment.stemgameapiurl + 'api/v2/';
    }
    getLearningflowDetails(playlistId) {
        this.userInfo = this._authService.getUserInfo();
        console.log("this.userInfo", this.userInfo);
        console.log("getLearningflowDetails", playlistId);
        let url = this.baseurl + 'learningflows/' + playlistId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                api_key: this.userInfo.apikey,
                username: this.userInfo.username,
                password: this.userInfo.password,
                option: 'urlPath'
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            console.log("getLearningflowDetails API res~~~", res);
            let result = [];
            result.push(res);
            return result;
        }));
    }
    userActivity(data) {
        this.userInfo = this._authService.getUserInfo();
        data["userID"] = this.userInfo.userid;
        // let url = this.gameurl + 'userActivity';
        let url = this.gameurl + 'resource-usage';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                api_key: this.userInfo.apikey,
                username: this.userInfo.username,
                password: this.userInfo.password
            })
        };
        return this.http.post(url, data, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
    getTopicDetails(topicId) {
        this.userInfo = this._authService.getUserInfo();
        let url = this.baseurl + 'topics/' + topicId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                api_key: this.userInfo.apikey,
                username: this.userInfo.username,
                password: this.userInfo.password,
                option: 'urlPath'
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            console.log("get Topic Details API res~~~", res);
            let result = [];
            result.push(res);
            return result;
        }));
    }
}
PlaylistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlaylistService_Factory() { return new PlaylistService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: PlaylistService, providedIn: "root" });
