import { UserModel } from '../../../models/user.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class UserMapper extends ItemMapper<UserModel> {
    protected mapFromItem(item): UserModel {
        const userModel = new UserModel();
        userModel.username = item.username;
        userModel.userid = item._id;
        if(item.generatedProfileImage){
          userModel.profileImg = item.generatedProfileImage.profileImage;
          userModel.text = item.generatedProfileImage.initials;
        } else {
          userModel.profileImg = "https://pagewerkz-main.s3.amazonaws.com/user-profile-svg-icons/green_deco_01.svg";
          userModel.text = item.username.substring(0, 2);
        }
        userModel.profileImgBorder = item ? this.getBorderColor(userModel.profileImg) : null;
        userModel.password = item.token;
        userModel.apikey = item.apikey;
        if(item.metadata){
          userModel.locale = item.metadata.locale != undefined ? item.metadata.locale : 'en-US';
          userModel.currentLocale = item.metadata.currentLocale != undefined ? item.metadata.currentLocale : null;
          userModel.secondaryLocale = item.metadata.secondaryLocale != undefined ? item.metadata.secondaryLocale : null;
        } else {
          userModel.locale = 'en-US';
          userModel.currentLocale = null;
          userModel.secondaryLocale = null;
        }

        delete userModel.idUser;
        delete userModel.orgname;
        delete userModel.permittedApps;
        return userModel;
    }

    getBorderColor(profileImgUrl){
      let color;
      let bgColor = this.getBackgroundColor(profileImgUrl);
      switch(bgColor) {
        case 'green':
          color = '#52CC85';
          break;
        case 'grey':
          color = "#7A8A99";
          break;
        case 'red':
          color = "#FF9E99";
          break;
        default:
          color = "#FFBF00";
      }
      return color;
    }

    getBackgroundColor(imageUrl){
      var filename = imageUrl.match(/.*\/(.*)$/)[1];
      let backgroundColor = filename.substring(0,filename.indexOf('_'));
      return backgroundColor;
    }
}
