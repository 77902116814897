import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../lib/auth.service";
export class SkillsDetailsService {
    constructor(http, _authService) {
        this.http = http;
        this._authService = _authService;
        this.baseurl = environment.apiurl + 'api/v1/';
        this.gameurl = environment.stemgameapiurl + 'api/v2/';
    }
    getSkillDetails(skillId, playlistId) {
        this.userInfo = this._authService.getUserInfo();
        console.log("getSkillDetails API", skillId, playlistId);
        let url = this.baseurl + 'skills/' + skillId + '?learningFlowId=' + playlistId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                api_key: this.userInfo.apikey,
                username: this.userInfo.username,
                password: this.userInfo.password,
                option: 'urlPath'
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            console.log("getSkillDetails API res~~~", res);
            let result = [];
            result.push(res);
            return result;
        }));
    }
    updateLocale(data) {
        this.userInfo = this._authService.getUserInfo();
        let url = this.gameurl + 'users/' + this.userInfo.userid;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                api_key: this.userInfo.apikey,
                username: this.userInfo.username,
                password: this.userInfo.password
            })
        };
        return this.http.put(url, data, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
}
SkillsDetailsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SkillsDetailsService_Factory() { return new SkillsDetailsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: SkillsDetailsService, providedIn: "root" });
