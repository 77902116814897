
<button
(click)="onButtonClick($event)"
[ngStyle]="{'width': width? width: 'auto' , 'height': height? height: 'auto', 'padding': padding}"
[class] = "disabled || loading? '': class"
[ngClass]="{
  'bg-accent100 button-loading': loading,
  'bg-s60 body-m text-s70 cursor-disable': disabled && !loading,
  'body-m text-s0 bg-accent500': class=='button-primary' && !disabled && !loading,
  'body-m text-accent500 bg-s0': class=='button-secondary' && !disabled && !loading,
  'body-m text-c300 bg-s30': class=='button-tertiary' && !disabled && !loading
}"
>
    <ng-content></ng-content>
</button>




