import {TopicdetailModel} from '../../../models/topicdetail.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class TopicDetailsMapper extends ItemMapper<TopicdetailModel> {
    protected mapFromItem(item): TopicdetailModel {
        const topicModel= new TopicdetailModel();
        topicModel.id = item.urlPath;
        topicModel.uniId = item.uniqueID;
        topicModel.url = item.bannerImage;
        topicModel.title = item.name;
        topicModel.author = item.author;
        topicModel.description = item.about;
        topicModel.playlist = [];
        topicModel.skill = [];
        return topicModel;
    }
}
