<div [class]="class" *ngIf="!multiline">
        <span *ngIf="id == 'Search' || id == 'search-landing'" class="input-group-append">
            <app-image (click)="Imgclick()" [src]="'./assets/image/search_l.svg'"></app-image>
        </span>
         <input
            #single
            [formControl]="control"
            [placeholder]="hint"
            *ngIf="!multiline"
            [type]="type"
            [readonly]= "readonly"
            [ngStyle]="{'width': width? width: 'auto' , 'height': height ? height : 'auto', 'text-align' : textAlign ? textAlign : 'left' }"
            [class]="textClass"
            [id]="id"
            />
        <ng-content></ng-content>
</div>
<div *ngIf="multiline">
    <textarea
            #multi
            [formControl]="control"
            [rows]="rows"
            *ngIf="multiline"
            [placeholder]="hint"
            [readonly]= "readonly"
            [ngStyle]="{'width': width? width: 'auto' , 'height': height  }"></textarea>
</div>
