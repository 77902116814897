import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SkillLoadingComponent} from '../shared-components/skill-loading/skill-loading.component';

const components = [
  SkillLoadingComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
    ]
})
export class SharedComponentsModule {}
