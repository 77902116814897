<div class="bg-1f m-20 lf-wrapper">
    <div *ngIf="totalSkill == '1'">
        <app-image *ngIf="image[0]" [src]="image[0]" [class]="'topic-img-cover'" [width]="'532px'" [height]="'260px'"></app-image>
        <div *ngIf="!image[0]" class="img-default bg-main100" style="width: 532px; height: 260px;"></div>
    </div>

    <div class="row" *ngIf="totalSkill == '2'">
      <div class="col-sm-6" style="padding-right: 6px;">
        <app-image *ngIf="image[0]" [src]="image[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
        <div *ngIf="!image[0]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
      </div>
      <div class="col-sm-6 sec-col" style="padding-left: 6px">
        <app-image *ngIf="image[1]" [src]="image[1]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
        <div *ngIf="!image[1]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
      </div>
    </div>

    <div class="row" *ngIf="totalSkill == '3'">
      <div class="col-sm-6" style="padding-right: 6px;">
        <app-image *ngIf="image[0]" [src]="image[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
        <div *ngIf="!image[0]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
      </div>
      <div class="col-sm-6 sec-col" style="padding-left: 6px">
        <div class="row" style="margin: 0;padding-bottom: 6px;">
          <app-image *ngIf="image[1]" [src]="image[1]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
          <div *ngIf="!image[1]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
        </div>
        <div class="row" style="margin: 0;padding-top: 6px;">
          <app-image *ngIf="image[2]" [src]="image[2]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
          <div *ngIf="!image[2]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="totalSkill == '4'">
      <div class="col-sm-6" style="padding-right: 6px;">
        <app-image *ngIf="image[0]" [src]="image[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
        <div *ngIf="!image[0]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
      </div>
      <div class="col-sm-6 sec-col" style="padding-left: 6px">
        <div class="row" style="margin: 0;padding-bottom: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[1]" [src]="image[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[2]" [src]="image[2]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[2]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
        <div class="row" style="margin: 0;padding-top: 6px;">
          <app-image *ngIf="image[3]" [src]="image[3]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
          <div *ngIf="!image[3]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="totalSkill == '5'">
      <div class="col-sm-6" style="padding-right: 6px;">
        <app-image *ngIf="image[0]" [src]="image[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
        <div *ngIf="!image[0]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
      </div>
      <div class="col-sm-6 sec-col" style="padding-left: 6px">
        <div class="row" style="margin: 0;padding-bottom: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[1]" [src]="image[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[2]" [src]="image[2]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[2]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
        <div class="row" style="margin: 0;padding-top: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[3]" [src]="image[3]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[3]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[4]" [src]="image[4]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[4]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="totalSkill == '6'">
      <div class="col-sm-6" style="padding-right: 6px;">
        <div class="row" style="margin: 0;padding-bottom: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[0]" [src]="image[0]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[0]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[1]" [src]="image[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
        <div class="row" style="margin: 0;padding-top: 6px;">
          <app-image *ngIf="image[2]" [src]="image[2]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
          <div *ngIf="!image[2]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
        </div>
      </div>
      <div class="col-sm-6 sec-col" style="padding-left: 6px">
        <div class="row" style="margin: 0;padding-bottom: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[3]" [src]="image[3]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[3]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[4]" [src]="image[4]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[4]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
        <div class="row" style="margin: 0;padding-top: 6px;">
          <app-image *ngIf="image[5]" [src]="image[5]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
          <div *ngIf="!image[5]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="totalSkill == '7'">
      <div class="col-sm-6" style="padding-right: 6px;">
        <div class="row" style="margin: 0;padding-bottom: 6px;">
          <app-image *ngIf="image[0]" [src]="image[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
          <div *ngIf="!image[0]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
        </div>
        <div class="row" style="margin: 0;padding-top: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[1]" [src]="image[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[2]" [src]="image[2]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[2]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 sec-col" style="padding-left: 6px">
        <div class="row" style="margin: 0;padding-bottom: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[3]" [src]="image[3]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[3]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[4]" [src]="image[4]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[4]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
        <div class="row" style="margin: 0;padding-top: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[5]" [src]="image[5]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[5]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[6]" [src]="image[6]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[6]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="totalSkill >= '8'">
      <div class="col-sm-6" style="padding-right: 6px;">
        <div class="row" style="margin: 0;padding-bottom: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[0]" [src]="image[0]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[0]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[1]" [src]="image[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
        <div class="row" style="margin: 0;padding-top: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[2]" [src]="image[2]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[2]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[3]" [src]="image[3]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[3]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 sec-col" style="padding-left: 6px">
        <div class="row" style="margin: 0;padding-bottom: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[4]" [src]="image[4]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[4]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[5]" [src]="image[5]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[5]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
        <div class="row" style="margin: 0;padding-top: 6px;">
          <div class="col-md-6" style="padding: 0;padding-right: 6px;">
            <app-image *ngIf="image[6]" [src]="image[6]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[6]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>

          <div class="col-md-6" style="padding: 0;padding-left: 6px;">
            <app-image *ngIf="image[7]" [src]="image[7]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
            <div *ngIf="!image[7]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
          </div>
        </div>
      </div>
    </div>

      <div class="d-flex align-items-start mt-12 relative">
        <div class="play">
          <app-image [src]="play" [ngClass]="stackPlay? 'stack-image' : 'mr-12'" [width]="'48px'"></app-image>
        </div>
        <div class="play_hover">
          <app-image [src]="play_hover" [ngClass]="stackPlay? 'stack-image' : 'mr-12'" [width]="'48px'"></app-image>
        </div>
        <div class="d-flex flex-column relative">
          <app-text class="lf-title">{{learningFlow.title}}</app-text>
          <div class="d-flex align-items-center light-body-m  text-s0">
            <!-- <app-text>{{learningFlow.author}}</app-text>
            <div *ngIf="learningFlow.author && learningFlow.author != ''" class="dot"> </div> -->
            <app-text *ngIf="totalSkill > 1">{{totalSkill}} {{ locale?.STEM_story.skills }}</app-text>
            <app-text *ngIf="totalSkill == 1">{{totalSkill}} {{ locale?.STEM_story.skill }}</app-text>&nbsp;
            <!-- <app-text *ngIf="learningFlow.duration && learningFlow.duration != ''" >, {{learningFlow.duration}}</app-text> -->
          </div>
        </div>
      </div>
</div>
