<div class="d-flex playlist-card-wrap cursor" [ngClass]="{'currentOam': oam.isCurrentOam}" (click)="onClickOamCard(oam)">
  <div class="oam-thumbnail-wrap">
    <app-image *ngIf="oam.thumbnailUrl != undefined" [src]="oam.thumbnailUrl" [width]="'108px'" [height]="'64px'" [class]="'playlist-oam-card'"></app-image>
    <div *ngIf="oam.thumbnailUrl == undefined" class="bg-main100 oam-default-card"></div>
    <app-image *ngIf="oam.isCurrentOam" class="playing-btn" [src]="'./assets/image/playing.svg'"></app-image>
    <div *ngIf="!oam.isCurrentOam" class="oam-number caption-light bg-s0">{{ index + 1 }}</div>
  </div>
  <div class="oam-name-wrap">
    <app-text class="oam-title body-m">{{oam.name}}</app-text>
  </div>
</div>
