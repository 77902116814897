import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import {DataRequirementsFake} from './data.requirements.fake';

import { TopicUseCase } from './use-cases/topic.use-case';
import { of, Observable } from 'rxjs';
import { GetAllSkillUseCase } from './use-cases/get-all-skill.use-case';
import {GetLearningFlowUseCase} from './use-cases/get-learning-flow.use-case';
import {SkillMapper} from './mappers/skill.mapper';
import {LearningFlowMapper} from './mappers/learningflow.mapper';
import {TopicMapper} from './mappers/topic.mapper';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
            // useClass: DataRequirementsFake
        },
        SkillMapper,
        LearningFlowMapper,
        TopicMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
        private getAllTopicUseCase: TopicUseCase,
        private getAllSkillUseCase: GetAllSkillUseCase,
        private getLearningFlowUseCase: GetLearningFlowUseCase

    ) {}
    getTopicDetail(topicId): Observable<any> {
        return this.getAllTopicUseCase.run(topicId)
    }

    getAllSkills(id, skip, limit): Observable<any> {
      return this.getAllSkillUseCase.run(id, skip, limit);
    }

    getAllLearningFlow(id,skip, limit): Observable<any> {
        return this.getLearningFlowUseCase.run(id, skip, limit);
    }
}
