import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';

@Component({
    selector: 'app-teacher-profile-list',
    templateUrl: './teacher-profile-list.component.html',
    styleUrls: ['./teacher-profile-list.component.css']
})
export class TeacherProfileListComponent implements OnChanges{
  @Input() profileList;
  @Input() locale;
  @Input() isLoading;
  @Output() onClickProfile = new EventEmitter<any>();

  ngOnChanges(){

  }

  onClickTeacherProfile($event){
    this.onClickProfile.emit($event);
  }
}
