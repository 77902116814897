import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/player.mapper";
import * as i2 from "../data.requirements";
export class GetPlayerDataUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(playerId) {
        return this.data.getPlayerData(playerId).pipe(this.mapper.map);
    }
}
GetPlayerDataUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetPlayerDataUseCase_Factory() { return new GetPlayerDataUseCase(i0.ɵɵinject(i1.PlayerMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetPlayerDataUseCase, providedIn: "root" });
