<div class="pt-2 pb-2">
  <app-text class="text-u text-c100 body-m mt-2 mb-2">{{ locale?.skillDetail.upNext }}</app-text>
</div>
<div class="upnext-view" *ngIf="upnextSkills?.length > 0">
  <div *ngFor="let skill of upnextSkills">
    <app-oam-small-block [oamData]="skill" (onClickSkill)="onSelectUpnextSkill(skill)"></app-oam-small-block>
  </div>
</div>
<div *ngIf="upnextSkills?.length == 0">
  <app-text class="body-l text-c100">{{ locale?.STEM_story.noSkill }}</app-text>
</div>
