import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/live-class.mapper";
import * as i2 from "../data.requirements";
export class GetLiveClassesUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run() {
        return this.data.getLiveClasses().pipe(this.mapper.map);
    }
}
GetLiveClassesUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetLiveClassesUseCase_Factory() { return new GetLiveClassesUseCase(i0.ɵɵinject(i1.LiveClassMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetLiveClassesUseCase, providedIn: "root" });
