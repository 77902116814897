import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface DataRequirements {
  getSkillDetails(skillId):Observable<any>;
  trackUserActivity(data):Observable<any>;
  getTopicDetails(topicId):Observable<any>;
  updateLocale(data):Observable<any>;
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('skills Data Requirements')
