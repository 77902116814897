import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-class-lists',
    templateUrl: './class-lists.component.html',
    styleUrls: ['./class-lists.component.css']
})
export class ClassListsComponent {
  @Input() classes;
  @Input() locale;
  @Input() usertype;
  @Output() clickEnrollBtn = new EventEmitter<any>();

  onClickEnroll($event){
    this.clickEnrollBtn.emit($event);
  }
}
