<div class="search-landing-wrapper" [ngStyle]="{'background-image': 'url(' + imgUrl + ')'}">
  <div class="d-flex justify-content-center align-items-center w-100 h-100">
    <div class="search-input-wrapper">
      <div class="pb-3">
        <app-text class="text-s0 h2-bold">{{ locale?.searchLandingInput.searchLandingText }}</app-text>
      </div>
      <!-- <app-search [id]="'search-landing'" [control]="data" [hint]="locale?.searchLandingInput.searchPlaceholder" [boxWidth]="'100%'" [boxHeight]="'56px'" [type]="'searchLanding'" (searchOutput)="getSearchValue($event)" (searchClick)="onSearchClick()"></app-search> -->
      <div class="position-relative">
        <form class="form" [formGroup]="form" action="/search" method="get">
          <span class="input-group-append">
            <app-image (click)="onSearchClick()" [src]="'./assets/image/search_l.svg'"></app-image>
          </span>
          <span class="d-flex justify-content-center align-items-center bg-accent500 icon-wrapper" (click)="onSearchClick()">
            <app-image [src]="'./assets/image/white-search-icon.svg'" [width]="'18px'" [height]="'18px'"></app-image>
          </span>
          <input formControlName="value" (onClick)="getSearchValue($event)" (searchClick)="onSearchClick()"  id="search-landing" class="search-input" type="search" [placeholder]="locale?.searchLandingInput.searchPlaceholder">
        </form>
      </div>
    </div>
  </div>
  <div class="bg-secondary500 text-center position-absolute w-100 scrollDownWrap">
    <app-text class="text-secondary700 light-body-l">{{ locale?.searchLandingInput.scrollDownToExplore }}</app-text>
  </div>
</div>
