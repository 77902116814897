import {Component,Input,Output,EventEmitter, OnChanges} from '@angular/core';

@Component({
    selector: 'app-language-switch-button',
    templateUrl: './language-switch-button.component.html',
    styleUrls: ['./language-switch-button.component.css']
})
export class LanguageSwitchButtonComponent implements OnChanges {
  @Input() localeName;
  @Input() switchButtons;
  @Output() onClick = new EventEmitter<any>();

  ngOnChanges(){

  }

  onButtonClick($event){
    this.onClick.emit($event);
  }
}
