import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {SkillService} from './api-services/skill.service';
import { Observable } from 'rxjs';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
      private skillApiService: SkillService
    ) {}

    getSkillDetails(skillId):Observable<any>{
      return this.skillApiService.getSkillDetails(skillId);
    }

    trackUserActivity(data):Observable<any>{
      return this.skillApiService.userActivity(data);
    }

    getTopicDetails(topicId):Observable<any>{
      return this.skillApiService.getTopicDetails(topicId);
    }

    updateLocale(data){
      return this.skillApiService.updateLocale(data)
    }
}
