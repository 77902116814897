import {Component, Input, ViewChild, ElementRef, OnInit} from '@angular/core';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {
  @Input() videoUrl;
  @ViewChild('videoPlayer', {static: false}) videoPlayer: ElementRef;

  public showPlayBtn:boolean = true;
  public onProgress:boolean = false;



  ngOnInit(){
    this.checkForVideoLoaded();
  }

  checkForVideoLoaded(){
    let interval = setInterval(() => {
      if(this.videoPlayer != undefined){
        console.log("this.videoPlayer != undefined")
        this.hideControls();
        // this.checkVideoIsReady();//for autoplay video
        clearInterval(interval);
      }
    },300)
  }

  checkVideoIsReady(){
    console.log("checkVideoIsReady");
    let videoElement = this.videoPlayer.nativeElement;
    let checkInterval = setInterval(() => {
      if(videoElement.readyState === 4){
        console.log("ready to play video")
        this.videoPlayer.nativeElement.play();
        this.showPlayBtn = false;
        this.showControls();
        clearInterval(checkInterval);
        window.addEventListener('unhandledrejection', event => {
          // Prevent error output on the console:
          event.preventDefault();
          console.log('Reason: ' + event.reason);
          this.showPlayBtn = true;
          this.showControls();
        });
      }
    },200)
  }

  playVideo(){
    console.log("video is playing")
     this.videoPlayer.nativeElement.play();
     this.showPlayBtn = false;
     this.showControls();
  }

  endedVideo(){
    console.log("video is ended")
    this.showPlayBtn = true;
    this.videoPlayer.nativeElement.currentTime = 0;
    this.videoPlayer.nativeElement.load();
    this.hideControls();
  }

  showControls(){
    this.videoPlayer.nativeElement.setAttribute('controls','controls');
  }

  hideControls(){
    this.videoPlayer.nativeElement.removeAttribute('controls');
  }
}
