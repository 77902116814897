import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import {DataRequirementsFake} from './data.requirements.fake';
import { from, Observable } from 'rxjs';

import {GetMonthlyTopicUseCase} from './use-cases/get-monthly-topic.use-case';
import {GetRecommendedLearningFlowUseCase} from './use-cases/get-recommended-learning-flow.use-case';
import {GetAllTopicUseCase} from './use-cases/get-all-topic.use-case';
import {GetAllSkillUseCase} from './use-cases/get-all-skill.use-case';
import {GetPlayerDataUseCase} from './use-cases/get-player-data.use-case';
import {GetValleyPopupStatusUseCase} from './use-cases/get-valley-popup-status.use-case';
import {SetValleyPopupStatusUseCase} from './use-cases/set-valley-popup-status.use-case';
import {SetLocalStorageUseCase} from './use-cases/set-local-storage.use-case';
import {GetLocalStorageUseCase} from './use-cases/get-local-storage.use-case';
import {RemoveLocalStorageUseCase} from './use-cases/remove-local-storage.use-case';
import {GetWeeklySkillsUseCase} from './use-cases/get-weekly-skills.use-case';

import {TopicMapper} from './mappers/topic.mapper';
import {MonthlyTopicMapper} from './mappers/monthly-topic.mapper';
import {PlaylistMapper} from './mappers/playlist.mapper';
import {SkillMapper} from './mappers/skill.mapper';
import {PlayerMapper} from './mappers/player.mapper';
import {ValleyPopupStatusMapper} from './mappers/valley-popup-status-mapper';
import {newSkillMapper} from './mappers/newSkill.mapper';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
            // useClass: DataRequirementsFake
        },
        TopicMapper,
        PlaylistMapper,
        SkillMapper,
        MonthlyTopicMapper,
        PlayerMapper,
        ValleyPopupStatusMapper,
        newSkillMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
      private getMonthlyTopicUsecase: GetMonthlyTopicUseCase,
      private getRecommendedLearningFlowUsecase: GetRecommendedLearningFlowUseCase,
      private getAllTopicUsecase: GetAllTopicUseCase,
      private getAllSkillUsecase: GetAllSkillUseCase,
      private getPlayerDataUsecase: GetPlayerDataUseCase,
      private getValleyPopupStatusUsecase: GetValleyPopupStatusUseCase,
      private setValleyPopupStatusUsecase: SetValleyPopupStatusUseCase,
      private setLocalStorageUsecase: SetLocalStorageUseCase,
      private getLocalStorageUsecase: GetLocalStorageUseCase,
      private removeLocalStorageUsecase: RemoveLocalStorageUseCase,
      private getWeeklySkillsUseCase: GetWeeklySkillsUseCase
    ) {}

    getMonthlyTopic(): Observable<any>{
      return this.getMonthlyTopicUsecase.run();
    }

    getRecommendedLearningFlow(): Observable<any>{
      return this.getRecommendedLearningFlowUsecase.run();
    }

    getNewSkills(): Observable<any>{
      return this.getWeeklySkillsUseCase.run();
    }

    getAllTopics(): Observable<any>{
      return this.getAllTopicUsecase.run();
    }

    getAllSkills(skip, limit): Observable<any>{
      return this.getAllSkillUsecase.run(skip, limit);
    }

    getPlayerData(playerId): Observable<any>{
      return this.getPlayerDataUsecase.run(playerId);
    }

    getValleyPopupStatus(data):Observable<any>{
      return this.getValleyPopupStatusUsecase.run(data);
    }

    setValleyPopupStatus(data):Observable<any>{
      return this.setValleyPopupStatusUsecase.run(data)
    }

    setLocalStorage(key, value){
      return this.setLocalStorageUsecase.run(key, value);
    }

    getLocalStorage(key){
      return this.getLocalStorageUsecase.run(key);
    }

    removeLocalStorage(key){
      return this.removeLocalStorageUsecase.run(key);
    }
}
