<div class="recommend-wrap">
    <div class="recommend-image">
      <app-image class="recommend-top" [src]="'assets/image/recommended_top_boder.svg'" [width]="'100%'"></app-image>
    </div>
    <div class="bg-bg33 margin-bottom-1">
      <div *ngIf="learningFlow" class="d-flex center-wrap">
        <div class="text-s0 left-recomm">
            <app-brush [brushColor]="'bg-1A'" [color]="'text-s70'" [width]="'180'" [height]="'34'" [margin]="'0 0 16px -4px'">{{ locale?.home.STEMPathForYouText }}</app-brush>
            <app-text class="text-main500 learning-flow-title title">{{learningFlow.title}}</app-text>
            <div class="d-flex align-items-center mt-12-mb-16">
                <!-- <app-text class="body-m">{{learningFlow.author}}</app-text>
                <app-text *ngIf="learningFlow.author && learningFlow.author != ''" class="dot bg-s0"></app-text> -->
                <app-text class="light-body-m">{{learningFlow.skillList.length>1? learningFlow.skillList.length+' '+locale?.STEM_story.skills : learningFlow.skillList.length+' '+locale?.STEM_story.skill}} </app-text>
                <!-- <app-text *ngIf="learningFlow.duration && learningFlow.duration != ''">,&nbsp; {{learningFlow.duration}}</app-text> -->
            </div>
            <div class="light-body-l text-s70 mb-36">{{learningFlow.description}}</div>
            <!-- <app-image class="cursor" [src]="'assets/image/play.png'" (click)="onPlayClick($event)"></app-image> -->
            <app-button [class]="'button-primary'" [width]="'160px'" (click)="onPlayClick($event)">{{ locale?.home.exploreButton }}</app-button>
        </div>
        <div class="right-recomm">
            <div class="row ml-36">
                <div class="skill-img">

                  <div *ngIf="learningFlow.skillList.length == 1">
                    <app-image [src]="learningFlow.skillList[0]" [class]="'topic-img-cover'" [width]="'556px'" [height]="'313px'"></app-image>
                  </div>

                  <div class="row" *ngIf="learningFlow.skillList.length == 2">
                    <div class="col-sm-6" style="padding-right: 6px;">
                      <app-image *ngIf="learningFlow.skillList[0]" [src]="learningFlow.skillList[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
                      <div *ngIf="!learningFlow.skillList[0]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
                    </div>
                    <div class="col-sm-6 sec-col" style="padding-left: 6px">
                      <app-image *ngIf="learningFlow.skillList[1]" [src]="learningFlow.skillList[1]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
                      <div *ngIf="!learningFlow.skillList[1]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
                    </div>
                  </div>

                  <div class="row" *ngIf="learningFlow.skillList.length == '3'">
                    <div class="col-sm-6" style="padding-right: 6px;">
                      <app-image *ngIf="learningFlow.skillList[0]" [src]="learningFlow.skillList[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
                      <div *ngIf="!learningFlow.skillList[0]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
                    </div>
                    <div class="col-sm-6 sec-col" style="padding-left: 6px">
                      <div class="row" style="margin: 0;padding-bottom: 6px;">
                        <app-image *ngIf="learningFlow.skillList[1]" [src]="learningFlow.skillList[1]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
                        <div *ngIf="!learningFlow.skillList[1]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
                      </div>
                      <div class="row" style="margin: 0;padding-top: 6px;">
                        <app-image *ngIf="learningFlow.skillList[2]" [src]="learningFlow.skillList[2]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
                        <div *ngIf="!learningFlow.skillList[2]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngIf="learningFlow.skillList.length == '4'">
                    <div class="col-sm-6" style="padding-right: 6px;">
                      <app-image *ngIf="learningFlow.skillList[0]" [src]="learningFlow.skillList[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
                      <div *ngIf="!learningFlow.skillList[0]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
                    </div>
                    <div class="col-sm-6 sec-col" style="padding-left: 6px">
                      <div class="row" style="margin: 0;padding-bottom: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[1]" [src]="learningFlow.skillList[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[2]" [src]="learningFlow.skillList[2]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[2]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                      <div class="row" style="margin: 0;padding-top: 6px;">
                        <app-image *ngIf="learningFlow.skillList[3]" [src]="learningFlow.skillList[3]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
                        <div *ngIf="!learningFlow.skillList[3]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngIf="learningFlow.skillList.length == '5'">
                    <div class="col-sm-6" style="padding-right: 6px;">
                      <app-image *ngIf="learningFlow.skillList[0]" [src]="learningFlow.skillList[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'260px'"></app-image>
                      <div *ngIf="!learningFlow.skillList[0]" class="img-default bg-main100" style="width: 260px; height: 260px;"></div>
                    </div>
                    <div class="col-sm-6 sec-col" style="padding-left: 6px">
                      <div class="row" style="margin: 0;padding-bottom: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[1]" [src]="learningFlow.skillList[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[2]" [src]="learningFlow.skillList[2]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[2]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                      <div class="row" style="margin: 0;padding-top: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[3]" [src]="learningFlow.skillList[3]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[3]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[4]" [src]="learningFlow.skillList[4]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[4]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngIf="learningFlow.skillList.length == '6'">
                    <div class="col-sm-6" style="padding-right: 6px;">
                      <div class="row" style="margin: 0;padding-bottom: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[0]" [src]="learningFlow.skillList[0]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[0]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[1]" [src]="learningFlow.skillList[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                      <div class="row" style="margin: 0;padding-top: 6px;">
                        <app-image *ngIf="learningFlow.skillList[2]" [src]="learningFlow.skillList[2]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
                        <div *ngIf="!learningFlow.skillList[2]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
                      </div>
                    </div>
                    <div class="col-sm-6 sec-col" style="padding-left: 6px">
                      <div class="row" style="margin: 0;padding-bottom: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[3]" [src]="learningFlow.skillList[3]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[3]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[4]" [src]="learningFlow.skillList[4]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[4]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                      <div class="row" style="margin: 0;padding-top: 6px;">
                        <app-image *ngIf="learningFlow.skillList[5]" [src]="learningFlow.skillList[5]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
                        <div *ngIf="!learningFlow.skillList[5]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngIf="learningFlow.skillList.length == '7'">
                    <div class="col-sm-6" style="padding-right: 6px;">
                      <div class="row" style="margin: 0;padding-bottom: 6px;">
                        <app-image *ngIf="learningFlow.skillList[0]" [src]="learningFlow.skillList[0]" [class]="'topic-img-cover'" [width]="'260px'" [height]="'124px'"></app-image>
                        <div *ngIf="!learningFlow.skillList[0]" class="img-default bg-main100" style="width: 260px; height: 124px;"></div>
                      </div>
                      <div class="row" style="margin: 0;padding-top: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[1]" [src]="learningFlow.skillList[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[2]" [src]="learningFlow.skillList[2]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[2]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 sec-col" style="padding-left: 6px">
                      <div class="row" style="margin: 0;padding-bottom: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[3]" [src]="learningFlow.skillList[3]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[3]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[4]" [src]="learningFlow.skillList[4]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[4]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                      <div class="row" style="margin: 0;padding-top: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[5]" [src]="learningFlow.skillList[5]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[5]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[6]" [src]="learningFlow.skillList[6]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[6]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="learningFlow.skillList.length >= '8'">
                    <div class="col-sm-6" style="padding-right: 6px;">
                      <div class="row" style="margin: 0;padding-bottom: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[0]" [src]="learningFlow.skillList[0]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[0]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[1]" [src]="learningFlow.skillList[1]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[1]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                      <div class="row" style="margin: 0;padding-top: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[2]" [src]="learningFlow.skillList[2]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[2]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[3]" [src]="learningFlow.skillList[3]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[3]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 sec-col" style="padding-left: 6px">
                      <div class="row" style="margin: 0;padding-bottom: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[4]" [src]="learningFlow.skillList[4]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[4]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <app-image *ngIf="learningFlow.skillList[5]" [src]="learningFlow.skillList[5]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[5]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                      <div class="row" style="margin: 0;padding-top: 6px;">
                        <div class="col-md-6" style="padding: 0;padding-right: 6px;">
                          <app-image *ngIf="learningFlow.skillList[6]" [src]="learningFlow.skillList[6]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <div *ngIf="!learningFlow.skillList[6]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>

                        <div class="col-md-6" style="padding: 0;padding-left: 6px;">
                          <div *ngIf="learningFlow.skillList.length > 8" class="plus8">+ {{learningFlow.skillList.length - 8}} {{ locale?.playlistDetail.moreItems }}</div>
                          <div class="img-wrap" *ngIf="learningFlow.skillList.length > 8">
                            <app-image *ngIf="learningFlow.skillList[7]" [src]="learningFlow.skillList[7]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          </div>
                          <app-image *ngIf="learningFlow.skillList[7] && learningFlow.skillList.length == 8" [src]="learningFlow.skillList[7]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image>
                          <!-- <app-image *ngIf="learningFlow.skillList[7]" [src]="learningFlow.skillList[7]" [class]="'topic-img-cover'" [width]="'124px'" [height]="'124px'"></app-image> -->
                          <div *ngIf="!learningFlow.skillList[7]" class="img-default bg-main100" style="width: 124px; height: 124px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <!-- <app-image *ngIf="image" [src]="image" [width]="'203px'" [height]="'133px'" [class]="'br-12 obj-fit'"></app-image>
                    <div *ngIf="!image" class="img-default"></div> -->
                </div>
            </div>
        </div>
      </div>
      <div *ngIf="!learningFlow || loading" class="center-wrap">
          <app-brush [brushColor]="'bg-1A'" [color]="'text-s70'" [width]="'220'" [height]="'34'" [margin]="'0 0 16px -4px'">{{ locale?.home.STEMPathForYouText }}</app-brush>
          <div class="p-20">
            <app-text *ngIf="!loading && !learningFlow" class="body-l text-s70">{{ locale?.STEM_path.noPlaylist }}</app-text>
            <div *ngIf="loading" class="row d-flex align-items-center">
                <div class="col-md-6">
                    <app-loader [spinColor]="'#b1b1b3'" [height]="'10px'" [width]="'50%'"></app-loader>
                    <app-loader [spinColor]="'#b1b1b3'" [height]="'70px'" [width]="'50%'"></app-loader>
                    <app-loader [spinColor]="'#b1b1b3'" [height]="'20px'" [width]="'50%'"></app-loader>
                </div>
                <div class="col-md-6">
                    <app-loader [spinColor]="'#b1b1b3'" [height]="'100px'" [width]="'50%'"></app-loader>
                </div>
            </div>
          </div>
      </div>
    </div>
    <div class="recommend-image">
      <app-image class="recommend-bottom" [src]="'assets/image/recommended_bottom_border.svg'" [width]="'100%'"></app-image>
    </div>
</div>
