export class PlaylistDetailsModel{
  details:any = {
    "_id": "learningflowID1",
    "uniqueID": "learingflow1",
    "name": "Cycle In Matter & Water Cycle, Properties Of Matter ~ ",
    "topicId": "topicID1",
    "author": "Amdon",
    "duration": "30 min",
    "skills": []
    // "skills": [
    //   {
    //       "_id": '5f3ccb33f099970012b97522',
    //       "name": 'The specific events in a plant life cycle ~ ',
    //       "url": 'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
    //       "thumbnailUrl": 'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
    //       "fileWidth": "1024",
    //       "fileHeight": "650",
    //       "description": "This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle."
    //   },
    //   {
    //     "_id":'5f3ccb33f099970012b97511',
    //     "name":'The specific events in a plant life cycle ~ ',
    //     "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
    //     "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
    //     "fileWidth":"1024",
    //     "fileHeight":"650",
    //     "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle."
    //   },
    //   {
    //     "_id":'5f3ccb33f099970012b97533',
    //     "name":'The specific events in a plant life cycle ~ ',
    //     "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
    //     "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
    //     "fileWidth":"1024",
    //     "fileHeight":"650",
    //     "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle."
    //   },
    //   {
    //     "_id":'5f3ccb33f099970012b97544',
    //     "name":'The specific events in a plant life cycle ~ ',
    //     "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
    //     "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
    //     "fileWidth":"1024",
    //     "fileHeight":"650",
    //     "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle."
    //   },
    //   {
    //     "_id":'5f3ccb33f099970012b97588',
    //     "name":'The specific events in a plant life cycle ~ ',
    //     "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
    //     "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
    //     "fileWidth":"1024",
    //     "fileHeight":"650",
    //     "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle."
    //   }
    // ]
  }
  relatedLearningFlow:any = []
  relatedSkills:any = []
  // relatedLearningFlow:any = [
  //   {
  //     "_id": 'xxx',
  //     "name": "Cycle In Matter & Water Cycle, Properties Of Matter ~ ",
  //     "duration": '1 min 30 sec',
  //     "author": 'Amdon',
  //     "skillList": ['https://picsum.photos/200/300'],
  //     "url": 'https://picsum.photos/200/300',
  //     "description": 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sit elit molestie cursus a'
  //   },
  //   {
  //     "_id": 'xxx',
  //     "name": "Cycle In Matter & Water Cycle, Properties Of Matter ~ ",
  //     "duration": '30 min',
  //     "author": 'Amdon',
  //     "skillList": ['https://picsum.photos/200/300',
  //                   'https://picsum.photos/200/300',
  //                   'https://picsum.photos/200/300'],
  //     "url": 'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
  //     "description": 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sit elit molestie cursus a'
  //   },
  //   {
  //     "_id": 'xxx',
  //     "name": "Cycle In Matter & Water Cycle, Properties Of Matter ~ ",
  //     "duration": '30 min',
  //     "author": 'Amdon',
  //     "skillList": ['https://picsum.photos/200/300',
  //                   'https://picsum.photos/200/300'],
  //     "url": 'https://picsum.photos/200/300',
  //     "description": 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sit elit molestie cursus a'
  //   },
  //   {
  //     "_id": 'xxx',
  //     "name": "Cycle In Matter & Water Cycle, Properties Of Matter ~ ",
  //     "duration": '30 min',
  //     "author": 'Amdon',
  //     "skillList": ['https://picsum.photos/200/300',
  //                   'https://picsum.photos/200/300',
  //                   'https://picsum.photos/200/300',
  //                   'https://picsum.photos/200/300'],
  //     "url": 'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
  //     "description": 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sit elit molestie cursus a'
  //   }
  // ]
  // relatedSkills:any = [
  //   {
  //     "_id":'5f3ccb33f099970012b97511',
  //     "name":'The specific events in a 3-stage bean plant ~',
  //     "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
  //     "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
  //     "fileWidth":"1024",
  //     "fileHeight":"650",
  //     "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle."
  //   },
  //   {
  //     "_id":'5f3ccb33f099970012b97533',
  //     "name":'The specific events in a 3-stage bean plant ~ ',
  //     "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
  //     "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
  //     "fileWidth":"1024",
  //     "fileHeight":"650",
  //     "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle."
  //   }
  // ]

}
