import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {LiveClassesHomeControllerComponent} from './live-classes-home-controller.component';
import {RouterModule} from '@angular/router';

import {ClassCardComponent} from './components/class-card/class-card.component';
import {TeacherProfileListComponent} from './components/teacher-profile-list/teacher-profile-list.component';
import {TeacherProfileComponent} from './components/teacher-profile/teacher-profile.component';

@NgModule({
    declarations: [
        LiveClassesHomeControllerComponent,
        ClassCardComponent,
        TeacherProfileListComponent,
        TeacherProfileComponent
    ],
    exports: [
        LiveClassesHomeControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class LiveClassesHomeModule {}
