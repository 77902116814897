import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
  getLearningflowDetails(playlistId):Observable<any>;
  getSkillsDetails(skillId,playlistId):Observable<any>;
  trackUserActivity(data):Observable<any>;
  getTopicDetails(topicId):Observable<any>;
  updateLocale(data):Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('playlists Business Requirements')
