import {Component,Input} from '@angular/core';

@Component({
    selector: 'app-skill-loading',
    templateUrl: './skill-loading.component.html',
    styleUrls: ['./skill-loading.component.css']
})
export class SkillLoadingComponent {
  @Input() wrapper_class;
  @Input() card_class;
  @Input() header_loading;
  @Input() locale;
}
