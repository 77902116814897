import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TopicService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl + 'api/v1/';
        this.userInfo = {
            username: "teacher_htet",
            password: "t4a62zxbp5jt4a62zxbp5j",
            api_key: "ndmc4acd43956e895b4de8aa7ec742edd3c"
        };
    }
    getMonthlyTopic() {
        let url = this.baseUrl + 'monthly-topics';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                API_KEY: this.userInfo.api_key,
                username: this.userInfo.username,
                password: this.userInfo.password
            })
        };
        return this.http.get(url).pipe(map((res) => {
            console.log("res>>", res);
            return res;
        }));
    }
    getAllToics() {
        let url = this.baseUrl + 'topics';
        return this.http.get(url).pipe(map((res) => {
            return res;
        }));
    }
}
TopicService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TopicService_Factory() { return new TopicService(i0.ɵɵinject(i1.HttpClient)); }, token: TopicService, providedIn: "root" });
