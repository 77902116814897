import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PlaylistService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl + 'api/v1/';
        this.tempData = {
            "_id": "skill1",
            "topicId": ["topicID1"],
            "aerReferenceID": "LPA-04-05",
            "urlPath": "LPA-04-05",
            "details": {
                "_id": "5f3ccb33f099970012b97522",
                "subject": [
                    "Science"
                ],
                "learningResourceType": [
                    "Text/Book",
                    "Text/Document"
                ],
                "language": [
                    "en"
                ],
                "languageRegiondesignator": [
                    "en-US"
                ],
                "textComplexity": [],
                "pedagogicalModels": [
                    "Explore",
                    "Evaluate"
                ],
                "author": [
                    "Amdon"
                ],
                "educationalAudience": [
                    "student",
                    "teacher",
                    "administrator"
                ],
                "accessibilityHazards": [],
                "accessMode": [
                    "auditory",
                    "color",
                    "textOnImage",
                    "visual"
                ],
                "name": "The specific events in a 3-stage bean plant life cycle",
                "aerReferenceID": "LPA-04-05",
                "targetFileURL": "https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html",
                "targetFileThumbnailURL": "https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png",
                "targetFileWidth": "1024",
                "targetFileHeight": "650",
                "aerGUID": "936b194f175d434180674b95",
                "description": "This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle.",
                "typicalAgeRange": "11-14",
                "learningObjectives": [
                    {
                        "_id": "5f3ccb33f099970012b97523",
                        "alignmentType": "Education Standard",
                        "caseItemUri": "http://frameworks.werkzpublishing.com/uri/ba8fe3a6-901d-11ea-9271-0242c0a82003",
                        "caseItemGUID": "ba8fe3a6-901d-11ea-9271-0242c0a82003"
                    }
                ],
                "publisher": "Werkz Publishing Inc",
                "timeRequired": "PT3M",
                "transcriptURL": "",
                "technicalFormat": "HTML",
                "createdAt": "2020-08-19T06:48:19.835Z",
                "updatedAt": "2020-08-19T06:48:19.835Z",
            }
        };
    }
    getRecommendedPlaylist() {
        let url = this.baseUrl + 'recommendations';
        return this.http.get(url).pipe(map((res) => {
            // res["skills"].push(this.tempData);
            // res["skills"].push(this.tempData);
            // res["skills"].push(this.tempData);
            let result = [];
            result.push(res);
            return result;
        }));
    }
}
PlaylistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlaylistService_Factory() { return new PlaylistService(i0.ɵɵinject(i1.HttpClient)); }, token: PlaylistService, providedIn: "root" });
