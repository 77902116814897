<div class="video-wrapper">
  <video
    (ended)="endedVideo()"
    id="game-demo"
    class="popup-video"
    width="100%"
    height="100%"
    poster="./assets/image/STEAMValley_video_cover.jpg"
    preload="metadata"
    playsinline
    #videoPlayer
  >
    <source [src]="videoUrl" type="video/mp4" preload="auto" />
  </video>
  <div *ngIf="showPlayBtn" class="play-button" (click)="playVideo()">
    <app-image [src]="'./assets/image/play_video.svg'" [width]="'100%'" [height]="'100%'"></app-image>
  </div>
</div>
