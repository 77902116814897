import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/playlist.mapper";
import * as i2 from "../data.requirements";
export class GetRecommendedLearningFlowUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run() {
        return this.data.getRecommendedLearningFlow().pipe(this.mapper.map);
    }
}
GetRecommendedLearningFlowUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetRecommendedLearningFlowUseCase_Factory() { return new GetRecommendedLearningFlowUseCase(i0.ɵɵinject(i1.PlaylistMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetRecommendedLearningFlowUseCase, providedIn: "root" });
