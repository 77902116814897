import {Inject, Injectable} from '@angular/core';
import {ValleyPopupStatusMapper} from '../mappers/valley-popup-status-mapper';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';

@Injectable({
    providedIn: 'root'
})
export class GetValleyPopupStatusUseCase {
    constructor(
        private mapper: ValleyPopupStatusMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(data) {
      return this.data.getValleyPopupStatus(data).pipe(this.mapper.map);
    }
}
