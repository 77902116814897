<div *ngIf="!loading" class="related-skill-wrap">
  <div class="pt-40 pb-32">
    <app-text *ngIf="relatedSkills.length == 1" class="mt-2 mb-2 related-skill-header">{{ locale.relatedSTEM_story.relatedStory }}</app-text>
    <app-text *ngIf="relatedSkills.length > 1" class="mt-2 mb-2 related-skill-header">{{ locale.relatedSTEM_story.relatedStories }}</app-text>
  </div>
  <div class="related-skill-list-wrap" *ngIf="relatedSkills?.length > 0">
    <app-related-skill *ngFor="let skill of relatedSkills" [skill]="skill" (onClickSkill)="onClickSkill($event)"></app-related-skill>
  </div>
  <div *ngIf="relatedSkills?.length == 0" class="pb-4">
    <app-text class="body-l text-c100">{{ locale.relatedSTEM_story.noRelatedStory }}</app-text>
  </div>

</div>

<div *ngIf="loading" class="related-skill-wrap">
  <app-loading [width]="'30%'" [height]="'20px'"></app-loading>
  <div class="row">
    <div class="col-md-6">
      <app-loading [width]="'100%'" [height]="'200px'"></app-loading>
    </div>
    <div class="col-md-6">
      <app-loading [width]="'100%'" [height]="'200px'"></app-loading>
    </div>
  </div>
</div>
