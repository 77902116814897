import { TotalSkillTopicPlaylistModel } from '../../../models/total-skill-topic-playlist.model';
import { SkillModel } from '../../../models/skill.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class SearchSkillMapper extends ItemMapper<TotalSkillTopicPlaylistModel> {
    protected mapFromItem(item): TotalSkillTopicPlaylistModel {
        //return if item comes from data fake
        if(item.skill) return item;

        //map item if it comes from data facade
        const searchResult = new TotalSkillTopicPlaylistModel();
        searchResult.skill = item.total;
        searchResult.data = [];

        item.result.forEach(element => {
            const skillObj = new SkillModel();
            skillObj.id = element.urlPath;
            skillObj.title = element.details? element.details.name :null;
            skillObj.type = element.details? element.details.subject[0] :null;
            skillObj.url = element.details? element.details.targetFileThumbnailURL : null;
            skillObj.duration = element.details && element.details.duration? element.details.duration : null;//not yet map
            // skillObj.topicId = element.topicId[0];
            skillObj.topicId = element.topicInfo && element.topicInfo.length > 0 ? element.topicInfo[0].urlPath: null;

            searchResult.data.push(skillObj);
        });

        return searchResult;
    }
}
