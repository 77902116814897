<div id="user-toogle" class="user-info-wrap bg-s0">
    <div class="user-info d-flex flex-column">
        <div class="d-flex align-items-center">
            <div class="position-relative">
                <app-image [src]="profileObj.url" [width]="'40px'" [height]="'40px'" [class]="'mr-3'"></app-image>
                <div *ngIf="profileObj.isDisplay" class="profile_initial"> <app-text class="body-m text-c300">{{ profileObj.text }}</app-text></div>
            </div>
            <div class="text-username">
                <app-text id="id_username">{{userObj.username}}</app-text>
            </div>
        </div>
        <div class="cursor" (click)="clickLogout($event)">
            <app-image [class]="'ml-2'" [src]="'assets/image/logout.svg'"></app-image>
            <app-text [class]="'ml-4'">{{ locale?.profile.logoutButton }}</app-text>
        </div>
    </div>
</div>
