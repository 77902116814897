import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {LoaderComponent} from './loader/loader.component';
import {LiveclassCardComponent} from './liveclass-card/liveclass-card.component';
import { PauseAcademyComponent } from './pause-academy/pause-academy.component';

const components = [
    LoaderComponent,
    LiveclassCardComponent,
    PauseAcademyComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GlobalComponentsModule,
    ]
})
export class SharedComponentsModule {}
