import {Component,Input,Output,OnInit,EventEmitter} from '@angular/core';

@Component({
    selector: 'app-playlist-collection-card',
    templateUrl: './playlist-collection-card.component.html',
    styleUrls: ['./playlist-collection-card.component.css']
})
export class PlaylistCollectionCardComponent implements OnInit {
  @Input() oam;
  @Input() index;
  @Input() locale;
  @Output() onClickOam = new EventEmitter<any>();

  ngOnInit(){

  }

  onClickOamCard(data){
    this.onClickOam.emit(data)
  }
}
