import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface DataRequirements {
    getTopicDetail(topicId) : Observable<any>;
    getAllSkills(id, skip, limit) : Observable<any>;
    getAllLearningFlow(id, skip, limit): Observable<any>;
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('topics Data Requirements')
