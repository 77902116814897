import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/skill.mapper";
import * as i2 from "../data.requirements";
export class SkillDetailUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(skillId) {
        return this.data.getSkillDetails(skillId).pipe(this.mapper.map);
    }
}
SkillDetailUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SkillDetailUseCase_Factory() { return new SkillDetailUseCase(i0.ɵɵinject(i1.SkillMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: SkillDetailUseCase, providedIn: "root" });
