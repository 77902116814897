import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {PlayerMapper} from '../mappers/player.mapper';

@Injectable({
    providedIn: 'root'
})
export class LoginPlayerUseCase {
    constructor(
        private mapper: PlayerMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(username, password) {
        return this.data.playerLogin(username, password).pipe(this.mapper.map);
    }
}
