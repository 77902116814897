import {Component, Input} from '@angular/core';
import {LfOamBlockStore} from './lf-oam-block.store'
import { TopicModel } from '../../models/topic.model';

@Component({
    selector: 'app-lf-oam-block',
    templateUrl: './lf-oam-block.component.html',
    styleUrls: ['./lf-oam-block.component.css']
})
export class LfOamBlockComponent {
    @Input('item') item: TopicModel = new TopicModel()
    @Input() learningFlow;
    @Input() locale;
    public play = './assets/image/play.png';
    public play_hover = './assets/image/play_hover.png'
    public image = [];
    public totalSkill;
    ngOnInit() {
        this.image = this.learningFlow.skillList;
        this.totalSkill = this.learningFlow.skillList.length;
    }
    // @Input() image = [];
    // @Input() title = "Let's explore how light travels";
    // @Input() duration = '1h 20m';
    // @Input() type = 'Science';
    // @Input() width = '500px';
    // @Input() height = '260px';
    // @Input() author = 'Amdon';
    // @Input() totalSkill = '7';
    @Input() stackPlay = false;
}
