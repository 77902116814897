import { MessageModel } from '../../../models/message.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class MessageMapper extends ItemMapper<MessageModel> {
    protected mapFromItem(item): MessageModel {
        const messageObj = new MessageModel();
        messageObj.message = item.message;

        return messageObj;
    }
}
