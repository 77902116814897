import { SkillModel } from '../../../models/skill.model';
import { TotalSkillTopicPlaylistModel } from '../../../models/total-skill-topic-playlist.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class newSkillMapper extends ItemMapper<TotalSkillTopicPlaylistModel> {
    protected mapFromItem(item): TotalSkillTopicPlaylistModel {
        console.log('skill mapper ', item);
        //return if item comes from data fake
        if(item.skill) return item;

        //map item if it comes from data facade
        const result = new TotalSkillTopicPlaylistModel();
        item.forEach(element => {
            const skillModel= new SkillModel()
            {
                skillModel.id = element.urlPath,
                skillModel.title = null,
                skillModel.url = null,
                skillModel.type = null,
                skillModel.duration = null,
                skillModel.topicId = 'topic'
            }
            if(element.details){
                skillModel.title = element.details.name;
                skillModel.url = element.details.targetFileThumbnailURL;
                skillModel.type = element.details.subject[0];
                skillModel.duration = element.details.duration? element.details.duration : null;//not yet map
            }
            if(element.topicId && element.topicId[0] && element.topicId[0].urlPath)
                skillModel.topicId = element.topicId[0].urlPath;
            result.data.push(skillModel);
        });

        // result.skill = item.total;
        console.log('new skills ',result);
        return result;
    }
}
