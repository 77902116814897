import {PlaylistDetailsModel} from '../../../models/playlist-details.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class PlaylistDetailsMapper extends ItemMapper<PlaylistDetailsModel> {
    protected mapFromItem(item): PlaylistDetailsModel {
      console.log("item in mapper..",item)
        const playlistModel= new PlaylistDetailsModel();
        playlistModel.details._id = item._id;
        playlistModel.details.urlPath = item.urlPath;
        playlistModel.details.uniqueID = item.uniqueID;
        playlistModel.details.name = item.name;
        // playlistModel.details.topicId = item.topicId;
        playlistModel.details.topicId = item.topicInfo? item.topicInfo: null;
        playlistModel.details.author = item.author != undefined ? item.author : null;
        playlistModel.details.duration = item.duration;
        // playlistModel.relatedLearningFlow = [];
        // playlistModel.relatedSkills = [];
        item.skills.map(data => {
          let skill = {
            "_id": data._id,
            "urlPath": data.urlPath,
            "name": data.details != undefined ? data.details.name : null,
            "author": data.details != undefined ? data.details.author : null,
            "subject": data.details != undefined ? data.details.subject : null,
            "fileHeight": data.details != undefined ? data.details.targetFileHeight : null,
            "fileWidth": data.details != undefined ? data.details.targetFileWidth : null,
            "url": data.details != undefined ? data.details.targetFileURL : null,
            "urlIOS": data.details != undefined && data.details.targetFileURLIOS != undefined && data.details.targetFileURLIOS != null ? data.details.targetFileURLIOS : null,
            "thumbnailUrl": data.details != undefined ? data.details.targetFileThumbnailURL : null,
            "description": data.details != undefined ? data.details.description : null,
            // "topicId": data.topicId.length > 0 ? data.topicId[0] : null,
            "topicId": data.topicInfo? data.topicInfo[0].urlPath : null,
            "playlistId": item.urlPath
          };
          playlistModel.details.skills.push(skill);
        })
        if(item.relatedLearningFlow == undefined){
          playlistModel.relatedLearningFlow = [];
        }else{
          item.relatedLearningFlow.map(data => {
            let learningflow = {
              "_id": data._id,
              "urlPath": data.urlPath,
              "name": data.name,
              "duration": data.duration,
              "author": data.author != undefined ? data.author : null,
              "url": data.targetFileURL,
              // "topicId": data.topicId,
              "topicId": data.topicInfo? data.topicInfo.urlPath : null,
              "skillList": []
            };
            data.skills.map(item => {
              if(item.details != undefined){
                let data = {
                  'url': item.details.targetFileThumbnailURL,
                  'urlPath': item.urlPath
                }
                learningflow.skillList.push(data)
              }else{
                learningflow.skillList.push(null)
              }
            })
            playlistModel.relatedLearningFlow.push(learningflow);
          })
        }

        if(item.relatedSkill == undefined){
          playlistModel.relatedSkills = [];
        }else{
          item.relatedSkill.map(data =>{
            let skillObj = {
              "_id": data._id,
              "urlPath": data.urlPath,
              "name": data.details != undefined ? data.details.name : null,
              "author": data.details != undefined ? data.details.author : null,
              "subject": data.details != undefined ? data.details.subject : null,
              "fileHeight": data.details != undefined ? data.details.targetFileHeight : null,
              "fileWidth": data.details != undefined ? data.details.targetFileWidth : null,
              "url": data.details != undefined ? data.details.targetFileURL : null,
              "thumbnailUrl": data.details != undefined ? data.details.targetFileThumbnailURL : null,
              "description": data.details != undefined ? data.details.description : null,
              // "topicId": data.topicId
              "topicId": data.topicInfo? data.topicInfo[0].urlPath : null,
            };
            playlistModel.relatedSkills.push(skillObj);
          })
        }

        return playlistModel;
    }

}
