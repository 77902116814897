import {Inject, Component, OnInit, HostListener} from '@angular/core';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';

import {Router,ActivatedRoute,Params} from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../../lib/shared-service';
import { AuthService } from '../../../lib/auth.service';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from '../../../lib/shared_data.service';
import { from } from 'rxjs';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
    selector: 'app-topic-home',
    templateUrl: './topic-home-controller.component.html',
    styleUrls: ['./topic-home-controller.component.css']
})
export class TopicHomeControllerComponent implements OnInit {
    public skillList;
    public topicDetail;
    public alllearningFlowList;
    public totalSkill;
    public totalPlaylist;
    public isLoadmoreLoading= true;
    public loadingLf = true;
    public loadingtopic = true;
    public topicId;
    public uniId;
    public emptySkill = false;
    public isImgCover:boolean;
    public locale:any;
    public userInfoSubscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _sharedService: SharedService,
        private toastr: ToastrService,
        private _authService: AuthService,
        private _sharedDataService: SharedDataService,
        private _localeService: LocaleService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
      this.userInfoSubscription = this._sharedService.changeUserInfo$.subscribe(
        data=> {
          this.checkLoginData();
        }
      )
    }

    ngOnInit() {
        this._sharedService.setPreviousURL(this.router.url);
        this.route.params.subscribe((params:Params) => {
          this.topicId = params.id;
        })
        this.getTopicDetails(this.topicId);
        this.getUserPermittedApps();
        this.getJson();
    }

    ngOnDestory(){
      this.userInfoSubscription.unsubscribe();
    }

    checkLoginData(){
      let alreadyLoggedin = this._authService.isLoginUser();
      if(!alreadyLoggedin){
        // this.ngOnInit();
        this.getJson();
      }
    }

    getJson(){
      this._localeService.getJson().subscribe(data => {
        this.locale = data;
      })
    }

    getUserPermittedApps(){
      let isAlreadyLogin = this._authService.isLoginUser();
      if(isAlreadyLogin){
        this._sharedDataService.getUserPermittedApps().subscribe(data=>{
          console.log("topic > getUserPermittedApps > data",data);
          this._sharedService.defineUserPermittedApps(data);
          this.getJson();
        })
      }
    }

    getLearningFlowList(skip, limit) {
        this.loadingLf = true;
        this.business.getAllLearningFlow(this.uniId, skip, limit).subscribe(res=>{
            this.loadingLf = false;
            if(skip==0)this.alllearningFlowList = res[0].data;
            else this.alllearningFlowList = [...this.alllearningFlowList, ...res[0].data];
            this.totalPlaylist = res[0].playlist;
            // console.log("this.alllearningFlowList>>",this.alllearningFlowList)
        }, err=>{
            console.log('error', err)
            // this.toastr.error('Fail to load learngin flow!');
            if(this.alllearningFlowList==null) {
                this.alllearningFlowList = [];
                this.totalPlaylist = 0;
            }
            this.loadingLf = false;
        })

    }

    getTopicDetails(topicId){
      this.loadingtopic = true;
      this.business.getTopicDetail(topicId).subscribe(res => {
        this.loadingtopic = false;
        this.topicDetail = res[0];
        // console.log("this.topicDetail>>",this.topicDetail)
        this.uniId = this.topicDetail.uniId;
        this.getLearningFlowList(0,4);
        this.getSkillList(0, 9);
      },err=>{
        console.log("err",err);
        this.loadingtopic = false;
        // this.toastr.error('Fail to load topic!');
        this.router.navigate(['/page-not-found'])
      })


    }

    clickLearningFlowDetail(e) {
      console.log("click Learning flow detail e.id>>",e)
      var alreadyLoggedin = this._authService.isLoginUser();
      var topic_id = e.topicId? e.topicId: this.topicId;
      if(alreadyLoggedin) {
        let obj = this.getTopicObjforNav();
        this._sharedService.definePlaylistNav(obj)
        this.router.navigate(['/' + topic_id + '/' + e.skillId + '/' + e.id ])
      }
      else {
        if(environment.showStartView == true) {
          let url = environment.stemwerkzStartUrl;
          window.open(url,"_blank");
        }else {
          this._sharedService.setEnableLogin({enable: true, url: '/' + topic_id + '/' + e.skillId + '/' + e.id });
        }
      }
    }

    clickSkillDetail(e){
      console.log("click skill detail>>",e)
      var alreadyLoggedin = this._authService.isLoginUser();
      if(alreadyLoggedin) {
        let obj = this.getTopicObjforNav();
        this._sharedService.defineSkillNav(obj);
        this.router.navigate(['/' + e.topicId + '/' + e.id]);
      }
      else {
        if(environment.showStartView == true) {
          let url = environment.stemwerkzStartUrl;
          window.open(url,'_blank');
        }else {
          this._sharedService.setEnableLogin({enable: true, url: '/' + e.topicId + '/' + e.id});
        }
      }
    }

    getTopicObjforNav(){
      let obj = [
        {
          '_id': this.topicDetail.id,
          'name': this.topicDetail.title,
          'url': this.topicDetail.id,
          'type': 'topic'
        }
      ];
      return obj;
    }

    loadMoreSkill(e) {
      this.isLoadmoreLoading = true;
      this.getSkillList(this.skillList.length, 9);
    }

    loadMorelearningFlow(e) {
      this.loadingLf = true;
      this.getLearningFlowList(this.alllearningFlowList.length, 4);
    }

    getSkillList(skip, limit) {
      this.business.getAllSkills(this.uniId, skip, limit).subscribe(res=>{
        if(skip==0) this.skillList = res[0].data;
        else this.skillList = [...this.skillList, ...res[0].data];
        if (res[0].data.length == 0){
          this.emptySkill = true;
        }
        this.totalSkill = res[0].skill;
        this.isLoadmoreLoading = false;
        // console.log("this.skillList>>",this.skillList)
      },err=>{
          // this.toastr.error('Fail to load skill!');
          if(skip==0) this.skillList = [];
          this.isLoadmoreLoading = false;
      });
    }
}
