export class LearningFlowModel {
  id: string = 'xxx'
  title: string = "Cycle In Matter & Water Cycle, Properties Of Matter"
  duration: string = '30 min'
  author: string = 'Amdon'
  skillList: any = ['https://picsum.photos/200/300']
  description: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sit elit molestie cursus a'
  url:  string = 'https://picsum.photos/200/300'
  playlist: any = []
  topicId: string = 'topic-xxx'
  skillId: string = 'skill-xxx'
}
