/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-frame.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../../../global-components/text/text.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../global-components/image/image.component.ngfactory";
import * as i6 from "../../../../../global-components/image/image.component";
import * as i7 from "./login-frame.component";
var styles_LoginFrameComponent = [i0.styles];
var RenderType_LoginFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginFrameComponent, data: {} });
export { RenderType_LoginFrameComponent as RenderType_LoginFrameComponent };
function View_LoginFrameComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "light-body-m text-secondary700 parent-portal-link"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.parentPortalUrl, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.locale.Login[_v.parent.context.$implicit]; _ck(_v, 1, 0, currVal_1); }); }
function View_LoginFrameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-text", [], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LoginFrameComponent_3)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit == "parent_portal"); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ((_v.context.$implicit != "parent_portal") ? _v.context.$implicit : ""); _ck(_v, 4, 0, currVal_1); }); }
function View_LoginFrameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center bg-s10 parent-portal-url-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginFrameComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.locale == null) ? null : _co.locale.Login.parentInfo); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoginFrameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "login-frame bg-s0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "login-img-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "login-logo-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-image", [], null, null, null, i5.View_ImageComponent_0, i5.RenderType_ImageComponent)), i1.ɵdid(4, 114688, null, 0, i6.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 14, "div", [["class", "d-flex flex-column login-form-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "form-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "pb-3 position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "login-mobileview-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-image", [], null, null, null, i5.View_ImageComponent_0, i5.RenderType_ImageComponent)), i1.ɵdid(10, 114688, null, 0, i6.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-text", [["class", "title text-secondary700"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(12, 49152, null, 0, i3.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-image", [], null, null, null, i5.View_ImageComponent_0, i5.RenderType_ImageComponent)), i1.ɵdid(16, 114688, null, 0, i6.ImageComponent, [], { src: [0, "src"], width: [1, "width"], height: [2, "height"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginFrameComponent_1)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "assets/image/STEMWerkzLogo2.png"; var currVal_1 = "100%"; var currVal_2 = "100%"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "assets/image/STEMWerkzLogo2.png"; var currVal_4 = "100%"; var currVal_5 = "100%"; _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "title text-secondary700"; _ck(_v, 12, 0, currVal_6); var currVal_8 = "assets/image/searchClose.svg"; var currVal_9 = "100%"; var currVal_10 = "100%"; _ck(_v, 16, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = !_co.isMobileApp; _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_7 = ((_co.locale == null) ? null : _co.locale.Login.frameTitle); _ck(_v, 13, 0, currVal_7); }); }
export function View_LoginFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-frame", [], null, null, null, View_LoginFrameComponent_0, RenderType_LoginFrameComponent)), i1.ɵdid(1, 114688, null, 0, i7.LoginFrameComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginFrameComponentNgFactory = i1.ɵccf("app-login-frame", i7.LoginFrameComponent, View_LoginFrameComponent_Host_0, { parentPortalUrl: "parentPortalUrl", isMobileApp: "isMobileApp", locale: "locale" }, { onClose: "onClose" }, ["*"]);
export { LoginFrameComponentNgFactory as LoginFrameComponentNgFactory };
