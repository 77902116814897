import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
  getLiveClasses():Observable<any>;
  enrollLiveClass(id):Observable<any>;
  removeLocalStorage(key);
  getTeacherList():Observable<any>;
  getTeacherProfile(teacherId):Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('live-classes Business Requirements')
