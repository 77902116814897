<div class="playlist-small-block cursor" (click)="onSelectLearningflow(data)">
  <div class="playlist-thumbnail">
    <div *ngIf="data.skillList.length == 1">
      <div *ngFor="let skill of data.skillList">
        <app-image *ngIf="skill != undefined" [width]="'275px'" [height]="'150px'" [src]="skill.url" [class]="'skill-thumbnail-cover'"></app-image>
        <div *ngIf="skill == undefined" class="skill-default-1"></div>
      </div>
    </div>
    <div class="d-flex" *ngIf="data.skillList.length == 2">
      <div *ngFor="let skill of data.skillList">
        <app-image *ngIf="skill != undefined" [width]="'275px'" [height]="'150px'" [src]="skill.url" [class]="'skill-thumbnail-cover'"></app-image>
        <div *ngIf="skill == undefined" class="skill-default-2"></div>
      </div>
    </div>
    <div class="d-flex flex-wrap skill-list-wrap" *ngIf="data.skillList.length == 3">
      <div>
        <app-image *ngIf="data.skillList[0] != undefined" [width]="'275px'" [height]="'150px'" [src]="data.skillList[0].url" [class]="'skill-thumbnail-cover'"></app-image>
        <div *ngIf="data.skillList[0] == undefined" class="skill-default-3-left"></div>
      </div>
      <div>
        <app-image *ngIf="data.skillList[1] != undefined" [width]="'275px'" [height]="'73px'" [src]="data.skillList[1].url" [class]="'skill-thumbnail-cover'"></app-image>
        <app-image *ngIf="data.skillList[2] != undefined" [width]="'275px'" [height]="'73px'" [src]="data.skillList[2].url" [class]="'skill-thumbnail-cover'"></app-image>
        <div *ngIf="data.skillList[1] == undefined" class="skill-default-3-right"></div>
        <div *ngIf="data.skillList[2] == undefined" class="skill-default-3-right"></div>
      </div>
    </div>
    <div class="d-flex flex-wrap skill-list-wrap" *ngIf="data.skillList.length == 4">
      <div class="four-item-grid" *ngFor="let skill of data.skillList">
        <app-image *ngIf="skill != undefined" [width]="'275px'" [height]="'73px'" [src]="skill.url" [class]="'skill-thumbnail-cover'"></app-image>
        <div *ngIf="skill == undefined" class="skill-default-4"></div>
        <div class="text-s0 light-body-m more-item" *ngIf="moreItems > 0">+ {{moreItems}} {{ locale?.playlistDetail.moreItems }}</div>
      </div>
    </div>
    <div class="play-btn">
      <app-image [src]="'./assets/image/play.png'" [class]="'play_icon'" [maxWidth]="'36px'"></app-image>
    </div>
    <div class="play-hover-btn">
      <app-image [src]="'./assets/image/play_hover.png'" [class]="'play_hover_icon'" [maxWidth]="'36px'"></app-image>
    </div>
  </div>
  <div>
    <app-text class="playlist-title h5-strong">{{data.name}}</app-text>
  </div>
  <div class="d-flex">
    <!-- <app-text class="text-c100 caption">{{data.author}}</app-text>
    <div *ngIf="data.author && data.author != ''" class="small-dot"></div> -->
    <app-text class="text-c100 caption-light">{{skillListLength>1? skillListLength+' '+ locale?.STEM_story.skills : skillListLength+' '+locale?.STEM_story.skill}}</app-text>
  </div>
</div>
