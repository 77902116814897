import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/search-learning-flow.mapper";
import * as i2 from "../data.requirements";
export class GetSearchLearningFlowUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(keyword, skip, limit) {
        return this.data.getSearchLearningFlow(keyword, skip, limit).pipe(this.mapper.map);
    }
}
GetSearchLearningFlowUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetSearchLearningFlowUseCase_Factory() { return new GetSearchLearningFlowUseCase(i0.ɵɵinject(i1.SearchLearningFlowMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetSearchLearningFlowUseCase, providedIn: "root" });
