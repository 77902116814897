import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/user.mapper";
import * as i2 from "../data.requirements";
export class UserInfoUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run() {
        return this.data.getUserInfo().pipe(this.mapper.map);
    }
}
UserInfoUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserInfoUseCase_Factory() { return new UserInfoUseCase(i0.ɵɵinject(i1.UserMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: UserInfoUseCase, providedIn: "root" });
