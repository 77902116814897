import {InjectionToken} from '@angular/core';

import {Observable} from 'rxjs';

export interface BusinessLogicRequirements {
  loginPlayer(username, password) : Observable<any>;
  setLocalstorage(key, value);
  removeLocalstorage(key);
  getSearchTopic(keyword) : Observable<any>;
  getSearchLearningFlow(keyword, skip, limit) : Observable<any>;
  getSearchSkill(keyword, skip, limit) : Observable<any>;
  getUserInfo() : Observable<any>;
  getPermittedApps(userId): Observable<any>;
  getLocalstorage(key);
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('dashboard Business Requirements')
