export class TeacherProfile{
  profile:any = {
    name: 'xxx xxx xxx',
    imgUrl: 'xxxxxx.xxx',
    location: 'xxxxxx',
    position: 'xxxxx xxxx xxxx',
    bio: 'xxxx xxxxx xxxx xxxx xxxx',
    teacherId: 'xxxxxxxxxxxx',
  }
  classes:any = [];
}
