import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/playlist-details.mapper";
import * as i2 from "../data.requirements";
export class PlaylistDetailUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(playlistId) {
        return this.data.getLearningflowDetails(playlistId).pipe(this.mapper.map);
    }
}
PlaylistDetailUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlaylistDetailUseCase_Factory() { return new PlaylistDetailUseCase(i0.ɵɵinject(i1.PlaylistDetailsMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: PlaylistDetailUseCase, providedIn: "root" });
