import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';

export interface BusinessLogicRequirements {
    getTopicDetail(topicId) : Observable<any>;
    getAllSkills(id, skip, limit): Observable<any>;
    getAllLearningFlow(id, skip, limit): Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('topics Business Requirements')
