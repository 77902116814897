import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SkillService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl + 'api/v1/';
    }
    getAllSkills(skip, limit) {
        let url = this.baseUrl + 'skills';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                skip: skip.toString(),
                limit: limit.toString()
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            console.log("result>>", result);
            return result;
        }));
    }
    getWeeklySkills() {
        let url = this.baseUrl + 'skills/weekly';
        return this.http.get(url).pipe(map((res) => {
            // res["skills"].push(this.tempData);
            // res["skills"].push(this.tempData);
            // res["skills"].push(this.tempData);
            let result = [];
            result.push(res);
            console.log('new siills ', result);
            return result;
        }));
    }
}
SkillService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SkillService_Factory() { return new SkillService(i0.ɵɵinject(i1.HttpClient)); }, token: SkillService, providedIn: "root" });
