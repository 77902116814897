import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
  getSkillDetails(skillId):Observable<any>;
  trackUserActivity(data):Observable<any>;
  getTopicDetails(topicId):Observable<any>;
  updateLocale(data):Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('skills Business Requirements')
