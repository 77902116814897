export class SkillDetailsModel{
  details:any = {
    "_id": '5f3ccb33f099970012b97522',
    "name": 'The specific events in a 3-stage bean plant life cycle ~ ',
    "url": 'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
    "urlIOS": null,
    "oamLocales": null,
    "thumbnailUrl": 'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
    "fileWidth": "1024",
    "fileHeight": "650",
    "description": "This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle.",
    "topicId": "topic-xxx",
    "aerReferenceID": "aerReferenceID-xxx",
  }
  upNext:any = [
    {
      "_id":'5f3ccb33f099970012b97511',
      "name":'The specific events in a 3-stage bean plant life cycle ~ ',
      "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
      "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
      "fileWidth":"1024",
      "fileHeight":"650",
      "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle.",
      "topicId": "topic-xxx"
    },
    {
      "_id":'5f3ccb33f099970012b97533',
      "name":'The specific events in a 3-stage bean plant life cycle ~ ',
      "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
      "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
      "fileWidth":"1024",
      "fileHeight":"650",
      "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle.",
      "topicId": "topic-xxx"
    },
    {
      "_id":'5f3ccb33f099970012b97544',
      "name":'The specific events in a 3-stage bean plant life cycle ~ ',
      "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
      "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
      "fileWidth":"1024",
      "fileHeight":"650",
      "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle.",
      "topicId": "topic-xxx"
    },
    {
      "_id":'5f3ccb33f099970012b97588',
      "name":'The specific events in a 3-stage bean plant life cycle ~ ',
      "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
      "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
      "fileWidth":"1024",
      "fileHeight":"650",
      "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle.",
      "topicId": "topic-xxx"
    }
  ]
  relatedSkills:any = [
    {
      "_id":'5f3ccb33f099970012b97211',
      "name":'Related Skills - The specific events in a bean plant ~ ',
      "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
      "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
      "fileWidth":"1024",
      "fileHeight":"650",
      "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle.",
      "topicId": "topic-xxx"
    },
    {
      "_id":'5f3ccb33f099970012b97333',
      "name":'Related Skills - The specific events in a bean plant ~ ',
      "url":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/LPA-04-05/Assets/index.html',
      "thumbnailUrl":'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png',
      "fileWidth":"1024",
      "fileHeight":"650",
      "description":"This is an interactive learning object in which students learn the specific events that take and do not take place in a 3-stage bean plant life cycle.",
      "topicId": "topic-xxx"
    }
  ]
}
