import {Component,Input,Output,EventEmitter,OnChanges} from '@angular/core';

@Component({
    selector: 'app-playlist-related-skills',
    templateUrl: './playlist-related-skills.component.html',
    styleUrls: ['./playlist-related-skills.component.css']
})
export class PlaylistRelatedSkillsComponent implements OnChanges {
  @Input() relatedSkills;
  @Input() loading;
  @Input() locale;
  @Output() onClickRelatedSkill = new EventEmitter<any>();

  ngOnChanges(){
    // console.log("PlaylistRelatedSkills value change~~~",this.relatedSkills)
    console.log("loading skill---",this.loading,this.locale)
  }

  onClickSkill($event){
    this.onClickRelatedSkill.emit($event)
  }
}
