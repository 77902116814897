<div class="learning-flow-list bg-bg33 d-flex flex-column align-items-center">
    <div class="topic-bottom" [ngClass]="sort!=2 && topic>0? 'height-100' : 'height-60'">
        <app-image [src]="sort!=2 && topic>0? 'assets/image/search_topic_bottom_border.svg': 'assets/image/search_result_bottom_border.svg'" [width]="'100%'"></app-image>
    </div>
    <app-brush [brushColor]="'bg-1A'" [color]="'text-s70'" [width]="'200'" [height]="'30'" [center]="'justify-content-center'">
        {{learningFlowCount}} {{learningFlowCount>1? locale?.STEM_path.playlists: locale?.STEM_path.playlist}}
    </app-brush>
    <div class="learning-flow-center row" [ngStyle]="{'max-width': learningFlowList.length==1? '566px': ''}">
        <app-lf-oam-block class="cursor" *ngFor="let item of learningFlowList" [learningFlow]="item" [locale]="locale" (click)="onPlaylistClick(item)"></app-lf-oam-block>
    </div>
    <app-button *ngIf="learningFlowCount>learningFlowList.length" [height]="'56px'" [class]="'button-primary'" [width]="'322px'" [loading]="loading" (onClick)="onLoadMore($event)">
        <app-text class="text-s0 mr-13 body-l">{{loading? locale?.loading+'...' : locale?.loadMore+'...'}}</app-text>
        <app-image *ngIf="!loading" [src]="'assets/image/downArrow.svg'"></app-image>
    </app-button>
</div>
<div class="lf-bottom">
    <app-image [src]="'assets/image/recommended_bottom_border.svg'" [width]="'100%'"></app-image>
</div>
