import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ClassService } from './api-services/class.service';
import { TeacherProfileService } from './api-services/teacher-profile.service';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
        private teacherApiService: TeacherProfileService,
        private classApiService: ClassService
    ) {}

    getLiveClasses():Observable<any> {
      return this.classApiService.getLiveClasslist();
    }

    enrollLiveClass(classid):Observable<any> {
      return this.classApiService.enrollLiveClass(classid);
    }

    removeLocalStorage(key){
      localStorage.removeItem(key);
    }

    getTeacherList():Observable<any>{
      return this.teacherApiService.getTeacherList();
    }

    getTeacherProfile(teacherId):Observable<any>{
      return this.teacherApiService.getTeacherProfile(teacherId);
    }
}
