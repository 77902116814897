import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface DataRequirements {
  getLiveClasses():Observable<any>;
  enrollLiveClass(classid):Observable<any>;
  removeLocalStorage(key);
  getTeacherList():Observable<any>;
  getTeacherProfile(teacherId):Observable<any>;
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('live-classes Data Requirements')
