/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upnext-skills-collection.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/oam-small-block/oam-small-block.component.ngfactory";
import * as i3 from "../../../../../global-components/oam-small-block/oam-small-block.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../global-components/text/text.component.ngfactory";
import * as i7 from "../../../../../global-components/text/text.component";
import * as i8 from "./upnext-skills-collection.component";
var styles_UpnextSkillsCollectionComponent = [i0.styles];
var RenderType_UpnextSkillsCollectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpnextSkillsCollectionComponent, data: {} });
export { RenderType_UpnextSkillsCollectionComponent as RenderType_UpnextSkillsCollectionComponent };
function View_UpnextSkillsCollectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-oam-small-block", [], null, [[null, "onClickSkill"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClickSkill" === en)) {
        var pd_0 = (_co.onSelectUpnextSkill(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_OamSmallBlockComponent_0, i2.RenderType_OamSmallBlockComponent)), i1.ɵdid(2, 114688, null, 0, i3.OamSmallBlockComponent, [i4.DomSanitizer], { oamData: [0, "oamData"] }, { onClickSkill: "onClickSkill" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_UpnextSkillsCollectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "upnext-view"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpnextSkillsCollectionComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.upnextSkills; _ck(_v, 2, 0, currVal_0); }, null); }
function View_UpnextSkillsCollectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [["class", "body-l text-c100"]], null, null, null, i6.View_TextComponent_0, i6.RenderType_TextComponent)), i1.ɵdid(2, 49152, null, 0, i7.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "body-l text-c100"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.locale == null) ? null : _co.locale.STEM_story.noSkill); _ck(_v, 3, 0, currVal_1); }); }
export function View_UpnextSkillsCollectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "pt-2 pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [["class", "text-u text-c100 body-m mt-2 mb-2"]], null, null, null, i6.View_TextComponent_0, i6.RenderType_TextComponent)), i1.ɵdid(2, 49152, null, 0, i7.TextComponent, [], { class: [0, "class"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpnextSkillsCollectionComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpnextSkillsCollectionComponent_3)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text-u text-c100 body-m mt-2 mb-2"; _ck(_v, 2, 0, currVal_0); var currVal_2 = (((_co.upnextSkills == null) ? null : _co.upnextSkills.length) > 0); _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_co.upnextSkills == null) ? null : _co.upnextSkills.length) == 0); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.locale == null) ? null : _co.locale.skillDetail.upNext); _ck(_v, 3, 0, currVal_1); }); }
export function View_UpnextSkillsCollectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upnext-skills-collection", [], null, null, null, View_UpnextSkillsCollectionComponent_0, RenderType_UpnextSkillsCollectionComponent)), i1.ɵdid(1, 573440, null, 0, i8.UpnextSkillsCollectionComponent, [], null, null)], null, null); }
var UpnextSkillsCollectionComponentNgFactory = i1.ɵccf("app-upnext-skills-collection", i8.UpnextSkillsCollectionComponent, View_UpnextSkillsCollectionComponent_Host_0, { upnext: "upnext", locale: "locale" }, { onClickUpnextSkill: "onClickUpnextSkill" }, []);
export { UpnextSkillsCollectionComponentNgFactory as UpnextSkillsCollectionComponentNgFactory };
