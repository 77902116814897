<div class="app-form-input d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center mb-2 mt-2">
      <app-text class="h4-strong text-c300">{{leftTitle}}</app-text>
      <app-text class="body-m text-secondary700 cursor" (click)="clickText()">{{rightTitle}}</app-text>
    </div>
    <div class="input-wrap bg-s10 mb-1" [ngClass]="error? 'err-border': ''">
        <app-input id="focus-input" [class]="'inputSearch'"
          [type]="type"
          [hint]="placeholder"
          [control]="form.get('data')"
          [width]="'100%'"
          [height] = "'48px'"
          [textClass] = "'input-text'"
        ></app-input>
        <app-image *ngIf="error" class="input-error" [src]="'assets/image/input_error.svg'"></app-image>
    </div>
    <app-text class="light-body-m text-r100 mb-3">{{error}}</app-text>
</div>
