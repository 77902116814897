import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/message.mapper";
import * as i2 from "../data.requirements";
export class EnrollLiveClassUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(classid) {
        return this.data.enrollLiveClass(classid).pipe(this.mapper.map);
    }
}
EnrollLiveClassUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnrollLiveClassUseCase_Factory() { return new EnrollLiveClassUseCase(i0.ɵɵinject(i1.MessageMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: EnrollLiveClassUseCase, providedIn: "root" });
