import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-brush',
    templateUrl: './brush.component.html',
    styleUrls: ['./brush.component.css']
})
export class BrushComponent implements OnInit {
  // public default_w = 166;
  public default_w = 120;
  public default_h = 40;
  @Input() brushColor = 'bg-secondary100';
  @Input() width = 120;
  @Input() height = 40;
  @Input() color = '';
  @Input() margin = '';
  @Input() center='';
  @Input() padding=0;

  ngOnInit() {
    this.default_w = parseFloat((this.width/this.default_w).toFixed(2));
  }

}
