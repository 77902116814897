<!-- <div class="wrapper">
  <div class="line animate" [ngStyle]="{
    'background': 'linear-gradient(to right, ' + blockColor +' 4%, #fff 25%, '+ blockColor + ' 36%)',
    'width': width,
    'height': height
  }"></div>
</div> -->
<div class="wrapper">
  <div class="line animate" [ngStyle]="{
    'background': 'linear-gradient(to right, ' + spinColor +' 4%, #fff 25%, '+ spinColor + ' 36%)',
    'width': width,
    'height': height
  }"></div>
</div>

