import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-profile-header',
    templateUrl: './profile-header.component.html',
    styleUrls: ['./profile-header.component.css']
})
export class ProfileHeaderComponent {
  @Input() profileBgImage;
  @Input() profileBgColor;
  @Input() profileImage;
  @Input() isLoading;
  @Output() onClickBackArrowBtn = new EventEmitter<any>();

  onClickBackArrowButton(){
    this.onClickBackArrowBtn.emit();
  }
}
