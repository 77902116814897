export class TopicdetailModel {
    id: string = 'xxx'
    uniId: string = 'xxx'
    url:  string = 'https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/936b194f175d434180674b95/resources/Default.png'
    title: string = "Cell System"
    author: string = ''
    playlist: any = []
    skill: any = []
    description: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pulvinar tellus\\';
    color:any = {
      "background": "#030D17",
      "text": "#FFFFFF"
    };
}
