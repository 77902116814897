import {PermittedAppsModel} from '../../../models/permitted-app.model';
import {ItemMapper} from '../../../lib/item-mapper';
import {environment} from '../../../../environments/environment';

export class PermittedAppsMapper extends ItemMapper<PermittedAppsModel> {
    protected mapFromItem(item): PermittedAppsModel {
        const permittedAppsModel = new PermittedAppsModel();
        permittedAppsModel.permittedApps = item.permittedApps;
        return permittedAppsModel;
    }
}
