import {Inject, Component, OnInit, HostListener,AfterViewChecked,ChangeDetectorRef} from '@angular/core';
import {Router,ActivatedRoute,NavigationEnd} from '@angular/router';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';

import {SharedService} from '../../../lib/shared-service';
import {AuthService} from '../../../lib/auth.service';
import { environment } from 'src/environments/environment';
import { LocaleService } from '../../../lib/locale.service';
import { DeeplinkService } from '../../../lib/deeplink.service';

@Component({
    selector: 'app-dashboard-home',
    templateUrl: './dashboard-home-controller.component.html',
    styleUrls: ['./dashboard-home-controller.component.css']
})
export class DashboardHomeControllerComponent implements OnInit, AfterViewChecked{

    public control;
    public profileObj = {
      isDisplay: true,
      text: "",
      url: "https://pagewerkz-main.s3.amazonaws.com/user-profile-svg-icons/green_deco_23.png",
      borderColor: ""
    };
    public userObj;
    public previousUrl = null;
    public nextUrl = null;
    public isLogin = false;
    public academyUrl = '/liveclasses';
    public valleyUrl;
    // public stemUrl;
    public data='';
    public sharedUserInfoservice;
    public isZone;
    public stemUrl = environment.stemUrl;
    public loginUrl = environment.membershipUrl + 'login';
    public dashboardUrl;
    public parentLoginUrl;
    public questsUrl;
    public defaultHome = '/';
    public isApp:boolean;
    public showNavBarMenu:boolean = false;
    public mobileOS;
    public browser;
    public permittedApps = [];
    public sharedPermittedAppsService;
    public locale: any;
    public isHomePage:boolean;
    public showSearchInput;
    public openApp:boolean = true;
    public showDefaultOpenApp:boolean = false;
    public appLink;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _sharedService: SharedService,
        private _authService: AuthService,
        private _localeService: LocaleService,
        private _deeplinkService: DeeplinkService,
        private cdRef : ChangeDetectorRef,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

      this.sharedUserInfoservice = this._sharedService.changeUserInfo$.subscribe(
        data => {
            this.setUserProfileData(false);
            this.checkLoginData();
      });

      this.sharedPermittedAppsService = this._sharedService.getUserPermittedApps.subscribe(
        data => {
          console.log("sharedPermittedAppsService",data)
          if(data['permittedApps'] != undefined){
            this.permittedApps = data['permittedApps'].map(item => item.toLowerCase());
            console.log("getUserPermittedApps",this.permittedApps)
          }
      })

      this.router.events.subscribe((val) => {
          if(val instanceof NavigationEnd){
              if(val.url.length == 1){
                this.isHomePage = true;
              }else{
                this.isHomePage = false;
              }

              if(!val.url.includes('/search/')) {
                this._sharedService.definePreviousUrl({url: val.url})
                this.control = null;
              }
              else {
                //For search keyword
                var keyword = this.router.url.split('/search/')[1];
                if(keyword) this.control = decodeURIComponent(decodeURIComponent(keyword));
              }
              if(window.scrollY>0)window.scrollTo({ top: 0 });
              this._sharedService.setEnableLogin({enable: false, url: 'previous'});
          }
      });

      this._sharedService.enableLogin$.subscribe(
        data => {
            if(data['enable']==true) {
              this.isLogin = true;
              this.nextUrl = data['url'];
            }
            else if(data['enable']==false){
              console.log('enable login', data)
              console.log('enable login this.nextUrl', this.nextUrl)
              // this.getPermittedApps();
              // this.getJson();
              this.isLogin = false;
              if(data['url']=='previous') console.log('nothing do');
              else if(this.nextUrl) this.router.navigate([this.nextUrl]);
            }
      });
    }

    @HostListener("window:scroll", [])
    onWindowScroll() {
      //we'll do some stuff here when the window is scrolled
      if(window.scrollY > 180 && this.isHomePage == true){
        this.showSearchInput = true;
      }else{
        this.showSearchInput = false;
      }
    }

    ngOnDestroy() {
      this.sharedUserInfoservice.unsubscribe();
      this.sharedPermittedAppsService.unsubscribe();
    }

    ngAfterViewChecked() {
      this.cdRef.detectChanges();
    }

    ngOnInit(){
      console.log("dashborad works");
      this.setUserProfileData(true);
      this._sharedService.isApp.subscribe(data => {
        this.isApp = data['isMobileApp'];
        this.showNavBarMenu = data['showMenu'];
        this.mobileOS = data['mobileOS'].toLowerCase();
        this.browser = data['browser'].toLowerCase();
        console.log("isApp",this.isApp,"showNavBarMenu",this.showNavBarMenu,"mobileOS",this.mobileOS );
      })
      this.getJson();
      this.appLink = this._deeplinkService.getAppLink();
    }

    @HostListener('document:click',['$event'])
    onClick(e){
        var target = e.target;
        if (!target.closest(".user-btn")) {
            let userDiv = document.getElementById("user-toogle");
            if(userDiv && userDiv.style.display == 'block'){
              userDiv.style.display = 'none';
            }
        }
        if (!target.closest(".zone-btn")) {
            let zoneDiv = document.getElementById("zone-toogle");
            if(zoneDiv && zoneDiv.style.display == 'block'){
              zoneDiv.style.display = 'none';
            }
        }
    }

    checkLoginData(){
      let alreadyLoggedin = this._authService.isLoginUser();
      if(!alreadyLoggedin){
        this.getJson();
      }
    }

    getJson(){
      this._localeService.getJson().subscribe(data => {
        this.locale = data;
      })
    }

    // getPermittedApps(){
    //   var isAlreadyLogin = this._authService.isLoginUser();
    //   if(isAlreadyLogin){
    //     let userData = this._authService.getUserInfo();
    //     let userId = userData.userid;
    //     this.business.getPermittedApps(userId).subscribe(data => {
    //       this.permittedApps = data[0].permittedApps;
    //       this.permittedApps = this.permittedApps.map(item => item.toLowerCase())
    //       console.log("this.permittedApps",this.permittedApps)
    //     },err=>{
    //       console.log("err",err);
    //       this.permittedApps = [];
    //     })
    //   }
    // }

    setUserProfileData(isNgOninit) {
        var isAlreadyLogin = this._authService.isLoginUser();
        if(isAlreadyLogin) {
          this.userObj  = this._authService.getUserInfo();
          this.valleyUrl = this._authService.getRedirectValleyUrl();
          this.questsUrl = this._authService.getRedirectQuestsUrl();
          this.dashboardUrl = environment.dashboardUrl;
          if(this.userObj.usertype == '5' || this.userObj.usertype == 5){
            this.parentLoginUrl = this._authService.getRedirectAdminUrl();
          }else{
            this.parentLoginUrl = environment.membershipUrl + 'login';
          }
          console.log('login user data~~~~~~~',this.userObj);
          if(this.userObj.usertype=='3' || this.userObj.usertype==3 || this.userObj.usertype=='5' || this.userObj.usertype==5 || !this.userObj.usertype){
              this.business.getUserInfo().subscribe(res=>{
                  this.profileObj.text = res[0] && res[0].text? res[0].text: this.userObj.username.substring(0,2);
                  if(res[0] && res[0].profileImg){
                    this.profileObj.url = res[0].profileImg;
                    this.profileObj.borderColor = res[0].profileImgBorder;
                  }
                  console.log("user>locale~~~~~~~",res[0])
                  let language = JSON.parse(this.business.getLocalstorage("userLocale"));
                  let currentLanguage = this.business.getLocalstorage("current_locale");
                  let currentLocale = res[0].currentLocale == undefined || res[0].currentLocale == null ? res[0].locale : res[0].currentLocale;
                  let user_locale = {
                    "locale": res[0].locale,
                    "secondaryLocale": res[0].secondaryLocale
                  }
                  if(language == undefined || (language.locale != res[0].locale || language.secondaryLocale != res[0].secondaryLocale)){
                    this.business.setLocalstorage("userLocale",JSON.stringify(user_locale));
                    if(currentLanguage != res[0].locale || currentLanguage != res[0].secondaryLocale){
                      this.business.setLocalstorage("current_locale",currentLocale);
                    }
                  }
                  this.getJson();
              }, err=>{
                  console.log('login error',err);
                  this.onClickLogout();
                  this.profileObj.text = null;
                  this.business.removeLocalstorage('locale');
              });
          }else if(isNgOninit && this.userObj.usertype) {
            // this.onClickLogout();
            this._sharedService.setEnableLogin({enable: true, url: 'previous'});
          }
        }
        else this.profileObj.text = null;
    }

    getSearchValue(val){
      console.log("search val>>",val)
        this.control = val;
        if(val == null || val == ''){
          this._sharedService.prevSearchUrl.subscribe(data => {
            this.previousUrl = data['url'] != null && data['url'] != undefined ? data['url'] : '';
            this.router.navigate([this.previousUrl]);
          })
        }
    }

    searchData(){
      console.log("call searching ....");
      if(this.control == null || this.control == ''){
          this._sharedService.prevSearchUrl.subscribe(data => {
            this.previousUrl = data['url'] != null && data['url'] != undefined ? data['url'] : '';
            this.router.navigate([this.previousUrl]);
          })
      }else {
          this.router.navigate(['/search/' + encodeURIComponent(this.control)]);
      }
    }

    onClickLogo() {
        console.log('on click stemwerkz logo');
        this.control = null;
        this.router.navigate(['']);
        this._sharedService.definePlaylistNav([]);
        this._sharedService.defineSkillNav([]);
    }

    onClickLogout() {
        console.log('on click logout');
        this.business.removeLocalstorage('userInfo');
        this._sharedService.emitUserInfo(true);
        window.location.href = environment.portalLogoutUrl;
    }
    goKidLogin(e){
      console.log("goKidLogin>>")
      this._sharedService.setEnableLogin({enable: true, url: this.router.url});

    }
    // clickChannel(e){
    //   console.log("clickChannel>>")
    //   this.control = null;
    //   this.router.navigate(['']);
    //   this._sharedService.definePlaylistNav([]);
    //   this._sharedService.defineSkillNav([]);
    // }

    toggleDropdown(type){
        console.log("toggleDropdown",type);
        if(type == 'user'){
            let userDiv = document.getElementById("user-toogle");
            console.log("user.style.display>>",userDiv.style.display)
            if (userDiv.style.display == 'none' || userDiv.style.display == null || userDiv.style.display == '') {
              userDiv.style.display = 'block';
            }
            else {
              userDiv.style.display = 'none';
            }
        }
        if(type == 'zone'){
            let zoneDiv = document.getElementById("zone-toogle");
            console.log("zone.style.display>>",zoneDiv.style.display)
            if (zoneDiv.style.display == 'none' || zoneDiv.style.display == null || zoneDiv.style.display == '') {
              zoneDiv.style.display = 'block';
            }
            else {
              zoneDiv.style.display = 'none';
            }
        }
    }

    showDefaultBanner(){
      this.openApp = false;
      this.showDefaultOpenApp = true;
    }


    triggerAppOpen(type) {
      if(type == 'default-banner'){
        // this.showDefaultOpenApp = false
      }else if(type == 'bottom-openapp-banner'){
        this.openApp = false;
        this.showDefaultOpenApp = true;
      }
      this._deeplinkService.fallbackToStore(this.mobileOS)
      // this._deeplinkService.deepLink(this.mobileOS,this.browser,this.locale.openInApp.appstoreRedirectConfirm)
    }

}
