import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import {DataRequirementsFake} from './data.requirements.fake';

import {PlaylistDetailUseCase} from './use-cases/playlist-detail.use-case';
import {SkillsDetailsUseCase} from './use-cases/skills-details.use-case';
import {UserActivityUseCase} from './use-cases/user-activity.use-case';
import {TopicDetailsUseCase} from './use-cases/topic-details.use-case';
import { UpdateLocaleUseCase } from './use-cases/update-locale.use-case';

import {PlaylistDetailsMapper} from './mappers/playlist-details.mapper';
import {SkillsDetailsMapper} from './mappers/skills-details.mapper';
import {TopicDetailsMapper} from './mappers/topic-details.mapper';

import { from } from 'rxjs';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
            // useClass: DataRequirementsFake
        },
        PlaylistDetailsMapper,
        SkillsDetailsMapper,
        TopicDetailsMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
      private playlistDetailUsecase: PlaylistDetailUseCase,
      private skillsDetailsUsecase: SkillsDetailsUseCase,
      private userActivityUsecase: UserActivityUseCase,
      private topicDetailsUsecase: TopicDetailsUseCase,
      private updateLocaleUsecase: UpdateLocaleUseCase
    ) {}

    getLearningflowDetails(playlistId){
      return this.playlistDetailUsecase.run(playlistId);
    }

    getSkillsDetails(skillId,playlistId){
      return this.skillsDetailsUsecase.run(skillId,playlistId);
    }

    trackUserActivity(data){
      return this.userActivityUsecase.run(data);
    }

    getTopicDetails(topicId){
      return this.topicDetailsUsecase.run(topicId);
    }

    updateLocale(data){
      return this.updateLocaleUsecase.run(data);
    }
}
