<div class="overlay"></div>
<div *ngIf="!parent" class="login-wrap" [ngClass]="{'loading-evt': isLoading}">
    <app-login-frame [locale]="locale" [parentPortalUrl]="parentPortalUrl" [isMobileApp]="isApp" (onClose)="onClickClose()">
        <form (keydown.enter)="$event.preventDefault(); loginEnterKeyEvt()">
            <app-login-input [locale]="locale" [placeholder]="locale?.Login.usernamePlaceHolder" [type]="'text'" [leftTitle]="locale?.Login.usernameLabel" (onInputChange)="onInputChange($event, 'username')" [error]="usernameError"></app-login-input>
            <app-login-input [locale]="locale" [placeholder]="locale?.Login.passwordPlaceHolder" [type]="isShowPwd? 'text': 'password'" [rightTitle]="isShowPwd? locale?.Login.hide: locale?.Login.show" [leftTitle]="locale?.Login.passwordLabel" [error]="loginError"
            (onChange)="onShowPwd($event)" (onInputChange)="onInputChange($event, 'password')"></app-login-input>
        </form>
        <div *ngIf="password && username" class="mb-4 mt-1">
          <app-button [class]="'button-primary'" [width]="'100%'" [height]="'48px'" [disabled]="password && username? false: true" [loading]="isLoading" (onClick)="login()"> <app-text>{{isLoading? locale?.pleaseWait+'...': locale?.Login.login}}</app-text> </app-button>
        </div>
        <div *ngIf="!password || !username" class="mb-4 mt-1">
          <app-button [class]="'button-primary'" [width]="'100%'" [height]="'48px'" [disabled]="password && username? false: true" [loading]="isLoading"> <app-text>{{isLoading? locale?.pleaseWait+'...': locale?.Login.login}}</app-text> </app-button>
        </div>
        <app-button [class]="'button-tertiary'" [width]="'100%'" [height]="'48px'" [disabled]="isLoading? true: false" (onClick)="onClickClose()"> <app-text>{{locale?.cancel}}</app-text> </app-button>
        <!-- <app-button *ngIf="password && username" [class]="'button-primary'" [width]="'100%'" [height]="'48px'" [disabled]="password && username? false: true" [loading]="isLoading" class="mb-4 mt-2" (onClick)="login()"> <app-text>{{isLoading? 'Please wait...': 'Log in'}}</app-text> </app-button>
        <app-button *ngIf="!password || !username" [class]="'button-primary'" [width]="'100%'" [height]="'48px'" [disabled]="password && username? false: true" [loading]="isLoading" class="mb-4 mt-2"> <app-text>{{isLoading? 'Please wait...': 'Log in'}}</app-text> </app-button>
        <app-button [class]="'button-tertiary'" [width]="'100%'" [height]="'48px'" [disabled]="isLoading? true: false" (onClick)="onClickClose()"> <app-text>Cancel</app-text> </app-button> -->
        <!-- <app-text [width]="'100%'" [cursor]="'pointer'" class="h4-strong forgot-text d-flex align-items-center justify-content-center text-c300">Forgot your password?</app-text> -->
        <div class="text-center pt-4">
          <app-tooltip [tooltipLabel]="locale?.Login.tooltipLabel" [tooltipText]="locale?.Login.tooltipText" [width]="'322px'" [class]="'text-c300 light-body-l tooltiptext'"></app-tooltip>
        </div>
    </app-login-frame>
</div>

<!-- <div *ngIf="parent" class="login-wrap">
    <app-login-parent [loading]="isLoadingParent" [adminUrl]="adminUrl" (onClose)="onClickAdminClose()" (adminLogout)="onClickLogout()"></app-login-parent>
</div> -->




