import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import { environment } from 'src/environments/environment';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {AuthService} from '../../../lib/auth.service';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    constructor(private http: HttpClient,private _authService:AuthService) {}

    private baseUrl = environment.apiurl;
    private api_key = environment.apikey;
    private gameUrl = environment.stemgameapiurl;

    getSearchResult(keyword, area, skip, limit): Observable<any> {
        let url = this.baseUrl + 'api/v1/search';
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            keyword: keyword,
            // area: 'topic',
            area: area,
            skip: skip.toString(),
            limit: limit.toString()
          })
        };
        return this.http.get(url, httpOptions).pipe(map((res:Response) => {
          let result = [];
          result.push(res);
          if(area=="topic") return result[0].result;

          else return result;
        }));
    }
    loginUser(username, password): Observable<any> {
        let url = this.gameUrl + 'login';
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            apikey: this.api_key,
            username: username,
            password: password,
            checksub: 'true'
          })
        };
        return this.http.get(url, httpOptions).pipe(map((res:Response) => {
          let result = [];
          result.push(res)
          return result;
        }));
    }
    userInfo(): Observable<any> {
      let userInfo = this._authService.getUserInfo();
      let url = this.gameUrl + 'api/v2/users/' + userInfo.userid;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          apikey: userInfo.apikey,
          username: userInfo.username,
          password: userInfo.password
        })
      };
      return this.http.get(url, httpOptions).pipe(map((res:Response) => {
        return res;
      }));
  }

  getPermittedApps(userId){
    let userInfo = this._authService.getUserInfo();
    let url = this.gameUrl + 'api/v2/users/permitted-apps'
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: userInfo.apikey,
        username: userInfo.username,
        password: userInfo.password
      })
    };
    return this.http.get(url, httpOptions).pipe(map((res:Response) => {
      let result = [];
      result.push(res);
      return result;
    }));
  }
}
