import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/search-skill.mapper";
import * as i2 from "../data.requirements";
export class GetSearchSkillUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(keyword, skip, limit) {
        return this.data.getSearchSkill(keyword, skip, limit).pipe(this.mapper.map);
    }
}
GetSearchSkillUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetSearchSkillUseCase_Factory() { return new GetSearchSkillUseCase(i0.ɵɵinject(i1.SearchSkillMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetSearchSkillUseCase, providedIn: "root" });
