<div class="d-flex justify-content-center pauseacademy-wrapper">
  <div>
    <div class="d-flex justify-content-center">
      <app-image [src]="'./assets/image/break_icon_2x.png'" [width]="'250px'"></app-image>
    </div>
    <div class="text-center">
      <div class="text-secondary700 h2-strong text-uppercase pt-4">
        Our teachers are going on a break!
      </div>
      <div class="text-c100 label-text pt-4">
        In the meantime, stay tuned for an improved Academy.
      </div>
    </div>
  </div>
</div>
