import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {SkillMapper} from '../mappers/skill.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetAllSkillUseCase {
    constructor(
        private mapper: SkillMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(id, skip, limit) {
      return this.data.getAllSkills(id, skip, limit).pipe(this.mapper.map);
    }
}
