import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {TextComponent} from './text/text.component';
import {ImageComponent} from './image/image.component';
import {ButtonComponent} from './button/button.component';
import {InputComponent} from './input/input.component';
import {SkillItemComponent} from './skill-item/skill-item.component';
import {SearchComponent} from './search/search.component';
import {BrushComponent} from './brush/brush.component';
import {BreadcrumpNavComponent} from './breadcrump-nav/breadcrump-nav.component';
import {TopicItemComponent} from './topic-item/topic-item.component';
import {OamViewComponent} from './oam-view/oam-view.component';
import {OamSmallBlockComponent} from './oam-small-block/oam-small-block.component';
import {LfOamBlockComponent} from './lf-oam-block/lf-oam-block.component';
import {RelatedSkillComponent} from './related-skill/related-skill.component';
import {OamViewDataComponent} from './oam-view-data/oam-view-data.component';
import {PlayViewLoadingComponent} from './play-view-loading/play-view-loading.component';
import {CustomNotificationComponent} from './custom-notification/custom-notification.component';
import {CustomPopupComponent} from './custom-popup/custom-popup.component';
import {TooltipComponent} from './tooltip/tooltip.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import { LanguageSwitchButtonComponent } from './language-switch-button/language-switch-button.component';

const components = [
  TextComponent,
  ImageComponent,
  ButtonComponent,
  InputComponent,
  SkillItemComponent,
  SearchComponent,
  BrushComponent,
  BreadcrumpNavComponent,
  TopicItemComponent,
  OamViewComponent,
  OamSmallBlockComponent,
  LfOamBlockComponent,
  RelatedSkillComponent,
  OamViewDataComponent,
  PlayViewLoadingComponent,
  CustomNotificationComponent,
  CustomPopupComponent,
  TooltipComponent,
  CheckboxComponent,
  LanguageSwitchButtonComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
    ]
})
export class GlobalComponentsModule {}
