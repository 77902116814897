import {Component,Input,Output,EventEmitter,OnInit} from '@angular/core';

@Component({
    selector: 'app-related-skill',
    templateUrl: './related-skill.component.html',
    styleUrls: ['./related-skill.component.css']
})
export class RelatedSkillComponent implements OnInit {
  @Input() skill;
  @Output() onClickSkill = new EventEmitter<any>();

  ngOnInit(){

  }

  onSelectSkill(skill){
    this.onClickSkill.emit(skill);
  }
}
