import {Inject, Component} from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { LocaleService } from 'src/app/lib/locale.service';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found-controller.component.html',
    styleUrls: ['./page-not-found-controller.component.css']
})
export class PageNotFoundControllerComponent {
    constructor(
        private router: Router,
        private _localeService: LocaleService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {}

    public locale:any;

    navigateToHome($event){
      this.router.navigate([''])
    }

    ngOnInit(){
      this.getJson();
    }

    getJson(){
      this._localeService.getJson().subscribe(data => {
        console.log("pageNotFound###############",data)
        this.locale = data;
      })
    }
}
